import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Logger } from '../../logger.service';
import {
    User,
    RoleType,
    ConfigurationTable,
    Role,
    Appointment,
    UserInformation,
    Renting,
    SearchServiceAdvisorDTO,
    UserGroup
} from '../../../shared/entities';
import { AbstractRestService } from '../generic.service';
import { AuthService } from '../../../auth/auth.service';

@Injectable()
export class UserService extends AbstractRestService<User, string> {
    constructor(protected http: HttpClient, protected logger: Logger, private authService: AuthService) {
        super('/user', http, logger);
    }

    public changeRole(user: User, roleIds: String[]): Promise<Role[]> {
        const url = `${this.actionUrl}/${user.id}/change-role`;

        return this.http
            .put(url, roleIds)
            .toPromise()
            .then(response => response as Role[])
            .catch(error => this.handleError(error));
    }

    public getServiceAdvisorsByOutlet(outletId: string): Promise<User[]> {
        const url = `${this.actionUrl}/by-outlet/${outletId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as User[])
            .catch(error => this.handleError(error));
    }

    public getActiveSadvByOutlet(outletId: string): Promise<User[]> {
        return this.getServiceAdvisorsByOutlet(outletId).then(serviceAdvisors =>
            serviceAdvisors.filter(sadv => sadv.active)
        );
    }

    public getActiveSadvByAppointment(appointment: Appointment): Promise<User[]> {
        const url = `${this.actionUrl}/by-appointment`;

        return this.http
            .post(url, appointment)
            .toPromise()
            .then(response => response as User[])
            .catch(error => this.handleError(error));
    }

    public getActiveServiceAdvisors(searchServiceAdvisorDTO: SearchServiceAdvisorDTO): Promise<User[]> {
        const url = `${this.actionUrl}/by-appointment`;

        return this.http
            .post(url, searchServiceAdvisorDTO)
            .toPromise()
            .then(response => response as User[])
            .catch(error => this.handleError(error));
    }

    public getManageableUsers(): Promise<User[]> {
        const url = `${this.actionUrl}/me/manageable-users`;
        const params = new HttpParams().append('roleId', this.authService.getSelectedRole());

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as User[])
            .catch(error => this.handleError(error));
    }

    public getLogged(): Promise<User> {
        const url = `${this.actionUrl}/me`;
        const params = new HttpParams().set('roleId', this.authService.getSelectedRole());

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as User)
            .catch(error => this.handleError(error));
    }

    public getManageableRoles(): Promise<RoleType[]> {
        const url = `${this.actionUrl}/me/manageable-roles`;
        const params = new HttpParams().append('roleId', this.authService.getSelectedRole());

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as RoleType[])
            .catch(error => this.handleError(error));
    }

    public saveConfigurationTable(configurationTableMap: { [index: string]: ConfigurationTable }): Promise<void> {
        const url = `${this.actionUrl}/me/configuration-table`;

        return this.http
            .post(url, configurationTableMap)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public findByDealerAndRole(dealerId: string, roleType: string): Promise<User[]> {
        const url = `${this.actionUrl}/by-dealer/${dealerId}/by-role/${roleType}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as User[])
            .catch(error => this.handleError(error));
    }

    public findByOutletAndRole(outletId: string, roleType: string): Promise<User[]> {
        const url = `${this.actionUrl}/by-outlet/${outletId}/by-role/${roleType}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as User[])
            .catch(error => this.handleError(error));
    }

    public getHelpFileList(): Promise<string[]> {
        const url = `${this.actionUrl}/get-help-by-role`;
        const params = new HttpParams().set('roleId', this.authService.getSelectedRole());

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as string[])
            .catch(error => this.handleError(error));
    }

    public getHelpFile(fileName: string): Promise<Blob> {
        const url = `${this.actionUrl}/get-help-file-by-role`;
        const params = new HttpParams().set('roleId', this.authService.getSelectedRole()).set('fileName', fileName);
        return this.http
            .get(url, { params, responseType: 'blob' as 'json' })
            .toPromise()
            .then(response => response as Blob)
            .catch(error => this.handleError(error));
    }

    public createUserWithInformation(userInformation: UserInformation): Promise<void> {
        const url = `${this.actionUrl}/with-information`;

        return this.http
            .post(url, userInformation)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public updateUserWithInformation(userInformation: UserInformation): Promise<void> {
        const url = `${this.actionUrl}/with-information`;

        return this.http
            .put(url, userInformation)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public getManageableRentingCompany(): Promise<Renting[]> {
        const url = `${this.actionUrl}/me/manageable-Renting-Company`;
        const params = new HttpParams().append('roleId', this.authService.getSelectedRole());

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as Renting[])
            .catch(error => this.handleError(error));
    }

    public getUserGroups(): Promise<UserGroup[]> {
        const url = `${this.actionUrl}/user-groups`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as UserGroup[])
            .catch(error => this.handleError(error));
    }
}
