import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Logger } from './../../logger.service';
import { AbstractRestService } from '../generic.service';
import {
    Customer,
    Vehicle,
    JwtAuthenticationRequest,
    CustomerSearchBean,
    CustomerSearch,
} from './../../../shared/entities';
import { Observable } from 'rxjs';
import { Utils } from '../../utils';

@Injectable()
export class CustomerService extends AbstractRestService<Customer, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/customer', http, logger);
    }

    public getVehicles(customerId: number): Promise<Vehicle[]> {
        const url = `${this.actionUrl}/${customerId}/vehicles`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Vehicle[])
            .catch(error => this.handleError(error));
    }

    public getLogged(fetchAttributes?: string[]): Promise<Customer> {
        let result: Observable<any>;
        const url = `${this.actionUrl}/logged`;

        if (!Utils.isNullOrUndefined(fetchAttributes)) {
            const params = new HttpParams().set(
                'fetch',
                fetchAttributes.reduce((prevVal, currVal) => `${prevVal}-${currVal}`)
            );
            result = this.http.get(url, { params });
        } else {
            result = this.http.get(url);
        }

        return result
            .toPromise()
            .then(response => response as Customer)
            .catch(error => this.handleError(error));
    }

    public repairVehiclesLogged(): Promise<void> {
        const url = `${this.actionUrl}/logged/repair-vehicles`;

        return this.http
            .get(url)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public getLoggedWithFixedOutlet(outletId: string, fetchAttributes?: string[]): Promise<Customer> {
        let result: Observable<any>;
        const url = `${this.actionUrl}/logged`;
        let params = new HttpParams().set('fixedOutlet', outletId);

        if (fetchAttributes) {
            params = params.append(
                'fetch',
                fetchAttributes.reduce((prevVal, currVal) => `${prevVal}-${currVal}`)
            );
        }
        result = this.http.get(url, { params });

        return result
            .toPromise()
            .then(response => response as Customer)
            .catch(error => this.handleError(error));
    }

    public getOneWithAttributes(customerId: string, fetchAttributes?: string[], outletId?: string): Promise<Customer> {
        let result: Observable<any>;
        const url = `${this.actionUrl}/${customerId}`;

        if (fetchAttributes) {
            const params = new HttpParams()
                .set(
                    'fetch',
                    fetchAttributes.reduce((prevVal, currVal) => `${prevVal}-${currVal}`)
                )
                .set('outletId', outletId);

            result = this.http.get(url, { params });
        } else if (outletId) {
            const params = new HttpParams().set('outletId', outletId);
            result = this.http.get(url, { params });
        } else {
            result = this.http.get(url);
        }

        return result
            .toPromise()
            .then(response => response as Customer)
            .catch(error => this.handleError(error));
    }

    public getOneWithAttributesVinVehicle(
        customerId: string,
        vin: string,
        fetchAttributes?: string[],
        outletId?: string
    ): Promise<Customer> {
        let result: Observable<any>;
        const url = `${this.actionUrl}/${customerId}/vin`;

        const requestBody = {          
            vin
        };

        if (fetchAttributes) {
            const params = new HttpParams()
                .set(
                    'fetch',
                    fetchAttributes.reduce((prevVal, currVal) => `${prevVal}-${currVal}`)
                )
                .set('outletId', outletId);

            result = this.http.post(url, requestBody, { params });
        } else if (outletId) {
            const params = new HttpParams().set('outletId', outletId);
            result = this.http.post(url, requestBody, { params });
        } else {
            result = this.http.post(url, requestBody);
        }

        return result
            .toPromise()
            .then(response => response as Customer)
            .catch(error => this.handleError(error));
    }

    public getOneWithOutlet(customerId: string, outletId?: string): Promise<Customer> {
        let result: Observable<any>;
        const url = `${this.actionUrl}/${customerId}`;

        if (outletId) {
            const params = new HttpParams().set('outletId', outletId);
            result = this.http.get(url, { params });
        } else {
            result = this.http.get(url);
        }

        return result
            .toPromise()
            .then(response => response as Customer)
            .catch(error => this.handleError(error));
    }

    public updateFavouriteOutlet(outletId: string): Promise<void> {
        const url = `${this.actionUrl}/favourite-outlet/outlet/${outletId}`;

        return this.http
            .put(url, null)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public changePassword(customerId: string, body: JwtAuthenticationRequest): Promise<void> {
        const url = `${this.actionUrl}/${customerId}/change-password`;

        return this.http
            .put(url, body)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public searchCustomer(customerSearchBean: CustomerSearchBean): Promise<CustomerSearch> {
        return this.http
            .post(`${this.actionUrl}/search`, customerSearchBean)
            .toPromise()
            .then(response => response as CustomerSearch)
            .catch(error => this.handleError(error));
    }

    public getVehicleFromLogged(vehicleId: string): Promise<Vehicle> {
        const url = `${this.actionUrl}/logged/vehicle/${vehicleId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Vehicle)
            .catch(error => this.handleErrorWithErrorPopup(error));
    }

    public getOneWithOutletIdAndVin(outletId: string, vin: string): Promise<Customer> {
        const requestBody = {          
            vin
        };       
        return this.http
            .post(`${this.actionUrl}/outlet/${outletId}/vin/`, requestBody)
            .toPromise()
            .then(response => response as Customer)
            .catch(error => this.handleError(error));
    }

    public getOneWithCustomerAndVin(outletId: string, vin: string, customerId: string): Promise<Customer> {
        const requestBody = {          
            vin
        };       
        return this.http
            .post(`${this.actionUrl}/outlet/${outletId}/customer/${customerId}/vin`, requestBody)
            .toPromise()
            .then(response => response as Customer)
            .catch(error => this.handleError(error));
    }

    createAndReturnId(entity: Customer): Promise<Customer> {
        return this.http
            .post(this.actionUrl, entity)
            .toPromise()
            .then(response => response as Customer)
            .catch(error => this.handleError(error));
    }

    public updateAccountId(customerId: string, newAccountId: string): Promise<void> {
        const url = `${this.actionUrl}/${customerId}/account-id/${newAccountId}`;

        return this.http
            .put(url, newAccountId)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }
}
