import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Logger } from './../logger.service';
import { Utils } from '../utils';

export abstract class AbstractRestService<T, K> {
    constructor(protected actionUrl: string, protected http: HttpClient, protected logger: Logger) {
        this.actionUrl = `${environment.apiUrl}${actionUrl}`;
    }

    getAll(): Promise<T[]> {
        return this.http
            .get(this.actionUrl)
            .toPromise()
            .then(response => response as T[])
            .catch(error => this.handleError(error));
    }

    getOne(id: K): Promise<T> {
        const url = `${this.actionUrl}/${id}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => response as T)
            .catch(error => this.handleError(error));
    }

    create(entity: T): Promise<any> {
        return this.http
            .post(this.actionUrl, entity)
            .toPromise()
            .then(response => response as any)
            .catch(error => this.handleError(error));
    }

    update(entity: T, id: K): Promise<any> {
        const url = `${this.actionUrl}/${id}`;
        return this.http
            .put(url, entity)
            .toPromise()
            .then(response => response as any)
            .catch(error => this.handleError(error));
    }

    delete(id: K): Promise<void> {
        const url = `${this.actionUrl}/${id}`;
        return this.http
            .delete(url)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    protected handleError(error: any): Promise<any> {
        //this.logger.debug('error');
        //this.logger.debug(error);

        return Promise.reject(error);
    }

    protected handleErrorWithErrorPopup(error: any): Promise<any> {
        Utils.showErrorPopup(error);

        return this.handleError(error);
    }

    protected handleErrorWithPopup(error: any): Promise<any> {
        Utils.showPopup(error);

        return this.handleError(error);
    }
}
