import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../auth.service';

@Injectable()
export class LoginGuard {
    constructor(protected authService: AuthService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkLogin();
    }

    private checkLogin(): boolean {
        let result = true;

        if (this.authService.isAuthorized() && this.authService.isRoleSelected()) {
            result = false;
            this.router.navigate([this.authService.getDashboardPath()]);
        }

        return result;
    }
}
