<div class="content-body">
    <router-outlet></router-outlet>
    <div [ngClass]="!isWelcomeTerminal && !isWelcomePage  ? 'content-footer' : 'content-welcome-terminal'">
        <footer *ngIf="!loading && !isWelcomeTerminal" class="container-footer">
            <a [href]="privacyPolicyLink" class="margin-footer-item" target="_blank">
                <span i18n="@@footerPrivacy">Privacy</span>
            </a>
            <a [href]="contactLink" class="margin-footer-item" target="_blank">
                <span i18n="@@footerContacts">Contacts</span>
            </a>
            <a [href]="legalNoteLink" class="margin-footer-item" target="_blank">
                <span i18n="@@footerLegalNotes">Legal notes</span>
            </a>
            <a [href]="homeLink" class="margin-footer-item" target="_blank">
                <span>{{ textHomeLink }}</span>
            </a>
            <a *ngIf="ePaasEnabled" routerLink="/cookies" class="margin-footer-item" target="_self">
                <span>Cookies</span>
            </a>
            <span class="margin-footer-item" target="_blank">{{ vatNumber }}</span>
            <a *ngIf="countryConfiguration.showDSALink" class="margin-footer-item" routerLink="/DSA" i18n="@@footerDSA"> DSA </a>
        </footer>
    </div>
    <ngx-loading [show]="loading" style="z-index: 9999; position: fixed;" [config]="{ fullScreenBackdrop: true }">
    </ngx-loading>
</div>
