import { Directive, ElementRef, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { GeoMarker, LocationComponent, LocationComponentType } from '../entities';
import { CountryConfiguration } from '../../country-configuration';
import { countryConfiguration } from '../../../configuration/country.configuration';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { GoogleMapsLoaderService } from '../google-maps-loader.service';

@Directive({
    selector: '[oasAddressAutocomplete]',
})
export class AddressAutocompleteDirective implements OnInit {
    @Output() private placeChange = new EventEmitter<google.maps.places.PlaceResult>();
    @Output() private addressChange = new EventEmitter<GeoMarker>();
    private addressInput: HTMLInputElement;
    private address: GeoMarker;
    public countryConfiguration: CountryConfiguration;

    constructor(
        element: ElementRef,
        private googleMapsLoaderService: GoogleMapsLoaderService,
        private ngZone: NgZone,
        private countryConfigurationService: CountryConfigurationService
    ) {
        this.addressInput = element.nativeElement;
        this.countryConfiguration = this.countryConfigurationService.getCountryConfiguration();
    }

    public ngOnInit() {
        this.googleMapsLoaderService.load().then(() => {
            this.loadGoogleAutocomplete();
        });
    }

    /**
     * Manage google address autocomplete
     */
    private loadGoogleAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(this.addressInput, {
            types: ['geocode'],
            componentRestrictions: { country: countryConfiguration.countryCode },
        });
        autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
                // get the place result
                const place: google.maps.places.PlaceResult = autocomplete.getPlace();
                this.placeChange.emit(place);

                this.address = {};
                this.address.locationComponentList = [];
                this.address.mapsPlaceId = place.place_id;
                this.address.address = this.formattedAddress(place.address_components);

                if (place.geometry) {
                    this.address.latitude = place.geometry.location.lat();
                    this.address.longitude = place.geometry.location.lng();
                }

                if (place.address_components) {
                    for (const addressComponent of place.address_components) {
                        this.manageAddressComponent(addressComponent);
                    }
                }

                this.addressChange.emit(this.address);
            });
        });
    }

    private manageAddressComponent(addressComponent: google.maps.GeocoderAddressComponent): void {
        if (addressComponent.types.includes('postal_code')) {
            this.address.zipCode = addressComponent.short_name;
        } else if (addressComponent.types.includes('country')) {
            this.address.country = {
                code: addressComponent.short_name,
                englishName: addressComponent.long_name,
                defaultName: addressComponent.long_name,
            };
            this.address.locationComponentList.push(this.createLocationComponent(addressComponent, 0, 'country'));
        } else if (addressComponent.types.includes('administrative_area_level_1')) {
            this.address.locationComponentList.push(
                this.createLocationComponent(addressComponent, 1, 'administrative_area_level_1')
            );
        } else if (addressComponent.types.includes('administrative_area_level_2')) {
            this.address.locationComponentList.push(
                this.createLocationComponent(addressComponent, 2, 'administrative_area_level_2')
            );
        } else if (addressComponent.types.includes('administrative_area_level_3')) {
            this.address.locationComponentList.push(
                this.createLocationComponent(addressComponent, 3, 'administrative_area_level_3')
            );
        } else if (addressComponent.types.includes('administrative_area_level_4')) {
            this.address.locationComponentList.push(
                this.createLocationComponent(addressComponent, 4, 'administrative_area_level_4')
            );
        } else if (addressComponent.types.includes('administrative_area_level_5')) {
            this.address.locationComponentList.push(
                this.createLocationComponent(addressComponent, 5, 'administrative_area_level_5')
            );
        } else if (addressComponent.types.includes('locality')) {
            this.address.locationComponentList.push(this.createLocationComponent(addressComponent, 6, 'locality'));
        }
    }

    private createLocationComponent(
        addressComponent: google.maps.GeocoderAddressComponent,
        level: number,
        label: LocationComponentType
    ): LocationComponent {
        return {
            shortName: addressComponent.short_name,
            longName: addressComponent.long_name,
            level,
            label: label,
        };
    }

    private formattedAddress(addressComponents: google.maps.GeocoderAddressComponent[]) {
        let street: string;
        let streetNumber: string;
        if (addressComponents != undefined && addressComponents != null) {
            for (const addressComponent of addressComponents) {
                if (addressComponent.types.includes('route')) {
                    street = addressComponent.long_name;
                }

                if (addressComponent.types.includes('street_number')) {
                    streetNumber = addressComponent.long_name;
                }
            }
        }

        return street + (streetNumber ? ', ' + streetNumber : '');
    }
}
