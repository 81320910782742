import { Directive, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryConfigurationService } from '../../../../configuration/country-configuration.service';
import { environment } from '../../../../environments/environment';
import { Utils } from '../../../core/utils';
import { CountryConfiguration } from '../../../country-configuration';
import { Customer, GeoMarker, LocationComponentType, Mobility } from '../../../shared/entities';
import { Observable } from 'rxjs';


@Directive()
export abstract class OutletMobilityBaseComponent {
    @Input() public mobilities: Mobility[];
    @Input() public customer: Customer;
    @Input() public pickupAndDelivery: Observable<void> = null;

    @Output() public mobilitiesChange = new EventEmitter<Mobility[]>();

    @ViewChild('addressDetailTemplate') addressDetailTemplate: TemplateRef<any>;
    @ViewChild('editPickAddressModal') editPickAddressModal: TemplateRef<any>;
    @ViewChild('editDeliveryAddressModal') editDeliveryAddressModal: TemplateRef<any>;

    public sourceAddress: GeoMarker = { country: {} };
    public destinationAddress: GeoMarker = { country: {} };
    public sourceForm: UntypedFormGroup;
    public destinationForm: UntypedFormGroup;
    public readOnlyAddress = false;

    public _sourceAddress: GeoMarker;
    public _destinationAddress: GeoMarker;
    public countryConfiguration: CountryConfiguration;

    public sourceSlideTimeInfo: string;
    public destinationSlideTimeInfo: string;
    public sourcePreferredReturnTimeStart: string;
    public sourcePreferredReturnTimeEnd: string;
    public destinationPreferredReturnTimeStart: string;
    public destinationPreferredReturnTimeEnd: string;

    public courtesyCarServiceId = environment.courtesyCarServiceId;
    public courtesyMotoServiceId = environment.courtesyMotoServiceId;

    config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
    };
    constructor(public formBuilder: UntypedFormBuilder, protected countryConfigurationService: CountryConfigurationService, public pickUpTime: string, public deliveryTime: string) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
    }

    public initPickupAndDelivery(): void {
        const pickup = this.mobilities && this.mobilities.find(mobility => mobility.mobilityType.id === environment.pickupServiceId);

        if (pickup) {
            this.sourceAddress = Object.assign({}, pickup.address);
            this.destinationAddress = Object.assign({}, pickup.deliveryAddress);
            this._sourceAddress = Object.assign({}, this.sourceAddress);
            this._destinationAddress = Object.assign({}, this.destinationAddress);
        } else {
            this.sourceAddress = Object.assign({}, this.customer.address);
            this.destinationAddress = Object.assign({}, this.customer.address);
            this._sourceAddress = Object.assign({}, this.sourceAddress);
            this._destinationAddress = Object.assign({}, this.destinationAddress);
        }

        this.sourceForm = this.formBuilder.group({
            enabled: pickup ? pickup.pickup : true,
            address: [this.sourceAddress.address, Validators.required],
            stairInt: [this.sourceAddress.stairInt],
            time: (pickup && pickup.pickupHour !== 0) ? pickup.pickupHour.toString().padStart(2, '0') + ':' + pickup.pickupMinute.toString().padStart(2, '0')  : this.pickUpTime,
        });
        this.destinationForm = this.formBuilder.group({
            enabled: pickup ? pickup.delivery : true,
            address: [this.destinationAddress.address, Validators.required],
            stairInt: [this.destinationAddress.stairInt],
            time: (pickup && pickup.deliveryHour !== 0) ? pickup.deliveryHour.toString().padStart(2, '0') + ':' + pickup.deliveryMinute.toString().padStart(2, '0')  : this.deliveryTime,
        });
    }

    public isInvalidForm() {
        if (!this.sourceForm.controls.address.value && this.sourceForm.get('enabled').value) {
            return true;
        } else if (!this.destinationForm.controls.address.value && this.destinationForm.get('enabled').value) {
            return true;
        } else {
            return false;
        }
    }

    public editAddress(): void {
        this.readOnlyAddress = false;
        if (!this.sourceAddress) {
            this.sourceAddress = { country: {} };
        }
        if (!this.destinationAddress) {
            this.destinationAddress = { country: {} };
        }
    }

    public onAddressChange(address: GeoMarker, form: UntypedFormGroup, type: string) {
        form.get('address').setValue(address.address);
        form.get('zipCode').setValue(address.zipCode);
        form.get('country').setValue(address.country.defaultName);
        form.get('town').setValue(this.getLocality(address));

        if (type === 'source') {
            this._sourceAddress = address;
        } else if (type === 'destination') {
            this._destinationAddress = address;
        }
    }

    private getLocality(address: GeoMarker): string {
        let locality;
        if (address.locationComponentList) {
            for (const locationComponent of address.locationComponentList) {
                if (locationComponent.label === <LocationComponentType>'locality') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_3') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_4') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_5') {
                    locality = locationComponent.longName;
                    break;
                }
            }
        }
        return locality;
    }

    public manageNewPickAddress(newAddress: GeoMarker) {
        this._sourceAddress = newAddress;
        this.sourceForm.get('address').setValue(newAddress.address);
        this.sourceForm.get('stairInt').setValue(newAddress.stairInt);
    }

    public manageNewDeliveryAddress(newAddress: GeoMarker) {
        this._destinationAddress = newAddress;
        this.destinationForm.get('address').setValue(newAddress.address);
        this.destinationForm.get('stairInt').setValue(newAddress.stairInt);
    }

    public formGroupSource(enabled: boolean) {
        if (enabled) {
            this.sourceForm.get('time').enable();
        } else {
            this.sourceForm.get('time').disable();
        }
    }

    public formGroupDestination(enabled: boolean) {
        if (enabled) {
            this.destinationForm.get('time').enable();
        } else {
            this.destinationForm.get('time').disable();
        }
    }

    public timeInfo(time: number, standardFormat: boolean = true): string {
        const hour = Utils.twoStringNumber(time);
        const minute = Utils.twoStringNumber(0);

        if (standardFormat) {
            return `${hour}:${minute}`;
        } else {
            return `${hour}h ${minute}m`;
        }
    }

    public isCourtesyCarSelected(): boolean {
        let isSelected = false;
        if (!Utils.isNullOrUndefined(this.mobilities)) {
            isSelected = this.mobilities.find(
                mobility =>
                    mobility.mobilityType.id === this.courtesyCarServiceId ||
                    mobility.mobilityType.id === this.courtesyMotoServiceId
            )
                ? true
                : false;
        }
        return isSelected;
    }
}
