import { Injectable } from '@angular/core';
import { Customer } from '../shared/entities';
import { CustomerService } from '../core/api/customer/customer.service';
import { Utils } from '../core/utils';

@Injectable()
export class CustomerEditResolverService {
    constructor(private customerService: CustomerService) {}

    resolve(): Promise<Customer> {
        return this.customerService
            .getLogged()
            .then()
            .catch(error => {
                Utils.showErrorPopup(error);
                return Promise.reject(error);
            });
    }
}
