import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { OasValidators } from '../../../../core/validator/oas-validators';
import { ValidateOnlineUsernameOnBlurDirective } from '../../../../shared/validation/validate-online-username-on-blur.directive';

@Component({
    selector: 'oas-user-form-edit-email',
    templateUrl: './user-form-edit-email.component.html',
    styleUrls: ['./user-form-edit-email.component.scss'],
})
export class UserFormEditEmailComponent implements OnInit {
    @Input() public accountId: string;
    @Output() public newAccountId = new EventEmitter<string>();
    @Output() public deleteAccountClicked = new EventEmitter<void>();
    @Output() public accountIdUpdated = new EventEmitter<string>();

    constructor(
        protected formBuilder: UntypedFormBuilder,
        private validateOnlineUsernameOnBlurDirective: ValidateOnlineUsernameOnBlurDirective
    ) {}

    public customerEmailForm: UntypedFormGroup;

    ngOnInit() {
        this.customerEmailForm = this.formBuilder.group(
            {
                email: [
                    '',
                    [Validators.required, OasValidators.email, OasValidators.getLatinValidator()],
                    [this.validateOnlineUsernameOnBlurDirective],
                    { updateOn: 'blur' },
                ],
                emailConfirm: [''],
            },
            { validator: this.matchEmail, updateOn: 'blur' }
        );
    }

    private matchEmail(control: AbstractControl): ValidationErrors | null {
        let result: ValidationErrors = null;

        if (control.get('email').value !== control.get('emailConfirm').value) {
            result = { matchEmail: true };
        }
        control.get('emailConfirm').setErrors(result);

        return result;
    }

    public updateAccountId() {
        if (this.customerEmailForm.valid) {
            this.accountIdUpdated.emit(this.customerEmailForm.get('email').value);
            this.customerEmailForm.reset();
        }
    }

    public deleteAccount(): void {
        this.deleteAccountClicked.emit();
    }
}
