import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../../app.component';
import { OasValidators } from '../../../core/validator/oas-validators';
import { AlertErrorComponent } from '../../../shared/alert/alert-error.component';
import { AlertSuccessComponent } from '../../../shared/alert/alert-success.component';
import { AuthService } from './../../../auth/auth.service';

import { dataAnalytics } from '../../../shared/data-analytics';
import { TracyService } from '../../../shared/tracy.service';

@Component({
    selector: 'oas-forgot-password',
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements AfterViewInit {
    @ViewChild('alertError') private alertError: AlertErrorComponent;
    @ViewChild('alertSuccess') private alertSuccess: AlertSuccessComponent;

    public errorResponse: HttpErrorResponse;
    public email = new UntypedFormControl('', [Validators.required, OasValidators.email, OasValidators.getLatinValidator()]);
    public gcdmErrors;
    public activityMapLink: string;
    public activityMapRegion: string;

    constructor(
        private appComponent: AppComponent,
        private router: Router,
        private authService: AuthService,
        private route: ActivatedRoute,
        private tracyService: TracyService
    ) {
       this.gcdmErrors = this.route.snapshot.data.externalSystemIsKo.errors;
        this.setDataAnalytics();
    }

    public ngAfterViewInit(): void {
       if (this.gcdmErrors.length > 0) {
          this.alertError.text = this.gcdmErrors[0].message;
          this.alertError.show().then(() => this.router.navigate(['/backoffice/login']));
       }
    }

    public resetPassword(): void {
        this.appComponent.loading = true;
        this.authService.checkUniqueUsername(this.email.value,'backoffice').subscribe(response => {
            if (response) {
                this.authService
                    .resetPassword(this.email.value)
                    .then(() => {
                        this.appComponent.loading = false;
                        this.alertSuccess.show();
                        this.setAlertDataAnalytics();
                        this.router.navigate(['/backoffice/login']);
                    })
                    .catch((errorResponse: HttpErrorResponse) => {
                        this.appComponent.loading = false;
                        this.alertError.showHttpError(errorResponse);
                    });
            } else {
                this.email.setErrors({ oasUsernameValidator: true });
                this.appComponent.loading = false;
            }
        });
    }

    private setDataAnalytics() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasRecoverPwdSent,
            dataAnalytics.page.variant.real
        );
        this.tracyService.sendPageView(dataAnalytics.referenceName.recoverPwd);
    }

    private setAlertDataAnalytics() {
        this.tracyService.sendPageView(dataAnalytics.referenceName.recoverPwdConfirm);
        this.tracyService.setAlertDataAnalytics(
            dataAnalytics.activityMap.region.oas,
            dataAnalytics.activityMap.link.resetLinkSentSuccess
        );
    }
}
