import { Injectable } from '@angular/core';
import { AbstractRestService } from './generic.service';
import { HeartBeatExternalSystemItem, BaseResponse } from '../../shared/entities';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../logger.service';

@Injectable()
export class HeartbeatExternalSystemService extends AbstractRestService<HeartBeatExternalSystemItem, number> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/heartbeat-external-system', http, logger);
    }

    public getHeartBeatExternalSystemStatus(): Promise<HeartBeatExternalSystemItem[]> {
        const url = `${this.actionUrl}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as HeartBeatExternalSystemItem[])
            .catch(error => this.handleError(error));
    }

    public isExternalSystemKo(): Promise<boolean> {
        const url = `${this.actionUrl}/is-ko`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as boolean)
            .catch(error => this.handleError(error));
    }

    public getExternalSystemStatus(externalSystem: String): Promise<BaseResponse<void>> {
        const url = `${this.actionUrl}/is-ko/${externalSystem}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as BaseResponse<void>)
            .catch(error => this.handleError(error));
    }
}
