import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VehicleService } from '../../core/api/vehicle/vehicle.service';
import { AlertErrorComponent } from '../../shared/alert/alert-error.component';
import { dataAnalytics } from '../../shared/data-analytics';
import { TracyService } from '../../shared/tracy.service';
import { AppComponent } from './../../app.component';
import { AuthService } from './../../auth/auth.service';
import { Vehicle, VehicleModel } from './../../shared/entities';
import { VehicleFormComponent } from 'app/customer/vehicle/vehicle-form.component';

@Component({
    selector: 'oas-vehicle-create-edit',
    templateUrl: './vehicle-create-edit.component.html'
})
export class VehicleCreateEditComponent implements OnInit {
    @ViewChild('alertSuccess') private alertSuccess: AlertErrorComponent;
    @ViewChild('alertError') private alertError: AlertErrorComponent;

    public errorResponse: HttpErrorResponse;
    public selectedVehicle: VehicleModel;
    public vehicle: Vehicle;
    public novehicles: boolean;
    public submitted = false;
    public vin: string;

    constructor(
        private appComponent: AppComponent,
        private route: ActivatedRoute,
        private vehicleService: VehicleService,
        private router: Router,
        private authService: AuthService,
        private tracyService: TracyService
    ) { }

    public ngOnInit() {
        this.novehicles = this.route.snapshot.paramMap.get('novehicles') === 'true';
        this.vehicle = this.route.snapshot.data.vehicle;
        if (this.vehicle) {
            this.selectedVehicle = this.vehicle.model;
            this.vin = this.vehicle.vin;
        }
        this.setDataAnalyticsForAddVehicle();
    }

    public onSelectedModelChange(vehicleModel: VehicleModel): void {
        this.selectedVehicle = vehicleModel;
    }

    public createOrUpdateVehicle(vehicleFormComponent: VehicleFormComponent): void {
        const vehicleForm: UntypedFormGroup = vehicleFormComponent.vehicleForm;
        this.submitted = true;
        if (this.isFormValid(vehicleForm)) {
            this.appComponent.loading = true;
            let result: Promise<void>;

            if (this.vehicle && this.vehicle.id) {
                result = this.updateVehicle(vehicleForm);
            } else {
                result = this.createVehicle(vehicleForm);
            }

            result
                .then(() => {
                    this.appComponent.loading = false;
                    this.alertSuccess.show().then(() => {
                        this.router.navigate([this.authService.getDashboardPath()]);
                    });
                })
                .catch((errorResponse: HttpErrorResponse) => {
                    this.errorResponse = errorResponse;
                    this.appComponent.loading = false;
                    this.alertError.show().then(() => {
                        this.router.navigate([this.authService.getDashboardPath()]);
                    });
                });
        }
    }

    //check if the displayed formControls are valid
    private isFormValid(vehicleForm: UntypedFormGroup): boolean{
        const vehicleFormDisplayedInputs = Array.from(document.getElementById('vehicle-form').getElementsByTagName("input"));

        for(const element of vehicleFormDisplayedInputs) {
            const formControlName = element.attributes['formcontrolname'].nodeValue;
            if(!vehicleForm.controls[formControlName].valid) {
                return false;
            }
        }

        return true;
    }

    private createVehicle(vehicleForm: UntypedFormGroup): Promise<void> {
        this.vehicle = {};
        this.vehicle.customerId = this.route.snapshot.paramMap.get('customerId');
        this.vehicle.model = { id: vehicleForm.get('modelId').value };
        this.vehicle.plate = vehicleForm.get('plate').value;
        this.vehicle.vin = vehicleForm.get('vin').value;
        if (vehicleForm.contains('mileage')) {
            this.vehicle.mileage = vehicleForm.get('mileage').value;
        }
        this.vehicle.matriculationDate = vehicleForm.get('matriculationDate').value;
        if (vehicleForm.contains('transmission')) {
            if (vehicleForm.get('transmission').value) {
                this.vehicle.transmission = vehicleForm.get('transmission').value;
            }
        }
        // this.addVehicleEvent();
        return this.vehicleService.create(this.vehicle);
    }

    private updateVehicle(vehicleForm: UntypedFormGroup): Promise<void> {
        this.vehicle.model = { id: vehicleForm.get('modelId').value };
        this.vehicle.plate = vehicleForm.get('plate').value;
        this.vehicle.vin = vehicleForm.get('vin').value;
        if (vehicleForm.contains('mileage')) {
            this.vehicle.mileage = vehicleForm.get('mileage').value;
        }
        this.vehicle.matriculationDate = vehicleForm.get('matriculationDate').value;
        if (vehicleForm.contains('transmission')) {
            if (vehicleForm.get('transmission').value) {
                this.vehicle.transmission = vehicleForm.get('transmission').value;
            }
        }
        return this.vehicleService.update(this.vehicle, this.vehicle.id);
    }

    public reset(vehicleForm: UntypedFormGroup) {
        this.selectedVehicle = null;
        vehicleForm.get('modelId').setValue('');
        vehicleForm.get('plate').setValue('');
        vehicleForm.get('vin').setValue('');
        vehicleForm.get('matriculationDate').setValue('');
    }

    // Analytics implementation start
    private setDataAnalyticsForAddVehicle() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasAddVehicle,
            dataAnalytics.page.variant.real
        );

        this.tracyService.setUserInformationIntoDataAnalytics(dataAnalytics.profile.returningStatus.returning);

        this.tracyService.sendPageView(dataAnalytics.referenceName.addVehicle);
    }

    /*private addVehicleEvent() {
        this.tracyService.setEventInformationIntoDataAnalytics(
            dataAnalytics.event.cause.default,
            dataAnalytics.event.effect.vehicleAdded,
            dataAnalytics.event.eventAction.internalClick,
            'na',
            'na',
            dataAnalytics.event.type.ux
        );
        this.tracyService.sendEvent(dataAnalytics.referenceName.accountEdit);
    }*/
}
