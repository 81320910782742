import { CountryConfiguration } from '../app/country-configuration';
import { RoleType } from '../app/shared/entities';
export let brazilCountryConfiguration: CountryConfiguration = {
    language: 'pt',
    countryCode: 'BR',
    locale: 'pt-BR',
    nscCicSearchOnDMS: true,
    welcomeTerminalAppointmentIdenabled: false,
    appointmentCountForSadvInDashboard: false,
    showCarShadowImageInClientSearch:true,
    phoneNumberRegex: /^.*[0-9]{1,}.*$/,
    mobileNumberRegex: [/^.*[0-9]{1,}.*$/],
    vatRegex: /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/,
    fiscalCodeRegex: /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}/,
    dsaFilePath: 'assets/pdf/BR/DSA_Notice.pdf',
    showDSALink: false,
    BMW: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.com.br/pt/footer/footer-section/legal-notice.html',
            contactLink: 'https://www.bmw.com.br/pt/ssl/fale-com-a-bmw.html',
            legalNoteLink: '',
            textHomeLink: 'BMW',
            homeLink: 'https://www.bmw.com.br/pt/index.html',
            vatNumber: '',
        },
        srpCampaignFootNote:
            'Os Pacotes de Serviço apresentam um PVP fechado com um desconto de 15%. Incluem Óleo Original, Peças Originais BMW, mão de obra e IVA à taxa legal em vigor.',
        tssbInfoUrl: 'https://www.bmw.pt/pt/topics/offers-and-services/service-workshop/bmw-teleservices.html',
        supportChatAvailable: false,
        quickAppointment: false,
        ePaas: {
            enabled: true,
            tenantId: 'agendamento_bmw_com_br',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: false,
        remoteKeyReadLink: '',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    MINI: {
        footer: {
            privacyPolicyLink: 'https://www.mini.com.br/pt_BR/home/footer/Legal.html',
            contactLink: 'https://www.bmw.com.br/pt/ssl/fale-com-a-bmw.html',
            legalNoteLink: 'https://www.mini.pt/pt_PT/home/footer/legal.html',
            textHomeLink: 'MINI',
            homeLink: 'https://www.mini.com.br/pt_BR/home.html',
            vatNumber: '',
        },
        srpCampaignFootNote:
            'Os Pacotes de Serviço apresentam um PVP fechado com um desconto de 15%. Incluem Óleo Original, Peças Originais MINI, mão de obra e IVA à taxa legal em vigor.',
        tssbInfoUrl: 'https://www.mini.pt/pt_PT/home/services/service-and-repair/teleservices.html',
        supportChatAvailable: false,
        quickAppointment: false,
        ePaas: {
            enabled: true,
            tenantId: 'agendamento_mini_com_br',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: false,
        remoteKeyReadLink: '',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    BMW_MOTORRAD: {
        footer: {
            privacyPolicyLink: 'https://www.bmw-motorrad.com.br/pt/public-pool/content-pool/privacy.html',
            contactLink: 'https://www.bmw.com.br/pt/ssl/fale-com-a-bmw.html',
            legalNoteLink: 'https://www.bmw-motorrad.pt/pt_PT/home/footer/legal.html',
            textHomeLink: 'BMW Motorrad',
            homeLink: 'https://www.bmw-motorrad.com.br/pt/home.html#/filter-todos',
            vatNumber: '',
        },
        srpCampaignFootNote:
            'Os Pacotes de Serviço apresentam um PVP fechado com um desconto de 15%. Incluem Óleo Original, Peças Originais BMW Motorrad, mão de obra e IVA à taxa legal em vigor.',
        tssbInfoUrl: '',
        supportChatAvailable: false,
        quickAppointment: false,
        longLifeCareMobilityBanner: false,
        ePaas: {
            enabled: true,
            tenantId: 'agendamento_bmw-motorrad_com_br',
        },
        remoteKeyRead: false,
        remoteKeyReadLink: '',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    BMW_I: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.com.br/pt/footer/footer-section/legal-notice.html',
            contactLink: 'https://www.bmw.com.br/pt/ssl/fale-com-a-bmw.html',
            legalNoteLink: '',
            textHomeLink: 'BMW',
            homeLink: 'https://www.bmw.com.br/pt/index.html',
            vatNumber: '',
        },
        srpCampaignFootNote:
            'Os Pacotes de Serviço apresentam um PVP fechado com um desconto de 15%. Incluem Óleo Original, Peças Originais BMW, mão de obra e IVA à taxa legal em vigor.',
        tssbInfoUrl: 'https://www.bmw.pt/pt/topics/offers-and-services/service-workshop/bmw-teleservices.html',
        supportChatAvailable: false,
        quickAppointment: false,
        ePaas: {
            enabled: true,
            tenantId: 'marcacoes-online_bmw_pt',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: false,
        remoteKeyReadLink: '',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    tssb: {
        enable: true,
        enableEmail: true,
        enableRecentPendingAppointmentCheckboxByRoles: new Map<RoleType, boolean>([
            [<RoleType>'ROLE_CIC_NSC_ADMIN', true],
            [<RoleType>'ROLE_CIC_NSC_USER', true],
            [<RoleType>'ROLE_CIC_EP_MANAGER', true],
            [<RoleType>'ROLE_CIC_EP_USER', true],
            [<RoleType>'ROLE_S_ADV', true],
            [<RoleType>'ROLE_S_ASSISTANT', true],
            [<RoleType>'ROLE_ASM', true],
            [<RoleType>'ROLE_RENTING_ADM', true],
            [<RoleType>'ROLE_RENTING_USER', true],
        ]),
    },
    emailQuickAppointment: 'no-reply-oas-br@bmw.com.br',
    showAllCapacityTeams: false,
    waitInOutlet: true,
    mobilityStepAvailability: false,
    enableToros: false,
    editCustomer: false,
    vatNumberValidator: '',
    dcomHealth: true,
    showFastLaneLink: false,
    // Quick appointment REFACTOR
    latitude: -23.533773,
    longitude: -46.62529,
    createVehicleFiscalCode: false,
    dcomValidation: true,
    mapsRadius: 30000,
    mapsZoom: 10,
    updateDMS: false,
    maxDelay: 72,
    daysOfWeek: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
    enableRenting: false,
    enableNscReport: true,
    nscReportFilename: 'APPOINTMENTS_DETAILS_FILE_NAME',
    enableSMS: false,
    sendSMS: false,
    automaticSelectionCbs99999: true,
    enableBsi: true,
    hideAutomaticVehicleRecognitionStepIntoAppointmentWithoutRegistration: true,
    enableKiosk: false,
    enablePPVSync: false,
    enableTjekvik: false,
    enableSharebox: false,
    enableSPOA: false,
    enableVinLookup: false,
    preRegistration: {
        enablePreRegistrationFromDms: true,
        enablePreRegistrationFromFastAppointment: true,
        hideCheckBoxWithoutRegistration: false,
        forceSendEmailWithoutRegistration: false,
    },
    showVinOnlineAppointmentResume: true,
    showFiscalNumberVehicleForm: false,
    servicesBannerEnableWithoutCampaign: true,
    vehicleFormSmartRegistrationType: 'MANUAL',
    showAppointmentWithoutRegistrationVehicleForm: false,
    fastAppointmentMigrationToConsumeCapacity: false,
    dateInputRangeFormat: 'DD/MM/YYYY',
    enableLeadApi: true,
    currencyCode: 'BRL',
    enableEmail: true,
    sendEmail: true,
    leadApiOutlets: ['OUTL_41869','OUTL_33690','OUTL_25921','OUTL_51114','OUTL_41022','OUTL_41752','OUTL_25349','OUTL_36470','OUTL_25332','OUTL_25351','OUTL_32799','OUTL_41442','OUTL_35282','OUTL_33458','OUTL_32007','OUTL_32017','OUTL_43577','OUTL_32008','OUTL_38824','OUTL_43371','OUTL_50862','OUTL_25336','OUTL_36646','OUTL_41416','OUTL_46183','OUTL_36087','OUTL_35262','OUTL_47290','OUTL_43753','OUTL_40568','OUTL_41261','OUTL_40998','OUTL_47560','OUTL_40484','OUTL_50485','OUTL_41062','OUTL_43244','OUTL_48563','OUTL_41675','OUTL_49124','OUTL_51963','OUTL_43755','OUTL_48988','OUTL_41267','OUTL_25410','OUTL_37060','OUTL_44866','OUTL_48610','OUTL_36934','OUTL_38913','OUTL_41397','OUTL_43637','OUTL_41338','OUTL_43720','OUTL_25341','OUTL_25345','OUTL_44026','OUTL_25401','OUTL_35800','OUTL_49769','OUTL_50153','OUTL_50368'],
    leadApiOutletFilterEnable: true,
    leadTssbAsmEnable: true,
    appointmentFastCustomerCreationGenderEnabled:true,
    eitherPhoneOrEmailEnabled:false,
    showOnlineWorkTeam: true,
    workingDaysEnabled:true,
    dropDownLeadStatuses: [
        'assignedToTsp',
        'contacting',
        'postponed'
    ],
    availabilityFilter: false,
    awp: {
        url: 'https://awp.bmwgroup.com/servicedemand?',
        vinParameter: 'Vin=',
        icon: {
            enabled: false,
            url: 'awp:/start?app=vehicledetails&vin=${vin}',
        }
    },
    enableLeadBattery: false,
    enableFastLaneResume: false,
    enableAwpLinkCustomerSearch: false,
    enableAppointmentShareBox: false,
    enableTuga: false
};
