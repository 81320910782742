import { Component, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';

@Component({
    template: ''
})
export abstract class Alert {
    @ViewChild(SwalComponent) protected swal: SwalComponent;

    constructor(@Inject(LOCALE_ID) protected locale: string, public readonly swalTargets: SwalPortalTargets) {}

    public show(): Promise<any> {
        return this.swal.fire().then(result => {
            this.reset();
            return result;
        });
    }

    protected abstract reset(): void;
}
