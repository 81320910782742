import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxLoadingModule } from 'ngx-loading';
import { CountryConfigurationService } from '../../configuration/country-configuration.service';
import { UserDataService } from '../backoffice/shared/user-data.service';
import { _3sService } from './api/3s/3s.service';
import { ActivityService } from './api/activity/activity.service';
import { AppointmentCategoriesService } from './api/appointment-category/appointment-link.service';
import { AppointmentLinkService } from './api/appointment-link/appointment-link.service';
import { AppointmentService } from './api/appointment/appointment.service';
import { AttachmentService } from './api/attachment/attachment.service';
import { BusinessSettingService } from './api/business-setting.service';
import { ConsentService } from './api/consent/consent.service';
import { CountryService } from './api/country/country.service';
import { CustomerService } from './api/customer/customer.service';
import { DealerService } from './api/dealer/dealer.service';
import { DictionaryService } from './api/dictionary.service';
import { EmailReportService } from './api/email-report/email-report.service';
import { FastLaneService } from './api/fast-lane/fast-lane.service';
import { FirstAvailableTimeslotService } from './api/first-available-timeslot/first-available-timeslot.service';
import { FleetVehicleService } from './api/fleet/fleet-vehicle.service';
import { FleetService } from './api/fleet/fleet.service';
import { HeartbeatExternalSystemService } from './api/heartbeat-external-system.service';
import { InfoBoxBsiService } from './api/info-box-bsi.service';
import { InfoBoxService } from './api/info-box.service';
import { KpiAppointmentService } from './api/kpi/kpi-appointment.service';
import { KpiTimeService } from './api/kpi/kpi-time.service';
import { KpiTssbService } from './api/kpi/kpi-tssb.service';
import { KpiLeadService } from './api/kpi/kpi-lead.service';
import { KpiWorkshopService } from './api/kpi/kpi-workshop.service';
import { LeadTimeService } from './api/leadtime/leadtime.service';
import { MechanicService } from './api/mechanic/mechanic.service';
import { MessageService } from './api/message/message.service';
import { MobilityService } from './api/mobility/mobility.service';
import { OutletDistrictService } from './api/outlet/outlet.district.service';
import { OutletService } from './api/outlet/outlet.service';
import { PreRegistrationService } from './api/pre-registration/pre-registration.service';
import { RentingService } from './api/renting/renting.service';
import { ReportService } from './api/report/report.service';
import { ShareBoxService } from './api/share-box/share-box.service';
import { SrpCampaignService } from './api/srp/srp-campaign.service';
import { TechnicalSettingService } from './api/technical-setting.service';
import { TorosService } from './api/toros/toros.service';
import { LeadTssbService } from './api/tssb/lead-tssb.service';
import { TssbService } from './api/tssb/tssb.service';
import { TugaService } from './api/tuga/tuga.service';
import { UserService } from './api/user/user.service';
import { UserInformationService } from './api/userInformation/user-information.service';
import { VehicleModelService } from './api/vehicle/vehicle-model.service';
import { VehicleSeriesService } from './api/vehicle/vehicle-series.service';
import { VehicleService } from './api/vehicle/vehicle.service';
import { WelcomeTerminalService } from './api/welcome-terminal/welcome-terminal.service';
import { WheelService } from './api/wheel/wheel.service';
import { WorkTeamService } from './api/work-team/work-team.service';
import { Logger } from './logger.service';
import { LoggedUser } from './model/loggedUser.service';
import { StyleSwitcherService } from './style-switcher.service';
import {LeadConfigurationService} from "./api/tssb/lead-configuration.service";
import { EventNotificationService } from './api/event-notification.service';
import { ReturnDateConfigService } from './api/return-date-config.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        NgxLoadingModule.forRoot({
            // animationType: ANIMATION_TYPES.circleSwish,
            fullScreenBackdrop: true,
        }),
        SweetAlert2Module.forRoot({
            // buttonsStyling: false,
            // customClass: 'modal-content',
            /*confirmButtonClass: 'btn btn-primary', //FIXME: removed in @sweetalert2/ngx-sweetalert2'
             cancelButtonClass: 'btn', //FIXME: removed in @sweetalert2/ngx-sweetalert2'
            */
            // ,allowOutsideClick: false
            // ,backdrop: false
            // ,allowEscapeKey: false,
        }),
        BrowserAnimationsModule,
    ],
    exports: [NgxLoadingModule, SweetAlert2Module],
    providers: [
        Logger,
        CustomerService,
        AppointmentService,
        AppointmentLinkService,
        OutletService,
        VehicleSeriesService,
        VehicleModelService,
        ActivityService,
        VehicleService,
        UserService,
        RentingService,
        MobilityService,
        LoggedUser,
        DealerService,
        CountryService,
        WorkTeamService,
        MessageService,
        StyleSwitcherService,
        KpiAppointmentService,
        KpiWorkshopService,
        KpiTssbService,
        KpiLeadService,
        KpiTimeService,
        TssbService,
        ReportService,
        WelcomeTerminalService,
        AttachmentService,
        _3sService,
        TorosService,
        DatePipe,
        TugaService,
        FastLaneService,
        WheelService,
        MechanicService,
        UserInformationService,
        UserDataService,
        HeartbeatExternalSystemService,
        BusinessSettingService,
        TechnicalSettingService,
        FleetService,
        FleetVehicleService,
        DictionaryService,
        FirstAvailableTimeslotService,
        LeadTimeService,
        EmailReportService,
        PreRegistrationService,
        InfoBoxService,
        InfoBoxBsiService,
        OutletDistrictService,
        CountryConfigurationService,
        ConsentService,
        AppointmentCategoriesService,
        SrpCampaignService,
        ShareBoxService,
        LeadTssbService,
        LeadConfigurationService,
        EventNotificationService, 
        ReturnDateConfigService
    ],
})
export class CoreModule {}
