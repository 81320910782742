import { Injectable } from '@angular/core';
import { UserEmailReport } from '../../../shared/entities';
import { AbstractRestService } from '../generic.service';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../../logger.service';

@Injectable()
export class EmailReportService extends AbstractRestService<UserEmailReport, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/email-report', http, logger);
    }

    public getUserEmailReports(): Promise<UserEmailReport[]> {
        const url = `${this.actionUrl}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as UserEmailReport[])
            .catch(error => this.handleError(error));
    }

    public addUserIntoEmailReport(userEmailReportMail: string) {
        const url = `${this.actionUrl}`;

        return this.http
            .post(url, userEmailReportMail)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public removeUserFromEmailReport(id: string) {
        const url = `${this.actionUrl}/${id}`;

        return this.http
            .delete(url)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }
}
