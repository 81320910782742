import { Appointment } from "../../shared/entities";

export const APPOINTMENT_WO_REGISTRATION_MOCK:Appointment={
    "withoutRegistration": true,
    "customer": {
        "name": null,
        "surname": null,
        "secondSurname": null,
        "email": null,
        "phoneNumber": null,
        "vatNumber": "ZPPGRL55H15A360Z"
    },
    "activityList": [],
    "mobilityList": [],
    "vehicle": {
        "plate": "GE525RB",
        "model": {
            "welcomeActivityId": null,
            "id": "2P41",
            "brand": "BMW",
            "modelLabel": "BMW 116D A",
            "descriptionList": [
                {
                    "language": null,
                    "country": {
                        "code": "IT",
                        "extendedCode": "ITA",
                        "numericCode": 380,
                        "continentCode": "EU",
                        "defaultName": "Italia",
                        "englishName": "Italy",
                        "capacityEngineId": 24478,
                        "active": false
                    },
                    "description": "BMW 116D A"
                }
            ],
            "series": {
                "id": "F21-HC",
                "bodyType": "HC",
                "eseries": "F21",
                "series": "1",
                "defaultImage": null
            },
            "productionStartYear": 2015,
            "productionEndYear": 2019,
            "fuel": "D",
            "type": "CAR",
            "activityTypeList": null
        },
        "mileage": 100000
    },
    "technicalCampaignsAvailable": false,
    "addingItemsToBasket": true,
    "editItemsFromBasket": true,
    "removingItemsFromBasket": true,
    "cancelAppointment": true
}