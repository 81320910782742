import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Utils } from '../../../core/utils';
import { AlertErrorComponent } from '../../../shared/alert/alert-error.component';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';
import { OutletService } from './../../../core/api/outlet/outlet.service';
import { UserService } from './../../../core/api/user/user.service';
import {
    Appointment,
    CalendarEvent,
    SearchServiceAdvisorDTO,
    SearchSlotBean,
    Slot,
    User,
} from './../../../shared/entities';
import { AppointmentService } from '../../../core/api/appointment/appointment.service';
import { OutletAgenda } from '../../../shared/outlet/outlet-agenda';
import * as moment from 'moment';
import { CountryConfigurationService } from '../../../../configuration/country-configuration.service';
import { BusinessSettingService } from '../../../core/api/business-setting.service';
import { EventInput } from '@fullcalendar/core';


/*
 * TODO: Generare slot Colori alternati per ogni slot, ogni secondo giorno pari avere #f8f8f8, dispari #f2f2f2
 *
 */
@Component({
    selector: 'oas-outlet-agenda',
    templateUrl: './outlet-agenda.component.html',
    styleUrls: ['./outlet-agenda.component.scss'],
})
export class OutletAgendaComponent extends OutletAgenda implements OnInit, AfterViewInit {
    eventClick(event: CalendarEvent, jsEvent: any, view: any) {
        // throw new Error('Method not implemented.');
    }
    @Input() public appointment: Appointment;

    @Output() private slotChange = new EventEmitter<Slot>();
    @Output() private changeServiceAdvisor = new EventEmitter<User>();

    @ViewChild(AlertErrorComponent) private alertError: AlertErrorComponent;
    @ViewChild('serviceAdvisorTooltip') serviceAdvisorTooltip: ElementRef;

    public errorResponse: HttpErrorResponse;
    public searchSlot: SearchSlotBean;

    public lastAppointment: Appointment;
    public loading = false;


    private selectedSlotHandleDate: any;
    private currentSelectedSlot: any;

    constructor(
        private userService: UserService,
        private appointmentService: AppointmentService,
        protected outletService: OutletService,
        protected translatePipe: TranslatePipe,
        protected countryConfigurationService: CountryConfigurationService,
        protected businessSettingService: BusinessSettingService,
    ) {
        super(translatePipe, outletService, countryConfigurationService, businessSettingService);
    }
    ngAfterViewInit(): void {
        if (this.appointment.id) {
            this.calendarView.getApi().gotoDate(this.appointment.startTime)
            if (this.appointment.outlet) {
                this.selectedOutlet = this.appointment.outlet;
            }
        } else {
            this.retrieveFirstTimeslotAvaiable = true;
        }

        if (window.navigator.userAgent.includes('Mobile')) {
            //TODO: change the calendarview to daygrid
            this.calendarView.getApi().changeView('timeGridDay')
            // this.calendarOptions.headerO.right = 'hidden';
        }
    }
    
    public ngOnInit() {

        // if (this.appointment.id) {
        //     this.calendarView.getApi().gotoDate(this.appointment.startTime)
        //     if (this.appointment.outlet) {
        //         this.selectedOutlet = this.appointment.outlet;
        //     }
        // } else {
        //     this.retrieveFirstTimeslotAvaiable = true;
        // }

        // if (window.navigator.userAgent.includes('Mobile')) {
        //     //TODO: change the calendarview to daygrid
        //     this.calendarView.getApi().changeView('timeGridDay')
        //     // this.calendarOptions.headerO.right = 'hidden';
        // }
    }

    public init(searchSlot: SearchSlotBean) {  
        this.searchSlot = searchSlot;
        const searchServiceAdvisorDTO: SearchServiceAdvisorDTO = {
            outletId: this.appointment.outlet.id,
            vehicleVin: this.appointment.vehicle.vin,
            activityList: this.appointment.activityList,
            vehicleBrand: this.appointment.vehicle.model.brand,
            fastLane: this.appointment.fastLane,
        };

        this.userService.getActiveServiceAdvisors(searchServiceAdvisorDTO).then(serviceAdvisors => {
            this.serviceAdvisors = serviceAdvisors;
            this.searchSlot.serviceAdvisorLogin = this.serviceAdvisors.map(userSa => userSa.username);  
            // this.calendarView.getApi().hydrate();
            this.viewRender();
        });

        document.addEventListener('click', () => {
            this.serviceAdvisorTooltip.nativeElement.style.visibility = 'hidden';
        });
        document.addEventListener('resize', () => {
            this.serviceAdvisorTooltip.nativeElement.style.visibility = 'hidden';
        });

        const isQuickAppointment = !Utils.isNullOrUndefined(this.appointment.customer.id);
        if (isQuickAppointment) {
            this.appointmentService.loadLastAppointmentByUser(this.appointment.customer.id).then(appointment => {
                this.lastAppointment = appointment;
            });
        }

        if (
            !this.selectedOutlet ||
            (this.appointment.outlet && this.selectedOutlet && this.selectedOutlet.id !== this.appointment.outlet.id)
        ) {
            // remove time slot selected when user change outlet
            Utils.clearTimeSlot(this.appointment);
            this.removeAllEvents();
            this.selectedOutlet = this.appointment.outlet;
            this.retrieveFirstTimeslotAvaiable = true;
        }
        //TODO: not sure if we should keep this or not
        // document.querySelector('div.fc-center h2').addEventListener(
        //     'click',
        //     () => {
        //         const picker = document.querySelector('#datepicker') as HTMLInputElement;
        //         picker.click();
        //     },
        //     false
        // );
    } 

    public destroyCalendar() {}

    public changeCalendarDate(date: Date) {
        this.calendarView.getApi().gotoDate(Utils.toUTCDate(date));
        setTimeout(() => {
            this.viewRender();
        }, 100)
    }

     /**
     * Create object wich one can interact with calendar
     * @param slots OSFC slot
     */
     public populateSlots(slots: Slot[]): EventInput[] {
        this.removeAllEvents();
        if(Utils.isEmptyArray(slots)) {
            return [];
        }
        const events: EventInput[] = [];
        slots = this.groupBySlots(slots);

        for (const slot of slots) {
            events.push(this.createEvent(slot));
        }

        if (
            this.appointment.id &&
            this.appointment.returnActivity &&
            this.appointment.serviceAdvisor &&
            this.searchSlot.serviceAdvisorLogin.indexOf(this.appointment.serviceAdvisor.username) > -1
        ) {
            const retActivity = this.appointment.returnActivity;
            const slotRet = {
                slotStart: retActivity.startTime,
                slotEnd: retActivity.endTime,
                timeSlotName: moment(retActivity.startTime).format('HH:mm'),
                saName: 'RETURN',
                vehicleList: []
            };
            events.push(this.createEvent(slotRet, true));
        }

        return events;
    }

    private createEvent(slot: Slot, isRet = false): EventInput {
        const saUsernames = slot.saName.split('|');
        const saIndeces = saUsernames.map(username => {
            let index = this.serviceAdvisors.map(sa => `${sa.username}`).indexOf(username);
            if (index < 0 && slot.color !== '') {
                index = 99;
            }
            return index;
        });

        const classNameColor = saIndeces.length === 1 ? this.sadvColors[saIndeces[0]] : this.multiColor;
        const calendarEvent: EventInput = {
            extendedProps: {
                slot: slot,
                saIndeces: saIndeces,
                selected: false
            },
            backgroundColor: isRet? 'white': classNameColor,
            borderColor: this.isSlotSelected(slot) ? 'red' : isRet ? 'white': classNameColor,
            start: slot.slotStart,
            end: slot.slotEnd,
            textColor: this.isSlotSelected(slot) ? 'red' : 'black',
            className: this.isSlotSelected(slot) ? 'slot' : isRet  ? 'diagonal-strip' : null
        };
        return calendarEvent;
    }

    private groupBySlots(collection: Slot[]): Slot[] {
        const result = [];

        for (let i = 0; i < collection.length; i++) {
            const slot = result.find(
                s =>
                    s.slotStart.getTime() === collection[i].slotStart.getTime() &&
                    s.slotEnd.getTime() === collection[i].slotEnd.getTime()
            );
            if (slot) {
                slot.saName = slot.saName + '|' + collection[i].saName;
            } else {
                result.push(collection[i]);
            }
        }
        return result;
    }

    // check if a slot is already selected when we scroll through the calendar or change service advisors to add border style to it
    private isSlotSelected(slot: Slot): boolean{
       
        if(Utils.isNotNullOrUndefined(this.appointment) && Utils.compareDate(slot.slotStart, this.appointment.startTime) &&  Utils.compareDate(slot.slotEnd, this.appointment.endTime)){
            // check if the slot's service advisor is the same as appointment's service advisor (retrieve slots by service advisor)
            const saName = slot.saName.split('|');
            if(saName.length === 1 && slot.saName !== this.appointment.serviceAdvisor.username){
                return false
            }
            return true;
        }
        return false;
    }

    public viewRender() {
        this.prevButtonDisabled = document.getElementsByClassName('fc-today-button')[0].hasAttribute('disabled');
        this.removeAllEvents();
        const calenderGridView = this.calendarView.getApi().getCurrentData().currentViewType;
        this.fromDate = this.calendarView.getApi().getCurrentData().dateProfile.currentRange.start;

        
        if (calenderGridView !== 'timeGridWeek') {
            this.removeAllEvents()
            this.toDate = this.fromDate;
            this.calendarView.getApi().render();
        } else {
            this.toDate = Utils.subDays(this.calendarView.getApi().getCurrentData().dateProfile.currentRange.end, 1);
            const today = new Date();
            if (
                this.fromDate.getFullYear() === today.getFullYear() &&
                this.fromDate.getMonth() === today.getMonth() &&
                this.fromDate.getDate() === today.getDate() - 1
            ) {
                this.fromDate = Utils.addDays(this.fromDate, 1);
            }
        }

        this.loading = true;
        this.showBannerEmptySlot = false;

        if(this.selectedOutlet){
           this.searchSlot.appointment.outlet = this.selectedOutlet;
        }

        if (this.retrieveFirstTimeslotAvaiable) {
            this.getFirstAvailableDate();
        } else {
            this.getFreeSlotAndFillCalendar();
        }
    }

    protected getFreeSlotAndFillCalendar(): void {
        this.searchSlot.dates = Utils.datesBetween(this.fromDate, this.toDate);
        // this.mainCalendar.fullCalendar('removeEvents');
        this.outletService
            .getFreeSlots(this.searchSlot)
            .then(slots => {
                setTimeout(() => {
                    this.calendarView.getApi().refetchEvents();
                    this.calendarOptions.events = this.populateSlots(slots);
                    this.formatTimeSlotLabel();
                    if(this.calendarView.getApi().getCurrentData().currentViewType !== 'timeGridWeek') {
                        // this.calendarView.getApi().hydrate();
                        this.calendarView.getApi().changeView('timeGridDay');
                        this.calendarView.getApi().gotoDate(this.fromDate);
                    }
                    this.calendarView.getApi().refetchEvents();
                    this.showBannerEmptySlot =this.calendarOptions.events.length === 0;
                    this.loading = false;
                }, 100)
            })
            .catch((errorResponse: HttpErrorResponse) => {
                this.removeAllEvents();
                this.errorResponse = errorResponse;
                this.loading = false;
                this.alertError.show();
            });
    }

    private getFirstAvailableDate() {
        this.retrieveFirstTimeslotAvaiable = false;
        this.outletService
            .getNextFreeSlot(this.searchSlot)
            .then(r => {
                if (!Utils.isNullOrUndefined(r.payload)) {
                    this.triggerGotoDateMainCalendarEvent(r.payload);
                }
            })
            .catch((errorResponse: HttpErrorResponse) => {
                this.removeAllEvents();
                // this.calendarView.getApi().hydrate();
                this.errorResponse = errorResponse;
                this.loading = false;
                this.showBannerEmptySlot = true;
            });
    }

    public handleDateClick(arg): void {
        this.selectedSlotHandleDate = arg;
        this.selectedEventSadvList = arg.event.extendedProps.saIndeces.map(index => this.serviceAdvisors[index]);

        setTimeout(() => {
            const top = arg.jsEvent.pageY - Math.round(this.serviceAdvisorTooltip.nativeElement.clientHeight / 2);
            const left = arg.jsEvent.pageX + 15;

            this.serviceAdvisorTooltip.nativeElement.style.top = top + 'px';
            this.serviceAdvisorTooltip.nativeElement.style.left = left + 'px';
            this.serviceAdvisorTooltip.nativeElement.style.visibility = 'visible';
        }, 0);

        arg.jsEvent.preventDefault();
        arg.jsEvent.stopPropagation();
    }

    public emitEvent(event: CalendarEvent, sadv: User): void {
        this.newSlotSelectAndStyle();
        this.slotChange.emit(this.currentSelectedSlot.event.extendedProps.slot);
        this.changeServiceAdvisor.emit(sadv);
        this.appointment.outlet = this.selectedOutlet;
    }

    private newSlotSelectAndStyle(): void{
        const slotsWithBorder = document.querySelectorAll<HTMLElement>('.slot');
        if(slotsWithBorder.length) {
            slotsWithBorder.forEach(el => {
                el.style.borderColor = el.style.backgroundColor;
                el.style.borderWidth = null;
                el.style.color = 'black';
                el.classList.remove('slot');
            })
        }    

        if(this.currentSelectedSlot){
            this.currentSelectedSlot.el.style.borderWidth = null;
            this.currentSelectedSlot.el.style.borderColor = null;
            this.currentSelectedSlot.el.style.color = 'black';
        }

        this.currentSelectedSlot = {...this.selectedSlotHandleDate}
       
        this.currentSelectedSlot.el.style.borderWidth = '3px';
        this.currentSelectedSlot.el.style.borderColor = 'red';
        this.currentSelectedSlot.el.style.color = 'red';
    }    

    public onSelectServiceAdvisor(serviceAdvisor?: User): void {
        this.removeAllEvents();
        this.searchSlot.serviceAdvisorLogin = serviceAdvisor
            ? [serviceAdvisor.username]
            : this.serviceAdvisors.map(user => user.username);

        if (
            Utils.isNullOrUndefined(this.appointment.welcomeActivity) ||
            Utils.isNullOrUndefined(serviceAdvisor) ||
            this.appointment.welcomeActivity.resourceId !== `${serviceAdvisor.username}`
        ) {
            this.retrieveFirstTimeslotAvaiable = true;
            this.viewRender();
        } else {
            this.triggerGotoDateMainCalendarEvent(this.appointment.startTime);
        }
    }

}
