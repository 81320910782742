import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertSuccessComponent } from '../../../shared/alert/alert-success.component';
import { dataAnalytics } from '../../../shared/data-analytics';
import { Customer, JwtAuthenticationRequest } from '../../../shared/entities';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';
import { TracyService } from '../../../shared/tracy.service';
import { AppComponent } from './../../../app.component';
import { AuthService } from './../../../auth/auth.service';
import { CustomerService } from './../../../core/api/customer/customer.service';
import { AlertErrorComponent } from './../../../shared/alert/alert-error.component';
import { CountryConfigurationService } from '../../../../configuration/country-configuration.service';
import { CountryConfiguration } from '../../../country-configuration';

@Component({
    selector: 'oas-customer-edit-wizard',
    templateUrl: './customer-edit-wizard.component.html',
})
export class CustomerEditWizardComponent implements OnInit {
    @ViewChild('alertSuccess') private alertSuccess: AlertSuccessComponent;
    @ViewChild('alertError') private alertError: AlertErrorComponent;

    @ViewChild('accountIdChangeRequestedTitleTmpl') accountIdChangeRequestedTitleTmpl: TemplateRef<any>;
    @ViewChild('accountIdChangeRequestedContentTmpl') accountIdChangeRequestedContentTmpl: TemplateRef<any>;

    public errorResponse: HttpErrorResponse;
    public customer: Customer;
    public authRequest: JwtAuthenticationRequest = {};
    public countryConfiguration: CountryConfiguration;

    public successAlerts: any = [];

    constructor(
        private appComponent: AppComponent,
        private route: ActivatedRoute,
        private customerService: CustomerService,
        private authService: AuthService,
        private router: Router,
        private translatePipe: TranslatePipe,
        private tracyService: TracyService,
        countryConfigurationService: CountryConfigurationService
    ) {
        this.setDataAnalytics();
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
    }

    public ngOnInit(): void {
        this.route.data.subscribe((data: { customer: Customer }) => {
            this.customer = data.customer;
            this.authRequest.email = this.customer.email;
        });
    }

    public updateCustomer(customer: Customer, isFirstStep?: boolean): void {
        this.customer = customer;
        this.appComponent.loading = true;
        this.customerService
            .update(this.customer, this.customer.id)
            .then(() => {
                this.appComponent.loading = false;
                if (isFirstStep) {
                    // this.updateCustomerFirsetStepEvent();
                } else {
                    // this.updateCustomerThirdStepEvent();
                }
                this.alertSuccess.show();
            })
            .catch((errorResponse: HttpErrorResponse) => {
                this.appComponent.loading = false;
                this.alertError.showHttpError(errorResponse);
            });
    }

    public changePassword(userForm: UntypedFormGroup): void {
        this.appComponent.loading = true;
        this.customerService
            .changePassword(this.customer.id, this.authRequest)
            .then(() => {
                this.appComponent.loading = false;
                userForm.get('oldPassword').reset();
                userForm.get('password').reset();
                userForm.get('confirmPassword').reset();
                this.authService.logout();
                this.alertSuccess.show();
                this.router.navigate(['/welcome']);
            })
            .catch((errorResponse: HttpErrorResponse) => {
                if (errorResponse.error.message == 'PasswordAlreadyUsed') {
                    this.alertError.text = this.translatePipe.transform(errorResponse.error.message);
                } else {
                    this.errorResponse = errorResponse;
                }
                this.appComponent.loading = false;
                this.alertError.show();
            });
    }

    public updateAccountId(newAccountId: string): void {
        this.appComponent.loading = true;
        this.customerService
            .updateAccountId(this.customer.id, newAccountId)
            .then(() => {
                this.appComponent.loading = false;
                this.alertSuccess.titleTmpl = this.accountIdChangeRequestedTitleTmpl;
                this.alertSuccess.contentTmpl = this.accountIdChangeRequestedContentTmpl;
                this.alertSuccess.show().then(() => this.showSuccessAlertAccountIdChangeRequested(newAccountId));
            })
            .catch((errorResponse: HttpErrorResponse) => {
                this.appComponent.loading = false;
                this.alertError.showHttpError(errorResponse);
            });
    }

    public showSuccessAlertAccountIdChangeRequested(email: string) {
        this.successAlerts = [
            {
                type: 'success',
                email: email,
                timeout: 10000,
            },
        ];
    }

    public deleteAccount(): void {
        this.appComponent.loading = true;
        this.customerService
            .delete(this.customer.id)
            .then(() => {
                this.appComponent.loading = false;
                this.removeCustomerEvent();
                this.authService.logout();
                this.alertSuccess.show();
                this.router.navigate(['/welcome']);
            })
            .catch((errorResponse: HttpErrorResponse) => {
                this.appComponent.loading = false;
                this.alertError.showHttpError(errorResponse);
            });
    }

    public updateCustomerPreferences(): void {
      this.appComponent.loading = true;
      this.customerService
         .update(this.customer, this.customer.id)
         .then(() => {
            this.appComponent.loading = false;
            this.alertSuccess.show();
         })
         .catch((errorResponse: HttpErrorResponse) => {
            this.appComponent.loading = false;
            this.alertError.showHttpError(errorResponse);
         });
    }

    // Analytics implementation start
    private setDataAnalytics() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasEditAccount,
            dataAnalytics.page.variant.real
        );

        this.tracyService.setUserInformationIntoDataAnalytics(dataAnalytics.profile.returningStatus.returning);

        this.tracyService.sendPageView(dataAnalytics.referenceName.accountEdit);
    }

    // prompt an event when saving the first step of customer detail
    /*private updateCustomerFirsetStepEvent() {
        this.tracyService.setEventInformationIntoDataAnalytics(
            dataAnalytics.event.cause.default,
            dataAnalytics.event.effect.profileDetailEdited,
            dataAnalytics.event.eventAction.internalClick,
            'na',
            dataAnalytics.event.subCategory1.edit_account[1],
            dataAnalytics.event.type.ux
        );
        this.tracyService.sendEvent(dataAnalytics.referenceName.accountEdit);
    }*/

    // third/mailing step
    /*private updateCustomerThirdStepEvent() {
        this.tracyService.setEventInformationIntoDataAnalytics(
            dataAnalytics.event.cause.default,
            dataAnalytics.event.effect.mailingEdited,
            dataAnalytics.event.eventAction.internalClick,
            'na',
            dataAnalytics.event.subCategory1.edit_account[3],
            dataAnalytics.event.type.ux
        );
        this.tracyService.sendEvent(dataAnalytics.referenceName.accountEdit);
    }

    // pwd/second step
    private changePasswordEvent() {
        this.tracyService.setEventInformationIntoDataAnalytics(
            dataAnalytics.event.cause.default,
            dataAnalytics.event.effect.passwordEdited,
            dataAnalytics.event.eventAction.internalClick,
            'na',
            dataAnalytics.event.subCategory1.edit_account[2],
            dataAnalytics.event.type.ux
        );
        this.tracyService.sendEvent(dataAnalytics.referenceName.accountEdit);
    }*/

    // delete customer
    private removeCustomerEvent() {
        this.tracyService.setEventInformationIntoDataAnalytics(
            dataAnalytics.event.cause.default,
            dataAnalytics.event.effect.passwordEdited,
            dataAnalytics.event.eventAction.internalClick,
            'na',
            'na',
            dataAnalytics.event.type.ux
        );
        this.tracyService.sendEvent(dataAnalytics.event.referenceName.accountEditProfileDeleted);
    }
}
