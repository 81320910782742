<ul class="services-list">
<li *ngIf="activities.length == 0" class="services-list__item">
   <p>
      <strong i18n="@@selectAtLeastOneService">Please select at least one service</strong>
   </p>
</li>
<li
   *ngFor="let activity of activities"
   class="services-list__item"
>
   <i class="icon icon-BMW-ico_air icon-active schedule-service-type-image"></i>
   <p>
      <strong>{{ activity.name }}</strong>
   </p>
   <!-- commented out untill we wish to use it back, 
      note that the current delete button in the modal doesnt work, 
      and is not being synced with the activity list component, at least visually.

      <span class="services-list__item-remove">
                    <button (click)="removeActivity(activity)" class="btn button&#45;&#45;clear" [disabled]="readOnly || isActivityReadOnly(activity)">
                        <i class="fa fa-times-circle-o fa-2x"></i>
                    </button>
                </span> -->
</li>
</ul>
