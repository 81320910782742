import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { KpiService } from './kpi.service';
import { Logger } from '../../logger.service';
import { SearchSettings, AverageCompletionTimePerServiceDealerOutlet } from '../../../shared/entities';

@Injectable()
export class KpiTimeService extends KpiService {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/time', http, logger);
    }

    public getAverageCompletion(
        searchSettings?: SearchSettings
    ): Promise<AverageCompletionTimePerServiceDealerOutlet[]> {
        return this.http
            .post(`${this.actionUrl}/averageCompletion/perServiceDealerOutlet`, searchSettings)
            .toPromise()
            .then(response => response as AverageCompletionTimePerServiceDealerOutlet[])
            .catch(error => this.handleError(error));
    }
}
