import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Logger } from './../../logger.service';
import { Renting, RentingMobility } from './../../../shared/entities';
import { AbstractRestService } from '../generic.service';
import { AuthService } from '../../../auth/auth.service';

@Injectable()
export class RentingService extends AbstractRestService<Renting, number> {
    constructor(protected http: HttpClient, protected logger: Logger, private authService: AuthService) {
        super('/renting', http, logger);
    }

    public getManageableRentingCompany(countryCode: string): Promise<Renting[]> {
        const url = `${this.actionUrl}/manageable-renting-company`;
        const params = new HttpParams().set('roleId', this.authService.getSelectedRole()).set('country', countryCode);

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as Renting[])
            .catch(error => this.handleError(error));
    }

    public createRentingCompany(renting: Renting): Promise<void> {
        const url = `${this.actionUrl}/create-renting-company`;

        return this.http
            .post(url, renting)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public updateRentingCompany(renting: Renting) {
        const url = `${this.actionUrl}/update-renting`;

        return this.http
            .post(url, renting)
            .toPromise()
            .then(response => response as Renting)
            .catch(error => this.handleError(error));
    }

    public getVehiclesFromRentingCompany(rentingId: number): Promise<number> {
        const url = `${this.actionUrl}/vehicles-from-renting`;

        return this.http
            .post(url, rentingId)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public updateRentingCompanyWithMobility(renting: Renting, outletId: string) {
        const url = `${this.actionUrl}/update-renting-with-mobility/${outletId}`;
        return this.http
            .post(url, renting)
            .toPromise()
            .then(response => response as Renting)
            .catch(error => this.handleError(error));
    }

    public getMobilityFromOutlet(renting: Renting, outletId: string): Promise<RentingMobility[]> {
        const url = `${this.actionUrl}/get-mobility-from-outlet/${outletId}`;
        return this.http
            .post(url, renting)
            .toPromise()
            .then(response => response as RentingMobility[])
            .catch(error => this.handleError(error));
    }

    public getRentingsFromOutlet(outletId: string): Promise<Renting[]> {
        const url = `${this.actionUrl}/get-rentings-from-outlet`;
        return this.http
            .post(url, outletId)
            .toPromise()
            .then(response => response as Renting[])
            .catch(error => this.handleError(error));
    }
}
