<div class="row dealer__search-row">
    <div class="col-md-4">
        <!-- <h2 class="assistance-title" i18n="@@selectDealer">Select a dealer</h2> -->
    </div>
    <div class="col-md-8">
        <div class="row">
            <div class="col-md-12">
                <div class="switcher-buttons dealer-search__switcher-buttons">
                    <i class="fa fa-sliders"></i>
                    <button
                        class="btn button--clear switcher-button dealer-search__switcher-button"
                        [class.dealer-search__switcher-button--active]="searchType === 'address'"
                        (click)="onSearchTypeChange('address')"
                        i18n="@@searchByAddress"
                    >
                        Search by address
                    </button>
                    <button
                        class="btn button--clear switcher-button dealer-search__switcher-button btn-search-by-dealer"
                        [class.dealer-search__switcher-button--active]="searchType === 'outlet'"
                        (click)="onSearchTypeChange('outlet')"
                        i18n="@@searchByDealer"
                    >
                        Search by dealer
                    </button>
                </div>
            </div>
        </div>
        <div class="row dealer__search-radius-row">
            <div class="col-md-7">
                <div
                    class="input-group assistance-search dealer__assistance-search"
                    [style.display]="searchType === 'address' ? 'inline-block' : 'none'"
                >
                    <input
                        #searchAddress
                        oasAddressAutocomplete
                        type="text"
                        class="form-control"
                        i18n-placeholder="@@searchForLocation"
                        placeholder="Search for location"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        [formControl]="searchAddressControl"
                        (placeChange)="onPlaceChange($event)"
                    />
                    <span class="clear-input">
                        <button
                            *ngIf="searchAddressControl.value"
                            class="btn btn-default button--clear"
                            (click)="searchAddressControl.reset()"
                        >
                            <i class="fa fa-times"></i>
                        </button>
                    </span>
                </div>
                <div
                    class="input-group assistance-search dealer__assistance-search"
                    [style.display]="searchType === 'outlet' ? 'inline-block' : 'none'"
                >
                    <input
                        #searchOutlet
                        type="text"
                        class="form-control ui-typeahead"
                        i18n-placeholder="@@searchOutlet"
                        placeholder="Search for outlet"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        [formControl]="searchOutletControl"
                        [typeahead]="outlets"
                        [typeaheadItemTemplate]="customItemSearch"
                        typeaheadOptionField="businessName"
                        (typeaheadOnSelect)="typeaheadOnSelect($event)"
                    />
                    <span class="clear-input">
                        <button
                            *ngIf="searchOutletControl.value"
                            class="btn btn-default button--clear"
                            (click)="searchOutletControl.reset()"
                        >
                            <i class="fa fa-times"></i>
                        </button>
                    </span>
                </div>
            </div>
            <div *ngIf="showSlider" class="col-md-5 alignSlider">
                <div class="dealer__search-radius--wrapper">
                    <div class="dealer__search-radius__slider--wrapper">
                        <nouislider
                            [connect]="[true, false]"
                            [min]="0.0"
                            [max]="100"
                            [step]="10.0"
                            [keyboard]="true"
                            [formControl]="radiusControl"
                            (update)="setRadius($event)"
                        ></nouislider>
                    </div>
                    <div class="dealer__search-radius__text--wrapper">
                        <div class="dealer__search-radius__text">{{ radiusControl.value }} Km</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="dealer-results__wrapper">
            <div class="row row-md-eq-height">
                <div class="col-md-4">
                    <div class="dealer-list__wrapper dealer-list-wrapper-align">
                        <ng-container *ngFor="let out of outlets; trackBy: trackByOutlet">
                            <div
                                *ngIf="out.visible"
                                class="dealer-info"
                                [class.dealer-info--selected]="outlet?.id === out.id"
                                (click)="onSelect(out)"
                            >
                                <img src="assets/images/ic_your-dealer.svg" alt="" class="dealer-info__icon" />
                                <p>
                                    <strong>{{ out.businessName }}</strong> ,
                                    <br />
                                    {{outletCityByAddress(out.address)}} - {{ out.address.address }}
                                    <span *ngIf="out.address.stairInt">
                                        <br />
                                        {{ out.address.stairInt }}
                                    </span>
                                    <br />
                                    <span class="brands-mr">
                                        {{ out.address.zipCode }}, {{ out.address.country.code }}
                                    </span>
                                    <br />
                                    <span *ngFor="let brand of getManageableBrands(out)">
                                        <i
                                            [class]="
                                                'bmw bmw-ms_ico_' +
                                                retrieveVehicleBrand(brand.id).toLowerCase() +
                                                ' ico_brand'
                                            "
                                        ></i>
                                    </span>
                                </p>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="dealer-results__map">
                        <google-map *ngIf="displayMap" #map (zoomChanged)="getMapZoom()"
                            [options]="mapOptions"
                            (mapClick)="closeInfoWindow()"
                        >
                            <map-circle
                                #circle
                                [options]="circleOptions"
                                (radiusChanged)="toggleOutletVisibility(); automaticRadius()"
                                (centerChanged)="toggleOutletVisibility(); automaticRadius()"
                                
                            ></map-circle>
                            <map-marker
                                #markerElement="mapMarker"
                                *ngFor="let out of outlets; trackBy: trackByOutlet"
                                [options]="{
                                    position: {lat: out.address.latitude, lng: out.address.longitude },
                                    title: out.businessName,
                                }"
                                (mapClick)="onSelect(out);openInfoWindow(markerElement, out);"
                            >
                                <!-- <agm-marker *ngFor="let out of outlets; trackBy:out?.id" [latitude]="out.address.latitude" [longitude]="out.address.longitude"
                           [visible]="out.visible" (markerClick)="onSelect(out)"> -->
                                
                            </map-marker>
                            <map-info-window #window [options]="infoWindowOptions">
                                <div>
                                    {{ markerTitle }}
                                    <div class="brands-mt">
                                        <span *ngFor="let brand of getManageableBrands(markerOutlet)">
                                            <i
                                                [class]="
                                                    'bmw bmw-ms_ico_' +
                                                    retrieveVehicleBrand(brand.id).toLowerCase() +
                                                    ' ico_brand'
                                                "
                                            ></i>
                                        </span>
                                    </div>
                                </div>
                            </map-info-window>
                        </google-map>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #customItemSearch let-model="item">
    <div class="typeahead-item">
        <p>
            {{ model.businessName }} <ng-container *ngIf="!isCustomer"> - {{ model.originalId }}</ng-container>
        </p>
        <p class="outlet-address">
            {{outletCityByAddress(model.address)}} - {{ model.address.address }}
            <br />
            {{ model.address.stairInt }}
        </p>
        <div class="brands-mt">
            <span *ngFor="let brand of this.getManageableBrands(model)">
                <i [class]="'bmw bmw-ms_ico_' + retrieveVehicleBrand(brand.id).toLowerCase() + ' ico_brand'"></i>
            </span>
        </div>
    </div>
</ng-template>
