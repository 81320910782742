<div class="dashboard-header wizard__dashboard-header">
    <ng-container *ngTemplateOutlet="header"> </ng-container>
</div>

<div class="container dashboard-content vehicle-data-content">
    <div class="col-md-3"></div>
    <div class="col-md-6 text-left">
        <h2 i18n="@@deleteTitleAppointmentCancelLink">
            Cancel appointment
        </h2>
        <p class="space-between" i18n="@@confirmDeleteAppointmentCancelLink">
            If you want to cancel your appointment please confirm here.
        </p>

        <p i18n="@@reasonToCancel">
            Reason to cancel:
        </p>
        <textarea id="comment" [formControl]="reasonForm" name="reason_cancel" rows="5" maxlength="500"></textarea>
        <div class="col-md-12 text-center">
            <button id="cancelBtn" class="btn btn-primary" (click)="delete()" i18n="@@deleteTitleAppointmentCancelLink">
                Cancel appointment
            </button>
        </div>
    </div>
    <div class="col-md-3"></div>
</div>

<ng-template #header>
    <div class="row no-gutters">
        <div class="container-your-dashboard-and-logo">
            <div class="container-your-dashboard">
                <h1 class="dashboard-header__title" i18n="@@cancelAppointment">
                    Cancel appointment
                </h1>
            </div>
            <div class="container-logo">
                <oas-logo></oas-logo>
            </div>
        </div>
        <div class="col-md-4">
            <div class="dashboard-header__title__wrapper">
                <a [routerLink]="['/welcome']" class="dashboard-header__dismiss-request">
                    <i class="fa fa-chevron-left"> </i>
                    <span i18n="@@dismissRequestQuestion">Dismiss this request?</span>
                </a>
            </div>
        </div>
        <div class="col-md-4 col-md-offset-0 col-sm-8 col-sm-offset-2">
            <div class="dashboard-header__hero-image--wrapper">
                <oas-vehicle-picture-ucp
                    [vin]="(appointment$ | async)?.vehicleVin"
                    [vehicleSeriesId]="(appointment$ | async)?.vehicleSeries"
                ></oas-vehicle-picture-ucp>
            </div>
        </div>
    </div>

    <ng-container *ngTemplateOutlet="wizardSteps"> </ng-container>
</ng-template>

<ng-template #wizardSteps>
    <div class="wizard-steps header__wizard-steps">
        <div class="container">
            <div class="row row-eq-height">
                <div class="col-md-4 col-sm-4 col-xs-4">
                    <div class="wizard-steps__step">
                        <div class="wizard-steps__step-name">
                            <div class="wizard-steps__step-name--inner">
                                <div class="text-center">
                                    <span class="bmw bmw-wizard_ico_service icon-lg"></span>
                                </div>
                                <div i18n="@@services">Services</div>
                            </div>
                        </div>
                        <div class="wizard-steps__step-description">
                            <ng-container
                                *ngIf="
                                    (appointment$ | async)?.activityList | filter: filterPredicate as filteredActivities
                                "
                            >
                                <ng-container *ngIf="filteredActivities.length > 3; else fewActivities">
                                    <div class="row no-pudding">{{ filteredActivities[0].name }},</div>
                                    <div class="row no-pudding">{{ filteredActivities[1].name }}</div>
                                    <div class="row no-pudding">
                                        <span i18n="@@and">and</span>
                                        <a
                                            i18n="@@wizardStepServicesMore"
                                            (click)="
                                                appComponent.openModal(
                                                    'services',
                                                    activitiesDetailTemplate,
                                                    'services__modal'
                                                )
                                            "
                                            >{{ filteredActivities.length - 2 }} more</a
                                        >
                                    </div>
                                </ng-container>
                                <ng-template #fewActivities>
                                    <div
                                        *ngFor="
                                            let activity of filteredActivities;
                                            let isLast = last;
                                            trackBy: trackByActivity
                                        "
                                        class="row no-pudding"
                                    >
                                        {{ activity.name }}
                                        <span *ngIf="!isLast">,</span>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4">
                    <div class="wizard-steps__step">
                        <div class="wizard-steps__step-name">
                            <div class="wizard-steps__step-name--inner">
                                <div class="text-center">
                                    <i class="bmw bmw-wizard_ico_dealer icon-lg"></i>
                                </div>
                                <div i18n="@@dealer">Dealer</div>
                            </div>
                        </div>
                        <div class="wizard-steps__step-description">
                            <div class="row">
                                {{ (appointment$ | async)?.outlet?.businessName }}
                            </div>
                            <div class="row">{{ (appointment$ | async)?.outlet?.address.address }}</div>
                            <div class="row">{{ (appointment$ | async)?.outlet?.address.stairInt }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4">
                    <div class="wizard-steps__step">
                        <div class="wizard-steps__step-name">
                            <div class="wizard-steps__step-name--inner">
                                <div class="text-center">
                                    <i class="bmw bmw-wizard_ico_schedule icon-lg"></i>
                                </div>
                                <div i18n="@@schedule">Schedule</div>
                            </div>
                        </div>
                        <div class="wizard-steps__step-description">
                            <ng-container *ngIf="(appointment$ | async)?.startTime && (appointment$ | async)?.endTime">
                                {{ (appointment$ | async)?.startTime | datex: 'medium' }}
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<oas-alert-error></oas-alert-error>
<oas-alert-success></oas-alert-success>
<oas-alert-confirm></oas-alert-confirm>

<ngx-loading [show]="loading"></ngx-loading>
