<div *ngIf="listCBS?.length > 0; else noServicesToDisplay" class="horizontal-scroll">
    <table class="table">
        <thead>
            <tr>
                <th *ngIf="enableSelection" class="col-md-1" i18n="@@selection">Selection</th>
                <th class="col-md-4" i18n="@@cbsIndicator">Service</th>
                <th class="col-md-1 center" *ngIf="bsiEnabled">BSI**</th>
                <th class="col-md-1 center" *ngIf="bsiEnabled" i18n="@@price">Price</th>
                <th class="col-md-1 center" i18n="@@cbsStatus">Status</th>
                <th class="col-md-1 center" i18n="@@cbsDueDistance">Due Distance</th>
                <th class="col-md-1 center" i18n="@@cbsRemainingDays" *ngIf="enableDays">Remaining Days</th>
                <th class="col-md-1 duedate-header" i18n="@@cbsDueDate" *ngIf="!enableDays">Due Date</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="standardVolumeCBS">
                <td *ngIf="enableSelection">
                    <input
                        type="checkbox"
                        class="ui-checkbox"
                        [checked]="isAlreadySelected(standardVolumeCBS)"
                        [disabled]="true"
                    />
                </td>

                <td class="col-md-4">{{ standardVolumeCBS.description }}<span class="bold-font">*</span></td>
                <td class="col-md-1" *ngIf="bsiEnabled"></td>
                <td class="col-md-1" *ngIf="bsiEnabled"></td>
                <td class="col-md-1 center">
                    <div>
                        <span class="cbs-state" [style.color]="standardVolumeCBS.state">● </span>
                        <span *ngIf="standardVolumeCBS.state === 'YELLOW'" i18n="@@cbsRequired">Required</span>
                        <span *ngIf="standardVolumeCBS.state === 'RED'" i18n="@@cbsExpired">Expired</span>
                    </div>
                </td>
                <td class="col-md-1 center">
                    <div *ngIf="!isNullOrUndefined(standardVolumeCBS.remainingDistance)">{{ standardVolumeCBS.remainingDistance }} <span i18n="@@km">KM</span></div>
                </td>
                <td class="col-md-1 center">
                    <ng-container *ngIf="!isNullOrUndefined(standardVolumeCBS.remainingTime)">
                        <ng-container *ngIf="enableDays">
                            <div>{{ standardVolumeCBS.remainingTime }} <span i18n="@@days">Days</span></div>
                        </ng-container>
                        <ng-container *ngIf="!enableDays">
                            <div>{{ getDueDate(standardVolumeCBS.remainingTime) | datex: 'dd/MM/yyyy' }}</div>
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
            <tr *ngFor="let cbs of listCBS">
                <ng-container *ngIf="!(cbs?.identifier === '99999' && countryConfiguration.automaticSelectionCbs99999)">
                    <td *ngIf="enableSelection" class="col-md-1">
                        <input
                            type="checkbox"
                            class="ui-checkbox"
                            [checked]="isAlreadySelected(cbs)"
                            (change)="onSelect(cbs, $event.target.checked)"
                        />
                    </td>

                    <td class="col-md-4">{{ cbs.description }}</td>
                    <td class="col-md-1 center" *ngIf="bsiEnabled">
                        <span *ngIf="cbs.siCheck" i18n="@@included">Included</span>
                        <span *ngIf="!cbs.siCheck" i18n="@@notIncluded">Not included</span>
                    </td>
                    <td class="center" *ngIf="bsiEnabled">{{cbs.price ? (cbs.price | currency: ('EUR')) : '-' }}</td>
                    <td class="col-md-1 center">
                        <div>
                            <span class="cbs-state" [style.color]="cbs.state">● </span>
                            <span *ngIf="cbs.state === 'GREEN'" i18n="@@cbsOK">OK</span>
                            <span *ngIf="cbs.state === 'YELLOW'" i18n="@@cbsRequired" >Required</span>
                            <span *ngIf="cbs.state === 'RED'" i18n="@@cbsExpired">Expired</span>
                        </div>
                    </td>
                    <td class="col-md-1 center">
                        <div *ngIf="!isNullOrUndefined(cbs.remainingDistance)">{{ cbs.remainingDistance }} <span i18n="@@km">KM</span></div>
                    </td>
                    <td class="col-md-1 center">
                        <ng-container *ngIf="!isNullOrUndefined(cbs.remainingTime)">
                            <ng-container *ngIf="enableDays">
                                <div>{{ cbs.remainingTime }} <span i18n="@@days">Days</span></div>
                            </ng-container>
                            <ng-container *ngIf="!enableDays">
                                <div>{{ getDueDate(cbs.remainingTime) | datex: 'dd/MM/yyyy' }}</div>
                            </ng-container>
                        </ng-container>
                    </td>
                </ng-container>
            </tr>
        </tbody>
    </table>

    <div class="bold-font required-service-label" *ngIf="standardVolumeCBS">
        *<span i18n="@@cbsRequiredService">CBS Service required for the vehicle</span>
    </div>
    <div class="bold-font required-service-label" *ngIf="bsiEnabled">
        **<span i18n="@@cbsSrpNotice">BSI NOTICE</span>
    </div>
</div>

<ng-template #noServicesToDisplay>
    <div class="row">
        <div class="col-md-12 cbs-service_no-item" i18n="@@noServicesToDisplay">No services to display</div>
    </div>
</ng-template>
