import { Logger } from './../../logger.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractRestService } from '../generic.service';

@Injectable()
export class ConsentService extends AbstractRestService<string, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/consents', http, logger);
    }

    public createConsent(): Promise<void> {
        return this.http
            .post(`${this.actionUrl}`, {})
            .toPromise()
            .catch(error => this.handleError(error));
    }

    public getLastUnacceptedTermsAndConditionsUrl(): Promise<string> {
        return this.http
            .get(`${this.actionUrl}/last-unaccepted-terms-and-conditions-url`, { responseType: 'text' })
            .toPromise()
            .then(content => content)
            .catch(error => this.handleError(error));

    }

    public isLastTermsAndConditionsAccepted(): Promise<boolean> {
        return this.http
            .get(`${this.actionUrl}/is-last-terms-and-conditions-accepted`)
            .toPromise()
            .then(content => content)
            .catch(error => this.handleError(error));

    }
}
