import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, TemplateRef } from '@angular/core';
import { Alert } from './alert';

@Component({
    selector: 'oas-alert-error',
    templateUrl: './alert-error.component.html',
})
export class AlertErrorComponent extends Alert {
    /**
     * Alert title
     */
    @Input() public title: string;

    /**
     * Alert content as plain text
     */
    @Input() public text: string;

    /**
     * Alert content as html code
     */
    @Input() public html: string;

    /**
     * Alert content template to use with SwalPartialDirective
     */
    @Input() public contentTmpl: TemplateRef<any>;

    /**
     * Alert content as HTTP error response
     */
    @Input() public errorResponse: HttpErrorResponse;

    public isDisplayableError(): boolean {
        return this.errorResponse && this.errorResponse.error;
    }

    public isDCOMInternalError(): boolean {
        return this.errorResponse.error.exceptionName === 'DCOMGenericErrorException';
    }

    protected reset(): void {
        this.title = null;
        this.text = null;
        this.html = null;
        this.contentTmpl = null;
        this.errorResponse = null;
    }

    public showHttpError(error: HttpErrorResponse): Promise<any> {
        this.title = null;
        if (error.error && error.error.errors && error.error.errors[0].title) {
            this.title = error.error.errors[0].title;
        }
        this.errorResponse = error;
        return this.show();
    }
}
