import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './not-found.component';
import { AppointmentWithoutRegistrationComponent } from './online/quick-appointment/appointment-without-registration.component';
import { LoginComponent } from './shared/login/login.component';
import { CookiesComponent } from './shared/cookies/cookies.component';
import { CustomerCreateWizardComponent } from './customer/account/create/customer-create-wizard.component';
import { OnlineAppointmentWizardComponent } from './online/appointment/online-appointment-wizard.component';
import { OutletResolverService } from './shared/outlet/outlet-resolver.service';
import { AppointmentCancelLinkComponent } from './customer/appointment-cancel-link/appointment-cancel-link.component';
import { AppointmentResolverService } from './shared/outlet/appointment-resolver.service';
import { DSAComponent } from './shared/DSA/dsa.component';

const appRoutes: Routes = [
    { path: '', redirectTo: 'welcome', pathMatch: 'full' },
    {
        path: 'dda/quick-appointment/:outletId',
        component: AppointmentWithoutRegistrationComponent,
        pathMatch: 'full',
        resolve: {
            outlet: OutletResolverService,
        },
    },
    { path: 'dda/:outletId', component: LoginComponent, pathMatch: 'full' },
    {
        path: 'registration?preRegistrationToken=:preRegistrationToken',
        component: CustomerCreateWizardComponent,
        pathMatch: 'full',
    },
    {
        path: 'cancel/appointment/:uuid/:appointmentId',
        component: AppointmentCancelLinkComponent,
        pathMatch: 'full'
    },

    {
        path: 'quick-appointment',
        component: AppointmentWithoutRegistrationComponent,
        resolve: { outlet: OutletResolverService },
    },
    {
        path: 'update/appointment/:uuid/:appointmentId',
        component: OnlineAppointmentWizardComponent,
        resolve: { appointmentLink: AppointmentResolverService },
        pathMatch: 'full'
    },
    {
        path: 'appointment',
        component: OnlineAppointmentWizardComponent,
    },
    { path: 'backoffice',loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackofficeModule)}, // LazyLoad for Backoffice module
    { path: 'online', loadChildren: () => import('./online/online.module').then(m => m.OnlineModule)}, // LazyLoad for online module
    { path: 'ef-tssb/:tssbId', component: LoginComponent },
    { path: 'cookies', pathMatch: 'full', component: CookiesComponent },
    { path: 'DSA', pathMatch: 'full', component: DSAComponent },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes
            // { enableTracing: true } // debugging purposes only
        ),
    ],
    exports: [RouterModule],
    providers: [OutletResolverService, AppointmentResolverService],
})
export class AppRoutingModule {}
