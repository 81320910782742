import { Pipe, PipeTransform } from '@angular/core';
import { Appointment, AppointmentStatus } from '../entities';

@Pipe({
    name: 'appointmentsHistory',
})
export class AppointmentsHistoryPipe implements PipeTransform {
    transform(appointments: Appointment[], vehicleId?: string): Appointment[] {
        return appointments.filter(appointment =>
            vehicleId
                ? appointment.status === <AppointmentStatus>'DELIVERED' && appointment.vehicleId === vehicleId
                : appointment.status === <AppointmentStatus>'DELIVERED'
        );
    }
}
