import { Component } from '@angular/core';

@Component({
  selector: 'oas-terms-and-conditions-it',
  templateUrl: './terms-and-conditions-it.component.html',
  styleUrls: ['../../terms-and-conditions.component.scss']
})
export class TermsAndConditionsItComponent {

}
