import { AuthGuardOutletAdmin } from './auth-guard-outlet-admin.service';
import { AuthGuardWorkshopManager } from './auth-guard-workshop-manager.service';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForgotPasswordComponent } from './../customer/account/form/forgot-password.component';
import { CustomerCreateWizardComponent } from './../customer/account/create/customer-create-wizard.component';
import { ActivateUserComponent } from './registration/activate-user/activate-user.component';
import { AuthGuardServiceAdvisor } from './auth-guard-s-adv.service';
import { AuthGuardAsm } from './auth-guard-asm.service';
import { AuthGuardCustomer } from './auth-guard-customer.service';
import { AuthService } from './auth.service';
import { LoginComponent } from '../shared/login/login.component';
import { LoginGuard } from './login/login-guard.service';
import { ResetPasswordComponent } from '../customer/account/form/reset-password.component';
import { AuthGuardBackoffice } from './auth-guard-backoffice.service';
import { AuthGuardCic } from './auth-guard-cic.service';
import { AuthGuardServiceAssistant } from './auth-guard-service-assistant.service';
import { AuthGuardNsc } from './auth-guard-nsc.service';
import { AuthGuardCicEp } from './auth-guard-cic-ep.service';
import { AuthGuardCicNsc } from './auth-guard-cic-nsc.service';
import { AuthGuardWelcomeTerminal } from './auth-guard-welcome-terminal.service';
import { AuthGuardRentingProfile } from './auth-guard-renting-profile.service';
import { ExternalSystemStatusResolverService } from '../backoffice/shared/independence/external-system-status-resolver.service';
import { ChangeActivationUserComponent } from './registration/change-activation-user/change-activation-user.component';
import { BackofficeResetPasswordComponent } from '../customer/account/form/backoffice-reset-password.component';
import {WelcomeComponent} from "../shared/welcome/welcome.component";
import {AuthCallbackComponent} from "../shared/auth-callback/auth-callback.component";
import {AuthGuardGroupManagerComponent} from "./auth-guard-group-manager.component";
import { AuthGuardTssb } from './auth-guard-tssb.service';

const routes: Routes = [
    {
       path: 'welcome',
       canActivate: [LoginGuard],
       component: WelcomeComponent,
    },
    {
       path: 'index',
       canActivate: [LoginGuard],
       component: WelcomeComponent,
    },
    {
       path: 'backoffice/login',
       canActivate: [LoginGuard],
       component: LoginComponent,
    },
    {
       path: 'authcallback',
       canActivate: [LoginGuard],
       component: AuthCallbackComponent,
    },
    {
        path: 'registration',
        canActivate: [LoginGuard],
        resolve: {
            externalSystemIsKo: ExternalSystemStatusResolverService,
        },
        data: {
            externalSystem: 'GCDM_ADMIN',
        },
        component: CustomerCreateWizardComponent,
    },
    {
        path: 'forgot-password',
        resolve: {
            externalSystemIsKo: ExternalSystemStatusResolverService,
        },
        data: {
            externalSystem: 'GCDM',
        },
        component: ForgotPasswordComponent,
    },
    {
        path: 'reset-customer-passwordactivation',
        component: ResetPasswordComponent,
    },
    {
        path: 'reset-customer-password',
        component: ResetPasswordComponent,
    },
    {
        path: 'reset-passwordactivation',
        component: BackofficeResetPasswordComponent,
    },
    {
        path: 'reset-password',
        component: BackofficeResetPasswordComponent,
    },
    {
        path: 'activate',
        component: ActivateUserComponent,
    },
    {
        path: 'change-activation',
        component: ChangeActivationUserComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        AuthGuardCustomer,
        AuthGuardAsm,
        AuthGuardServiceAdvisor,
        AuthGuardCic,
        AuthService,
        LoginGuard,
        AuthGuardBackoffice,
        AuthGuardOutletAdmin,
        AuthGuardWorkshopManager,
        AuthGuardServiceAssistant,
        AuthGuardNsc,
        AuthGuardCicEp,
        AuthGuardCicNsc,
        AuthGuardWelcomeTerminal,
        AuthGuardRentingProfile,
        ExternalSystemStatusResolverService,
        AuthGuardGroupManagerComponent,
        AuthGuardTssb
    ],
})
export class AuthRoutingModule {}
