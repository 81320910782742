interface Script {
    name: string;
    src: string;
}
export const ScriptStore: Script[] = [
    { name: 'liveagent', src: 'https://c.la1-c2-fra.salesforceliveagent.com/content/g/js/45.0/deployment.js' },
    { name: 'epaas', src: 'https://www.bmw.com/etc/clientlibs/wcmp/consentcontroller.fallback/epaas.js' },
    { name: 'tracy', src: './assets/tracking/tracy_traking.js' },
    { name: 'livechat', src: 'https://cdn.livechatinc.com/tracking.js' },
    { name: 'jquery', src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js' },
    { name: 'genesys_bmw', src: 'https://cctbank-rp.bmw.com/widgets/bmw/es-nfsc-mad-sidebar/js/bmw.config.js' },
    { name: 'genesys_mini', src: 'https://cctbank-rp.bmw.com/widgets/mini/es-nfsc-mad-sidebar/js/mini.config.js' },
];
