import { Injectable } from '@angular/core';
import { UserInformation } from '../../../shared/entities';
import { AbstractRestService } from '../generic.service';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../../logger.service';

@Injectable()
export class UserInformationService extends AbstractRestService<UserInformation, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/user-information', http, logger);
    }
}
