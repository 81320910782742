import { Injectable } from '@angular/core';
import { CountryConfigurationService } from '../../configuration/country-configuration.service';
import { TechnicalSettingConfiguration } from './technical-setting-configuration';
import { Loader } from "@googlemaps/js-api-loader";
@Injectable()
export class GoogleMapsLoaderService {
    private mapLoader: Loader;
    private promises: Promise<any>[] = [];

    constructor(
      private countryConfigurationService: CountryConfigurationService,
      private technicalSettingConfiguration: TechnicalSettingConfiguration
    ) {
        this.mapLoader = new Loader({
            apiKey: this.technicalSettingConfiguration.getTechnicalSetting('GOOGLE_MAPS_API_KEY'),
            language: this.countryConfigurationService.getCountryConfiguration().language
          });

        this.promises.push(this.mapLoader.importLibrary('places'));
        this.promises.push(this.mapLoader.importLibrary('geometry'));
        this.promises.push(this.mapLoader.importLibrary('geocoding'));
        this.promises.push(this.mapLoader.importLibrary('maps'));
        this.promises.push(this.mapLoader.importLibrary('core'));
        this.promises.push(this.mapLoader.importLibrary('marker'));
    }    

    public load() :Promise<any>{
      return Promise.all(this.promises);
    }
}
