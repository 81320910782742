import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
    ShareBoxAddMember,
    ShareboxCustomer,
    ShareboxEmailLogResponse,
    ShareBoxEventResponse,
    ShareBoxEventSearch,
    ShareBoxEventType,
    ShareBoxLocationResponse,
    ShareboxLogInput,
    ShareBoxOpenLockerInput,
    ShareboxOrder,
    ShareBoxReceiverResponse,
    ShareBoxReportResponse,
    ShareBoxReservationDetailResponse,
    ShareBoxReservationResponse,
    ShareBoxReservationSearch,
    ShareBoxResponseEntity,
    ShareboxSmsLogResponse,
} from '../../../shared/entities';

@Injectable()
export class ShareBoxService {
    private actionUrl: string;

    private _customer$: BehaviorSubject<ShareboxCustomer> = new BehaviorSubject(null);
    public readonly customer$ = this._customer$.asObservable();
    private customer: Observable<ShareboxCustomer>;
    private defaultLocationOrder: ShareboxOrder = { column: 'location', order: 'asc' };
    constructor(private http: HttpClient) {
        this.actionUrl = `${environment.apiUrl}/share-box`;
    }

    getReservation(searchInput: ShareBoxReservationSearch): Observable<ShareBoxReservationResponse> {
        const url = `${this.actionUrl}/reservations`;
        return this.http.post<ShareBoxReservationResponse>(url, searchInput);
    }
    getReservationDetail(reservationId: number): Observable<ShareBoxReservationDetailResponse> {
        const url = `${this.actionUrl}/reservation/${reservationId}`;
        return this.http.get<ShareBoxReservationDetailResponse>(url);
    }
    getCustomer() {
        const url = `${this.actionUrl}/customer`;
        return this.http.get<ShareboxCustomer>(url);
    }
    pushCustomer(customer: ShareboxCustomer) {
        this._customer$.next(customer);
    }
    getCustomer$() {
        this.customer$;
    }
    getCurrentCustomer() {
        return this.customer;
    }
    getLocations(customerId: number, orderBy?: ShareboxOrder[]): Observable<ShareBoxLocationResponse> {
        const orders = orderBy != null ? orderBy : [this.defaultLocationOrder];
        const url = `${this.actionUrl}/locations`;
        return this.http.post<ShareBoxLocationResponse>(url, { customerId, orderBy: orders });
    }

    getLocationsByOutletId(outletId: string): Observable<ShareBoxLocationResponse> {
        const url = `${this.actionUrl}/locations/${outletId}`;
        return this.http.get<ShareBoxLocationResponse>(url);
    }

    openLocker(lockerInput: ShareBoxOpenLockerInput): Observable<ShareBoxResponseEntity<string>> {
        const url = `${this.actionUrl}/open-locker`;
        return this.http.post<ShareBoxResponseEntity<string>>(url, lockerInput);
    }

    getActiveReceiver(reservationId: number): Observable<ShareBoxReceiverResponse> {
        const url = `${this.actionUrl}/active-receivers/${reservationId}`;
        return this.http.get<ShareBoxReceiverResponse>(url);
    }

    getInActiveReceiver(reservationId: number): Observable<ShareBoxReceiverResponse> {
        const url = `${this.actionUrl}/inactive-receivers/${reservationId}`;
        return this.http.get<ShareBoxReceiverResponse>(url);
    }

    getEvents(searchInput: ShareBoxEventSearch): Observable<ShareBoxEventResponse> {
        const url = `${this.actionUrl}/events`;
        return this.http.post<ShareBoxEventResponse>(url, searchInput);
    }

    getEventTypes(): Observable<ShareBoxEventType[]> {
        const url = `${this.actionUrl}/event-types`;
        return this.http.get<ShareBoxEventType[]>(url);
    }

    changeExchangeMethod(reservationId: number, method: string) {
        const url = `${this.actionUrl}/set-exchange-method/${reservationId}/${method}`;
        return this.http.get(url);
    }
    changeSubName(reservationId: number, subname: string) {
        const url = `${this.actionUrl}/set-sub-name/${reservationId}/${subname}`;
        return this.http.get(url);
    }
    addMember(member: ShareBoxAddMember) {
        const url = `${this.actionUrl}/add-member`;
        return this.http.post<ShareBoxResponseEntity<any>>(url, member);
    }
    resetReservation(reservationId: number) {
        const url = `${this.actionUrl}/reset-reservation/${reservationId}`;
        return this.http.get(url);
    }

    getSmsLogs(search: ShareboxLogInput): Observable<ShareboxSmsLogResponse> {
        const url = `${this.actionUrl}/sms-logs`;
        return this.http.post<ShareboxSmsLogResponse>(url, search);
    }

    getEmailLogs(search: ShareboxLogInput): Observable<ShareboxEmailLogResponse> {
        const url = `${this.actionUrl}/email-logs`;
        return this.http.post<ShareboxEmailLogResponse>(url, search);
    }

    getReports(outletId: String): Observable<ShareBoxReportResponse> {
        const url = `${this.actionUrl}/get-reports/${outletId}`;
        return this.http.get<ShareBoxReportResponse>(url);
    }

    addMemberFromAppointmentData(reservation: ShareBoxAddMember): Promise<ShareBoxResponseEntity<any>> {
        const url = `${this.actionUrl}/add-member-from-appointment`;
        return this.http.post<ShareBoxResponseEntity<any>>(url, reservation).toPromise();
    }
}
