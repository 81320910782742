import { Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class StyleSwitcherService {
    public brand = new BehaviorSubject<string>(undefined);
    public renderer: Renderer2;

    constructor() {}

    public changeStyle(brand: string): void {
        if (this.brand.getValue() !== brand) {
            if (this.brand.getValue()) {
                this.renderer.removeClass(document.body, `brand-${this.brand.getValue().toLowerCase()}`);
            }
            this.renderer.addClass(document.body, `brand-${brand.toLowerCase()}`);

            this.brand.next(brand);
        }
    }

    public isBrandSet(): boolean {
        return !!this.brand.getValue();
    }
}
