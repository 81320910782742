import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export abstract class AuthGuard {
    constructor(protected authService: AuthService, protected router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;

        return this.checkLogin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    protected checkLogin(url: string): boolean {
        let result = true;

        if (!this.authService.isAuthorized()) {
            result = false;
            // Store the attempted URL for redirecting
            // this.authService.setRedirectUrl(url);

            // Navigate to the login page with extras
            this.router.navigate(
                ['/welcome']
                // { queryParams: { 'redirectUrl': url } }
            );
        } else if (!this.checkRole()) {
            result = false;
            this.router.navigate([this.authService.getDashboardPath()]);
        }

        return result;
    }

    protected abstract checkRole(): boolean;
}
