import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ScriptService } from '../script.service';
import { CountryConfiguration } from '../../country-configuration';
import { StyleSwitcherService } from '../../core/style-switcher.service';
import { Utils } from '../../core/utils';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { VehicleBrand } from '../entities';

@Component({
    selector: 'oas-support-chat',
    templateUrl: './support-chat.component.html',
    styleUrls: ['./support-chat.component.scss'],
})
export class SupportChatComponent implements OnInit, OnDestroy {
    private countryConfiguration: CountryConfiguration;
    @Input() isAllowToDisplay: boolean;
    constructor(
        private scriptService: ScriptService,
        private styleSwitcherService: StyleSwitcherService,
        countryConfigurationService: CountryConfigurationService
    ) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
    }

    public ngOnDestroy(): void {
        if (this.isSupportChatAvailable() && !Utils.isNullOrUndefined(window['LiveChatWidget'])) {
            this.destroyLiveChat();
        }
    }
    public ngOnChanges(): void {
        if (this.isSupportChatAvailable() && !Utils.isNullOrUndefined(window['LiveChatWidget'])) {
            window['LiveChatWidget'].call(!this.isAllowToDisplay ? 'hide' : 'minimize');
        }
    }
    public ngOnInit() {
        if (this.isSupportChatAvailable()) {
            if (this.countryConfiguration.language === 'es') {
                this.loadGenesysChat();
            } else {
                // portugal
                this.loadLiveChatAgent();
            }
        }
    }

    private loadGenesysChat(): void {
        const brand = this.styleSwitcherService.brand.getValue().toUpperCase() as VehicleBrand;
        this.scriptService
            .load(false, 'jquery',<VehicleBrand>'MINI'=== brand ? 'genesys_mini' : 'genesys_bmw')
            .then(data => {
            })
            .catch(error => {});
    }

    private loadLiveChatAgent(): void {
        this.liveChatAgaentConfig();
        this.scriptService
            .load(false, 'livechat')
            .then(data => {
                // close widget on the welcome greeting if page not allowed
                window['LiveChatWidget'].on('new_event', liveData => {
                    if (liveData.greeting.id === 1 && !this.isAllowToDisplay) {
                        this.hideLiveChat();
                    }
                });
            })
            .catch(error => {});
    }

    private liveChatAgaentConfig(): void {
        window['__lc'] = window['__lc'] || {};
        window['__lc'].license = 12333456;
        const api = {
            _q: [],
            _h: null,
            _v: '2.0',
            on: function () {
                return api._h
                    ? api._h.apply(null, ['on', [].slice.call(arguments)])
                    : api._q.push(['on', [].slice.call(arguments)]);
            },
            once: function () {
                return api._h
                    ? api._h.apply(null, ['once', [].slice.call(arguments)])
                    : api._q.push(['on', [].slice.call(arguments)]);
            },
            off: function () {
                return api._h
                    ? api._h.apply(null, ['off', [].slice.call(arguments)])
                    : api._q.push(['on', [].slice.call(arguments)]);
            },
            get: function () {
                if (!api._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
                return api._h
                    ? api._h.apply(null, ['get', [].slice.call(arguments)])
                    : api._q.push(['on', [].slice.call(arguments)]);
            },
            call: function () {
                return api._h
                    ? api._h.apply(null, ['call', [].slice.call(arguments)])
                    : api._q.push(['on', [].slice.call(arguments)]);
            },
        };
        window['LiveChatWidget'] = window['LiveChatWidget'] || api;
    }
    public isSupportChatAvailable(): boolean {
        return this.countryConfiguration[this.styleSwitcherService.brand.getValue().toUpperCase()].supportChatAvailable;
    }

    public startChat(): void {
        window['liveagent'].startChat('5730Y000000PTGq');
    }

    private hideLiveChat(): void {
        window['LiveChatWidget'].call('hide');
    }
    private destroyLiveChat(): void {
        window['LiveChatWidget'].call('destroy');
    }
}
