import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';


export interface Message {
   type: string;
   payload: any;
}

@Injectable()
export class BroadcastService {

   constructor() { }
   private _handler: Subject<Message> = new Subject<Message>();

   broadcast(type: string, payload: any = null) {
      this._handler.next({ type, payload });
   }

   subscribe(type: string, callback: (payload: any) => void): Subscription {
      return this._handler
      .pipe(
         filter((message: Message) => message.type === type),
         map((message: Message) => message.payload)
      )
      .subscribe(callback);
   }
}
