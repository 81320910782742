<div *ngIf="showTimeslotNotFoundBanner" class="banner-no-timeslot"> 
    <h3 i18n="@@noAvailableTimeslotMessage"> 
        No timeslot available for the selected dates! 
    </h3> 
</div>


<ng-template #timeslotLoadingTemplate> 
    <div class="loading-timeslot"> 
        <h3 class="loading-timeslot-text" i18n="@@loadingTimeslotMessage"> 
            We are looking for an available timeslot 
        </h3> 
    </div> 
</ng-template> 
 


<ngx-loading [show]="show" [template]="timeslotLoadingTemplate"></ngx-loading> 