import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Customer } from '../shared/entities';
import { CustomerService } from '../core/api/customer/customer.service';
import { Utils } from '../core/utils';

@Injectable()
export class DashboardCustomerResolverService {
    constructor(private router: Router, private customerService: CustomerService) {}

    resolve(): Promise<Customer> {
        return this.customerService
            .repairVehiclesLogged()
            .then(() => this.customerService.getLogged(['appointmentList', 'favouriteOutlet']))
            .then((customer: Customer) => {
                if (customer.vehicleList.length > 0) {
                    customer.vehicleList = customer.vehicleList.filter(vehicle => vehicle.status === 'active');
                    return customer;
                } else {
                    // no vehicles
                    this.router.navigate([`/online/dashboard/${customer.id}/vehicle`, { novehicles: true }]);
                    return null;
                }
            })
            .catch(error => {
                Utils.showErrorPopup(error);
                return Promise.reject(error);
            });
    }
}
