import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { AppointmentService } from '../../core/api/appointment/appointment.service';
import { Appointment, BaseResponse } from '../../shared/entities';

@Injectable()
export class AppointmentResolverService {
    constructor(
        private router: Router,
        private appointmentService: AppointmentService,
        private authService: AuthService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<BaseResponse<Appointment>> {
        const appointmentId = route.paramMap.get('appointmentId');
        const fetch = ['vehicle', 'customer', 'activityList', 'mobilityList', 'outlet.skills'];

        return this.appointmentService.getAppointment(appointmentId, fetch).then(response => {
            if (response) {
                return response;
            } else {
                this.router.navigate([this.authService.getDashboardPath()]);
                return null;
            }
        });
    }
}
