import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '../../../app.component';
import { AuthService } from '../../../auth/auth.service';
import { OasValidators } from '../../../core/validator/oas-validators';
import { AlertErrorComponent } from '../../../shared/alert/alert-error.component';
import { dataAnalytics } from '../../../shared/data-analytics';
import { TracyService } from '../../../shared/tracy.service';
import { AlertSuccessComponent } from './../../../shared/alert/alert-success.component';

@Component({
  selector: 'oas-backoffice-reset-password',
  templateUrl: './backoffice-reset-password.component.html',
})
export class BackofficeResetPasswordComponent implements OnInit {
    @ViewChild('alertError') private alertError: AlertErrorComponent;
    @ViewChild('alertSuccess') private alertSuccess: AlertSuccessComponent;

    public errorResponse: HttpErrorResponse;
    public userForm: UntypedFormGroup;
    public minLength = 15;
    public maxLength = 30
    ;
    private token: string;
    public passwordUsed = false;

    constructor(
        private appComponent: AppComponent,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private tracyService: TracyService
    ) {
        this.setDataAnalytics();
    }

    public ngOnInit() {
        this.route.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            this.token = params['token'];
        });
        this.createForm();
    }

    private createForm() {
        this.userForm = this.formBuilder.group(
            {
                password: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(this.minLength),
                        Validators.maxLength(this.maxLength),
                        (pw: AbstractControl) => OasValidators.userCompliantPassword(pw),
                    ]),
                ],
                confirmPassword: [''],
            }
        );
    }

    public matchPassword(): ValidationErrors | null {
        let result: ValidationErrors = null;

        if (this.userForm.get('password').value !== this.userForm.get('confirmPassword').value) {
            result = { matchPassword: true };
        }
        this.userForm.get('confirmPassword').setErrors(result);

        return result;
    }

    public setPassword(): void {
        this.passwordUsed = false;
        this.appComponent.loading = true;
        this.authService
            .setPassword(this.token, this.userForm.get('password').value)
            .then(() => {
                this.appComponent.loading = false;
                this.alertSuccess.show();
                this.setAlertDataAnalytics();
                this.router.navigate(['/backoffice/login']);
            })
            .catch((errorResponse: HttpErrorResponse) => {
                this.errorResponse = errorResponse;
                this.appComponent.loading = false;
                if (errorResponse.status === 401) {
                    this.passwordUsed = true;
                } else {
                    this.alertError.show();
                }
            });
    }

    private setDataAnalytics() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasPwdActivation,
            dataAnalytics.page.variant.real
        );
        this.tracyService.sendPageView(dataAnalytics.referenceName.pwdActivation);
    }

    private setAlertDataAnalytics() {
        this.tracyService.sendPageView(dataAnalytics.referenceName.pwdActivationSuccess);
        this.tracyService.setAlertDataAnalytics(
            dataAnalytics.activityMap.region.oas,
            dataAnalytics.activityMap.link.pwdActivated
        );
    }
}


