<div class="registration-page">
    <div class="container-logo-absolute">
        <oas-logo></oas-logo>
    </div>
    <div class="registration-page__mobile">
        <ng-container *ngTemplateOutlet="vehiclePictureTmpl"></ng-container>
    </div>
    <div class="registration-page__row-wrapper">
        <div class="registration-page__form-wrapper">
            <div class="registration-page__form">
                <a
                    *ngIf="!novehicles"
                    routerLink="/online/dashboard"
                    routerLinkActive="active"
                    class="dismiss-request text-primary"
                >
                    <i class="fa fa-chevron-left"> </i>
                    <span i18n="@@backToDashboard">Back to dashboard</span>
                </a>
                <h1 class="registration-form__title" i18n="@@selectCar">Select a car</h1>
                <oas-vehicle-form
                    #vehicleForm
                    [vehicle]="vehicle"
                    (selectedModelChange)="onSelectedModelChange($event)"
                    [submitted]="submitted"
                    (vinChange)="vin = $event"
                ></oas-vehicle-form>
                <div class="row">
                    <div class="col-md-12">
                        <div class="navigation__buttons">
                            <div class="registration__add-vehicle--wrapper">
                                <button
                                    class="ui-button btn btn-primary"
                                    i18n="@@previous"
                                    (click)="vehicleForm.back(); reset(vehicleForm.vehicleForm)"
                                    *ngIf="vehicleForm.isManual == 1 && !novehicles"
                                >
                                    Go back
                                </button>
                               <button class="ui-button btn btn-primary" style="margin-right: 20px; font-family: bmwTypeWebBoldAll, Arial, Helvetica, sans-serif;" *ngIf="novehicles">
                                  <span><oas-logout [isWhiteColor]="true"></oas-logout></span>
                               </button>
                                <button
                                    *ngIf="vehicle && vehicle.id; else addVehicleButton"
                                    class="btn btn-primary ui-button"
                                    (click)="createOrUpdateVehicle(vehicleForm)"
                                    i18n="@@saveVehicle"
                                >
                                    Save vehicle
                                </button>
                                <ng-template #addVehicleButton>
                                    <button
                                        class="btn btn-primary ui-button"
                                        (click)="createOrUpdateVehicle(vehicleForm)"
                                        i18n="@@addVehicle"
                                    >
                                        Add this vehicle
                                    </button>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngTemplateOutlet="vehiclePictureTmpl"></ng-container>
    </div>
</div>

<oas-alert-success #alertSuccess></oas-alert-success>
<oas-alert-error #alertError [errorResponse]="errorResponse"></oas-alert-error>

<ng-template #vehiclePictureTmpl>
    <div *ngIf="selectedVehicle" class="registration-page__vehicle-wrapper">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <oas-vehicle-picture-ucp
                        [vin]="vin"
                        [vehicleSeriesId]="selectedVehicle?.series?.id"
                    ></oas-vehicle-picture-ucp>
                </div>
            </div>
        </div>
    </div>
</ng-template>
