import {Component, Input} from '@angular/core';
import {CountryConfigurationService} from "../../../configuration/country-configuration.service";
import {CountryConfiguration} from "../../country-configuration";
import {StyleSwitcherService} from "../../core/style-switcher.service";

@Component({
    selector: 'oas-customer-wizard',
    templateUrl: './customer-wizard.component.html',
})
export class CustomerWizardComponent {
    @Input() public enableVehiclePictureUcp: boolean;
    @Input() public vin: string;
    @Input() public vehicleSeriesId: string;
    @Input() public loading: boolean;
    public local: string;
    countryConfiguration: CountryConfiguration;

    constructor(countryConfigurationService: CountryConfigurationService, private styleSwitcherService: StyleSwitcherService) {
       this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
       this.local = this.countryConfiguration.locale;
    }

    showNewBackgroundImage(){
      return ['it-IT', 'es-ES', 'pt-PT'].includes(this.local)
         && this.styleSwitcherService.isBrandSet()
         && this.styleSwitcherService.brand.getValue().toLowerCase() === 'bmw_motorrad';
    }
}
