import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CountryConfigurationService } from '../../../../configuration/country-configuration.service';
import { StyleSwitcherService } from '../../../core/style-switcher.service';
import { CountryConfiguration } from '../../../country-configuration';
import { VehicleBrand } from '../../../shared/entities';

@Component({
    selector: 'oas-customer-form-without-registration-terms',
    templateUrl: './customer-form-without-registration-terms.component.html',
    styleUrls: ['./customer-form-without-registration-terms.component.scss'],
})
export class CustomerFormWithoutRegistrationTermsComponent implements OnInit {
    @Output() public onChecked = new EventEmitter<boolean>();
    private isTermsAndConditionsChecked: boolean;

    public brand: VehicleBrand;
    public privacyPolicyLink: string;
    public countryConfiguration: CountryConfiguration;

    constructor(
        private countryConfigurationService: CountryConfigurationService,
        private styleSwitcherService: StyleSwitcherService
    ) {
        this.countryConfiguration = this.countryConfigurationService.getCountryConfiguration();
    }

    ngOnInit() {
        this.brand = <VehicleBrand>this.styleSwitcherService.brand.getValue().toUpperCase();
        this.privacyPolicyLink = this.countryConfiguration[this.brand].footer.privacyPolicyLink;
    }

    public setTermsAndConditions(isTermsAndConditionsChecked: boolean): void {
        this.isTermsAndConditionsChecked = isTermsAndConditionsChecked;
        this.onChecked.emit(isTermsAndConditionsChecked);
    }

    public isTermsAndConditionsAccepted() {
        return this.isTermsAndConditionsChecked;
    }
}
