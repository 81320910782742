import { AfterContentChecked, Component, Input, TemplateRef } from '@angular/core';
import { Alert } from './alert';

@Component({
    selector: 'oas-alert-confirm',
    templateUrl: './alert-confirm.component.html',
})
export class AlertConfirmComponent extends Alert implements AfterContentChecked {
    ngAfterContentChecked(): void {
        const doc = document.getElementsByClassName('swal2-confirm')[0];
        if(doc) {
            doc.setAttribute('id', 'warning-confirm');
        }
    }
    /**
     * Alert title
     */
    @Input() public title: string;

    /**
     * Alert content as plain text
     */
    @Input() public text: string;

    /**
     * Alert content as html code
     */
    @Input() public html: string;

    /**
     * Alert title template to use with SwalPartialDirective
     */
    @Input() public titleTmpl: TemplateRef<any>;

    /**
     * Alert content template to use with SwalPartialDirective
     */
    @Input() public contentTmpl: TemplateRef<any>;

    /**
     * Alert confirm button template to use with SwalPartialDirective
     */
    @Input() public confirmButtonTmpl: TemplateRef<any>;

    /**
     * Alert cancel button template to use with SwalPartialDirective
     */
    @Input() public cancelButtonTmpl: TemplateRef<any>;

    protected reset(): void {
        this.title = null;
        this.text = null;
        this.html = null;
        this.titleTmpl = null;
        this.contentTmpl = null;
        this.confirmButtonTmpl = null;
        this.cancelButtonTmpl = null;
    }
}
