import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Logger } from './../../logger.service';
import { VehicleBrand, VehicleModel } from './../../../shared/entities';
import { AbstractRestService } from '../generic.service';

@Injectable()
export class VehicleModelService extends AbstractRestService<VehicleModel, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/vehicle-model', http, logger);
    }

    public getAllByVehicleBrands(vehicleBrands: Set<VehicleBrand>): Promise<VehicleModel[]> {
        const params = new HttpParams().set('vehicleBrands', Array.from(vehicleBrands).join(','));

        return this.http
            .get(this.actionUrl, { params: params })
            .toPromise()
            .then(response => response as VehicleModel[])
            .catch(error => this.handleError(error));
    }
}
