import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Logger } from '../../logger.service';
import {
    BlobFile,
    LeadTimeAverage,
    LeadTimeExtractionDateRange,
    PaginatedLeadTime,
} from '../../../shared/entities';
import { ApiUtils } from '../utils/utils';
import { Utils } from '../../utils';

@Injectable()
export class LeadTimeService {
    private actionUrl: string;

    constructor(protected http: HttpClient, protected logger: Logger) {
        this.actionUrl = `${environment.apiUrl}/lead-times`;
    }

    public async exportNscLeadTimeCsv(from: Date, to: Date): Promise<BlobFile> {
        const url = `${this.actionUrl}/reports`;
        const params = new HttpParams()
            .set('startCalculationDate', Utils.getDateFromDateTime(from))
            .set('endCalculationDate', Utils.getDateFromDateTime(to));

        return this.downloadCsv(url, params);
    }

    public async exportLeadTimeOutletsCsv(page: number, size: number, sort?: string): Promise<BlobFile> {
        const url = `${this.actionUrl}/csv`;

        let params = new HttpParams().set('page', page.toString()).set('size', size.toString());

        if (!Utils.isNullOrUndefined(sort)) {
            params = params.set('sort', sort);
        }

        return this.downloadCsv(url, params);
    }

    private async downloadCsv(url: string, params: HttpParams): Promise<BlobFile> {
        try {
            const response = await this.http
                .get(url, { params: params, observe: 'response', responseType: 'blob' })
                .toPromise();
            const BOM = '\uFEFF';
            return {
                blob: new Blob([BOM, response.body], { type: 'text/csv;charset=utf-8' }),
                filename: ApiUtils.retrieveFileName(response),
            };
        } catch (error) {
            return await ApiUtils.handleError(error, this.logger);
        }
    }

    public async getAverage(): Promise<LeadTimeAverage> {
        const url = `${this.actionUrl}/average`;

        try {
            const response = await this.http.get(url).toPromise();
            return response as LeadTimeAverage;
        } catch (error) {
            return await ApiUtils.handleError(error, this.logger);
        }
    }

    public async getLastLeadTimes(page: number, size: number, sort?: string): Promise<PaginatedLeadTime> {
        const url = `${this.actionUrl}`;

        let params = new HttpParams().set('page', page.toString()).set('size', size.toString());

        if (!Utils.isNullOrUndefined(sort)) {
            params = params.set('sort', sort);
        }

        try {
            const response = await this.http.get(url, { params: params }).toPromise();
            return response as PaginatedLeadTime;
        } catch (error) {
            return await ApiUtils.handleError(error, this.logger);
        }
    }

    public async getLeadTimeExtractionDateRange(): Promise<LeadTimeExtractionDateRange> {
        const url = `${this.actionUrl}/report/available-date-range`;

        try {
            const response = await this.http.get(url).toPromise();
            return response as LeadTimeExtractionDateRange;
        } catch (error) {
            return await ApiUtils.handleError(error, this.logger);
        }
    }
}
