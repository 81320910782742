import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {Observable} from "rxjs";

@Injectable({
   providedIn: 'root'
})
export class MessageService{
   private subject$ = new Subject();

   sendMessage(message: any) {
      this.subject$.next({ data: message });
   }

   getMessage(): Observable<any> {
      return this.subject$;
   }
}
