<div class="vehicle-form-container" [formGroup]="vehicleForm">
    <div *ngIf="!isPlateFormDisabled()" class="col-md-6">
        <div class="col align-self-center">
            <div class="ui-input-wrapper">
                <label i18n="@@plate">Plate</label>
                <input
                    formControlName="plate"
                    class="ui-input login-form__input transform-uppercase"
                    type="text"
                    id="plate"
                    name="plate"
                    i18n-placeholder="@@plate"
                    placeholder="Plate"
                />
            </div>

            <div>
                <ng-container *ngTemplateOutlet="validationError; context: { control: vehicleForm.get('plate') }">
                </ng-container>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <div class="col align-self-center">
            <div class="ui-input-wrapper">
                <label i18n="@@kmEstimated">Estimated KM</label>
                <input
                    formControlName="mileage"
                    class="ui-input login-form__input"
                    type="number"
                    id="km"
                    name="km"
                    i18n-placeholder="@@estimatedKm"
                    placeholder="Estimated kilometers*"
                    min="0"
                />
            </div>

            <div>
                <ng-container *ngTemplateOutlet="validationError; context: { control: vehicleForm.get('mileage') }">
                </ng-container>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <div class="col align-self-center">
            <div class="ui-input-wrapper">
                <div class="input-group assistance-search dealer__assistance-search">
                    <label i18n="@@model">Model</label>
                    <input
                        formControlName="model"
                        type="text"
                        class="form-control ui-typeahead"
                        i18n-placeholder="@@searchForModel"
                        placeholder="Search for model"
                        [typeahead]="vehicleModels"
                        [typeaheadScrollable]="true"
                        [typeaheadOptionsInScrollableView]="5"
                        typeaheadOptionField="modelLabel"
                        (typeaheadOnSelect)="onSelectModel($event.item)"
                    />
                </div>

                <div>
                    <ng-container *ngTemplateOutlet="validationError; context: { control: vehicleForm.get('model') }">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="navigation__buttons">
        <div class="col next-button">
            <div *ngIf="!hideBackButton" class="prev-step__button-wrapper">
                <button id="backVehicleManualBtn" class="ui-button btn btn-secondary" i18n="@@previous" (click)="onBackButtonClicked()">
                    Go back
                </button>
            </div>

            <div class="next-step__button-wrapper">
                <button id="proceedVehicleManualBtn" class="ui-button btn btn-primary" i18n="@@proceed" (click)="onContinueButtonClicked()">
                    Continue
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #validationError let-control="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched || submitted)" class="ui-input-wrapper">
        <div class="errors">
            <p *ngIf="control.errors.required" i18n="@@required">
                Required!
            </p>
            <p *ngIf="control.errors.plate" i18n="@@notCompliantPlate">
                Plate not compliant!
            </p>
        </div>
    </div>
</ng-template>
