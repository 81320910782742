import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';

@Component({
    selector: 'oas-dsa',
    templateUrl: './dsa.component.html',
    styleUrls: ['./dsa.component.scss'],
})
export class DSAComponent implements OnInit{

    public countryCode: string;

    constructor(public appComponent: AppComponent, private router: Router, countryConfigurationService: CountryConfigurationService) {
        this.countryCode = countryConfigurationService.getCountryConfiguration().countryCode;
        // prevent scrolling to bottom fix for Chrome
        window.scrollTo(0, 1);
    }

    ngOnInit() {
        window.scrollTo(0, 1);
    }

    public goToWelcome(): void {
        this.router.navigate(['/welcome']);
    }

}
