<form [formGroup]="userForm">
    <div class="ui-input-wrapper">
        <input
            type="password"
            class="ui-input login-form__input registration__icon-password"
            i18n-placeholder="@@oldPassword"
            placeholder="Old Password"
            formControlName="oldPassword"
            [(ngModel)]="edit.oldPassword"
        />
    </div>
    <ng-container
        *ngTemplateOutlet="passwordValidationError; context: { control: userForm.get('oldPassword') }"
    ></ng-container>

    <div class="ui-input-wrapper">
        <input
            [tooltip]="pswTooltip"
            type="password"
            class="ui-input login-form__input registration__icon-password"
            i18n-placeholder="@@newPassword"
            placeholder="New Password"
            formControlName="password"
            [(ngModel)]="edit.password"
        />
    </div>
    <ng-container
        *ngTemplateOutlet="passwordValidationError; context: { control: userForm.get('password') }"
    ></ng-container>

    <div class="ui-input-wrapper">
        <input
            type="password"
            class="ui-input login-form__input registration__icon-password"
            i18n-placeholder="@@confirmPassword"
            placeholder="Confirm Password"
            formControlName="confirmPassword"
        />
    </div>
    <ng-container
        *ngTemplateOutlet="matchPasswordValidationError; context: { control: userForm.get('confirmPassword') }"
    ></ng-container>
</form>

<ng-template #passwordValidationError let-control="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched)" class="ui-input-wrapper">
        <div class="errors">
            <p *ngIf="control.errors.required" i18n="@@required">
                Required!
            </p>
            <p *ngIf="control.errors.minlength" i18n="@@minLength">Minimum length is {{ minLength }}!</p>
            <p *ngIf="control.errors.maxlength" i18n="@@maxLength">Maximum length is {{ maxLength }}!</p>
            <p *ngIf="control.errors.compliantPassword" i18n="@@notCompliantPassword">
                Password not compliant!
            </p>
        </div>
    </div>
</ng-template>

<ng-template #matchPasswordValidationError let-control="control">
    <div
        *ngIf="control.invalid && (control.dirty || control.touched) && control.errors.matchPassword"
        class="ui-input-wrapper"
    >
        <div class="errors">
            <p i18n="@@invalidPassword">Password not match!</p>
        </div>
    </div>
</ng-template>

<ng-template #pswTooltip>
    <div style="text-align: left;" i18n="@@passwordRules">
        Password cannot be part of your email address and must contain at least 2 out of the following 3 groups:
        <ul>
            <li>letters</li>
            <li>digits</li>
            <li>special characters</li>
        </ul>
        Allowed special characters:
        <ul>
            <li>- . / ' , ; &amp; @ # * ) ( _ + : &quot; ´ ` ~</li>
        </ul>
    </div>
</ng-template>
