<div class="login-page" [ngClass]="showNewBackgroundImage() && currentBrand == 'BMW_MOTORRAD' ? 'login-page-motorrad-italy' : ''">
    <div class="container-logo-login">
        <oas-logo [loginLogo]="true"></oas-logo>
    </div>
    <div *ngIf="loginPageBannerText?.length > 0" class="banner-container">
        <h3 class="banner-title">{{ loginPageBannerTitle }}</h3>
        <span [innerHTML]="loginPageBannerText"></span>
    </div>
    <div class="login-page__mobile"></div>
    <div class="login-page__row-wrapper">
        <div class="login-page__form-wrapper">
            <div class="login-page__form" style="margin-left: 0">
                <ng-container *ngIf="multirole; else loginTmpl">
                    <h2 class="login-form__title" i18n="@@yourRole">Your role</h2>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="ui-select-wrapper">
                                <select
                                    class="ui-select login-form__input"
                                    [formControl]="roleFormControl"
                                    placeholder="Role"
                                    i18n-placeholder="@@role"
                                >
                                    <option value="" disabled selected hidden i18n="@@role">Role</option>
                                    <option *ngFor="let role of roles" [value]="role">
                                        {{ role | translate }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button
                                type="button"
                                class="btn btn-primary btn-block login-form__submit ui-button"
                                form="login-form"
                                i18n="@@goToDasboard"
                                [disabled]="!roleFormControl.valid"
                                (click)="goToDasboard()"
                            >
                                Go to dashboard
                            </button>
                        </div>
                    </div>
                </ng-container>
                <ng-template #loginTmpl>
                    <h1 class="login-form__title" i18n="login header app@@loginHeader">Welcome</h1>
                    <form id="login-form" [formGroup]="loginForm" (ngSubmit)="login(username, pwd.value)">
                        <div class="ui-input-wrapper input-group">
                            <div class="input-group-addon">
                                <i class="fa fa-user text-primary"></i>
                            </div>
                            <ng-container *ngIf="isBmw(); else miniLogin">
                                <input
                                    #usernameBmw
                                    type="text"
                                    class="ui-input"
                                    placeholder="Username"
                                    i18n-placeholder="@@usernameEmail"
                                    formControlName="username"
                                    (change)="username = usernameBmw.value"
                                />
                                <div class="input-group-addon-right">
                                    <i
                                        class="fa fa-info text-primary"
                                        i18n-tooltip="@@usernameConnectedDriveTooltipMessage"
                                        tooltip="If you are already registered on Connected Drive, use your credential and then update your profile to complete your registration"
                                        [containerClass]="'input-group-addon-tooltip'"
                                    ></i>
                                </div>
                            </ng-container>
                            <ng-template #miniLogin>
                                <input
                                    #usernameMini
                                    type="text"
                                    class="ui-input"
                                    i18n-placeholder="@@usernameEmailMini"
                                    placeholder="MyMINI username"
                                    formControlName="username"
                                    (change)="username = usernameMini.value"
                                />
                                <div class="input-group-addon-right">
                                    <i
                                        class="fa fa-info text-primary"
                                        i18n-tooltip="@@usernameMiniConnectedTooltipMessage"
                                        tooltip="If you are already registered on Mini Connected, use your credential and then update your profile to complete your registration"
                                        [containerClass]="'input-group-addon-tooltip'"
                                    ></i>
                                </div>
                            </ng-template>
                        </div>
                        <div class="ui-input-wrapper input-group">
                            <div class="input-group-addon">
                                <i class="fa fa-lock text-primary"></i>
                            </div>
                            <input
                                #pwd
                                type="password"
                                class="ui-input"
                                i18n-placeholder="@@password"
                                placeholder="Password"
                                formControlName="password"
                            />
                        </div>
                        <div
                            *ngIf="invalidCredential"
                            class="alert alert-danger"
                            role="alert"
                            i18n="@@notValidUsername"
                        >
                            Username or password not valid!
                        </div>
                        <div *ngIf="accountLocked" class="alert alert-danger" role="alert" i18n="@@accountLocked">
                            Account Blocked!
                        </div>
                        <div *ngIf="generalError" class="alert alert-danger" role="alert" i18n="@@generalError">
                            Generic error. Please contact support.
                        </div>
                        <div *ngIf="externalSystemErrorMessage" class="alert alert-danger" role="alert">
                            {{ externalSystemErrorMessage }}
                        </div>
                        <div data-tracking-regionid="online service appointment">
                            <a
                                (click)="goToForgotPassword()"
                                routerLinkActive="active"
                                class="text-primary login__forgot-password"
                                i18n="@@forgotPassword"
                                data-tracking-linkid="local > recover password opened"
                            >
                                Forgot password?
                            </a>
                        </div>
                        <div data-tracking-regionid="online service appointment">
                            <button
                                type="submit"
                                class="btn btn-primary btn-block ui-button responsive-button-login"
                                [ngClass]="isAppointmentWithoutRegistrationEnabled() ? 'login-form__submit' : ''"
                                form="login-form"
                                i18n="@@logIn"
                                data-tracking-linkid="local > logged in"
                            >
                                Log in
                            </button>
                        </div>
                    </form>
                    <!--<div>
                        <button
                            type="button"
                            *ngIf="isAppointmentWithoutRegistrationEnabled()"
                            (click)="changeShowForm()"
                            class="btn btn-secondary-original btn-block login-form__submit ui-button responsive-button-login"
                            i18n="@@requestAppointmentWithoutRegistration"
                            data-tracking-linkid="local > appointment without registration"
                        >
                            Appointment without registration
                        </button>
                    </div>
                    <div class="login__create-account" data-tracking-regionid="online service appointment">
                        <span i18n="@@OrCreateA">or create a</span>
                        <a
                            data-tracking-linkid="local > registration form opened"
                            (click)="goToRegister(); dataAnalyticsNewAccountEvent()"
                            routerLinkActive="active"
                            i18n="@@newAccount"
                            >new account</a
                        >
                    </div>-->
                </ng-template>
            </div>
        </div>
        <div class="modal-background" *ngIf="showForm" (click)="closeModal()">
            <div class="modal-home" *ngIf="showForm">
                <div class="modal-header">
                    <h4 i18n="@@advantageNoQuickAppointment">Advantages of a complete record</h4>
                </div>
                <div class="modal-body">
                    <div class="text">
                        <p i18n="@@noQuickAppointmentInfo">
                            You have selected to request an appointment without making a complete record of your data.
                            We inform you that by registering you will obtain the following benefits:
                        </p>
                        <p i18n="@@noQuickAppointmentInfo1">1. Digital profile with the data of your vehicle.</p>
                        <p i18n="@@noQuickAppointmentInfo2">2. Notifications to the telephone / email.</p>
                        <p i18n="@@noQuickAppointmentInfo3">3. Possibility of modifying the online appointment.</p>
                    </div>
                    <div class="buttons" data-tracking-regionid="online service appointment ">
                        <button
                            (click)="goToQuickAppointment()"
                            class="btn btn-secondary-original btn-block login-form__submit ui-button responsive-button"
                            routerLinkActive="active"
                            i18n="@@withoutRegistration"
                            data-tracking-linkid="local > continue without registration"
                        >
                            Appointment without registration
                        </button>
                        <button
                            (click)="goToRegister()"
                            class="btn btn-primary btn-block login-form__submit ui-button responsive-button"
                            routerLinkActive="active"
                            i18n="@@completeAndBookAppointment"
                            data-tracking-linkid="local > register and book appointment"
                        >
                            Complete registration and book appointment
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<oas-alert-warning ></oas-alert-warning >
<ng-template #tokenOrAppointmentInvalid>
   <h2 i18n="@@titleTokenOrAppointmentInvalid" >
    Not possible to update appointment
   </h2>
   <span i18n="@@bodyTokenOrAppointmentInvalid">
    It is not possible to update this appointment in the platform. Please try to
    contact your dealer to update this appointment.
   </span>
</ng-template>
<ng-template #alertPasswordExpiredTitleTmpl>
    <span i18n="@@passwordExpired">
        Your password has expired. Please reset your password by clicking "Forgot password” before attempting to login again.
    </span>
</ng-template>
<ng-template #alertPasswordExpiredTextTmpl>
    <span></span>
</ng-template>
