<div class="condition-container">
  <h2>
    Servicio de Solicitud de Cita de Taller “on-line” BMW/MINI/Motorrad: Términos y Condiciones de Uso
  </h2>

  <h4>
    1.Introducción
  </h4>

  <p>
    Los presentes Términos y Condiciones de Uso (en adelante, los “Términos y Condiciones”) regulan la utilización del
    servicio de solicitud de cita de taller “on-line”que BMW Ibérica, S.A. ofrece a través de los portales de Internet
    <a href="https://cita-online-taller.bmw.es/">https://cita-online-taller.bmw.es/</a>,
    <a href="https://cita-online-taller.mini.es/">https://cita-online-taller.mini.es/</a> y <a
      href="https://cita-online-taller.bmw-motorrad.es/">https://cita-online-taller.bmw-motorrad.es/</a>
    (en adelante, los “Portales”) para las marcas <span class="text-bold">BMW</span>, <span class="text-bold">MINI</span> y
    <span class="text-bold">BMW-Motorrad</span> (en adelante, también denominado el “<span class="text-bold">Servicio</span>”).
  </p>
  <p>
    La sociedad responsable de los Portales es BMW Ibérica, S.A, con CIF A-28713642,domicilio social en Avda. de Burgos
    nº 118, C.P. 28050,Madrid, España e inscrita en el Registro Mercantil de Madrid al Tomo 6.092,General 5.132,de la
    Sección 3ª del Libro de Sociedades, Folio 127, Hoja nº 50.686, Inscripción 1ª (en adelante, también denominada
    “<span class="text-bold">BMW</span>”).
  </p>
  <div>
    <p>Si desea contactar con BMW, puede hacerlo a través de los siguientes medios:</p>
    <ul>
      <li>
        Correo electrónico: <a href="mailto:rclientes@bmw.es">rclientes@bmw.es</a>
      </li>
      <li>
        Teléfono: <a href="tel:+34900357902">900357902</a>
      </li>
    </ul>
  </div>
  <p>
    Los Portales permiten solicitar y gestionar citas con los concesionarios y talleres autorizados BMW, MINI y Motorrad
    que integran su red en España (en adelante denominados los “<span class="text-bold">Concesionarios y Talleres
      Autorizados</span>”). Los
    Concesionarios y Talleres Autorizados son entidades diferenciadas e independientes de BMW Ibérica, S.A.
  </p>
  <p class="underline-bold">
    BMW Ibérica, S.A. no presta servicio alguno de reparación o de asistencia técnica, servicios estos que se
    regirán, en su caso, por términos y condiciones específicos aprobados por cada Concesionario o Taller Autorizado.
  </p>

  <br/>

  <h4>
    2.Navegación por los Portales sin uso del servicio de solicitud de cita de taller “<em>on-line</em>”.
  </h4>

  <p>
    La navegación por los Portales que no implique la utilización del Servicio se rige por los siguientes Avisos Legales:
  </p>
  <ul>
    <li>
      <span class="underline-bold">Aviso Legal de BMW y MINI</span> (<a
        href="https://www.bmw.es/es/footer/metanavigation/legal-disclaimer/aviso-legal.html">enlace</a>) para los
      Portales <a href="https://cita-online-taller.bmw.es/">https://cita-online-taller.bmw.es/</a> y
      <a href="https://cita-online-taller.mini.es/">https://cita-online-taller.mini.es/</a>
    </li>
    <li>
      <span class="underline-bold">Aviso Legal de Motorrad para el Portal</span> (<a
        href="https://www.bmw-motorrad.es/es/footer/metanavigation/legal-disclaimer/aviso-legal.html">enlace</a>) <a
        href="https://cita-online-taller.bmw-motorrad.es/">https://cita-online-taller.bmw-motorrad.es/</a>
    </li>
  </ul>

  <br/>

  <h4>
    3.Acceso y uso del servicio de solicitud de cita de taller “on-line”
  </h4>
  <p>
    Al completar los formularios que figuran en los Portales para hacer uso del Servicio, el solicitante adquiere la
    condición de usuario (en adelante, el “Usuario”), quedando obligado por los presentes Términos y Condiciones.
    <span class="text-bold"> El uso
      del Servicio requiere la aceptación de los Términos y Condiciones.</span>
  </p>
  <p>
    El Servicio está dirigido a mayores de 18 años, propietarios o conductores legítimos de vehículos de las marcas BMW,
    MINI o Motorrad, y se presta para todo el territorio nacional español.
  </p>
  <p>
    El Usuario podrá hacer uso del Servicio, que se presta a través de dos modalidades:
  </p>
  <p class="text-bold">
    A. Solicitud de cita para Usuarios registrados que disponen del ID único de BMW
  </p>
  <p>
    Bayerische Motoren Werke Aktiengesellschaft, Petuelring 130, 80788 Múnich (en adelante, “<span class="text-bold">BMW
      AG</span>”), empresa matriz del
    Grupo BMW, le ofrece la posibilidad de crear una cuenta personal de cliente on-line que le
    permitirá acceder a todas las aplicaciones sin necesidad de volver a inscribirse, y especialmente a páginas web y
    aplicaciones del Grupo BMW y sus marcas. Usted podrá usar una única contraseña para administrar todos sus datos
    personales desde un solo lugar.
  </p>
  <p>
    Si usted desea crear esta cuenta y obtener el ID único de BMW, deberá realizar un registro adicional, sometido a
    términos y condiciones de uso específicos. Consulte más información aquí enlace.
  </p>
  <p>
    Si usted accede al Servicio como Usuario registrado, dispondrá de las siguientes funcionalidades adicionales a la
    gestión de su cita:
  </p>
  <ul>
    <li>
      Visualización del perfil digital de su vehículo (información técnica relativa al mismo).
    </li>
    <li>
      Posibilidad de modificación on-line de la cita ya concertada.
    </li>
    <li>
      Información sobre los servicios de mantenimiento que están próximos a expirar, de forma que podrá para planificar
      sus citas dentro de plazo.
    </li>
  </ul>
  <p class="text-bold">
    B. Solicitud de cita sin registro
  </p>
  <p>
    El Servicio puede utilizarse sin necesidad de registro previo de ningún tipo. En este caso, el Usuario
    <span class="underline-bold">no dispondrá</span> de
    las siguientes funcionalidades adicionales:
  </p>
  <ul>
    <li>
      Visualización del perfil digital de su vehículo (información técnica relativa al mismo).
    </li>
    <li>
      Posibilidad de modificación on-line de la cita ya concertada.
    </li>
    <li>
      Información sobre los servicios de mantenimiento que están próximos a expirar.
    </li>
  </ul>
  <p>
    El presente servicio de solicitud de cita de taller “on-line” prestado por BMW a través de los Portales tiene
    carácter gratuito.No obstante, el servicio técnico posterior de asistencia o reparación prestado por los
    Concesionarios y Talleres Autorizados podría implicar un coste económico para el Usuario.
  </p>
  <p>
    Se recuerda al Usuario que los Concesionarios y Talleres Autorizados son entidades jurídicas distintas e
    independientes de BMW Ibérica, S.A. que se rigen por sus propios términos y condiciones de uso.
  </p>
  <p>
    La presentación de productos o servicios en pantalla, respecto de los cuales el usuario puede gestionar una cita, no
    constituye en sí una oferta de venta o de servicios propiamente dichos desde un punto de vista contractual, sino que
    se trata de una oferta de información cuyas condiciones finales de contratación serán las establecidas por la
    empresa prestadora dentro de la red de Concesionarios y Talleres Autorizados. Si en algún momento se indicara algún
    precio de servicios prestados por Concesionarios o Talleres Autorizados en pantalla, éste tendrá meramente carácter
    orientativo.
  </p>

  <br/>

  <h4>
    4. Obligaciones del Usuario
  </h4>
  <p>
    <span class="text-bold">Uso de claves y contraseñas de acceso</span>
  </p>
  <p>
    Con carácter general, los Usuarios podrán acceder a los Portales de forma libre y gratuita. No obstante, BMW se
    reserva, respetando en todo caso la legislación vigente, el derecho de limitar el acceso a determinadas áreas de los
    Portales a Usuarios registrados.
  </p>
  <p>
    El Usuario elegirá su propio identificador y contraseña cuando así lo indique el Servicio. Para la elección del
    identificador, el Usuario no podrá optar por expresiones, palabras o conjuntos de letras que puedan resultar
    insultantes, injuriosos o contrarios a la ley, a la moral y a las buenas costumbres. De igual modo, tampoco podrá
    utilizar marcas, rótulos de establecimientos, expresiones publicitarias, nombres y pseudónimos de personajes
    públicos o conocidos sin su autorización.
  </p>
  <p>
    El uso de la contraseña es personal e intransferible no estando permitida la cesión, ni siquiera temporal, a
    terceros. En tal sentido, el Usuario se compromete a hacer un uso diligente y a mantener en secreto la misma,
    asumiendo toda responsabilidad por las consecuencias de su divulgación a terceros.
  </p>
  <p>
    En el supuesto de que el Usuario conozca o sospeche del uso de su contraseña por terceros, deberá poner tal
    circunstancia en conocimiento de BMW con la mayor brevedad.
  </p>
  <p>
    <span class="text-bold">Utilización correcta del Servicio</span>
  </p>
  <p>
    El Usuario se obliga a usar el Servicio de manera diligente, correcta y lícita y, en particular, se compromete a
    abstenerse de:
  </p>
  <ul>
    <li>
      Utilizar el Servicio con fines contrarios a la ley, a la moral y a las buenas costumbres generalmente aceptadas o
      al orden público.
    </li>
    <li>
      Reproducir o copiar, distribuir, permitir el acceso del público a través de cualquier modalidad de comunicación
      pública, transformar o modificar los Servicios, a menos que se cuente con la autorización del titular de los
      correspondientes derechos o ello resulte legalmente permitido.
    </li>
    <li>
      Emplear los Servicios y, en particular, la información de cualquier clase obtenida a través de los Portales para
      remitir publicidad, comunicaciones con fines de venta directa o con cualquier otra clase de finalidad comercial,
      mensajes solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, así como de
      comercializar o divulgar de cualquier modo dicha información.
    </li>
    <li>
      Vulnerar derechos de propiedad intelectual o industrial pertenecientes a BMW o a terceros.
    </li>
  </ul>
  <p>
    BMW velará en todo momento por el respeto del ordenamiento jurídico vigente y estará legitimado para interrumpir, a
    su entera discreción, el Servicio o para excluir al Usuario de los Portales en caso de que se detecte algún
    incumplimiento de los Términos y Condiciones o existan indicios de que el Usuario está llevando a cabo actos
    ilícitos.
  </p>

  <br/>

  <h4>
    5.Derechos de propiedad intelectual e industrial
  </h4>
  <p>
    Todos los contenidos del Portal, tales como textos, gráficos, fotografías, logotipos, iconos, imágenes, así como el
    diseño gráfico, código fuente y software, son de la exclusiva propiedad de BMW o de terceros, cuyos derechos
    reconoce BMW y están sujetos a derechos de propiedad intelectual e industrial protegidos por la legislación nacional
    e internacional.
  </p>
  <p>
    Queda estrictamente prohibido la utilización de todos los elementos objeto de propiedad industrial e intelectual con
    fines comerciales, así como su distribución, modificación, alteración o descompilación.
  </p>

  <br/>

  <h4>
    6.Disponibilidad de los Portales y responsabilidad de BMW
  </h4>
  <p>
    BMW se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones
    respecto a la información, presentación y configuración de los Portales. Tanto el acceso a los mismos como el uso que
    pueda hacerse de la información contenida en ellos es de la exclusiva responsabilidad de quien lo realiza.
  </p>
  <p>
    BMW se reserva igualmente el derecho a interrumpir el acceso a los Portales, así como la prestación del Servicio en
    cualquier momento y sin previo aviso, ya sea por motivos técnicos, de seguridad, de control, de mantenimiento, por
    fallos de suministro eléctrico, o por cualquier otra causa. Por tanto, BMW no garantiza la disponibilidad ni la
    continuidad de los Portales ni del Servicio.
  </p>
  <p>
    BMW no asume ninguna responsabilidad en caso de que existan interrupciones del Servicio, demoras o errores en el
    mismo.
  </p>

  <br/>

  <h4>
    7.Protección de datos personales
  </h4>
  <p>
    Los datos facilitados por el Usuario deberán transmitirse al Concesionario o Taller Autorizado seleccionado con la
    finalidad de confirmar y gestionar la cita. La prestación de los servicios técnicos de reparación o asistencia por
    parte de los Concesionarios o Talleres Autorizados puede requerir la recogida de datos adicionales, que se regirán
    por la política de privacidad de cada Concesionario o Taller Autorizado.
  </p>
  <p>
    Para más información sobre el tratamiento de sus datos personales por parte de BMW, consulte la Política de
    Privacidad para clientes de BMW y MINI <a class="underline-text" href="https://www.bmw.es/es/footer/metanavigation/legal-disclaimer/aviso-legal-gdpr.html">aquí</a> y la
    Política de Privacidad para clientes de Motorrad <a class="underline-text" href="https://www.bmw-motorrad.es/es/public-pool/content-pool/privacy-policy.html#:~:text=BMW%20AG%20conservar%C3%A1%20por%20separado,%2C%20letra%20b)%20del%20RGPD.">aquí</a>.
  </p>

  <br/>

  <h4>
    8.Ley aplicable y jurisdicción
  </h4>
  <p>
    La ley aplicable será la vigente en España. Cualquier controversia o litigio podrá resolverse en los Juzgados y
    Tribunales españoles que correspondan en aplicación de la legislación vigente.
  </p>
</div>