<div>
  <div class="text-center">
    <h4 class="title" i18n="@@termsAndConditionsTitle">Terms and Conditions</h4>
    <h5 class="subtitle" i18n="@@termsAndConditionsSubtitle">Please scroll down and read all the following terms and conditions</h5>
  </div>

  <div class="terms-container" [ngSwitch]="countryCode">
    <oas-terms-and-conditions-it *ngSwitchCase="'IT'"> </oas-terms-and-conditions-it>
    <oas-terms-and-conditions-es *ngSwitchCase="'ES'"> </oas-terms-and-conditions-es>
    <oas-terms-and-conditions-it *ngSwitchDefault> </oas-terms-and-conditions-it>
    <div class="read-more-text"  *ngIf="displayReadMore">
      <span (click)="readMore()" i18n="@@readMore">read more</span>
    </div>
  </div>

  <div class="ui-checkbox summary__tos-accept">
    <div>
      <input type="checkbox" style="-webkit-appearance: none;-moz-appearance: none;border: 1px solid black;opacity: 100%;" id="accept_tos" (change)="accept($event.target.checked)"/>
      <label for="accept_tos">
          <span i18n="@@acceptTermsAndConditions">
            I have read and I accept the Terms and Conditions.
          </span>
      </label>
    </div>
  </div>

  <div class="text-center">
    <button class="btn btn-primary ui-button priority-help__action-button" (click)="continue()" i18n="@@continue" [disabled]="!isAccepted">
      Continue
    </button>
  </div>
</div>  