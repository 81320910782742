<swal
    text="this won't be displayed"
    icon="warning"
    showCancelButton="true"
    i18n-confirmButtonText="@@yes"
    confirmButtonText="Yes"
    i18n-cancelButtonText="@@no"
    cancelButtonText="No"
>
    <div *swalPortal="swalTargets.title">
        <ng-container *ngIf="titleTmpl; else simpleTitleTmpl">
            <ng-container *ngTemplateOutlet="titleTmpl"></ng-container>
        </ng-container>
    </div>
    <div *swalPortal>
        <ng-container *ngIf="contentTmpl; else simpleContentTmpl">
            <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
        </ng-container>
    </div>
    <ng-container *ngIf="confirmButtonTmpl">
        <div *swalPortal="swalTargets.confirmButton">
            <ng-container *ngTemplateOutlet="confirmButtonTmpl"></ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="cancelButtonTmpl">
        <div *swalPortal="swalTargets.cancelButton">
            <ng-container *ngTemplateOutlet="cancelButtonTmpl"></ng-container>
        </div>
    </ng-container>
</swal>

<ng-template #simpleTitleTmpl>
    <span *ngIf="title">{{ title }}</span>
    <span *ngIf="!title" i18n="@@areYouSure">Are you sure?</span>
</ng-template>

<ng-template #simpleContentTmpl>
    <span>{{ text }}</span>
</ng-template>
