<oas-customer-wizard>
    <div data-tracking-regionid="online service appointment">
        <a
            [routerLink]="['/online/dashboard']"
            [queryParams]="{ firstTime: false }"
            class="dismiss-request text-primary"
            data-tracking-linkid="local > back to dashboard"
        >
            <i class="fa fa-chevron-left"> </i>
            <span i18n="@@backToDashboard">Back to dashboard</span>
        </a>
    </div>

    <tabset justified="true" class="assistance-tabs">
        <!--<tab customClass="assistance-tabs__tab">
            <ng-template tabHeading>
                <div class="assistance-tabs__tab--inner">
                    <i class="fa fa-address-card fa-2x" aria-hidden="true"></i>
                    <span class="warningTab" *ngIf="!customer.businessName && customerForm.customerForm.invalid">
                        *</span
                    >
                </div>
            </ng-template>
            <div class="assistance-tabs__tab-content assistence-tabs__min-height" [hidden]="customer.businessName">
                <oas-customer-form #customerForm [customer]="customer"></oas-customer-form>
                <button
                    class="ui-button btn btn-primary"
                    (click)="updateCustomer(customerForm.turnIntoModel(), true)"
                    [disabled]="
                        customerForm.customerForm.invalid ||
                        (!customer.preferences.contactByMail && !customer.preferences.contactBySms)
                    "
                    i18n="@@save"
                >
                    Save
                </button>
            </div>
        </tab>
        <tab customClass="assistance-tabs__tab">
            <ng-template tabHeading>
                <div class="assistance-tabs__tab--inner">
                    <i class="fa fa-user fa-2x" aria-hidden="true"></i>
                </div>
            </ng-template>
            <ng-container *ngTemplateOutlet="changeEmail"> </ng-container>
        </tab>

        <tab customClass="assistance-tabs__tab">
            <ng-template tabHeading>
                <div class="assistance-tabs__tab--inner">
                    <i class="fa fa-lock fa-2x" aria-hidden="true"></i>
                </div>
            </ng-template>
            <ng-container *ngTemplateOutlet="resetPassword"> </ng-container>
        </tab>-->
        <tab customClass="assistance-tabs__tab">
            <ng-template tabHeading>
                <div class="assistance-tabs__tab--inner">
                    <i class="fa fa-envelope fa-2x" aria-hidden="true"></i>
                    <span
                        class="warningTab"
                        *ngIf="!customer.preferences.contactByMail && !customer.preferences.contactBySms"
                    >
                        *</span
                    >
                </div>
            </ng-template>
            <div class="assistance-tabs__tab-content assistence-tabs__min-height">
                <div class="row">
                    <div class="col-md-12">
                        <div class="ui-checkbox summary__tos-accept">
                            <input
                                type="checkbox"
                                id="contact_by_mail"
                                [(ngModel)]="customer.preferences.contactByMail"
                            />
                            <label for="accept_tos">
                                <span i18n="@@contactMeByMail" style="color: #333;">Contact me by email</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="countryConfiguration.enableSMS">
                    <div class="col-md-12">
                        <div class="ui-checkbox summary__tos-accept">
                            <input
                                type="checkbox"
                                id="contact_by_sms"
                                [(ngModel)]="customer.preferences.contactBySms"
                            />
                            <label for="accept_tos">
                                <span i18n="@@contactMeBySms" style="color: #333;">Contact me by sms</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="!customer.preferences.contactByMail && !customer.preferences.contactBySms"
                    class="ui-input-wrapper"
                >
                    <div class="errors">
                        <p i18n="@@requiredContactChannel">At least one contact channel must be selected!</p>
                    </div>
                </div>
                <div *ngIf="!customer.businessName">
                    <button
                        class="ui-button btn btn-primary"
                        (click)="updateCustomerPreferences()"
                        [disabled]="
                            (!customer.preferences.contactByMail && !customer.preferences.contactBySms)
                        "
                        i18n="@@save"
                    >
                        Save
                    </button>
                </div>
            </div>
        </tab>
    </tabset>
</oas-customer-wizard>

<oas-alert-success #alertSuccess></oas-alert-success>
<oas-alert-error #alertError [errorResponse]="errorResponse"></oas-alert-error>
<oas-alert-confirm #alertConfirm></oas-alert-confirm>

<ng-template #resetPassword>
    <div class="assistance-tabs__tab-content assistence-tabs__min-height">
        <!-- TODO RENAME -->
        <oas-user-form-edit #userForm [edit]="authRequest"></oas-user-form-edit>
        <button
            class="ui-button btn btn-primary"
            (click)="changePassword(userForm.userForm)"
            [disabled]="userForm.userForm.invalid"
            i18n="@@save"
        >
            Save
        </button>
    </div>
</ng-template>

<ng-template #changeEmail>
    <div class="assistance-tabs__tab-content assistence-tabs__min-height">
        <oas-user-form-edit-email
            #userEditEmailForm
            [accountId]="customer.userAccountEmail"
            (accountIdUpdated)="updateAccountId($event)"
            (deleteAccountClicked)="deleteAccount()"
        ></oas-user-form-edit-email>
    </div>

    <div *ngFor="let alert of successAlerts">
        <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">
            <span i18n="@@successAlertAccountIdChangeRequested"> Instructions has been sent to {{ alert.email }} </span>
        </alert>
    </div>
</ng-template>

<ng-template #accountIdChangeRequestedTitleTmpl>
    <span i18n="@@accountIdChangeRequestedTitle">Change requested</span>
</ng-template>

<ng-template #accountIdChangeRequestedContentTmpl>
    <span i18n="@@accountIdChangeRequestedContent">We sent you an email to validate your new account email.</span>
</ng-template>
