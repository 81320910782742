<oas-customer-wizard
    [vin]="userPreRegistrationVehicle?.vin"
    [vehicleSeriesId]="userPreRegistrationVehicle?.model?.series?.id"
    [enableVehiclePictureUcp]="showVehicleImageInPreRegistration()"
    [loading]="loadingPreRegistrationData"
>
    <div class="login-form__section">
        <img src="assets/images/ic_account-register.svg" alt="" />
        <h2 class="login-form__section-title" i18n="@@registration">
            Registration
        </h2>
    </div>
    <mat-horizontal-stepper #wizard (selectionChange)="onStepChange($event)" [@.disabled]="true" class="hidden-stepper-headers">
        <!-- CUSTOMER FORM STEP -->
        <mat-step>
            <h1 class="login-form__title" i18n="@@yourAccount">Your account</h1>
            <oas-customer-form #customerForm [customer]="customer" [submitted]="submitted"></oas-customer-form>
            <div class="navigation__buttons">
                <div class="next-step__button-wrapper">
                    <button class="ui-button btn btn-secondary" i18n="@@cancel" (click)="goToLoginPage()">
                        Cancel
                    </button>
                    <button
                        class="ui-button btn btn-primary"
                        i18n="@@proceed"
                        [disabled]="!customerForm.isValid()"
                        (click)="firstStepProceedClick(customerForm); dataAnalyticsVisitorCompletedFirstStep()"
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </mat-step>

        <mat-step>
            <h1 class="login-form__title" i18n="@@yourVehicle">Your vehicle</h1>

            <form id="vehicle-form" [formGroup]="vehicleForm">
                <div class="ui-input-wrapper">
                    <label i18n="@@model">Model</label>
                    <input
                        class="ui-input login-form__input"
                        formControlName="model"
                        i18n-placeholder="@@model"
                        placeholder="Model"
                    />
                </div>

                <div class="ui-input-wrapper">
                    <label i18n="@@plate">Plate</label>
                    <input
                        class="ui-input login-form__input"
                        formControlName="plate"
                        i18n-placeholder="@@plate"
                        placeholder="Plate"
                    />
                </div>

                <div class="ui-input-wrapper">
                    <label i18n="@@mileage">Mileage</label>
                    <input
                        class="ui-input login-form__input"
                        formControlName="mileage"
                        i18n-placeholder="@@mileage"
                        placeholder="Mileage"
                    />
                </div>
            </form>

            <div class="navigation__buttons">
                <div class="next-step__button-wrapper">
                    <button class="ui-button btn btn-secondary" [goToStep]="0" i18n="@@previous">
                        Go back
                    </button>
                    <button class="ui-button btn btn-primary" i18n="@@skip" (click)="secondStepSkip()">
                        Skip
                    </button>
                    <button
                        class="ui-button btn btn-primary"
                        i18n="@@proceed"
                        [disabled]="!vehicleForm.valid"
                        (click)="secondStepProceed()"
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </mat-step>

        <!-- USER FORM STEP -->
        <mat-step>
            <h1 class="login-form__title" i18n="@@yourCredentials">Your credentials</h1>
            <oas-user-form-create #userForm [user]="customer.userApp"></oas-user-form-create>
            <div class="navigation__buttons">
                <div class="prev-step__button-wrapper">
                    <button
                        class="ui-button btn btn-secondary"
                        (click)="thirdStepBack(); dataAnalyticsVisitorGoBackEvent(true)"
                        i18n="@@previous"
                    >
                        Go back
                    </button>
                </div>
                <div class="next-step__button-wrapper">
                    <button
                        class="ui-button btn btn-primary"
                        [disabled]="userForm.userForm.invalid"
                        nextStep
                        i18n="@@proceed"
                        (click)="thirdStepForward(); dataAnalyticsVisitorCompletedThirdStep()"
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </mat-step>
        <!-- RESUME STEP -->
        <mat-step>
            <div class="login-form__section">
                <oas-customer-create-resume
                    [customer]="customer"
                    (accept)="isAgree = $event"
                ></oas-customer-create-resume>
            </div>
            <div class="move_captcha">
                <re-captcha [formControl]="recaptcha" (resolved)="resolved($event)"></re-captcha>
            </div>
            <div class="navigation__buttons resume_step_buttons">
                <div class="prev-step__button-wrapper">
                    <button
                        class="ui-button btn btn-secondary"
                        (click)="fourthStepBack(); dataAnalyticsVisitorGoBackEvent()"
                        previousStep
                        i18n="@@previous"
                    >
                        Go back
                    </button>
                </div>
                <div class="next-step__button-wrapper">
                    <button
                        class="ui-button btn btn-primary"
                        [disabled]="!isAgree || recaptcha.invalid"
                        (click)="register(); dataAnalyticsVisitorCompletedRegistration()"
                        i18n="@@completeRegistration"
                    >
                        Complete registration
                    </button>
                </div>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</oas-customer-wizard>

<oas-alert-success
    #alertSuccess
    i18n-text="@@sentConfirmMail"
    i18n-title="@@success"
    title="Success"
    text="To complete the registration a confirmation email has been sent to your email address. Please, check your email and follow the instructions in the message."
    timer="3600000"
></oas-alert-success>
<oas-alert-error #alertError [errorResponse]="errorResponse"></oas-alert-error>
