import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppComponent } from './../../app.component';
import { CountryService } from './../../core/api/country/country.service';
import { Country, GeoMarker, LocationComponentType } from './../entities';
import { CountryConfiguration } from '../../country-configuration';
import { countryConfiguration } from '../../../configuration/country.configuration';
import { OasValidators } from '../../core/validator/oas-validators';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { GoogleMapsLoaderService } from '../google-maps-loader.service';
import { Utils } from 'app/core/utils';

@Component({
    selector: 'oas-address-pick',
    templateUrl: './address-pick.component.html',
    styleUrls: ['./address-pick.component.scss'],
})
export class AddressPickComponent implements OnInit {
    @Input() public address: GeoMarker;
    @Input() public showMap = true;
    @Input() public readOnly = false;

    @Output() public newAddress = new EventEmitter<GeoMarker>();

    public lat = 0;
    public lng = 0;
    public addressForm: UntypedFormGroup;
    public countryList: Country[];
    public selectedCountryCode: string;
    public timeZones$: Promise<string[]>;
    public zoom = 4;
    public style: any = [
        {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#444444',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
                {
                    color: '#f2f2f2',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'all',
            stylers: [
                {
                    saturation: -100,
                },
                {
                    lightness: 45,
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'all',
            stylers: [
                {
                    color: '#46bcec',
                },
                {
                    visibility: 'on',
                },
            ],
        },
    ];
    public countryConfiguration: CountryConfiguration;
    public mapOptions: google.maps.MapOptions;
    public markerOptions: google.maps.MarkerOptions;
    public isMapLoaded = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private googleMapsLoaderService: GoogleMapsLoaderService,
        private countryService: CountryService,
        public appComponent: AppComponent,
        private countryConfigurationService: CountryConfigurationService
    ) {
        this.countryConfiguration = this.countryConfigurationService.getCountryConfiguration();
    }

    public ngOnInit() {
        this.googleMapsLoaderService.load().then(() => {this.isMapLoaded = true});
        this.timeZones$ = this.countryService.getTimeZones();
        this.countryService
            .getAll()
            .then(countryList => {
                this.countryList = countryList.sort((a, b) => {
                    // non-anonymous as you ordered...
                    return b.englishName < a.englishName
                        ? 1 // if b should come earlier, push a to end
                        : b.englishName > a.englishName
                        ? -1 // if b should come later, push a to begin
                        : 0; // a and b are equal
                });
            })
            .catch(() => {});

        if (this.address && this.address.latitude && this.address.longitude) {
            this.lat = this.address.latitude;
            this.lng = this.address.longitude;
        } else {
            this.lat = this.countryConfiguration.latitude;
            this.lng = this.countryConfiguration.longitude;
        }

        if (this.address) {
            if (Utils.isNullOrUndefined(this.address.latitude) || Utils.isNullOrUndefined(this.address.longitude)) {
                this.address.latitude = this.lat;
                this.address.longitude = this.lng;
            } else {
                this.zoom = countryConfiguration.mapsZoom;
            }
        }

        this.createForm();
        this.setMapOPtion();
    }

    public createForm(): any {
        this.addressForm = this.formBuilder.group({
            address: [
                { value: this.address ? this.address.address : '', disabled: this.readOnly },
                [Validators.required, OasValidators.getLatinValidator()],
            ],
            zipCode: [
                { value: this.address ? this.address.zipCode : '', disabled: this.readOnly },
                [Validators.required, OasValidators.getLatinValidator()],
            ],
            town: [
                {
                    value: this.retrieveLocality(this.address),
                    disabled: false,
                },
                [Validators.required, OasValidators.getLatinValidator()],
            ],
            country: [
                {
                    value: this.address && this.address.country ? this.address.country.code : '',
                    disabled: this.readOnly,
                },
                [Validators.required, OasValidators.getLatinValidator()],
            ],
            stairInt: [
                { value: this.address ? this.address.stairInt : '', disabled: this.readOnly },
                OasValidators.getLatinValidator(),
            ],
        });
        this.addressForm.valueChanges.subscribe(() => {
            this.updateAddressManual();
        });
    }

    private retrieveLocality(address: GeoMarker): string {
        let locality;

        if (address && address.locationComponentList) {
            for (const locationComponent of address.locationComponentList) {
                if (locationComponent.label === <LocationComponentType>'locality') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_3') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_4') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_5') {
                    locality = locationComponent.longName;
                    break;
                }
            }
        }

        return locality;
    }

    public clickMoveMap(cords: any) {
        if (!this.address) {
            this.address = {
                country: {},
                locationComponentList: [],
            };
        } else {
            this.address.id = '';
            this.address.mapsPlaceId = '';
        }
        this.zoom = countryConfiguration.mapsZoom;
        this.lat = cords.latLng.lat();
        this.lng = cords.latLng.lng();
        this.address.longitude = this.lng;
        this.address.latitude = this.lat;

        this.setMapOPtion();
    }

    private setMapOPtion(): void {
        this.mapOptions = {
            center: {
                lat: this.lat,
                lng: this.lng
            },
            zoom: this.zoom,
            styles: this.style,
            streetViewControl: false
        }
        this.markerOptions = {
            position: {
                lat: this.lat,
                lng: this.lng
            },
            draggable: !this.readOnly
        } 

    }
    public onAddressChange(address: GeoMarker) {
        this.addressForm.get('address').setValue(address.address !== 'undefined' ? address.address : '');
        this.addressForm.get('zipCode').setValue(address.zipCode);
        this.addressForm.get('country').setValue(address.country.code);
        this.addressForm.get('town').setValue(this.retrieveLocality(address));
        this.lat = address.latitude;
        this.lng = address.longitude;
        this.address = address;
        this.address.longitude = this.lng;
        this.address.latitude = this.lat;
        this.address.stairInt = this.addressForm.get('stairInt').value;
        this.zoom = countryConfiguration.mapsZoom;

        this.setMapOPtion();
    }

    public updateAddressManual(): any {
        if (
            this.address &&
            (this.addressForm.get('address').value !== this.address.address ||
                this.addressForm.get('zipCode').value !== this.address.zipCode ||
                this.addressForm.get('country').value !== this.address.country.code ||
                this.addressForm.get('stairInt').value !== this.address.stairInt ||
                (this.address.locationComponentList &&
                    this.address.locationComponentList.length > 0 &&
                    this.address.locationComponentList[0].longName !== this.addressForm.get('town').value))
        ) {
            this.address.id = '';
            this.address.mapsPlaceId = '';
        } else if (!this.address) {
            this.address = {
                country: {},
                locationComponentList: [],
            };
        }

        if (!this.showMap) {
            this.address.latitude = null;
            this.address.longitude = null;
        } else {
            this.address.longitude = this.lng;
            this.address.latitude = this.lat;
        }

        if (this.addressForm.get('address').value !== this.address.address) {
            this.address.address = this.addressForm.get('address').value;
        }
        if (this.addressForm.get('zipCode').value !== this.address.zipCode) {
            this.address.zipCode = this.addressForm.get('zipCode').value;
        }

        if (this.addressForm.get('stairInt').value !== this.address.stairInt) {
            this.address.stairInt = this.addressForm.get('stairInt').value;
        }
        if (!this.address.country || this.addressForm.get('country').value !== this.address.country.code) {
            this.address.country = {};
            this.address.country.code = this.addressForm.get('country').value;
        }
        if (
            this.address.locationComponentList &&
            this.address.locationComponentList.length > 0 &&
            this.address.locationComponentList[0].longName !== this.addressForm.get('town').value
        ) {
            this.address.locationComponentList = [
                {
                    label: <LocationComponentType>'locality',
                    longName: this.addressForm.get('town').value,
                    shortName: this.addressForm.get('town').value,
                    level: 6,
                },
            ];
        } else if (
            !this.address ||
            this.address.locationComponentList ||
            (this.address.locationComponentList &&
                this.address.locationComponentList.length === 0 &&
                this.addressForm.get('town').value)
        ) {
            this.address.locationComponentList = [
                {
                    label: <LocationComponentType>'locality',
                    longName: this.addressForm.get('town').value,
                    shortName: this.addressForm.get('town').value,
                    level: 6,
                },
            ];
        } else if (this.address && !this.address.locationComponentList && this.addressForm.get('town').value) {
            this.address.locationComponentList = [
                {
                    label: <LocationComponentType>'locality',
                    longName: this.addressForm.get('town').value,
                    shortName: this.addressForm.get('town').value,
                    level: 6,
                },
            ];
        }
    }

    public saveAddress() {
        this.newAddress.emit(this.address);
        this.appComponent.closeModal('oas-maps-edit');
    }

    public addressInvalid() {
        return false;
    }
}
