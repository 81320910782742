import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    Appointment,
    UserPreRegistration,
    UserPreRegistrationCreateDTO,
    UserPreRegistrationCreateWithCustomerDataDTO,
    UserPreRegistrationCreateWithCustomerDataWithoutDms,
} from '../../../shared/entities';
import { Logger } from '../../logger.service';
import { AbstractRestService } from '../generic.service';

@Injectable()
export class PreRegistrationService extends AbstractRestService<UserPreRegistration, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/pre-registration', http, logger);
    }

    public initializeRegistration(userPreRegistrationCreateDTO: UserPreRegistrationCreateDTO): Promise<void> {
        const url = `${this.actionUrl}`;

        return this.http
            .post(url, userPreRegistrationCreateDTO)
            .toPromise()
            .catch(error => this.handleError(error));
    }

    public initializeRegistrationWithCustomerData(
        userPreRegistrationCreateWithCustomerDataDTO: UserPreRegistrationCreateWithCustomerDataDTO
    ): Promise<void> {
        const url = `${this.actionUrl}/with-customer-data`;

        return this.http
            .post(url, userPreRegistrationCreateWithCustomerDataDTO)
            .toPromise()
            .catch(error => this.handleError(error));
    }

    public async initializeRegistrationWithCustomerDataAndAppointmentId(
        appointmentId: string,
        appointment: Appointment
    ): Promise<any> {
        const url = `${this.actionUrl}/${appointmentId}/with-customer-data`;
        const userPreRegistrationCreateWithCustomerDataDTO = this.createUserPreRegistrationCreateWithCustomerDataWithoutDmsFromAppointment(
            appointment
        );
        try {
            return this.http.post(url, userPreRegistrationCreateWithCustomerDataDTO).toPromise();
        } catch (error) {
            return await this.handleError(error);
        }
    }

    private createUserPreRegistrationCreateWithCustomerDataWithoutDmsFromAppointment(
        appointment: Appointment
    ): UserPreRegistrationCreateWithCustomerDataWithoutDms {
        const userPreRegistrationCreateWithCustomerDataDTO: UserPreRegistrationCreateWithCustomerDataWithoutDms = {
            userPreRegistrationCreateWithoutDms: {
                outletId: appointment.outlet.id,
                vehicleVin: appointment.vehicle.vin,
                vehiclePlate: appointment.vehicle.plate,
            },
            userPreRegistrationCustomerDataDTO: {
                name: appointment.customer.name,
                surname: appointment.customer.surname,
                secondSurname: appointment.customer.secondSurname,
                gender: appointment.customer.gender,
                email: appointment.customer.email,
                phoneNumber: appointment.customer.phoneNumber,
                vatNumber: appointment.customer.vatNumber,
            },
        };
        return userPreRegistrationCreateWithCustomerDataDTO;
    }

    public exist(email: string): Observable<any> {
        const url = `${this.actionUrl}/exist`;
        const requestBody = {
            email
         };
        return this.http.post(url, requestBody);
    }
}
