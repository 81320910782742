<div class="page-wizard">
    <div class="dashboard-header wizard__dashboard-header">
        <div class="row no-gutters">
            <div class="container-your-dashboard-and-logo">
                <div class="container-your-dashboard">
                    <div [ngSwitch]="wizard.selectedIndex + 1">
                        <h1 *ngSwitchCase="1" class="dashboard-header__title" i18n="@@selectAssistance">
                            Select the assistance
                        </h1>
                        <h1 *ngSwitchCase="2" class="dashboard-header__title" i18n="@@selectFavouriteDealer">
                            Select dealer
                        </h1>
                        <h1 *ngSwitchCase="3" class="dashboard-header__title" i18n="@@selectAdditionalService">
                            Select an additional service
                        </h1>
                        <h1 *ngSwitchCase="4" class="dashboard-header__title" i18n="@@selectStaffMemberAndDate">
                            Select a staff member and a date
                        </h1>
                        <h1 *ngSwitchCase="5" class="dashboard-header__title" i18n="@@customerDataStepTitle">
                            Customer data
                        </h1>
                        <h1 *ngSwitchCase="6" class="dashboard-header__title" i18n="@@resume">
                            Resume
                        </h1>
                    </div>
                </div>
                <div class="container-logo">
                    <oas-logo></oas-logo>
                </div>
            </div>
            <div class="col-md-4">
                <div class="dashboard-header__title__wrapper" data-tracking-regionid="online service appointment ">
                    <div class="dashboard-header__account" *ngIf="!isFromWithoutRegistration">
                        <span dropdown>
                             <a href dropdownToggle (click)="false">
                                 <img
                                    src="assets/images/ic_account-register-white-bordered.svg"
                                    alt=""
                                    class="dashboard-header__account-icon"
                                 />
                             </a>
                             <ul *dropdownMenu class="dropdown-menu dropdown-collapse" style="min-width: unset;">
                                 <li>
                                     <a
                                        class="dropdown-item"
                                        [href]="editProfile()"
                                        target="_blank"
                                        i18n="@@myProfile"
                                     >My profile</a>
                                 </li>
                                 <li>
                                     <a
                                        routerLink="/online/account-edit"
                                        routerLinkActive="active"
                                        class="dropdown-item"
                                        i18n="@@notificationPreferences"
                                     >Notification preferences</a>
                                 </li>
                             </ul>
                         </span>
                        <span *ngIf="local != 'it-IT'">|</span>
                        <span><oas-logout></oas-logout></span>
                    </div>
                    <a (click)="dismiss()" class="dashboard-header__dismiss-request">
                        <i class="fa fa-chevron-left"> </i>
                        <span i18n="@@dismissRequestQuestion" data-tracking-linkid="local > cancel appointment request"
                            >Dismiss this request?</span
                        >
                    </a>
                </div>
            </div>
            <div class="col-md-4 col-md-offset-0 col-sm-8 col-sm-offset-2">
                <div class="dashboard-header__hero-image--wrapper">
                    <h3 class="dashboard-header__hero-image-title">
                        {{ appointment.vehicle.model?.modelLabel }}
                    </h3>
                    <h4 class="text-center">{{ appointment.vehicle.plate }}</h4>
                    <oas-vehicle-picture-ucp
                        [vin]="appointment.vehicle.vin"
                        [vehicleSeriesId]="appointment.vehicle?.model?.series?.id"
                    ></oas-vehicle-picture-ucp>
                </div>
            </div>
        </div>
        <div class="wizard-steps header__wizard-steps online__header__wizard-steps" *ngIf="!(wizard.selectedIndex === wizard.steps.length - 1)">
            <div class="container">
                <div class="row row-eq-height">
                    <div class="col-md-4 col-sm-4 col-xs-4">
                        <div
                            class="wizard-steps__step"
                            [class.wizard-steps__step--active]="wizard.currentStepIndex === 0"
                        >
                            <div
                                class="wizard-steps__step-name"
                                (click)="
                                    appComponent.openModal('services', activitiesDetailTemplate, 'services__modal')
                                "
                            >
                                <div class="wizard-steps__step-name--inner">
                                    <div class="text-center">
                                        <i class="bmw bmw-wizard_ico_service icon-lg"></i>
                                    </div>
                                    <div i18n="@@services">Services</div>
                                </div>
                            </div>
                            <div class="wizard-steps__step-description">
                                <ng-container
                                    *ngIf="appointment.activityList | filter: filterPredicate as filteredActivities"
                                >
                                    <ng-container *ngIf="filteredActivities.length > 3; else fewActivities">
                                        <div class="row no-pudding">{{ filteredActivities[0].name }},</div>
                                        <div class="row no-pudding">{{ filteredActivities[1].name }}</div>
                                        <div class="row no-pudding">
                                            <span i18n="@@and">and</span>
                                            <a
                                                i18n="@@wizardStepServicesMore"
                                                (click)="
                                                    appComponent.openModal(
                                                        'services',
                                                        activitiesDetailTemplate,
                                                        'services__modal'
                                                    )
                                                "
                                                >{{ filteredActivities.length - 2 }} more</a
                                            >
                                        </div>
                                    </ng-container>
                                    <ng-template #fewActivities>
                                        <div
                                            *ngFor="
                                                let activity of filteredActivities;
                                                let isLast = last;
                                                trackBy: trackByActivity
                                            "
                                            class="row no-pudding"
                                        >
                                            {{ activity.name }}
                                            <span *ngIf="!isLast">,</span>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-4">
                        <div
                            class="wizard-steps__step"
                            [class.wizard-steps__step--active]="wizard.selectedIndex === 1"
                        >
                            <div
                                class="wizard-steps__step-name"
                                (click)="appComponent.openModal('outlet', dealerDetailTemplate, 'services__modal')"
                            >
                                <div class="wizard-steps__step-name--inner">
                                    <div class="text-center">
                                        <i class="bmw bmw-wizard_ico_dealer icon-lg"></i>
                                    </div>
                                    <div i18n="@@dealer">Dealer</div>
                                    <div
                                        *ngIf="appointment.outlet && isActivitySelected()"
                                        class="wizard-steps__step__name-edit"
                                        (click)="
                                            wizard.selectedIndex = 1;
                                            appComponent.closeModal('outlet');
                                            onDelearChange();
                                            $event.stopPropagation()
                                        "
                                    >
                                        <span i18n="@@change">Change</span>
                                        <i class="fa fa-pencil"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="wizard-steps__step-description">
                                <ng-container *ngIf="appointment.outlet">
                                    <div class="row" *ngIf="appointment.outlet">
                                        {{ appointment.outlet?.businessName }}
                                    </div>
                                    <div class="row">{{ appointment.outlet?.address.address }}</div>
                                    <div class="row">{{ appointment.outlet?.address.stairInt }}</div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-4">
                        <div
                            class="wizard-steps__step"
                            [class.wizard-steps__step--active]="wizard.selectedIndex === 3"
                        >
                            <div
                                class="wizard-steps__step-name"
                                (click)="appComponent.openModal('schedule', scheduleDetailTemplate, 'services__modal')"
                            >
                                <div class="wizard-steps__step-name--inner">
                                    <div class="text-center">
                                        <i class="bmw bmw-wizard_ico_schedule icon-lg"></i>
                                    </div>
                                    <div i18n="@@schedule">Schedule</div>
                                </div>
                            </div>
                            <div class="wizard-steps__step-description">
                                <ng-container *ngIf="appointment?.startTime && appointment?.endTime">
                                    {{ appointment.startTime | datex: 'medium' }}
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="dashboard-content">
        <div [style.display]="showSupportChat() ? '' : 'none'" class="support-chat-container">
            <oas-support-chat [isAllowToDisplay]="showSupportChat()"></oas-support-chat>
        </div>

        <div class="container">
            <mat-horizontal-stepper #wizard (selectionChange)="onStepChange($event)" [@.disabled]="true" class="hidden-stepper-headers">
                <!-- ACTIVITY STEP -->
                <mat-step>
                    <oas-activity
                        #activityComponent
                        *ngIf="appointment.activityList && !existFixedPrices"
                        [vehicle]="appointment.vehicle"
                        [activities]="appointment.activityList"
                        [appointment]="appointment"
                        [(fastLaneSelected)]="appointment.fastLane"
                        [selectedOutlet]="appointment.outlet"
                        [waitInOutletSelected]="appointment.waitInOutlet"
                        [preselectedActivityPackageIds]="preselectedActivityPackageIds"
                        [preselectedActivityCategoryId]="preselectedActivityCategoryId"
                        (waitInOutletSelectedChange)="onChangeWaitInOutlet($event)"
                        (categoryDurationMapChange)="onCategoryDurationMapChange($event)"
                        (attachmentsChange)="onChangeAttachments($event)"
                        (activityEvent)="onChangeActivity($event)"
                    ></oas-activity>

                    <oas-activity-price
                        *ngIf="appointment.activityList && existFixedPrices && !activityPriceDesabled"
                        [vehicle]="appointment.vehicle"
                        [activities]="appointment.activityList"
                        [appointment]="appointment"
                        [(fastLaneSelected)]="appointment.fastLane"
                        [waitInOutletSelected]="appointment.waitInOutlet"
                        [preselectedActivityPackageIds]="preselectedActivityPackageIds"
                        (waitInOutletSelectedChange)="onChangeWaitInOutlet($event)"
                        (categoryDurationMapChange)="onCategoryDurationMapChange($event)"
                        (attachmentsChange)="onChangeAttachments($event)"
                    ></oas-activity-price>

                    <div class="navigation__buttons">
                        <div *ngIf="isFromWithoutRegistration && !isUpdateLink" class="prev-step__button-wrapper">
                            <button
                                id="backOnlineServiceBtn"
                                class="ui-button btn btn-secondary"
                                i18n="@@previous"
                                (click)="onActivityStepBackButtonClicked()"
                            >
                                Go back
                            </button>
                        </div>

                        <div class="next-step__button-wrapper" data-tracking-regionid="online service appointment">
                            <button
                                id="proceedOnlineServiceBtn"
                                class="ui-button btn btn-primary"
                                [disabled]="!activityComponent?.valid()"
                                i18n="@@proceed"
                                (click)="goToStepRelative(1)"
                                data-tracking-linkid="local > next step"
                            >
                                Proceed
                            </button>
                        </div>
                    </div>
                </mat-step>
                <!-- OUTLET STEP -->
                <mat-step>
                    <oas-outlet-maps
                        #myMap
                        [(outlet)]="appointment.outlet"
                        (outletChange)="outletChange($event)"
                        [defaultAddress]="appointment.customer.address"
                        [appointment]="appointment"
                        [edit]="appointment.id ? true : false"
                        [welcomeCategory]="retrieveWelcomeCategory(appointment.vehicle.model.brand)"
                    ></oas-outlet-maps>
                    <div class="navigation__buttons">
                        <div class="prev-step__button-wrapper">
                            <button
                                id="backOnlineOutletBtn"
                                class="ui-button btn btn-secondary"
                                i18n="@@previous"
                                (click)="goToStepRelative(-1); sendPageViewForServicesSelection()"
                            >
                                Go back
                            </button>
                        </div>
                        <div class="next-step__button-wrapper">
                            <button
                                id="proceedOnlineOutletBtn"
                                class="ui-button btn btn-primary"
                                [disabled]="!isOutletSelected()"
                                i18n="@@proceed"
                                (click)="goToStepRelative(1);sendPageViewForServicesSelectionModified()"
                            >
                                Proceed
                            </button>
                        </div>
                    </div>
                </mat-step>
                <!-- ADDITIONAL SERVICE STEP -->
                <mat-step>
                    <oas-outlet-mobility
                        #mobility
                        [(mobilities)]="appointment.mobilityList"
                        [customer]="appointment.customer"
                        [showMobilityAddressAlert]="true"
                        [vehicleBrand]="appointment.vehicle?.model.brand"
                        [outlet]="appointment.outlet"
                    ></oas-outlet-mobility>
                    <div class="navigation__buttons">
                        <div class="prev-step__button-wrapper">
                            <button
                                id="backOnlineMobilityBtn"
                                class="ui-button btn btn-secondary"
                                i18n="@@previous"
                                (click)="goToStepRelative(-1); appointment.courtesyCarRequired = mobility.isCourtesyCarSelected()"
                            >
                                Go back
                            </button>
                        </div>
                        <div class="next-step__button-wrapper">
                            <button class="ui-button btn btn-primary" i18n="@@proceed" (click)="goToStepRelative(1);appointment.courtesyCarRequired = mobility.isCourtesyCarSelected()">
                                Proceed
                            </button>
                        </div>
                    </div>
                </mat-step>
                <!-- SCHEDULE STEP -->
                <mat-step >
                    <oas-outlet-agenda
                        #agenda
                        [appointment]="appointment"
                        (slotChange)="onSlotChange($event)"
                        (changeServiceAdvisor)="onChangeServiceAdvisor($event)"
                    ></oas-outlet-agenda>
                    <div class="navigation__buttons">
                        <div class="prev-step__button-wrapper" data-tracking-regionid="online service appointment">
                            <button
                                id="backOnlineSlotBtn"
                                class="ui-button btn btn-secondary"
                                i18n="@@previous"
                                (click)="goToStepRelative(-1)"
                                data-tracking-linkid="local > previous step"
                            >
                                Go back
                            </button>
                        </div>
                        <div class="next-step__button-wrapper" data-tracking-regionid="online service appointment">
                            <button
                                id="proceedOnlineSlotBtn"
                                class="ui-button btn btn-primary"
                                i18n="@@proceed"
                                [disabled]="!isSlotSelected() && !isServiceAdvisorSelected()"
                                (click)="goToStepRelative(1)"
                                data-tracking-linkid="local > next step"
                            >
                                Proceed
                            </button>
                        </div>
                    </div>
                </mat-step>
                <mat-step>
                    <h2 class="assistance-title customer-data-step-title" i18n="@@customerDataStepTitle">
                        Customer data
                    </h2>

                    <oas-customer-form
                        [customer]="appointment.customer"
                        [hideAddress]="true"
                        [ignoreErrorWhenUserAlreadyExistInGcdm]="true"
                    ></oas-customer-form>
                    <oas-customer-form-without-registration-terms
                        (onChecked)="onTermsAndConditionsChecked()"
                    ></oas-customer-form-without-registration-terms>

                    <div class="navigation__buttons">
                        <div class="next-step__button-wrapper" data-tracking-regionid="online service appointment">
                            <div class="prev-step__button-wrapper" data-tracking-regionid="online service appointment">
                                <button
                                    id="backOnlineCustomerBtn"
                                    class="ui-button btn btn-secondary"
                                    i18n="@@previous"
                                    (click)="goToStepRelative(-1)"
                                    data-tracking-linkid="local > previous step"
                                >
                                    Go back
                                </button>
                            </div>

                            <button
                                id="proceedOnlineCustomerBtn"
                                class="ui-button btn btn-primary"
                                i18n="@@proceed"
                                [disabled]="isNextButtonOfCustomerDataStepDisabled()"
                                (click)="updateCustomerData(); goToStepRelative(1)"
                                data-tracking-linkid="local > next step"
                            >
                                Proceed
                            </button>
                        </div>
                    </div>
                </mat-step>
                <!-- RESUME STEP -->
                <mat-step>
                    <h2 class="assistance-title" i18n="@@resume">Resume</h2>
                    <oas-appointment-resume
                        #resume
                        [showRegistrationCheckbox]="showRegistrationCheckbox"
                        (goToWizardStep)="goToStepFromResumeStep($event)"
                        (sendPreRegistrationEmail)="updateSendPreRegistrationEmailValue($event)"
                    ></oas-appointment-resume>
                    <div class="navigation__buttons">
                        <div *ngIf="appointment.id; else newAppointment" class="next-step__button-wrapper">
                            <button id="cancelRequestBtn" class="ui-button btn btn-secondary" i18n="@@cancelAppointment" (click)="cancel()">
                                Cancel the assistance
                            </button>
                            <button id="updateRequestBtn" class="ui-button btn btn-primary" i18n="@@updateAppointment" (click)="book()">
                                Update the assistance
                            </button>
                        </div>  
                        <ng-template #newAppointment>
                            <div
                                class="next-step__button-wrapper ui-margin-bottom"
                                data-tracking-regionid="online service appointment"
                            >
                                <button
                                    id="bookRequestBtn"
                                    class="ui-button btn btn-primary"
                                    (click)="book()"
                                    data-tracking-linkid="local > appointment booked"
                                >
                                    <span i18n="@@bookYourAppointment">Book the assistance</span>
                                    <span class="small">*</span>
                                </button>
                            </div>
                            <div *ngIf="countryConfiguration.countryCode !== 'MX'" class="row small">
                                *
                                <strong
                                    ><span i18n="@@customerSharingDataMessage"
                                        >By booking the appointment you agree to share your data with the
                                        {{ appointment?.vehicle.model.brand | translate }} Retailer</span
                                    ></strong
                                >
                            </div>
                        </ng-template>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
</div>

<ng-template #wizardNavigation>
    <div class="navigation__buttons">
        <div class="prev-step__button-wrapper">
            <button class="ui-button btn btn-secondary" i18n="@@previous" (click)="goToStepRelative(-1)">
                Go back
            </button>
        </div>
        <div class="next-step__button-wrapper">
            <button class="ui-button btn btn-primary" i18n="@@proceed" (click)="goToStepRelative(1)">Proceed</button>
        </div>
    </div>
</ng-template>

<ng-template #activitiesDetailTemplate>
    <div class="modal-header services__modal-header">
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="appComponent.closeModal('services')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" i18n="@@selectedServices">Selected Services</h4>
    </div>
    <div class="modal-body services__modal-body">
        <oas-activity-basket-modal
            [activities]="appointment.activityList"
            [readOnly]="isBasketReadOnly()"
            (deleteActivity)="removeActivity($event)"
            [readOnlyActivityIds]="getReadOnlyActivityIds()"
        ></oas-activity-basket-modal>
    </div>
</ng-template>

<ng-template #dealerDetailTemplate>
    <div class="modal-header services__modal-header">
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="appComponent.closeModal('outlet')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" i18n="@@selectDealer">Selected Dealer</h4>
    </div>
    <div class="modal-body services__modal-body">
        <ng-container *ngIf="appointment.outlet; else dealer_selection">
            <div class="dealer-name">
                {{ appointment.outlet?.businessName }}
                <button
                    *ngIf="isActivitySelected()"
                    class="btn btn-primary button--clear button--nopadding"
                    (click)="wizard.selectedIndex = 1; appComponent.closeModal('outlet')"
                >
                    <i class="fa fa-pencil"></i>
                </button>
            </div>
            <div class="dealer-address">
                {{ appointment.outlet?.address?.address }}
            </div>
        </ng-container>
        <ng-template #dealer_selection i18n="@@noDealerSelected">Please select a dealer</ng-template>
    </div>
</ng-template>

<ng-template #scheduleDetailTemplate>
    <div class="modal-header services__modal-header">
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="appComponent.closeModal('schedule')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" i18n="@@selectSchedule">Selected Schedule</h4>
    </div>
    <div class="modal-body services__modal-body">
        <ng-container *ngIf="!appointment.startTime">
            <div class="dealer-address" i18n="@@selectScheduleFirst">
                Please select a schedule first
            </div>
        </ng-container>
        <ng-container *ngIf="appointment.startTime">
            <div class="dealer-name">
                {{ appointment.startTime | datex: 'fullDate' }}
            </div>
            <div class="dealer-address" i18n="@@selectScheduleFirst">
                {{ appointment.startTime | datex: 'shortTime' }}
            </div>
        </ng-container>
    </div>
</ng-template>

<oas-alert-error [errorResponse]="errorResponse"></oas-alert-error>
<oas-alert-success></oas-alert-success>
<oas-alert-warning #alertWarning></oas-alert-warning>
<oas-alert-confirm></oas-alert-confirm>

<ng-template #appointmentSuccessfullyCreatedTmpl>
    <span i18n="@@appointmentSuccessfullyCreated">Appointment successfully created</span>
</ng-template>

<ng-template #appointmentSuccessfullyUpdatedTmpl>
    <span i18n="@@appointmentSuccessfullyUpdated">Appointment successfully updated</span>
</ng-template>

<ng-template #alertWarningInvalidFormTitleTmpl>
    <span i18n="@@alertWarningInvalidFormTitle">Missing or incorrect data:</span>
</ng-template>

<ng-template #alertWarningInvalidFormContentTmpl>
    <div class="text-center">
        <li *ngIf="appointmentResumeComponent.mileage?.invalid" i18n="@@mileage">Mileage</li>
    </div>
    <div class="text-center">
        <li *ngIf="appointmentResumeComponent.matriculationDate?.invalid" i18n="@@matriculationDate">
            Matriculation date
        </li>
    </div>
    <div class="text-center">
        <li *ngIf="appointmentResumeComponent.phoneNumber?.invalid" i18n="@@phone">Phone</li>
    </div>
    <div class="text-center">
        <li *ngIf="appointmentResumeComponent.addressForm?.invalid" i18n="@@address">Address</li>
    </div>
    <div class="text-center">
        <li *ngIf="appointmentResumeComponent.note?.invalid" i18n="@@note">Note</li>
    </div>
    <div class="text-center">
        <li *ngIf="appointmentResumeComponent.plate?.invalid" i18n="@@plate">Plate</li>
    </div>
</ng-template>
<ng-template #bodyAndPaintAlertTitleTmpl>
    <span i18n="@@bodyAndPaintAlertTitle">Assurance Service Inspection</span>
</ng-template>

<ng-template #bodyAndPaintAlertTextTmpl>
    <span i18n="@@bodyAndPaintAlertText"
        >It could be necessary to have the assurance service inspection done before delivering the vehicle.</span
    >
</ng-template>

<ng-template #cannotPreSelectFavOutletTitleTmpl>
    <span i18n="@@cannotPreSelectFavOutletTitle">Cannot pre-select favourite outlet!</span>
</ng-template>

<ng-template #cannotPreSelectFavOutletTextTmpl>
    <span i18n="@@cannotPreSelectFavOutletText"
        >Favourite outlet doesn't manage {{ appointment.vehicle.model.brand | translate }} vehicles.</span
    >
</ng-template>

<ng-template #favouriteOutletInactiveTitleTmpl>
    <span i18n="@@favouriteOutletInactiveTitle">Favourite outlet not available</span>
</ng-template>

<ng-template #favouriteOutletInactiveTextTmpl>
    <span i18n="@@favouriteOutletInactiveText">Please select an outlet from map.</span>
</ng-template>

<ng-template #confirmProceedActivityStepTitleTmpl>
    <span i18n="@@outletservicesavailability">Outlet services availability</span>
</ng-template>

<ng-template #confirmProceedActivityStepContentTmpl>
    <div class="text-left">
        <span i18n="@@followingServicesNotAvailableWithOutlet"
            >The following selected services are not available at your selected outlet:</span
        >
        <ul>
            <li *ngFor="let notAvailableServiceLabel of notAvailableServicesLabels">
                {{ notAvailableServiceLabel }}
            </li>
            <li *ngIf="fastLaneSkillNotAvailable">
                <span i18n="@@fastLane">Fast Lane</span>
            </li>
        </ul>
        <span i18n="@@selectOutletToCompleteBooking"
            >Please, contact your Outlet at {{ appointment.outlet.phoneNumber }} or continue the booking by selecting
            another outlet.</span
        >
        <span i18n="@@wantContinue">Do you want to continue?</span>
    </div>
</ng-template>

<ng-template #fastLaneNoLongerAvailableContentTmpl>
    <span i18n="@@fastLaneNoLongerAvailable">
        Fast Lane service is no longer available. You need to select a new time slot.
    </span>
</ng-template>

<oas-alert-warning
    #warningFixedPricesSameDates
    i18n-title="@@warning"
    title="Fixed Prices Warning"
    i18n-text="@@fixedPricesTextWarningSameDates"
    text="The Price will only be valid if the appointment is inside the campaign period: {{
        campaignDate
    }}. The Price will only be valid if your car was registered before than {{ warningYearDate }}."
></oas-alert-warning>
<oas-alert-warning
    #warningFixedPricesDistinctDates
    i18n-title="@@warning"
    title="Fixed Prices Warning"
    i18n-text="@@fixedPricesTextWarningDistinctDates"
    text="Please, check with your Authorized Outlet the availability of this campaign."
></oas-alert-warning>
