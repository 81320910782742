import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HeartbeatExternalSystemService } from '../../../core/api/heartbeat-external-system.service';
import { BaseResponse } from '../../../shared/entities';

@Injectable()
export class ExternalSystemStatusResolverService {
    constructor(private heartBeatExternalSystemSerice: HeartbeatExternalSystemService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<BaseResponse<void>> {
        return this.heartBeatExternalSystemSerice.getExternalSystemStatus(route.data.externalSystem).then(result => {
            return result;
        });
    }
}
