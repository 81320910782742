import { Component, EventEmitter, Input, OnInit, Output, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { OutletService } from '../../core/api/outlet/outlet.service';
import { GeoMarker, Outlet, VehicleBrand, ActivityCategory, LocationComponentType } from '../../shared/entities';
import { CountryConfiguration } from '../../country-configuration';
import { countryConfiguration } from '../../../configuration/country.configuration';
import { AuthService } from '../../auth/auth.service';
import { UserDataService } from '../../backoffice/shared/user-data.service';
import { dataAnalytics } from '../../shared/data-analytics';
import { TracyService } from '../../shared/tracy.service';
import { Utils } from '../../core/utils';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { GoogleMapsLoaderService } from 'app/shared/google-maps-loader.service';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
    selector: 'oas-favourite-dealer',
    templateUrl: './favourite-dealer.component.html',
    styleUrls: ['./favourite-dealer.component.scss']
})
export class FavouriteDealerComponent implements OnInit {
    @Input() public outlet: Outlet;
    @Input() public defaultAddress: GeoMarker;
    @Input() public outletSelectTicket: Outlet;
    @Input() public vehicleBrands: Set<VehicleBrand>;
    @Output() public outletChange = new EventEmitter<Outlet>();
    @ViewChild(MapInfoWindow,  { static: false }) infoWindow: MapInfoWindow;
    @ViewChild(GoogleMap) private googleMap: GoogleMap;


    public markerTitle: string = '';
    public outlets: Outlet[] = [];
    public countryCode: string;
    public manageableBrands: ActivityCategory[] = [];
    public searchType = 'address';
    public latitude: number;
    public longitude: number;
    public zoom: number;
    public radius;
    public searchAddressControl = new UntypedFormControl();
    public searchOutletControl = new UntypedFormControl();
    public radiusControl;
    public isCustomer = false;
    public showSlider = true;
    public onPlaceChangeExecuted = false;
    public style: any = [
        {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#444444',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
                {
                    color: '#f2f2f2',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'all',
            stylers: [
                {
                    saturation: -100,
                },
                {
                    lightness: 45,
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'all',
            stylers: [
                {
                    color: '#46bcec',
                },
                {
                    visibility: 'on',
                },
            ],
        },
    ];
    public mapOptions: google.maps.MapOptions;
    public circleOptions: google.maps.CircleOptions;
    public displayMap = false;
    public infoWindowOptions: google.maps.InfoWindowOptions = {
        minWidth: 200,
        maxWidth: 201
    }
    public markerOutlet: Outlet = null;

    private countryConfiguration: CountryConfiguration;

    constructor(
        private outletService: OutletService,
        private authService: AuthService,
        protected userDataService: UserDataService,
        @Inject(LOCALE_ID) private locale: string,
        private tracyService: TracyService,
        countryConfigurationService: CountryConfigurationService,
        public googleMapsLoaderService: GoogleMapsLoaderService,

    ) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
        this.radius = this.countryConfiguration.mapsRadius;
        this.radiusControl = new UntypedFormControl(this.radius / 1000);
    }

    public ngOnInit(): void {
        if (this.locale.length > 2) {
            this.countryCode = this.locale.substring(3, 5);
        } else {
            this.countryCode = this.locale.substring(0, 2);
        }

        this.googleMapsLoaderService.load().then(() => {
            this.displayMap = true;
            this.setGoogleMapsDefault();
        });
        if (
            this.authService.isRentingAdmProfile() ||
            this.authService.isRentingUserProfile() ||
            this.authService.isCicEpManager() ||
            this.authService.isCicEpUser() ||
            this.authService.isCicNscAdmin() ||
            this.authService.isCicNscUser()
        ) {
            this.outlets = this.userDataService.getManageableOutlets();
        } else {
            const countryCodeCheck = this.countryCode === 'en' ? 'in' : this.countryCode;
            this.outletService.getAllByCountryAndVehicleBrands(countryCodeCheck, this.vehicleBrands).then(outlets => {
                this.outlets = outlets;
                // If Spain, load AD outlets too
                if (this.countryConfiguration.additionalCountryFavouriteOutlet) {
                    this.outletService.getAllByCountryAndVehicleBrands(this.countryConfiguration.additionalCountryFavouriteOutlet, this.vehicleBrands).then(outlets => {
                        outlets.forEach(out => {
                            this.outlets.push(out);
                        });
                        this.toggleOutletVisibility();
                    });
                } else {
                    this.toggleOutletVisibility();
                }
            });
        }
        if (this.outletSelectTicket) {
            this.onSelect(this.outletSelectTicket);
        }
        if (!Utils.isNullOrUndefined(this.outlet)) {
            this.setDealerInformationIntoDataAnalytics(this.outlet);
        }

        this.setDataAnalytics();

        if (this.authService.isCustomer() || Utils.isNullOrUndefined(this.authService.getSelectedRole())) {
            this.isCustomer = true;
        }
    }

    // TODO Go back BMWDS-1406 and BMWDS-1432
    //    public ngOnInit(): void {
    //        this.setGoogleMapsDefault();
    //        this.outletService.getAllByCountry(this.countryConfiguration.countryCode).then(outlets => {
    //            this.outlets = outlets;
    //            this.toggleOutletVisibility();
    //        });
    //    }
    public openInfoWindow(marker: MapMarker, outlet: Outlet) {
        this.markerTitle = outlet.businessName;
        this.markerOutlet = outlet;
        this.infoWindow.open(marker);
    }

    public closeInfoWindow() {
        this.infoWindow.close()
    }
    
    public getManageableBrands(outlet: Outlet) {
        let manageableBrands = [];
        if (outlet && outlet.skills) {
            manageableBrands = outlet.skills.filter(skill => skill.id.startsWith('WELCOME_'));
            manageableBrands.sort(function (a, b) {
                return a.id - b.id || a.id.localeCompare(b.id);
            });
            manageableBrands.reduce((result, skill) => {
                return {
                    ...result,
                    [this.retrieveVehicleBrand(skill.id)]: false,
                };
            }, {});
        }
        return manageableBrands;
    }

    public retrieveVehicleBrand(welcomeCategoryId: string): VehicleBrand {
        switch (welcomeCategoryId) {
            case 'WELCOME_BMW':
                return 'BMW';

            case 'WELCOME_BMWi':
                return 'BMW_I';

            case 'WELCOME_MINI':
                return 'MINI';

            case 'WELCOME_MOTORRAD':
                return 'BMW_MOTORRAD';
        }

        return null;
    }

    /**
     * Set google map with default configuration
     */
    private setGoogleMapsDefault() {
        this.latitude = this.outlet ? this.outlet.address.latitude : this.defaultAddress.latitude;
        this.longitude = this.outlet ? this.outlet.address.longitude : this.defaultAddress.longitude;
        this.zoom = countryConfiguration.mapsZoom;
        this.setMapAndCircleOptions();
    }

    public setMapAndCircleOptions() {
        this.mapOptions = {
            center: { 
                lat: this.latitude, 
                lng: this.longitude 
            },
            zoom : this.zoom,
            styles: this.style,
            scrollwheel: false,
            streetViewControl: false
        };

        this.circleOptions = {
            center: {
                lat: this.latitude, 
                lng: this.longitude 
            },
            radius: this.radius,
            clickable: false,
            visible: true
        };
    }

    public reset(): void {
        this.searchOutletControl.reset();
        this.outletChange.emit(null);
    }

    /**
     * On outlet select event handler
     * @param outlet selected outlet
     */
    private onSelect(outlet: Outlet): void {
        if (outlet) {
            this.latitude = outlet.address.latitude;
            this.longitude = outlet.address.longitude;
            this.setMapAndCircleOptions();
        }
        this.outlet = outlet;
        this.outletChange.emit(outlet);
    }

    public onSearchTypeChange(searchType: string): void {
        this.searchType = searchType;
        this.searchAddressControl.reset();
        this.searchOutletControl.reset();
        if (this.searchType === 'outlet') {
            this.showSlider = false;
        } else {
            this.showSlider = true;
        }
    }

    /**
     * Show/hide outlets based on destance between selected center and radius
     */
    public toggleOutletVisibility(): void {
        const circleCenter = new google.maps.LatLng(this.latitude, this.longitude);
        for (const outlet of this.outlets) {
            const outletLatLng = new google.maps.LatLng(outlet.address.latitude, outlet.address.longitude);
            if (google.maps.geometry.spherical.computeDistanceBetween(circleCenter, outletLatLng) > this.radius) {
                outlet.visible = false;
            } else {
                outlet.visible = true;
                if (this.onPlaceChangeExecuted) {
                    this.onPlaceChangeExecuted = false;
                }
            }
        }
    }
    public automaticRadius(): void {
        if (this.onPlaceChangeExecuted) {
            this.setRadius(String(this.radius / 1000 + 10));
            this.radiusControl = new UntypedFormControl(this.radius / 1000);
        }
    }
    public typeaheadOnSelect(e: TypeaheadMatch): void {
        this.onSelect(e.item);
    }

    public setRadius(value: string): void {
        this.radius = parseFloat(value) * 1000;
        this.circleOptions.radius = this.radius;
    }

    public trackByOutlet(outlet: Outlet) {
        return outlet.id;
    }

    public onPlaceChange(place: google.maps.places.PlaceResult): void {
        if (place.geometry) {
            // set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = countryConfiguration.mapsZoom;
            this.onPlaceChangeExecuted = true;
            this.setMapAndCircleOptions();
        }
    }

    public setDealerInformationIntoDataAnalytics(outlet: Outlet) {
        if (!Utils.isNullOrUndefined(outlet)) {
            this.tracyService.setDealerInformationIntoDataAnalytics(
                outlet.dealerId,
                outlet.businessName,
                outlet.id,
                'selected'
            );
        }
    }

    private setDataAnalytics() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );
        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasServiceCenterSelection,
            dataAnalytics.page.variant.virtual
        );
        if (!Utils.isNullOrUndefined(this.outlet)) {
            this.tracyService.setDealerInformationIntoDataAnalytics(
                this.outlet.dealerId,
                this.outlet.businessName,
                this.outlet.id,
                'selected'
            );
            this.tracyService.setUserInformationIntoDataAnalytics('na');
            this.tracyService.sendPageView(dataAnalytics.referenceName.serviceCenterChange);
        }
    }

    outletCityByAddress(address: GeoMarker): string {
        let locality;

        if (address && address.locationComponentList) {
            for (const locationComponent of address.locationComponentList) {
                if (locationComponent.label === <LocationComponentType>'locality') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_3') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_4') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_5') {
                    locality = locationComponent.longName;
                    break;
                }
            }
        }

        return locality;
    }

    public getMapZoom() {
        this.zoom = this.googleMap.getZoom();
        this.mapOptions.zoom = this.googleMap.getZoom();
    }
}
