import {Component, Input} from "@angular/core";
import {Utils} from "../../../core/utils";
import {TssbLeadCcmEvent, TssbTicket} from "../../entities";


@Component({
   selector: 'oas-ccm-lead-api',
   templateUrl: './ccm-lead-api.component.html',
   styleUrls: ['ccm-lead-api.component.scss']
})
export class CcmLeadApiComponent {
   @Input() tssbTicket: TssbTicket;
   public listCcm: TssbLeadCcmEvent[] = [];
   constructor(){}

   private leadAutoSelectCcm(): void {
      if (
         !Utils.isNullOrUndefined(this.tssbTicket) &&
         !Utils.isNullOrUndefined(this.tssbTicket.id) &&
         !Utils.isNullOrUndefined(this.tssbTicket.tssbStaticTicketData.keyData.ccmEvents) &&
         !Utils.isNullOrUndefined(this.tssbTicket.tssbStaticTicketData.keyData.ccmEvents.length > 0)
      ) {
         this.listCcm = this.tssbTicket.tssbStaticTicketData.keyData.ccmEvents;
      }
   }

   ngOnInit(): void {
      this.leadAutoSelectCcm();
   }
}
