import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { CountryConfiguration } from '../app/country-configuration';
import { setCountryConfiguration, countryConfiguration } from './country.configuration';
import { indiaCountryConfiguration } from './india.country.configuration';
import { italyCountryConfiguration } from './italy.country.configuration';
import { portugalCountryConfiguration } from './portugal.country.configuration';
import { spainCountryConfiguration } from './spain.country.configuration';
import { mexicoCountryConfiguration } from './mexico.country.configuration';
import { brazilCountryConfiguration } from './brazil.country.configuration';

@Injectable()
export class CountryConfigurationService {
    
    constructor(@Inject(LOCALE_ID) public locale: string) {
        this.setCountryConfiguration(locale);
    }

    private setCountryConfiguration(locale: string): void {
        switch (locale) {
            case 'es':
            case 'es-ES':
                setCountryConfiguration(spainCountryConfiguration);
                break;
            case 'pt':
            case 'pt-PT':
                setCountryConfiguration(portugalCountryConfiguration);
                break;
            case 'en':
            case 'en-IN':
                setCountryConfiguration(indiaCountryConfiguration);
                break;
            case 'it':
            case 'it-IT':
                setCountryConfiguration(italyCountryConfiguration);
                break;
            case 'es-MX':
                setCountryConfiguration(mexicoCountryConfiguration);
                break;
            case 'pt-BR':
                setCountryConfiguration(brazilCountryConfiguration);
                break;
            default:
                setCountryConfiguration(italyCountryConfiguration);
                break;
        }
    }

    public getCountryConfiguration(): CountryConfiguration {
        return countryConfiguration;
    }
}
