import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { Logger } from '../../logger.service';

export enum GroupingDate {
    DATE,
    MONTH,
}

export abstract class KpiService {
    constructor(protected actionUrl: string, protected http: HttpClient, protected logger: Logger) {
        this.actionUrl = `${environment.apiUrl}/kpi${this.actionUrl}`;
    }

    protected handleError(error: any): Promise<any> {
        this.logger.debug('error');
        this.logger.debug(error);

        return Promise.reject(error);
    }
}
