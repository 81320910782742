import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { ConsentService } from '../../core/api/consent/consent.service';
import { VehicleService } from '../../core/api/vehicle/vehicle.service';
import { StyleSwitcherService } from '../../core/style-switcher.service';
import { Utils } from '../../core/utils';
import { CountryConfiguration } from '../../country-configuration';
import { VehicleFormComponent } from '../../customer/vehicle/vehicle-form.component';
import {Appointment, Customer, GenderType, Outlet, Vehicle} from '../../shared/entities';
import { AppComponent } from './../../app.component';
import {OutletService} from "../../core/api/outlet/outlet.service";
import swal, {SweetAlertOptions} from "sweetalert2";
import {TranslatePipe} from "../../shared/pipe/translate.pipe";
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'oas-appointment-without-registration',
    templateUrl: './appointment-without-registration.component.html',
    styleUrls: ['./appointment-without-registration.component.scss'],
})
export class AppointmentWithoutRegistrationComponent implements OnInit, OnDestroy {
    @ViewChild(VehicleFormComponent) private vehicleFormComponent: VehicleFormComponent;
    @ViewChild('termsAndConditionsTemplate') private termsAndConditionsTemplate: TemplateRef<any>;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private countryConfigurationService: CountryConfigurationService,
        public appComponent: AppComponent,
        private modalService: BsModalService,
        private styleSwitcherService: StyleSwitcherService,
        private consentService: ConsentService,
        private vehicleService: VehicleService,
        private outletService: OutletService,
        private translatePipe: TranslatePipe
    ) {
        this.countryConfiguration = this.countryConfigurationService.getCountryConfiguration();
    }

    public vehicle: Vehicle;
    public manualStep: boolean;
    public hideBackButtonForManualStep: boolean;
    public plate: string;
    public kilometers: number;
    public appointment: Appointment;
    public countryConfiguration: CountryConfiguration;

    public preselectedOutlet: Outlet = null;

    protected termsAndConditionsModalRef: BsModalRef;
    protected isMini: boolean;

    private searchByVinOnFirstVehicleNotFoundException = true;

    public tcModalClosed = true;

    protected modalConfig = {
        animated: false,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    public tcModalClosedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    ngOnInit(): void {
        this.checkConsent();
        this.checkPreselectedOutlet();
        if (this.countryConfiguration.hideAutomaticVehicleRecognitionStepIntoAppointmentWithoutRegistration) {
            this.manualStep = true;
            this.hideBackButtonForManualStep = true;
        }

        this.appointment = this.createBaseAppointment();

        this.isMini =
            this.styleSwitcherService.isBrandSet() &&
            this.styleSwitcherService.brand.getValue().toLowerCase() === 'mini';
    }

    public ngOnDestroy(): void {
        if (document.getElementsByTagName('modal-container').length) {
            this.hideTermsAndConditions();
        }
    }

    private checkConsent(): void {
        if(sessionStorage.getItem('isTCAccepted') === 'true') {
            this.fillVehicleDataFromQueryParamIfPresent();
            sessionStorage.removeItem('isTCAccepted');
        } else {
            this.consentService.isLastTermsAndConditionsAccepted().then(isLastTermsAndConditionsAccepted => {
                if (isLastTermsAndConditionsAccepted) {
                    this.fillVehicleDataFromQueryParamIfPresent();
                } else {
                    this.showTermsAndConditions();
    
                }
            });
        }
    }

    private showTermsAndConditions(): void {
        this.tcModalClosed = false;
        this.tcModalClosedSubject.next(this.tcModalClosed);
        this.termsAndConditionsModalRef = this.modalService.show(this.termsAndConditionsTemplate, {
            ...this.modalConfig,
            class: 'terms-and-conditions-modal modal-lg',
        });
    }

    private hideTermsAndConditions(): void {
        this.tcModalClosed = true;
        this.tcModalClosedSubject.next(this.tcModalClosed);

        if (this.termsAndConditionsModalRef) {
            this.termsAndConditionsModalRef.hide();
        }
    }

    private createBaseAppointment(): Appointment {
        return {
            withoutRegistration: true,
            customer: this.createBaseCustomer(),
            activityList: [],
            mobilityList: [],
            vehicle: {},
            outlet: this.preselectedOutlet,
            technicalCampaignsAvailable: false,
            addingItemsToBasket: true,
            editItemsFromBasket: true,
            removingItemsFromBasket: true,
            cancelAppointment: true,
        };
    }

    private createBaseCustomer(): Customer {
        const gender = this.route.snapshot.queryParamMap.get('gender');
        return {
            name: this.route.snapshot.queryParamMap.get('name'),
            surname: this.route.snapshot.queryParamMap.get('surname'),
            secondSurname: this.route.snapshot.queryParamMap.get('second_surname'),
            gender: gender === <GenderType>'MALE' || gender === <GenderType>'FEMALE' ? <GenderType>gender : undefined,
            email: this.route.snapshot.queryParamMap.get('email'),
            phoneNumber: this.route.snapshot.queryParamMap.get('phone'),
        };
    }

    public goToLogin() {
        this.router.navigate(['/welcome']);
    }

    public vehicleNotFoundFromExternalSystem(value: boolean) {
        const vin = this.route.snapshot.queryParamMap.get('vin');
        if (!Utils.isNullOrUndefined(vin) && this.searchByVinOnFirstVehicleNotFoundException) {
            this.searchByVinOnFirstVehicleNotFoundException = false;
            this.searchVehicleByVehicleVin(vin);
        } else {
            this.showManualStep(value);
        }
    }

    private showManualStep(value: boolean): void {
        this.plate = this.vehicleFormComponent.vehicleForm.controls['plate'].value;
        if (this.route.snapshot.queryParamMap.has('kilometers')) {
            const kilometersFromQueryParam = +this.route.snapshot.queryParamMap.get('kilometers');
            this.kilometers = isNaN(kilometersFromQueryParam) ? undefined : kilometersFromQueryParam;
        }
        this.vehicleFormComponent.vehicleForm.reset();
        this.manualStep = value;
    }

    private searchVehicleByVehicleVin(vin: string): void {
        this.vehicleFormComponent.vehicleForm.get('vin').setValue(vin);
        this.vehicleFormComponent.onBlurVin(vin);
    }

    public updateVehicle(value: Vehicle) {
        this.appointment.vehicle = value;
        this.goToAppointmentComponent();
    }

    public updateCustomerFiscalCode(value: string) {
        this.appointment.customer.vatNumber = value;
    }

    private async goToAppointmentComponent(): Promise<void> {
        sessionStorage.setItem('appointmentFromWithoutRegistration', JSON.stringify(this.appointment));

        const queryParams = {
            isWithoutRegistration: true,
            selectedCbsIds: undefined,
            showErrorImpossibleToAutomaticSelectActivities: undefined,
            RKR: undefined,
        };

        if (this.appointment.vehicle.vin) {
            queryParams.RKR = await this.retrieveVehicleRemoteKeyRead(this.appointment.vehicle.vin);
        }

        if (this.manualStep) {
            queryParams.showErrorImpossibleToAutomaticSelectActivities = this.route.snapshot.queryParamMap.has(
                'preselectedCBS'
            )
                ? true
                : undefined;
        } else {
            queryParams.selectedCbsIds = this.route.snapshot.queryParamMap.get('preselectedCBS');
        }

        this.router.navigate(['/appointment'], {
            queryParams: queryParams,
        });
    }

    private async retrieveVehicleRemoteKeyRead(vin: string) {
        const response = await this.vehicleService.getVehicleRemoteKeyRead(vin, undefined);
        return response as boolean;
    }

    public onBackButtonClickedFromManualForm() {
        this.manualStep = false;
    }

    public onAgree(): void {
        this.tcModalClosed = true;
        this.tcModalClosedSubject.next(this.tcModalClosed)

        this.hideTermsAndConditions();
        this.fillVehicleDataFromQueryParamIfPresent();
    }

    private fillVehicleDataFromQueryParamIfPresent(): void {
        const plate = this.route.snapshot.queryParamMap.get('plate');
        if (!Utils.isNullOrUndefined(plate)) {
            this.vehicleFormComponent.vehicleForm.get('plate').setValue(plate);
        }
        const fiscalCode = this.route.snapshot.queryParamMap.get('fiscal_code');
        if (!Utils.isNullOrUndefined(fiscalCode) && this.countryConfiguration.showFiscalNumberVehicleForm) {
            this.vehicleFormComponent.vehicleForm.get('fiscalNumber').setValue(fiscalCode);
        }
    }

    private checkPreselectedOutlet(){
       const preselectedOutletInfo = sessionStorage.getItem('preselectedOutletInfo');
      if (preselectedOutletInfo) {
         this.outletService.getByOutletId("OUTL_" + preselectedOutletInfo).then(outlet => {
            if(outlet != null){
               this.preselectedOutlet = outlet;
               sessionStorage.setItem('preselectedOutlet', JSON.stringify(outlet));
            } else {
               const modal: SweetAlertOptions = {
                  title: this.translatePipe.transform('OUTLET_NOT_FOUND_TITLE'),
                  text:  this.translatePipe.transform('OUTLET_NOT_FOUND_TEXT'),
                  icon: 'error',
               };
              swal.fire(modal);
            }
         });
      }
   }
}
