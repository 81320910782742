import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { VehicleService } from '../../core/api/vehicle/vehicle.service';
import { BsiBaseComponent } from './bsi-base.component';

@Component({
    selector: 'oas-bsi',
    templateUrl: './bsi.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class BsiComponent extends BsiBaseComponent implements OnInit {
    constructor(
        protected vehicleService: VehicleService,
        protected countryConfigurationService: CountryConfigurationService
    ) {
        super(vehicleService, countryConfigurationService);
    }

    ngOnInit() {
        super.ngOnInit();
    }
}
