import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FastLane } from '../../../shared/entities';
import { Logger } from '../../logger.service';
import { AbstractRestService } from '../generic.service';

@Injectable()
export class FastLaneService extends AbstractRestService<FastLane, number> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/fastlane', http, logger);
    }

    public findByOutlet(outletId: string): Promise<FastLane[]> {
        const url = `${this.actionUrl}/by-outlet/${outletId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as FastLane[])
            .catch(error => this.handleError(error));
    }

    public findActiveFastLaneByOutlet(outletId: string): Promise<FastLane[]> {
        return this.findByOutlet(outletId).then(fastLane =>
            fastLane.filter(sl => sl.active)
        );
    }

    public countByOutlet(outletId: string, status?: boolean): Promise<number> {
        const url = `${this.actionUrl}/by-outlet/${outletId}/count`;
        let params = new HttpParams();

        if (status != undefined || status != null) {
            params = params.set('status', String(status));
        }

        return this.http
            .get(url, { params, responseType: 'text' })
            .toPromise()
            .then(response => +response)
            .catch(error => this.handleError(error));
    }
}
