export const environment = {
    production: true,
    apiUrl: '/api',
    imgUrl: '/bmw-car-img',
    welcomeTerminalUrl: '/welcome-terminal',
    captchaKey: '6LctjD8UAAAAAD3ncrwZr5Ok4UQbMxmRe9naCe6Z',
    debug: false,
    attachmentsEnabled: true,
    attachmentsUrl: '/attachments',
    pickupServiceId: 2,
    courtesyCarServiceId: 1,
    courtesyMotoServiceId: 9,
    fastLaneThreshold: 120,
    allDayCourtesyCarDuration: 900,
    halfDayAmCourtesyCarDuration: 420,
    halfDayPmCourtesyCarDuration: 480,
    enableWheelsMgmt: true, // TODO: remove me
    toros: {
        url: 'https://dealerapps-es.bmwgroup.com/toros/Search/Get?',
        plateParameter: 'PlateNo=',
        vinParameter: 'Vin=',
    },
    supportEmailAddress: 'Service.Check-in.Support@everis.com',
    tuga: 'https://sss01qly.claranet.pt/ords/bmw/oas/veh?searchattrib=1&searchval='
};
