<div class="page--dashboard">
    <div class="dashboard-header cover-{{ currentSeasonName }}">
        <div class="row no-gutters">
            <div class="container-your-dashboard-and-logo">
                <div class="container-your-dashboard">
                    <h1 class="dashboard-header__title" i18n="@@yourDashboard">
                        Your Dashboard
                    </h1>
                </div>
                <div class="container-logo">
                    <oas-logo></oas-logo>
                </div>
            </div>
            <div class="col-md-3">
                <div class="dashboard-header__title__wrapper">
                    <div class="dashboard-header__account">
                        <span dropdown>
                            <a href dropdownToggle (click)="(false)">
                                <img
                                    src="assets/images/ic_account-register-white-bordered.svg"
                                    alt=""
                                    class="dashboard-header__account-icon"
                                />
                            </a>
                            <ul *dropdownMenu class="dropdown-menu dropdown-collapse" style="min-width: unset;" [ngClass]="tcModalClosed ? 'tc_modal_closed':'tc_modal_opened'">
                                <li>
                                    <a class="dropdown-item" [href]="editProfile()" target="_blank" i18n="@@myProfile"
                                        >Il mia profila</a
                                    >
                                </li>
                                <li>
                                    <a
                                        (click)="navigateToEditAccountPage()"
                                        routerLinkActive="active"
                                        class="dropdown-item"
                                        i18n="@@notificationPreferences"
                                        >Notification preferences</a
                                    >
                                </li>
                            </ul>
                        </span>
                        <span *ngIf="local != 'it-IT'">|</span>
                        <span><oas-logout></oas-logout></span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-md-offset-0 col-sm-8 col-sm-offset-2" [ngClass]="tcModalClosed ? 'tc_modal_closed':'tc_modal_opened'">
                <oas-vehicle-carousel
                    [vehicles]="customer?.vehicleList"
                    [(swiperIndex)]="swiperIndex"
                    (select)="onSelectVehicle($event)"
                ></oas-vehicle-carousel>
            </div>
        </div>
    </div>
    <div class="section-request-assistance" [ngClass]="tcModalClosed ? 'tc_modal_closed':'tc_modal_opened'">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-md-offset-4">
                    <button
                        [disabled]="scheduledAppointment || wrongCustomerInfo || !tcModalClosed"
                        class="btn btn-primary btn-request-assistance ui-button"
                        (click)="onClickAssistance()"
                        i18n="@@requestAssistance"
                    >
                        Request assistance for this {{ selectedVehicle?.model?.brand | translate }}
                    </button>
                </div>
                <div class="col-md-4">
                    <div class="action-buttons" data-tracking-regionid="online service appointment">
                        <a
                            (click)="navigateToVehiclePage()"
                            routerLinkActive="active"
                            class="btn btn-clear"
                            data-tracking-linkid="local > add vehicle"
                        >
                            <i class="bmw bmw-generic_ico_add_vehicle icon-lg"></i>
                            <span i18n="@@addVehicle">Add vehicle</span>
                        </a>
                        <a
                            class="btn btn-clear"
                            data-tracking-linkid="local > vehicle selection"
                            (click)="openVehiculePopup()"
                        >
                            <i class="bmw bmw-generic_ico_your_vehicles icon-lg"></i>
                            <span i18n="@@yourVehicles">Your vehicles</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="section-content" [ngClass]="tcModalClosed ? 'tc_modal_closed':'tc_modal_opened'">
            <div class="row">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="section-content--your-dealer">
                                <div class="card card-dealer">
                                    <div class="card-block">
                                        <div class="card-title">
                                            <i class="bmw bmw-generic_ico_dealer icon-lg"></i>
                                            <span i18n="@@yourDealer">Your dealer</span>
                                        </div>
                                        <ng-container *ngIf="customer.favouriteOutlet; else noFavouriteDealer">
                                            <div class="dealer-name">
                                                {{ customer.favouriteOutlet.businessName }}
                                                <span
                                                    *ngIf="customer.favouriteOutlet.status == 'inactive'"
                                                    i18n-tooltip="@@favouriteOutletNotAvailable"
                                                    tooltip="Outlet not available"
                                                    placement="right"
                                                >
                                                    <i
                                                        class="fa fa-exclamation-triangle fa-lg"
                                                        aria-hidden="true"
                                                        style="color: goldenrod;"
                                                    ></i>
                                                </span>
                                            </div>
                                            <div class="dealer-name">
                                                <span i18n="@@phone">Phone</span>:
                                                {{ customer.favouriteOutlet.phoneNumber }}
                                            </div>
                                            <div class="dealer-name">
                                                <span i18n="@@email">Email</span>: {{ customer.favouriteOutlet.email }}
                                            </div>
                                            <div class="dealer-address">
                                                {{ customer.favouriteOutlet?.address?.address }}
                                                <span *ngIf="customer.favouriteOutlet?.address?.stairInt">
                                                    <br />{{ customer.favouriteOutlet?.address?.stairInt }}</span
                                                >
                                                <br />
                                                {{ customer.favouriteOutlet?.address?.zipCode }},
                                                {{ customer.favouriteOutlet?.address?.country.code }}
                                            </div>
                                            <button
                                                [disabled]="!tcModalClosed"
                                                class="btn-change-dealer"
                                                (click)="openDealerSelectionPopup()"
                                                i18n="@@changeFavouriteDealer"
                                            >
                                                CHANGE DEALER
                                            </button>
                                        </ng-container>
                                        <ng-template #noFavouriteDealer>
                                            <div class="scheduled-assistances-body--empty">
                                                <i class="fa fa-map-signs"></i>
                                                <p i18n="@@noFavouriteDealer">
                                                    You have no favourite dealer
                                                </p>
                                            </div>

                                            <button
                                                class="btn-change-dealer"
                                                [class.disabled]="wrongCustomerInfo"
                                                [disabled]="wrongCustomerInfo || !tcModalClosed"
                                                (click)="openDealerSelectionPopup()"
                                                i18n="@@selectFavouriteDealer"
                                            >
                                                SELECT DEALER
                                            </button>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div class="section-content">
                                <div class="section-content--bsi">
                                    <div class="card">
                                        <div class="card-block bsi-card-container">
                                            <div class="card-title">
                                                <i class="bmw bmw-ico_generic icon-lg"></i>
                                                <span>{{ infoBoxBsi.title }}</span>
                                            </div>
                                            <ng-container
                                                *ngIf="
                                                    countryConfiguration.enableBsi &&
                                                        selectedVehicle &&
                                                        selectedVehicle.bsiContracts &&
                                                        selectedVehicle.bsiContracts.length > 0;
                                                    else noBsiContract
                                                "
                                            >
                                                <oas-bsi [vehicle]="selectedVehicle"></oas-bsi>
                                                <a
                                                    *ngIf="infoBoxBsi.linkWithBsi?.length > 0"
                                                    class="btn-bsi-information"
                                                    href="{{ infoBoxBsi.linkWithBsi }}"
                                                    target="blank"
                                                >
                                                    {{ infoBoxBsi.linkText }}
                                                </a>
                                            </ng-container>
                                            <ng-template #noBsiContract>
                                                <span class="no-bsi-description">{{ infoBoxBsi.description }}</span>
                                                <ng-template #showLinkWithoutBsi>
                                                    <a
                                                        *ngIf="infoBoxBsi.linkWithoutBsi?.length > 0"
                                                        class="btn-bsi-information"
                                                        href="{{ infoBoxBsi.linkWithoutBsi }}"
                                                        target="blank"
                                                    >
                                                        {{ infoBoxBsi.linkText }}
                                                    </a>
                                                </ng-template>
                                                <button
                                                    *ngIf="countryConfiguration.enableSPOA; else showLinkWithoutBsi"
                                                    type="button"
                                                    [disabled]="scheduledAppointment || wrongCustomerInfo || !tcModalClosed"
                                                    class="btn btn-primary ui-button btn-text-wrap"
                                                    (click)="onClickSPOA()"
                                                    i18n="@@bookMaintenancePackage"
                                                >
                                                    Book to subscribe your maintenance package
                                                </button>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="section-content--scheduled-assistances">
                                <div class="card card-schedule">
                                    <div class="card-block">
                                        <div class="card-title">
                                            <i class="bmw bmw-generic_ico_scheduled_assistance icon-lg"></i>
                                            <span i18n="@@scheduledAssistance">Scheduled assistances</span>
                                        </div>
                                        <ng-container *ngIf="scheduledAppointment; else noScheduledAssistance">
                                            <div
                                                class="assistance-schedule assistance-schedule--transparent assistance-schedule--nopadding"
                                            >
                                            <ng-container
                                                    *ngIf="scheduledAppointment.welcomeTerminalAppointmentId"
                                                >
                                                <div class="assistance-schedule__date">

                                                    <strong>
                                                        <span i18n="@@welcomeTerminalID">Appointment ID</span>:
                                                        {{ scheduledAppointment.welcomeTerminalAppointmentId}}
                                                    </strong>
                                                </div>
                                            </ng-container>
                                                <hr class="assistance-schedule__separator" />

                                                <div class="assistance-schedule__welcomeTerminalAppointmentId">
                                                    <strong
                                                        >{{ scheduledAppointment.startTime | datex: 'shortDate' }}
                                                    </strong>
                                                    {{ scheduledAppointment.startTime | datex: 'shortTime' }}
                                                </div>
                                                <div class="assistance-schedule__dealer-name">
                                                    {{ scheduledAppointment.outlet.businessName }}
                                                </div>
                                                <div class="assistance-schedule__dealer-address">
                                                    {{ scheduledAppointment.outlet.address.address }}
                                                </div>
                                                <ng-container
                                                    *ngIf="scheduledAppointment.serviceAdvisor.privacyAgreement"
                                                >
                                                    <hr class="assistance-schedule__separator" />
                                                    <div class="assistance-schedule__dealer-address">
                                                        {{ scheduledAppointment.serviceAdvisor.name }}
                                                        {{ scheduledAppointment.serviceAdvisor.surname }}
                                                    </div>
                                                    <div class="assistance-schedule__dealer-address">
                                                        <span i18n="@@phone">Phone</span>:
                                                        {{ scheduledAppointment.serviceAdvisor.mobilePhone }}
                                                    </div>
                                                    <div class="assistance-schedule__dealer-address">
                                                        <span i18n="@@email">Email</span>:
                                                        {{ scheduledAppointment.serviceAdvisor.email }}
                                                    </div>
                                                </ng-container>
                                                <hr class="assistance-schedule__separator" />

                                                <ul class="assistance-schedule__types">
                                                    <li
                                                        *ngFor="
                                                            let activity of scheduledAppointment.activityList
                                                                | filter: filterPredicate
                                                        "
                                                        class="assistance-schedule__type"
                                                    >
                                                        <div class="centred-container">
                                                            <ng-container
                                                                *ngTemplateOutlet="
                                                                    iconTmp;
                                                                    context: {
                                                                        iconName: activity.iconClass
                                                                    }
                                                                "
                                                            ></ng-container>

                                                            <p class="activity-name-centred">
                                                                {{ activity.name }}
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>

                                                <ul class="assistance-schedule__types">
                                                    <li
                                                        *ngFor="let mobility of scheduledAppointment.mobilityList"
                                                        class="assistance-schedule__type"
                                                    >
                                                        <div class="centred-container">
                                                            <i
                                                                class="bmw bmw-{{
                                                                    mobility.mobilityType.iconClass
                                                                }} icon-default mobility-icon"
                                                            ></i>

                                                            <p class="activity-name-centred">
                                                                {{ mobility.mobilityType.descriptionLocalized }}
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ng-container *ngIf="scheduledAppointment.status == 'IN_VALIDATION'">
                                                    <hr class="assistance-schedule__separator" />
                                                    <div class="assistance-schedule__dealer-address">
                                                        <span class="dashboard-in-validation">{{
                                                            'IN_VALIDATION' | translate
                                                        }}</span>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="card-schedule--edit-schedule text-right">
                                                <button
                                                    [disabled]="!tcModalClosed"
                                                    class="btn text-primary card-schedule--edit-schedule-btn"
                                                    (click)="onClickEditAssistance(scheduledAppointment)"
                                                    *ngIf="
                                                        (scheduledAppointment.status === 'IN_VALIDATION' ||
                                                        scheduledAppointment.status === 'PENDING') && !scheduledAppointment.fromMobileAppConnectedDrive
                                                    "
                                                    i18n="@@edit"
                                                >
                                                    EDIT
                                                    <span class="fa fa-pencil"></span>
                                                </button>
                                            </div>
                                        </ng-container>
                                        <div
                                            *ngIf="this.multimediaAllowed.get(scheduledAppointment?.outlet?.id)"
                                            class="section-content--scheduled-multimedia"
                                        >
                                            <div class="card card-schedule">
                                                <div class="card-block">
                                                    <div class="card-title">
                                                        <span class="icon glyphicon glyphicon-film"></span>
                                                        <span i18n="@@multimedia">Multimedia</span>
                                                    </div>
                                                    <div
                                                        class="assistance-schedule assistance-schedule--transparent assistance-schedule--nopadding"
                                                    >
                                                        <div
                                                            class="multimedia-container"
                                                            *ngFor="
                                                                let item of scheduledAppointment?.multimediaResourceList
                                                            "
                                                        >
                                                            <span (click)="directPlay(item)">
                                                                <span [class]="iconByType(item.resourceTypeId)"></span>
                                                                {{ item.resourceObservations }}
                                                            </span>
                                                            <span
                                                                *ngIf="item.user == null"
                                                                class="icon glyphicon glyphicon-remove"
                                                                (click)="resourceToDelete = item.id"
                                                            ></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <multimedia
                                                [multimediaResource]="multimediaResource"
                                                [resourceToDelete]="resourceToDelete"
                                                [mmConfig]="optionMultimedia"
                                                [env]="env"
                                                [dealerId]="scheduledAppointment?.outlet?.dealerId"
                                                [userId]="customer.id"
                                                [customerId]="scheduledAppointment?.customerVatNumber"
                                                [userName]="customer.displayName"
                                                [vehiclePlate]="scheduledAppointment?.vehiclePlate"
                                                [languaje]="countryConfiguration.language"
                                                [bsconfig]="{
                                                    dateInputFormat: 'DD/MM/YYYY',
                                                    containerClass: 'theme-dark-blue'
                                                }"
                                                [origin]="3"
                                                (assocResources)="associateResource($event)"
                                                (disasResources)="disassociateResource($event)"
                                            >
                                            </multimedia> -->
                                        </div>
                                        <ng-template #noScheduledAssistance>
                                            <div class="scheduled-assistances-body--empty">
                                                <i class="fa fa-wrench"></i>
                                                <p i18n="@@noScheduledAssistance">
                                                    You have no scheduled assistances
                                                </p>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div class="section-content">
                                <div class="previous-assistances">
                                    <div class="card card-schedule">
                                        <div class="card-block">
                                            <div class="card-title">
                                                <i class="bmw bmw-ico_generic icon-lg"></i>
                                                <span i18n="@@previousAssistance">Previous assistances</span>
                                            </div>
                                            <div class="priority-help__text" i18n="@@requestAssistanceDescription">
                                                Check the history of your previous services.
                                            </div>
                                            <div data-tracking-regionid="online service appointment">
                                                <button
                                                    [disabled]="!tcModalClosed"
                                                    class="btn btn-primary ui-button priority-help__action-button"
                                                    (click)="openAssistanceHistoryPopup()"
                                                    i18n="@@view"
                                                    data-tracking-linkid="local > historical appointment viewed"
                                                >
                                                    View
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6"></div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div *ngIf="vehicleBrand != 'BMW_MOTORRAD' || infoBox.active" class="section-content--services">
                        <div class="card card-services required-services">
                            <div class="card-block">
                                <div class="card-title">
                                    <span i18n="@@requiredServices">Required services</span>
                                </div>
                                <div
                                    *ngIf="
                                        requiredServicesLoading;
                                        else thereAreRequiredServices() ? requiredServices : noRequiredServices
                                    "
                                    class="loader-container"
                                >
                                    <ngx-loading [show]="requiredServicesLoading"></ngx-loading>
                                </div>
                                <ng-template #requiredServices>
                                    <div
                                        *ngIf="leadTssbTickets?.length; else noRequiredServices"
                                        class="schedule-service-types"
                                    >
                                        <ng-container
                                            *ngIf="leadTssbTickets?.length && countryConfiguration.enableLeadApi"
                                        >
                                            <a
                                                [class.disabled]="scheduledAppointment || wrongCustomerInfo"
                                                (click)="openNotificationsPopup()"
                                                class="schedule-service-type"
                                            >
                                                <span>
                                                    <i
                                                        class="fa fa-eye icon-active schedule-service-type-image"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                                <span i18n="@@RemoteServicesNeeded">
                                                    Services required by the vehicle (Notification)
                                                </span>
                                                <span class="schedule-service-type--select">
                                                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                                </span>
                                            </a>
                                        </ng-container>
                                    </div>
                                </ng-template>
                                <ng-template #noRequiredServices i18n="@@noRequiredServices">
                                    No required services
                                </ng-template>
                                <div class="card-info" *ngIf="countryConfiguration.enableLeadApi">
                                    <span i18n="@@tssbInfoMessage"
                                        >tssb info message {{ selectedVehicle?.model?.brand | translate }}</span
                                    >
                                    <a
                                        *ngIf="tssbInfoUrlEnable"
                                        href="{{ tssbInfoUrl }}"
                                        target="blank"
                                        i18n="@@tssbInfoLink"
                                        >tssb info link {{ selectedVehicle?.model?.brand | translate }}</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div *ngIf="infoBox.active" class="card card-services info-box">
                            <div class="card-block">
                                <div class="card-title">
                                    <span>{{ infoBox.title }}</span>
                                </div>
                                <div class="schedule-service-types">
                                    <span>{{ infoBox.description }}</span>
                                    <div class="card-info info-box-link-container">
                                        <span>{{ infoBox.descriptionForLink }}</span>
                                        <a class="info-box-link-text" href="{{ infoBox.link }}" target="blank">
                                            {{ infoBox.linkText }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #yourVehicleTemplate>
    <div class="modal-header services__modal-header">
        <button type="button" class="close" aria-label="Close" (click)="appComponent.closeModal('yourVehicle')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" i18n="@@yourVehicles">Your Vehicles</h4>
        <p class="modal-caption" i18n="@@yourVehiclesDescription">
            Click and select the vehicle to get assistance.
        </p>
    </div>
    <div class="modal-body">
        <oas-vehicle-list [customer]="customer" (select)="changeSwiperIndex($event)" (refresh)="refreshCustomer()">
        </oas-vehicle-list>
    </div>
</ng-template>

<ng-template #termsAndConditionsTemplate>
    <div class="modal-body">
        <oas-terms-and-conditions
            [countryCode]="countryConfiguration.countryCode"
            (onAgree)="onAgree()"
        ></oas-terms-and-conditions>
    </div>
</ng-template>

<ng-template #dealerSelectionTemplate>
    <div class="modal-header services__modal-header">
        <button type="button" class="close" aria-label="Close" (click)="appComponent.closeModal('dealerSelection')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" i18n="@@yourDealer">Your Dealer</h4>
        <p class="modal-caption" i18n="@@yourDealerDescription">
            Type for searching and select your favourite dealer.
        </p>
    </div>
    <div class="modal-body">
        <oas-favourite-dealer
            [outlet]="customer.favouriteOutlet"
            [defaultAddress]="customer.address"
            [vehicleBrands]="customerVehicleBrands"
            (outletChange)="selectedFavouriteOutlet = $event"
        ></oas-favourite-dealer>
        <div class="text-center">
            <button
                type="button"
                class="btn btn-primary ui-button"
                [disabled]="selectedFavouriteOutlet ? false : true"
                (click)="saveFavouriteOutlet(); setDataAnalyticsEventForSavingFavouriteDealer()"
                i18n="@@save"
            >
                Save
            </button>
        </div>
    </div>
</ng-template>

<ng-template #assistanceHistory>
    <div class="modal-header services__modal-header">
        <button type="button" class="close" aria-label="Close" (click)="appComponent.closeModal('assistance')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" i18n="@@assistanceHistory">Assistance History</h4>
    </div>
    <div class="modal-body">
        <ul class="assistance-schedules">
            <li
                *ngFor="let appointment of customer.appointmentList | appointmentsHistory: selectedVehicle.id"
                class="assistance-schedule"
            >
                <div class="assistance-schedule__date">
                    <strong>{{ appointment.startTime | datex: 'shortDate' }} </strong>
                    {{ appointment.startTime | datex: 'shortTime' }}
                </div>
                <div class="assistance-schedule__dealer-name">
                    {{ appointment.outlet?.businessName }}
                </div>
                <ul class="assistance-schedule__types">
                    <li *ngFor="let activity of appointment.activityList" class="assistance-schedule__type">
                        <div class="centred-container">
                            <ng-container
                                *ngTemplateOutlet="
                                    iconTmp;
                                    context: {
                                        iconName: activity.iconClass
                                    }
                                "
                            ></ng-container>
                            <strong class="activity-name-centred">
                                {{ activity.name }}
                            </strong>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</ng-template>

<ng-template #cbsAppointmentTemplate>
    <div class="modal-header services__modal-header">
        <button type="button" class="close" (click)="closeCbsAppointment()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" i18n="@@cbsRequiredServices">Services required by the vehicle (Notifications)</h4>
    </div>
    <div class="modal-body services__modal-body appointment-detail__modal-body tssb-detail">
       <div *ngIf="leadTssbTickets?.length > 0">
          <tabset justified="true">
             <tab [active]="true" customClass="assistance-tabs__tab" *ngIf="filterTicketsByType('CBS')?.tssbStaticTicketData?.keyData?.conditionBasedServices?.length > 0">
                <ng-template tabHeading>
                   <div class="assistance-tabs__tab--inner">
                      <p class="assistance-tabs__tab-service-name" i18n="@@cbs">CBS</p>
                   </div>
                </ng-template>
                <div class="assistance-tabs__tab-content">
                   <div class="row">
                      <oas-cbs-teleservices [tssbTicket]="filterTicketsByType('CBS')" (selectedCBSList)="preselectedCBSList = $event">
                      </oas-cbs-teleservices>
                   </div>
                </div>
             </tab>
             <!-- MANUAL ACTIVITY -->
             <tab [active]="!(filterTicketsByType('CBS')?.tssbStaticTicketData?.keyData?.conditionBasedServices?.length > 0)" customClass="assistance-tabs__tab" *ngIf="filterTicketsByType('CCM')?.tssbStaticTicketData?.keyData?.ccmEvents?.length > 0">
                <ng-template tabHeading>
                   <div class="assistance-tabs__tab--inner">
                      <p class="assistance-tabs__tab-service-name" i18n="@@ccm">CCM</p>
                   </div>
                </ng-template>
                <div class="assistance-tabs__tab-content">
                   <div class="row">
                      <oas-ccm-lead-api [tssbTicket]="filterTicketsByType('CCM')"></oas-ccm-lead-api>
                   </div>
                </div>
             </tab>
             <tab [active]="!(filterTicketsByType('CCM')?.tssbStaticTicketData?.keyData?.ccmEvents?.length > 0 || filterTicketsByType('CBS')?.tssbStaticTicketData?.keyData?.conditionBasedServices?.length > 0)" customClass="assistance-tabs__tab" *ngIf="filterTicketsByType('TIRE')?.tssbStaticTicketData?.keyData?.tireEvents?.length > 0">
                <ng-template tabHeading>
                   <div class="assistance-tabs__tab--inner">
                      <p class="assistance-tabs__tab-service-name" i18n="@@tires">TIRE</p>
                   </div>
                </ng-template>
                <div class="assistance-tabs__tab-content">
                   <div class="row">
                    <oas-tires-lead-api [tssbTicket]="filterTicketsByType('TIRE')"></oas-tires-lead-api>
                   </div>
                </div>
             </tab>
             <tab *ngIf="leadBatteryEnabled">
                <ng-template tabHeading>
                   <div class="assistance-tabs__tab--inner">
                      <p class="assistance-tabs__tab-service-name">BATTERY</p>
                   </div>
                </ng-template>
                <div class="assistance-tabs__tab-content">
                   <div class="row">
                    <oas-battery-lead-api [tssbTicket]="filterTicketsByType('BATTERY')"></oas-battery-lead-api>
                   </div>
                </div>
             </tab>
          </tabset>
       </div>
        <div class="row">
            <div data-tracking-regionid="online service appointment" class="col-md-4 col-md-offset-4">
                <button
                    *ngIf="leadTssbTickets?.length > 0"
                    data-tracking-linkid="local > teleservice start appointment"
                    [disabled]="scheduledAppointment || wrongCustomerInfo"
                    class="btn btn-primary btn-request-assistance ui-button"
                    (click)="onClickLeadTssbTicket(leadTssbTickets , preselectedCBSList); closeCbsAppointment()"
                    i18n="@@cbsStartAppointment"
                >
                    Start appointment
                </button>
            </div>
        </div>
    </div>
</ng-template>

<oas-alert-success #alertSuccess></oas-alert-success>
<oas-alert-error #alertError [errorResponse]="errorResponse"></oas-alert-error>
<oas-alert-confirm #alertConfirm></oas-alert-confirm>
<oas-alert-warning
    #warningUpdateProfile
    i18n-title="@@completeProfile"
    title="Please complete the profile!"
    i18n-text="@@warningUpdateProfile"
    text="Update your profile to use Service Checkin!"
></oas-alert-warning>

<ng-template #missingVehicleInformationHeader>
    <span i18n="@@missingVehicleInformationHeader">Some mandatory information on the vehicle is missing</span>
</ng-template>

<ng-template #missingVehicleInformationContent>
    <span i18n="@@missingVehicleInformationContent"
        >It's necessary to update them before creating a new appointment. Would you like to proceed?</span
    >
</ng-template>

<ng-template #iconTmp let-iconName="iconName">
    <div class="svg-icon-container">
        <svg class="svg-icon">
            <use [attr.xlink:href]="'assets/images/icons/on_brigth_background_sprite.svg#bmw_' + iconName"></use>
        </svg>
    </div>
</ng-template>