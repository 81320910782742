import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {AuthService} from "../../auth/auth.service";

@Component({
   selector: 'oas-auth-callback',
   templateUrl: './auth-callback.component.html',
   // styleUrls: ['./name.component.scss']
})
export class AuthCallbackComponent implements OnInit {
   private accessToken: string;
   private error: string;
   state: string;
   private expiresIn: string;
   constructor(private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService) { }

   ngOnInit(): void {
      this.activatedRoute.fragment.subscribe(fragment => {
         this.accessToken = new URLSearchParams(fragment).get('access_token');
         this.error = new URLSearchParams(fragment).get('error');
         this.state = new URLSearchParams(fragment).get('state');
         this.expiresIn = new URLSearchParams(fragment).get('expires_in');
      });

      if(this.error != null){
         this.router.navigate(["/welcome"]);
      } else {
         this.authService
            .gcdmLogin(this.accessToken, Number(this.expiresIn))
            .then((user: any) => {
               if (this.authService.isAuthorized()) {
                  this.router.navigate([this.authService.getDashboardPath()]);
               }
            })
            .catch((error: HttpErrorResponse) => {
               this.router.navigate(["/welcome"]);
            });
      }
   }
}
