import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OutletService } from '../../core/api/outlet/outlet.service';
import { Utils } from '../../core/utils';
import { Outlet } from '../entities';
import { AuthService } from './../../auth/auth.service';

@Injectable()
export class OutletResolverService {
    constructor(private outletService: OutletService, private authService: AuthService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Outlet> {
        let outletId: string;
        if (!Utils.isNullOrUndefined(this.authService.getFixedOutlet())) {
            outletId = this.authService.getFixedOutlet();
        } else if (route.paramMap.has('outletId')) {
            outletId = route.paramMap.get('outletId');
        } else if (route.queryParamMap.has('outlet_id')) {
            outletId = route.queryParamMap.get('outlet_id');
        }

        if (!Utils.isNullOrUndefined(outletId)) {
            return this.outletService.getOneProxy(outletId).then(outlet => {
                if (outlet) {
                    return outlet;
                }
            });
        }
    }
}
