import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PneumaticType, VehicleWheel } from '../../../shared/entities';
import { Logger } from '../../logger.service';
import { AbstractRestService } from '../generic.service';

@Injectable()
export class WheelService extends AbstractRestService<VehicleWheel, number> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/vehicleWheel', http, logger);
    }

    public findByVehicle(vehicleVin: string): Promise<VehicleWheel[]> {
        const url = `${this.actionUrl}/by-vehicle`;
        const requestBody = {
            vehicleVin
        };
        return this.http
            .post(url, requestBody)
            .toPromise()
            .then(response => response as VehicleWheel[])
            .catch(error => this.handleError(error));
    }

    public getPneumaticTypes(): Promise<PneumaticType[]> {
        const url = `${this.actionUrl}/pneumatic-types`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as PneumaticType[])
            .catch(error => this.handleError(error));
    }
}
