import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { VehicleService } from '../../core/api/vehicle/vehicle.service';
import { Utils } from '../../core/utils';
import { CountryConfiguration } from '../../country-configuration';
import { BaseError, Vehicle } from '../entities';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
    template: ''
})
export abstract class BsiBaseComponent implements OnInit {
    @Input() public vehicle: Vehicle;
    @Input() public retrieveInformation: boolean;

    @Output() private errors = new EventEmitter<BaseError[]>();
    public countryConfiguration: CountryConfiguration;
    public config: SwiperOptions = {
        direction: 'horizontal',
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        keyboard: true,
        slidePrevClass: '.bsi-prev',
        slideNextClass: '.bsi-next',
    };

    constructor(
        protected vehicleService: VehicleService,
        protected countryConfigurationService: CountryConfigurationService
    ) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
    }

    ngOnInit() {
        if (
            this.retrieveInformation &&
            !Utils.isNullOrUndefined(this.vehicle) &&
            Utils.isNullOrUndefined(this.vehicle.bsiContracts)
        ) {
            this.searchVehicleBsiInformation(this.vehicle.vin);
        }
    }

    protected searchVehicleBsiInformation(vin: string): void {
        this.vehicleService.getVehicleBsiInformation(vin).then(response => {
            const errors = response.errors;
            if (errors.length !== 0) {
                this.returnErrors(errors);
            } else {
                this.vehicle.bsiContracts = response.payload;
            }
        });
    }

    protected returnErrors(e: BaseError[]) {
        this.errors.emit(e);
    }
}
