import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MobilityType, OutletMobilityType } from '../../../shared/entities';
import { Logger } from '../../logger.service';
import { AbstractRestService } from '../generic.service';

@Injectable()
export class MobilityService extends AbstractRestService<MobilityType, number> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/mobility-type', http, logger);
    }

    public getAvailableMobilityTypes(outletId: string): Promise<OutletMobilityType[]> {
        const url = `${this.actionUrl}/by-outlet/${outletId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as OutletMobilityType[])
            .catch(error => this.handleError(error));
    }

    public getAvailable(): Promise<MobilityType[]> {
        const url = `${this.actionUrl}/find-available`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as MobilityType[])
            .catch(error => this.handleError(error));
    }

    public getAvailableForRenting(outletId: string): Promise<MobilityType[]> {
        const url = `${this.actionUrl}/find-available-for-renting/${outletId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as MobilityType[])
            .catch(error => this.handleError(error));
    }
}
