import { Component, OnInit } from "@angular/core";
import { AppComponent } from "../../../../app/app.component";

@Component({
    selector: 'oas-dsa-it',
    templateUrl: 'dsa-it.component.html',
    styleUrls: ['../dsa.component.scss']
})

export class DSAITComponent implements OnInit{
    public brand: string;
    constructor(private appComponent: AppComponent) {}


    ngOnInit(): void {
        this.brand = this.appComponent.brand;
    }
}