import { Component, Input } from '@angular/core';

@Component({
    selector: 'oas-timeslot-loader',
    templateUrl: './timeslot-loader.component.html',
    styleUrls: ['./timeslot-loader.component.scss'],
})
export class TimeslotLoaderComponent {
    @Input() public show: boolean;
    @Input() public showTimeslotNotFoundBanner: boolean;
}
