import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Logger } from '../logger.service';
import { AbstractRestService } from './generic.service';
@Injectable()
export class ReturnDateConfigService extends AbstractRestService<string, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/return-date', http, logger);
    }
    
    getReturnTimeConfigByCountry(returnDateDto: any): Observable<ReturnDateApiResponse> {
        return this.http.post<ReturnDateApiResponse>(`${this.actionUrl}/config`, returnDateDto);
    }  
}
interface ReturnDateApiResponse {
  errors: any[];
  payload: Date;
}
