import { Injectable } from '@angular/core';
import { AbstractRestService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../logger.service';
import { Dictionary } from '../../shared/entities';

@Injectable()
export class DictionaryService extends AbstractRestService<Dictionary, number> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/dictionary', http, logger);
    }

    public getAllByCategoryId(categoryId: string): Promise<Dictionary[]> {
        const url = `${this.actionUrl}/${categoryId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Dictionary[])
            .catch(error => this.handleError(error));
    }

    public async findByName(name: string): Promise<Dictionary> {
        const url = `${this.actionUrl}/name/${name}`;

        try {
            const response = await this.http.get(url).toPromise();
            return response as Dictionary;
        } catch (error) {
            return await this.handleError(error);
        }
    }

    public async findByNameAndBrand(name: string, brand: string): Promise<Dictionary> {
        const BMW_REQUEST = '_BMW';
        const MINI_REQUEST = '_MINI';
        const BMW_MOTORRAD_REQUEST = '_BMW_MOTORRAD';

        switch (brand) {
            case 'MINI':
                return this.findByName(name + MINI_REQUEST);
            case 'BMW_MOTORRAD':
                return this.findByName(name + BMW_MOTORRAD_REQUEST);
            default:
                return this.findByName(name + BMW_REQUEST);
        }
    }
}
