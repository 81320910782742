import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
// import { CalendarModule } from 'ap-angular2-fullcalendar';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ImageCropperModule } from 'ngx-img-cropper';
import { NouisliderModule } from 'ng2-nouislider';
import { AccordionConfig, AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarConfig, ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule, TabsetConfig } from 'ngx-bootstrap/tabs';
import { TooltipDirective, TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadConfig, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AddressAutocompleteDirective } from './address-autocomplete/address-autocomplete.directive';
import { AddressPickComponent } from './address-pick/address-pick.component';
import { FiscalCodePickComponent } from './fiscalcode-pick/fiscalcode-pick.component';
import { AlertConfirmComponent } from './alert/alert-confirm.component';
import { AlertErrorComponent } from './alert/alert-error.component';
import { AlertSuccessComponent } from './alert/alert-success.component';
import { AlertWarningComponent } from './alert/alert-warning.component';
import { BsiComponent } from './fbm/bsi.component';
import { LogoComponent } from './logo/logo.component';
import { LogoutComponent } from './logout/logout.component';
import { PictureSelectorComponent } from './picture/picture-selector.component';
import { AppointmentsHistoryPipe } from './pipe/appointments-history.pipe';
import { DatexPipe } from './pipe/datex.pipe';
import { DealerFilterPipe } from './pipe/dealer-filter.pipe';
import { FilterPurePipe } from './pipe/filter-pure.pipe';
import { FilterPipe } from './pipe/filter.pipe';
import { OutletFilterPipe } from './pipe/outlet-filter.pipe';
import { TableFilterPipe } from './pipe/table-filter.pipe';
import { TranslatePipe } from './pipe/translate.pipe';
import { ValidateOnBlurDirective } from './validation/validate-on-blur.directive';
import { VehiclePictureUcpComponent } from './vehicle/vehicle-picture-ucp.component';
import { VehiclePictureComponent } from './vehicle/vehicle-picture.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SearchTextComponent } from '../backoffice/shared/column-search/search-text.component';
import { SearchSelectComponent } from '../backoffice/shared/column-search/search-select.component';
import { NewServicesAppointmentComponent } from '../backoffice/shared/appointment/new/new-services-appointment.component';
import { CookiesComponent } from './cookies/cookies.component';
import { CBSTeleservicesComponent } from './cbs-teleservices/cbs-teleservices.component';
import { ActivityBasketModalComponent } from './activity-basket/activity-basket-modal.component';
import { ScriptService } from './script.service';
import { SupportChatComponent } from './support-chat/support-chat.component';
import { SCiTablePaginatorComponent } from './table-paginator/sci-table-paginator.component';
import { NgxLoadingModule } from 'ngx-loading';
import { TimeslotLoaderComponent } from './timeslot-loader.component';
import { TracyService } from './tracy.service';
import { PreRegistrationCheckboxComponent } from './appointment/pre-registration-checkbox/pre-registration-checkbox.component';
import { TechnicalSettingService } from '../core/api/technical-setting.service';
import { LoginComponent } from './login/login.component';
import { Utils } from '../core/utils';
import { TechnicalSettingConfiguration } from './technical-setting-configuration';
import { GoogleMapsLoaderService } from './google-maps-loader.service';
import { ValidateOnlineUsernameOnBlurDirective } from './validation/validate-online-username-on-blur.directive';
import { TimeSloteSelectionService } from './time-slote-selection.service';
import { DealerHideInactivePipe } from './pipe/dealer-hideInactive.pipe';
import { OutletHideInactivePipe } from './pipe/outlet-Active-filter.pipe';
import {WelcomeComponent} from "./welcome/welcome.component";
import {AuthCallbackComponent} from "./auth-callback/auth-callback.component";
import {BroadcastService} from "./broadcast.service";
import {MessageService} from "./message.service";
import {TiresLeadApiComponent} from "./lead-api/lead-tire/tires-lead-api.component";
import {CcmLeadApiComponent} from "./lead-api/lead-ccm/ccm-lead-api.component";
import { TwoDigitPipe } from './pipe/two-digit.pipe';
import { AngularSvgIconModule, provideAngularSvgIcon } from 'angular-svg-icon';
import { EmailBodyPipe } from './pipe/email-body.pipe';
import { MultiSelectComponent } from '../backoffice/shared/column-search/multi-select.component';
import { DSAComponent } from './DSA/dsa.component';
import { DSAITComponent } from './DSA/dsa-it/dsa-it.component';
import { DSAPTComponent } from './DSA/dsa-pt/dsa-pt.component';
import { SafeUrlPipe } from './pipe/safe-url';
import { BatteryLeadApiComponent } from './lead-api/lead-battery/battery-lead-api.component';
import { BsDatepickerConfig, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { TimepickerConfig } from 'ngx-bootstrap/timepicker';
import { GoogleMapsModule } from '@angular/google-maps';
import { register } from 'swiper/element/bundle';
import { LastSubstringAfterDashPipe } from './pipe/last-substring-after-dash.pipe';

register();

// import { AddCustomerComponent } from 'app/backoffice/shared/customer/add-customer.component';
/**
 * Put here components, directives, and pipes needed by different components
 * import the module whenever you need inside a component
 */
@NgModule({
    imports: [
        GoogleMapsModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        // CalendarModule,
        FullCalendarModule,
        NouisliderModule,
        TypeaheadModule.forRoot(),
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        SweetAlert2Module.forRoot(),
        AccordionModule.forRoot(),
        ProgressbarModule.forRoot(),
        ImageCropperModule,
        NgxLoadingModule.forRoot({
            fullScreenBackdrop: true,
        }),
        NgxDatatableModule,
        AngularSvgIconModule,
        BsDropdownModule.forRoot()
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        // CalendarModule,
        FullCalendarModule,
        NouisliderModule,
        TypeaheadModule,
        ModalModule,
        TabsModule,
        TooltipModule,
        TooltipDirective,
        LogoutComponent,
        AlertErrorComponent,
        AlertSuccessComponent,
        AlertConfirmComponent,
        AlertWarningComponent,
        AppointmentsHistoryPipe,
        VehiclePictureComponent,
        VehiclePictureUcpComponent,
        LogoComponent,
        OutletFilterPipe,
        AccordionModule,
        AddressAutocompleteDirective,
        FilterPipe,
        FilterPurePipe,
        DealerFilterPipe,
        DealerHideInactivePipe,
        OutletHideInactivePipe,
        ProgressbarModule,
        TableFilterPipe,
        DatexPipe,
        PictureSelectorComponent,
        ImageCropperModule,
        NgxLoadingModule,
        AddressPickComponent,
        FiscalCodePickComponent,
        TranslatePipe,
        BsiComponent,
        SweetAlert2Module, // FIXME: remove after resolved the problem of alerts inside user-create-edit component
        SearchTextComponent,
        MultiSelectComponent,
        SearchSelectComponent,
        NewServicesAppointmentComponent,
        CBSTeleservicesComponent,
        SupportChatComponent,
        SCiTablePaginatorComponent,
        TimeslotLoaderComponent,
        PreRegistrationCheckboxComponent,
        ActivityBasketModalComponent,
        TiresLeadApiComponent,
        CcmLeadApiComponent,
        TwoDigitPipe,
        EmailBodyPipe,
        SafeUrlPipe,
        AngularSvgIconModule,
        BatteryLeadApiComponent,
        LastSubstringAfterDashPipe
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: Utils.getTechnicalSettings,
            deps: [TechnicalSettingService, TechnicalSettingConfiguration],
            multi: true,
        },
        TranslatePipe,
        ScriptService,
        TracyService,
        BroadcastService,
        GoogleMapsLoaderService,
        ValidateOnlineUsernameOnBlurDirective,
        TimeSloteSelectionService,
        MessageService,
        TooltipConfig,
        BsDropdownConfig,
        BsDatepickerConfig,
        TypeaheadConfig,
        TabsetConfig,
        BsDaterangepickerConfig,
        AccordionConfig,
        ProgressbarConfig,
        TimepickerConfig,
        BsModalRef,
        provideAngularSvgIcon()
    ],
    declarations: [
        // AddCustomerComponent,
        LoginComponent,
        LogoutComponent,
        AlertErrorComponent,
        AlertSuccessComponent,
        AlertConfirmComponent,
        AppointmentsHistoryPipe,
        VehiclePictureComponent,
        VehiclePictureUcpComponent,
        ValidateOnBlurDirective,
        LogoComponent,
        OutletFilterPipe,
        AddressAutocompleteDirective,
        FilterPipe,
        FilterPurePipe,
        DealerFilterPipe,
        DealerHideInactivePipe,
        OutletHideInactivePipe,
        AlertWarningComponent,
        TableFilterPipe,
        DatexPipe,
        PictureSelectorComponent,
        AddressPickComponent,
        FiscalCodePickComponent,
        TranslatePipe,
        VehiclePictureUcpComponent,
        BsiComponent,
        SearchTextComponent,
        MultiSelectComponent,
        SearchSelectComponent,
        NewServicesAppointmentComponent,
        CookiesComponent,
        CBSTeleservicesComponent,
        SupportChatComponent,
        SCiTablePaginatorComponent,
        ActivityBasketModalComponent,
        TimeslotLoaderComponent,
        PreRegistrationCheckboxComponent,
        WelcomeComponent,
        AuthCallbackComponent,
        TiresLeadApiComponent,
        CcmLeadApiComponent,
        TwoDigitPipe,
        EmailBodyPipe,
        SafeUrlPipe,
        DSAComponent,
        DSAITComponent,
        DSAPTComponent,
        BatteryLeadApiComponent,
        LastSubstringAfterDashPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

})
export class SharedModule {}
