import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TssbLeadTicket, TssbTicket, TssbTicketCBS } from '../entities';
import { Utils } from '../../core/utils';
import { countryConfiguration } from '../../../configuration/country.configuration';
import { CountryConfiguration } from '../../country-configuration';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';

@Component({
    selector: 'oas-cbs-teleservices',
    templateUrl: './cbs-teleservices.component.html',
    styleUrls: ['./cbs-teleservices.component.scss'],
})
export class CBSTeleservicesComponent implements OnInit {
    @Input() public tssbTicket: TssbTicket;
    @Input() public leadTssbTicket: TssbLeadTicket;
    @Input() public enableSelection = true;
    @Input() public enableDays = true;
    @Input() public bsiEnabled;

    @Output() public selectedCBSList = new EventEmitter<String[]>();
    public listCBS: TssbTicketCBS[] = [];
    private selectedCBSs: String[] = [];
    public standardVolumeCBS: TssbTicketCBS;
    public countryConfiguration: CountryConfiguration;

    constructor(countryConfigurationService: CountryConfigurationService) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
    }

    ngOnInit() {
        if (this.tssbTicket) {
            this.autoselectCBS();
        } else if (this.leadTssbTicket) {
            this.leadAutoselectCBS();
        }

        this.standardVolumeCBS = countryConfiguration.automaticSelectionCbs99999
            ? this.getStandardVolumeCBS()
            : undefined;
    }

    public isAlreadySelected(cbs: TssbTicketCBS): boolean {
        return this.selectedCBSs.includes(cbs.identifier);
    }

    public onSelect(cbs: TssbTicketCBS, checked: boolean): void {
        if (checked) {
            this.selectedCBSs.push(cbs.identifier);
            if (!this.selectedCBSs.includes('99999') && countryConfiguration.automaticSelectionCbs99999) {
                this.selectedCBSs.push('99999');
            }
        } else {
            this.selectedCBSs.splice(this.selectedCBSs.indexOf(cbs.identifier), 1);
            if (
                this.selectedCBSs.length === 1 &&
                this.selectedCBSs.includes('99999') &&
                countryConfiguration.automaticSelectionCbs99999
            ) {
                this.selectedCBSs.splice(0, 1);
            }
        }
        this.selectedCBSList.emit(this.selectedCBSs);
    }

    private autoselectCBS(): void {
        if (
            !Utils.isNullOrUndefined(this.tssbTicket) &&
            !Utils.isNullOrUndefined(this.tssbTicket.id) &&
            !Utils.isNullOrUndefined(this.tssbTicket.tssbStaticTicketData) &&
            !Utils.isNullOrUndefined(this.tssbTicket.tssbStaticTicketData.keyData) &&
            !Utils.isNullOrUndefined(this.tssbTicket.tssbStaticTicketData.keyData.conditionBasedServices)
        ) {
            this.listCBS = this.tssbTicket.tssbStaticTicketData.keyData.conditionBasedServices.filter(
                cbs => !Utils.isNullOrUndefined(cbs)
            );
            this.listCBS
                .filter(cbs => cbs.state === 'YELLOW' || cbs.state === 'RED')
                .forEach(cbs => {
                    this.onSelect(cbs, true);
                });
        }
    }

    private leadAutoselectCBS(): void {
        if (
            !Utils.isNullOrUndefined(this.leadTssbTicket) &&
            !Utils.isNullOrUndefined(this.leadTssbTicket.id) &&
            !Utils.isNullOrUndefined(this.leadTssbTicket.cbsEvents) &&
            !Utils.isNullOrUndefined(this.leadTssbTicket.cbsEvents.length > 0)
        ) {
            this.listCBS = this.leadTssbTicket.cbsEvents.map(item => {
                const { cbsEventIdentifier, cbsState, remainingTime, remainingDistance, description } = item;
                return {
                    identifier: cbsEventIdentifier,
                    state: cbsState,
                    remainingTime,
                    remainingDistance,
                    description,
                };
            });
            this.listCBS
                .filter(cbs => cbs.state === 'YELLOW' || cbs.state === 'RED')
                .forEach(cbs => {
                    this.onSelect(cbs, true);
                });
        }
    }

    public getDueDate(days: number) {
        console.log('Hola ' + Utils.addDays(new Date(), days));
        return Utils.addDays(new Date(), days);
    }

    public isNullOrUndefined(value: any) {
        return Utils.isNullOrUndefined(value);
    }

    private getStandardVolumeCBS(): TssbTicketCBS {
        const standardVolumeCBS: TssbTicketCBS = {};
        const standardVolumeCBSInput = this.listCBS.find(cbs => cbs.identifier === '99999');
        if (!Utils.isNullOrUndefined(standardVolumeCBSInput)) {
            standardVolumeCBS.id = standardVolumeCBSInput.id;
            standardVolumeCBS.description = standardVolumeCBSInput.description;
            standardVolumeCBS.identifier = standardVolumeCBSInput.identifier;
            standardVolumeCBS.state = this.listCBS.find(cbs => cbs.state === 'RED') ? 'RED' : 'YELLOW';
            const minRemainingDistance = this.listCBS
                .map(cbs => cbs.remainingDistance)
                .filter(remainingDistance => !Utils.isNullOrUndefined(remainingDistance))
                .sort((a, b) => a - b)[0];
            const minRemainingTime = this.listCBS
                .map(cbs => cbs.remainingTime)
                .filter(remainingTime => !Utils.isNullOrUndefined(remainingTime))
                .sort((a, b) => a - b)[0];
            if (!(minRemainingDistance >= 0 && minRemainingTime < 0)) {
                standardVolumeCBS.remainingDistance = minRemainingDistance;
            }
            if (!(minRemainingTime >= 0 && minRemainingDistance < 0)) {
                standardVolumeCBS.remainingTime = minRemainingTime;
            }
        }

        return standardVolumeCBS;
    }
}
