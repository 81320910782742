import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppointmentLinkService } from '../core/api/appointment-link/appointment-link.service';
import { Appointment } from '../shared/entities';

@Injectable()
export class UpdateAppointWoregistrResolverService {

  constructor(
    private router:Router,
    private appointLink:AppointmentLinkService
    
  ) { }

  /**
   * Link should be as follow: 
   * `/update/appointment?isWithoutRegistration=true&uuid=erreee&appointmentId=ioee`
   * @param route 
   * @param state 
   * @returns 
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Promise<Appointment> {
    //getting UUI and Appointment from route.
    const {uuid,appointmentId,isWithoutRegistration} = route.queryParams
    if(!uuid || !isWithoutRegistration || !appointmentId){
      this.router.navigateByUrl("/")
      throw("Link is invalid, please provide a valid one ")
    }

    //Checking if token is valid and updating session storage 
    return this.appointLink.getOneByTypeAndUuidAndAppointmentId("DELETE",uuid,appointmentId)
    .then(result=>{
      sessionStorage.setItem("appointmentFromWithoutRegistration",JSON.stringify(result))
      return result
    })
    .catch(error=>{
      return null
    })
  }

}
