import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Dealer } from '../../../shared/entities';
import { AbstractRestService } from '../generic.service';
import { Logger } from '../../logger.service';
import { AuthService } from '../../../auth/auth.service';

@Injectable()
export class DealerService extends AbstractRestService<Dealer, string> {
    constructor(protected http: HttpClient, protected logger: Logger, private authService: AuthService) {
        super('/dealer', http, logger);
    }

    public getOne(id: string): Promise<Dealer> {
        const url = `${this.actionUrl}/${id}`;
        const params = new HttpParams().set('roleId', this.authService.getSelectedRole());

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as Dealer)
            .catch(error => this.handleError(error));
    }

    public getAllByCountry(): Promise<Dealer[]> {
        const url = `${this.actionUrl}/by-country`;
        const params = new HttpParams().append('roleId', this.authService.getSelectedRole());

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as Dealer[])
            .catch(error => this.handleError(error));
    }

    // BMWDS-302
    public getAllDealers(): Promise<Dealer[]> {
        const url = `${this.actionUrl}/all`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Dealer[])
            .catch(error => this.handleError(error));
    }
}
