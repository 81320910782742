<div *ngIf="isSupportChatAvailable()">
    <img
        id="liveagent_button_online_5730Y000000PTGq"
        style="width: 15rem; display: none; border: 0px none; cursor: pointer;"
        (click)="startChat()"
        src="https://bmwcic.secure.force.com/ESNSC/resource/1512040501000/ES_NSC_online"
    />
    <div id="liveagent_button_offline_5730Y000000PTGq" style="display: none;">
        <!-- Offline Chat Content -->
    </div>
    <div></div>
</div>
