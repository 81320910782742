<div class="horizontal-scroll" style="margin-right: 35px;" *ngIf="listTires?.length > 0 else noServicesToDisplay">
   <table class="table table-bordered table-striped">
      <thead>
      <tr>
         <th *ngIf="online" class="col-md-1" i18n="@@selection">Selection</th>
         <th class="col-md-4" i18n="@@description">Description</th>
         <th class="col-md-4" i18n="@@urgency">Urgency</th>
         <th class="col-md-3"><span i18n="@@remainingDistance">Remaining Distance</span><span> KM</span></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let event of listTires">
         <td *ngIf="online" class="col-md-1" style="text-align: center">
            <input
               type="checkbox"
               class="ui-checkbox"
               [checked]="isAlreadySelected(event)"
               [disabled]="true"
            />
         </td>
         <td class="col-md-4" style="text-align: center">
            <ng-container *ngIf="event?.text; else showIdentifier">
               {{event.text}}
           </ng-container>
           <ng-template #showIdentifier>
               {{event?.identifier?.toLowerCase() | translate}}
           </ng-template>
         </td>
         <td class="col-md-4" style="text-align: center">
            <span
                  class="cbs-state"
                  [style.color]="event.urgency == 'PENDING' || event.urgency == 'OVERDUE' ? 'YELLOW' : 'RED'"
               >
                  ●
               </span>&nbsp;
               <span i18n="@@notUrgent" *ngIf="event.urgency == 'PENDING' || event.urgency == 'OVERDUE'">Not urgent</span>
               <span i18n="@@urgent" *ngIf="event.urgency == 'CRITICAL' || event.urgency == 'WARNING'">Urgent</span>
         </td>
         <td class="col-md-3" style="text-align: center">
            <div>{{ event.remainingDistance }}<span i18n="@@km">KM</span></div>
         </td>
      </tr>
      </tbody>
   </table>
   <div class="col-md-12 d-flex justify-content-center" *ngIf="showAdd">
      <div class="col-md-3">
         <button class="btn btn-primary btn-block ui-button" (click)="broadcastTireServices()" i18n="@@add">Add
         </button>
      </div>
   </div>
</div>

<ng-template #noServicesToDisplay>
   <div class="row">
      <div class="col-md-12 cbs-service_no-item" i18n="@@noServicesToDisplay">No services to display</div>
   </div>
</ng-template>
