import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Logger } from '../../logger.service';
import { KpiService } from './kpi.service';
import { SearchSettings, AverageFruPerService, AverageFruPerVehicle } from '../../../shared/entities';

@Injectable()
export class KpiWorkshopService extends KpiService {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/workshop', http, logger);
    }

    public getFruPerService(searchSettings?: SearchSettings): Promise<AverageFruPerService[]> {
        return this.http
            .post(`${this.actionUrl}/fru/perService`, searchSettings)
            .toPromise()
            .then(response => response as AverageFruPerService[])
            .catch(error => this.handleError(error));
    }

    public getFruPerVehicle(searchSettings?: SearchSettings): Promise<AverageFruPerVehicle[]> {
        return this.http
            .post(`${this.actionUrl}/fru/perVehicle`, searchSettings)
            .toPromise()
            .then(response => response as AverageFruPerVehicle[])
            .catch(error => this.handleError(error));
    }
}
