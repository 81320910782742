<div *ngIf="countryConfiguration.enableBsi && vehicle && vehicle.bsiContracts && vehicle.bsiContracts.length > 0">
    <div>
        <div class="swiper-wrapper bsi-container">
            <div *ngFor="let bsi of vehicle.bsiContracts">
                <div class="bsi">
                    <div class="bsi--title">
                        <strong>{{ bsi.description }}</strong>
                    </div>
                    <div class="bsi--content">
                        <span class="p-bsi-content" i18n="@@validUntil">Valid until</span>
                        <span class="p-bsi-content">{{ bsi.endMatriculation | datex: 'dd/MM/yyyy' }}</span>
                        <span class="p-bsi-content" i18n="@@orDuring">or during</span>
                        <span>{{ bsi.endMileage }} KM</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
