<oas-customer-wizard>
    <a [routerLink]="['/welcome']" class="dismiss-request text-primary">
        <i class="fa fa-chevron-left"> </i>
        <span i18n="@@backToLogin">Back to login</span>
    </a>
    <div class="login-form__section">
        <img src="assets/images/ic_account-register.svg" alt="" />
        <h2 class="login-form__section-title" i18n="@@forgotPassword">
            Forgot password
        </h2>
    </div>
    <h1 class="login-form__title" i18n="@@emailAccount">Email account</h1>
    <!-- Email -->
    <div class="ui-input-wrapper">
        <input
            type="text"
            class="ui-input login-form__input registration__icon-mail"
            i18n-placeholder="@@email"
            placeholder="Email"
            [formControl]="email"
        />
    </div>
    <!-- Email Validation Errors -->
    <div class="row">
        <div class="col-md-6">
            <ng-container *ngTemplateOutlet="emailValidationError; context: { control: email }"></ng-container>
        </div>
    </div>
    <div class="navigation__buttons">
        <div class="next-step__button-wrapper" data-tracking-regionid="online service appointment">
            <button
                class="ui-button btn btn-primary"
                [disabled]="email.invalid"
                (click)="resetPassword()"
                i18n="@@sendRequest"
                data-tracking-linkid="local > password reset link send"
            >
                Send request
            </button>
        </div>
    </div>
</oas-customer-wizard>

<ng-template #emailValidationError let-control="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched)" class="ui-input-wrapper">
        <div class="errors">
            <p *ngIf="control.errors.email || control.errors.pattern" i18n="@@invalidEmail">
                Invalid email!
            </p>
            <p *ngIf="control.errors.oasUsernameValidator" i18n="@@notExistEmail">
                The email provided does not exist!
            </p>
        </div>
    </div>
</ng-template>

<oas-alert-success
    #alertSuccess
    i18n-text="@@sentConfirmMail"
    i18n-title="@@success"
    title="Success"
    text="A confirm email has been sent to you"
></oas-alert-success>
<oas-alert-error #alertError [errorResponse]="errorResponse"></oas-alert-error>
