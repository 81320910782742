import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AppointmentLinkService } from '../../core/api/appointment-link/appointment-link.service';
import { OasValidators } from '../../core/validator/oas-validators';
import { AlertConfirmComponent } from '../../shared/alert/alert-confirm.component';
import { AlertErrorComponent } from '../../shared/alert/alert-error.component';
import { AlertSuccessComponent } from '../../shared/alert/alert-success.component';
import { Appointment } from '../../shared/entities';

@Component({
    selector: 'oas-appointment-cancel-link',
    templateUrl: './appointment-cancel-link.component.html',
    styleUrls: ['./appointment-cancel-link.component.scss'],
})
export class AppointmentCancelLinkComponent implements OnInit {
    @ViewChild(AlertErrorComponent) private alertError: AlertErrorComponent;
    @ViewChild(AlertSuccessComponent) private alertSuccess: AlertSuccessComponent;
    @ViewChild(AlertConfirmComponent) private alertConfirm: AlertConfirmComponent;
    public appointment$ = new BehaviorSubject<Appointment>(undefined);

    public uuid: string;
    public appointmentId: string;
    public loading: boolean;
    public reasonForm = new UntypedFormControl('', [OasValidators.getLatinValidator()]);

    constructor(
        private route: ActivatedRoute,
        private appointmentLinkService: AppointmentLinkService,
        private router: Router
    ) {}

    public filterPredicate = act =>
        act.catalogType !== 'TECHNICAL_CAMPAIGNS' &&
        (act.activityType.showOnline ||
            act.activityType.id === '000-00-FLATRATE' ||
            act.activityType.id === '000-00-FIXEDPRICES' ||
            act.activityType.id === '000-00-PACKAGE');

    public ngOnInit() {
        this.route.params.subscribe(params => {
            this.uuid = params['uuid'];
            this.appointmentId = params['appointmentId'];
            this.loading = true;
            this.appointmentLinkService
                .getOneByTypeAndUuidAndAppointmentId('DELETE', this.uuid, this.appointmentId)
                .then(result => {
                    this.loading = false;
                    this.appointment$.next(result.appointment);
                })
                .catch(error => {
                    this.loading = false;
                    this.alertError.showHttpError(error).then(vakue => this.router.navigate(['/welcome']));
                });
        });
    }

    public delete(): void {
        this.alertConfirm.show().then(result => {
            if (result.value) {
                this.loading = true;
                this.appointmentLinkService
                    .deleteAppointment(this.uuid, this.appointmentId, this.reasonForm.value)
                    .then(() => {
                        this.loading = false;
                        this.alertSuccess.show().then(() => {
                            this.router.navigate(['/welcome']);
                        });
                    })
                    .catch(error => {
                        this.loading = false;
                        this.alertError.showHttpError(error).then(value => {
                            this.router.navigate(['/welcome']);
                        });
                    });
            }
        });
    }
}
