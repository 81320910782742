import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from './../../logger.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class WelcomeTerminalService {
    private actionUrl: string;

    constructor(protected http: HttpClient, protected logger: Logger) {
        this.actionUrl = `${environment.apiUrl}/welcome-terminal`;
    }

    public getImages(outletId: string): Promise<string[]> {
        const url = `${this.actionUrl}/outlets/${outletId}/images`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as string[])
            .catch(error => this.handleError(error));
    }

    public insertImage(outletId: string, image: File): Promise<string> {
        const url = `${this.actionUrl}/outlets/${outletId}/images`;

        return this.http
            .post(url, image, { responseType: 'text' })
            .toPromise()
            .then(response => response as string)
            .catch(error => this.handleError(error));
    }

    public insertVideo(outletId: string, video: File, type: string): Promise<string> {
        const url = `${this.actionUrl}/outlets/${outletId}/video/${type}`;

        return this.http
            .post(url, video, { responseType: 'text' })
            .toPromise()
            .then(response => response as string)
            .catch(error => this.handleError(error));
    }

    public deleteImage(outletId: string, imageName: string): Promise<void> {
        const url = `${this.actionUrl}/outlets/${outletId}/images/${imageName}`;

        return this.http
            .delete(url)
            .toPromise()
            .catch(error => this.handleError(error));
    }

    protected handleError(error: any): Promise<any> {
        this.logger.debug('error');
        this.logger.debug(error);

        return Promise.reject(error);
    }

    public enableWelcomeTerminal(): Promise<Boolean> {
        const url = `${this.actionUrl}/enabled`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as string[])
            .catch(error => this.handleError(error));
    }

    public imageSizeWelcomeTerminal(): Promise<number> {
        const url = `${this.actionUrl}/imageSize`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as string[])
            .catch(error => this.handleError(error));
    }

    public videoSizeWelcomeTerminal(): Promise<number> {
        const url = `${this.actionUrl}/videoSize`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as string[])
            .catch(error => this.handleError(error));
    }
}
