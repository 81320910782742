import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'datex',
})
export class DatexPipe extends DatePipe implements PipeTransform {
    transform(value: any, format: string = ''): any {
        if (!moment.isDate(value)) {
            return value;
        }

        const valueUTC = new Date(
            value.getUTCFullYear(),
            value.getUTCMonth(),
            value.getUTCDate(),
            value.getUTCHours(),
            value.getUTCMinutes()
        );

        return super.transform(valueUTC, format);
    }
}
