import { Injectable } from '@angular/core';
import { AbstractRestService } from '../generic.service';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../../logger.service';
import { FleetVehicle } from 'app/shared/entities';

@Injectable()
export class FleetVehicleService extends AbstractRestService<FleetVehicle, number> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/fleetVehicle', http, logger);
    }

    public deleteFleetVehicle(fleetVehicleid: number): Promise<void> {
        const url = `${this.actionUrl}/delete`;
        return this.http
            .post(url, fleetVehicleid)
            .toPromise()
            .then(response => response as any)
            .catch(error => this.handleError(error));
    }

    public createFleetVehicle(fleetVehicle: FleetVehicle): Promise<void> {
        const url = `${this.actionUrl}/create-vehicle`;
        return this.http
            .post(url, fleetVehicle)
            .toPromise()
            .then(response => response as any)
            .catch(error => this.handleError(error));
    }

    public retrieveFleetVehiclesByRentingId(id: number): Promise<string[]> {
        const url = `${this.actionUrl}/by-renting/${id}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => response as string[])
            .catch(error => this.handleError(error));
    }
}
