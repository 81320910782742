<button class="ui-button btn danger" (click)="deleteAccount()" i18n="@@deleteAccount">
    Delete Account
</button>

<oas-alert-confirm #alertConfirm></oas-alert-confirm>

<ng-template #alertTitleTmpl>
    <span i18n="@@alertTitleDeleteAccountTmpl">Do you really want to continue deleting your account? </span>
</ng-template>

<ng-template #alertContentTmpl>
    <span i18n="@@alertContentDeleteAccountTmpl"
        >If you continue you will delete all your information from your profile and you will be no longer able to book
        appointments or use our online services.</span
    >
</ng-template>
