import {Injectable} from "@angular/core";
import {AbstractRestService} from "../generic.service";
import {LeadConfiguration} from "../../../shared/entities";
import {HttpClient} from "@angular/common/http";
import {Logger} from "../../logger.service";

@Injectable()
export class LeadConfigurationService extends AbstractRestService<string, string> {
   constructor(protected http: HttpClient, protected logger: Logger) {
      super('/lead-config', http, logger);
   }

   getConfiguration(type: string): Promise<LeadConfiguration> {
      const url = `${this.actionUrl}/${type}`;
      return this.http
         .get<LeadConfiguration>(url)
         .toPromise()
         .then(response => response)
         .catch(error => this.handleError(error));
   }
}
