<ng-container *ngTemplateOutlet="newAccountId; context: { form: customerEmailForm }"> </ng-container>
<ng-container *ngTemplateOutlet="newAccountIdConfirm; context: { form: customerEmailForm }"> </ng-container>

<div class="buttons-container">
    <button
        class="ui-button btn btn-primary"
        [disabled]="customerEmailForm.invalid"
        (click)="updateAccountId()"
        i18n="@@save"
    >
        Save
    </button>

    <oas-user-form-delete-account (deleteAccountClicked)="deleteAccount()"></oas-user-form-delete-account>
</div>

<ng-template #newAccountId let-form="form">
    <div class="ui-input-wrapper">
        <label i18n="@@accountId">Account ID</label>
        <input
            i18n-placeholder="@@newEmailPlaceholder"
            type="email"
            value="{{ accountId }}"
            class="ui-input"
            disabled="true"
        />
    </div>
    <div [formGroup]="customerEmailForm" class="ui-input-wrapper">
        <label i18n="@@newAccountId">New account ID</label>
        <input
            placeholder="New email"
            i18n-placeholder="@@newEmailPlaceholder"
            type="email"
            formControlName="email"
            class="ui-input"
        />
        <ng-container *ngTemplateOutlet="emailValidationError; context: { control: customerEmailForm.get('email') }">
        </ng-container>
    </div>
</ng-template>

<ng-template #newAccountIdConfirm let-form="form">
    <div [formGroup]="customerEmailForm" class="ui-input-wrapper">
        <label i18n="@@repeatNewAccountId">Repeat account ID</label>
        <input
            placeholder="Repeat email"
            i18n-placeholder="@@repeatNewEmailPlaceholder"
            type="email"
            formControlName="emailConfirm"
            class="ui-input"
        />
        <ng-container
            *ngTemplateOutlet="matchEmailValidationError; context: { control: customerEmailForm.get('emailConfirm') }"
        >
        </ng-container>
    </div>
</ng-template>

<ng-template #emailValidationError let-control="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched)" class="ui-input-wrapper">
        <div class="errors">
            <p *ngIf="control.errors.required" i18n="@@required">
                Required
            </p>
            <p *ngIf="control.errors.oasUsernameValidator" i18n="@@existEmail">
                The email provided already exist!
            </p>
            <p *ngIf="control.errors.email || control.errors.pattern" i18n="@@invalidEmail">
                Invalid email!
            </p>
        </div>
    </div>
</ng-template>

<ng-template #matchEmailValidationError let-control="control">
    <div
        *ngIf="control.invalid && (control.dirty || control.touched) && control.errors.matchEmail"
        class="ui-input-wrapper"
    >
        <div class="errors">
            <p i18n="@@invalidEmailNotMatch">Email not match!</p>
        </div>
    </div>
</ng-template>
