import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SearchSettings } from '../../../shared/entities';
import { environment } from '../../../../environments/environment';
import { Logger } from '../../logger.service';
import { ApiUtils } from '../utils/utils';

@Injectable()
export class ReportService {
    
    private actionUrl: string;
    
    private _isCapacityReportExportInProgress: boolean = false;

    private _capacityReportWeekNumber: string = null;

    set capacityReportWeekNumber(value: string ) {
        this._capacityReportWeekNumber = value;
    }

    get capacityReportWeekNumber(): string {
        return this._capacityReportWeekNumber;
    }

    set isCapacityReportExportInProgress(value: boolean) {
        this._isCapacityReportExportInProgress = value;
    }

    get isCapacityReportExportInProgress(): boolean {
        return this._isCapacityReportExportInProgress;
    }

    constructor(protected http: HttpClient, protected logger: Logger) {
        this.actionUrl = `${environment.apiUrl}/report`;
    }

    public getDictionary(): Promise<{ [key: string]: string }> {
        const url = `${this.actionUrl}/dictionary`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as { [key: string]: string })
            .catch(error => this.handleError(error));
    }

    private handleError(error: any): Promise<any> {
        this.logger.debug('error');
        this.logger.debug(error);

        return Promise.reject(error);
    }

    public async getReport(searchSettings: SearchSettings): Promise<HttpResponse<Blob>>{
        const url = `${this.actionUrl}/report`;
        try {
            const response = await this.http.post(url, searchSettings, { responseType: 'blob', observe: 'response'})
                .toPromise();
            return response as HttpResponse<Blob>;
        } catch (error) {
            return await ApiUtils.handleError(error, this.logger);
        }
    }

    public async getTssbReport(searchSettings: SearchSettings): Promise<HttpResponse<Blob>>{
        const url = `${this.actionUrl}/download/tssb`;
        try {
            const response = await this.http.post(url, searchSettings, { responseType: 'blob', observe: 'response'}).toPromise();
            return response as HttpResponse<Blob>;
        } catch (error) {
            return await ApiUtils.handleError(error, this.logger);
        }
    }

    public async getLeadReport(searchSettings: SearchSettings): Promise<HttpResponse<Blob>>{
        const url = `${this.actionUrl}/download/lead`;
        try {
            const response = await this.http.post(url, searchSettings, { responseType: 'blob', observe: 'response'}).toPromise();
            return response as HttpResponse<Blob>;
        } catch (error) {
            return await ApiUtils.handleError(error, this.logger);
        }
    }

}
