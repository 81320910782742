import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractRestService } from '../generic.service';
import { OutletDistrict } from '../../../shared/entities';
import { Logger } from '../../logger.service';

@Injectable()
export class OutletDistrictService extends AbstractRestService<OutletDistrict, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/outlet-districts', http, logger);
    }

    public async getAllOutletDistrictsByCountryCode(countryCode: string): Promise<OutletDistrict[]> {
        const url = `${this.actionUrl}`;
        const params = new HttpParams().set('countryCode', countryCode);

        try {
            const response = await this.http.get(url, { params }).toPromise();
            return response as OutletDistrict[];
        } catch (error) {
            return await this.handleError(error);
        }
    }
}
