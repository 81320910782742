import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Logger } from '../../logger.service';
import { ApiUtils } from '../utils/utils';

@Injectable()
export class TugaService {
    private actionUrl: string;

    constructor(protected http: HttpClient, protected logger: Logger) {
        this.actionUrl = `${environment.apiUrl}/tuga`;
    }

    public getUseTicketUrl(): Promise<string> {
        const url = `${this.actionUrl}/use-ticket-url`;

        return this.http
            .get(url, { responseType: 'text' })
            .toPromise()
            .then(response => response as string)
            .catch(error => ApiUtils.handleError(error, this.logger));
    }

    public getAppointmentUrl(vehicleVin: string): Promise<string> {
        const url = `${this.actionUrl}/appointment-url/${vehicleVin}`;

        return this.http
            .get(url, { responseType: 'text' })
            .toPromise()
            .then(response => response as string)
            .catch(error => ApiUtils.handleError(error, this.logger));
    }
}
