import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { KpiService } from './kpi.service';
import { Logger } from '../../logger.service';
import { SearchSettings, TssbHistoricData, TssbDetailData, LeadExportResult } from '../../../shared/entities';

@Injectable()
export class KpiLeadService extends KpiService {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/oas-as/lead', http, logger);
    }

    public leadAssignedToDealerCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/assignedToDealers/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public leadCancelledCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/cancelled/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public leadPostonedCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/postponed/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public leadEscalatedCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/escalated/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public leadInformationSentCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/informationSent/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public leadAssignedToTspCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/assignedToTsp/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public leadContactingCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/contacting/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public leadEmailFirstCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/emailFirst/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public leadDetailData(searchSettings?: SearchSettings): Promise<TssbDetailData[]> {
        return this.http
            .post(`${this.actionUrl}/detailData`, searchSettings)
            .toPromise()
            .then(response => response as TssbDetailData[])
            .catch(error => this.handleError(error));
    }

    public leadHistoricData(searchSettings?: SearchSettings): Promise<TssbHistoricData[]> {
        return this.http
            .post(`${this.actionUrl}/historic`, searchSettings)
            .toPromise()
            .then(response => response as TssbHistoricData[])
            .catch(error => this.handleError(error));
    }

    public leadCountKpi(searchSettings?: SearchSettings): Promise<LeadExportResult> {
        return this.http
            .post(`${this.actionUrl}/export-kpi`, searchSettings)
            .toPromise()
            .then(response => response as LeadExportResult)
            .catch(error => this.handleError(error));
    }
}
