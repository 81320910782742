import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CustomerService } from '../../core/api/customer/customer.service';
import { Vehicle } from '../../shared/entities';

@Injectable()
export class VehicleResolverService {
    constructor(private customerService: CustomerService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Vehicle> {
        const vehicleId = route.paramMap.get('vehicleId');

        return this.customerService.getVehicleFromLogged(vehicleId).then(vehicle => {
            if (vehicle) {
                return vehicle;
            } else {
                // id not found
                return null;
            }
        });
    }
}
