import { Pipe, PipeTransform } from '@angular/core';
import { countryConfiguration } from '../../../configuration/country.configuration';

@Pipe({
    name: 'translate',
})
export class TranslatePipe implements PipeTransform {
    private language: string;
    private country: string;
    /** Map<valueToTranslate, Map<language, translatedValue>> */
    private dictionary = new Map<string, Map<string, string>>();

    constructor() {
        this.language = countryConfiguration.language;
        this.country = countryConfiguration.countryCode;
        /** AVAILABLE LANGUAGES */
        this.dictionary.set(null, new Map<string, string>());
        this.dictionary.set('en-IN', new Map<string, string>());
        this.dictionary.set('it-IT', new Map<string, string>());
        this.dictionary.set('es-ES', new Map<string, string>());
        this.dictionary.set('pt-PT', new Map<string, string>());
        this.dictionary.set('es-MX', new Map<string, string>());
        this.dictionary.set('pt-BR', new Map<string, string>());

        /** BRANDS */
        this.dictionary.get(null).set('BMW', 'BMW');
        this.dictionary.get(null).set('MINI', 'MINI');
        this.dictionary.get(null).set('BMW_I', 'BMWi');
        this.dictionary.get(null).set('BMW_MOTORRAD', 'BMW Motorrad');

        /** GROUPS */
        this.dictionary.get(null).set('ONLINE', 'Online');
        this.dictionary.get(null).set('CALL_CENTER', 'Call Center');
        this.dictionary.get(null).set('RENTING', 'Renting');
        this.dictionary.get(null).set('BACK_OFFICE', 'Onsite');

        /** ROLES */
        this.dictionary.get(null).set('ROLE_ASM', 'Aftersales manager');
        this.dictionary.get(null).set('ROLE_CIC_P', 'Contact center, provider');
        this.dictionary.get(null).set('ROLE_CIC_EP_MANAGER', 'External contact center, provider manager');
        this.dictionary.get(null).set('ROLE_CIC_EP_USER', 'External contact center, provider user');
        this.dictionary.get(null).set('ROLE_CIC_NSC_ADMIN', 'National contact center, provider admin');
        this.dictionary.get(null).set('ROLE_CIC_NSC_USER', 'National contact center, provider user');
        this.dictionary.get(null).set('ROLE_NSC', 'Country administrator');
        this.dictionary.get(null).set('ROLE_OUTLET_ADM', 'Outlet admin');
        this.dictionary.get(null).set('ROLE_S_ADV', 'Outlet Service Advisor');
        this.dictionary.get(null).set('ROLE_S_ASSISTANT', 'Outlet Service Assistant');
        this.dictionary.get(null).set('ROLE_WORKSHOP_MANAGER', 'Workshop Manager');
        this.dictionary.get(null).set('ROLE_WELCOME_TERMINAL', 'Welcome Terminal');
        this.dictionary.get(null).set('ROLE_RENTING_ADM', 'Renting Admin');
        this.dictionary.get(null).set('ROLE_RENTING_USER', 'Renting User');
        this.dictionary.get(null).set('FAST_LANE', 'Fast Lane');

        this.dictionary.get('pt-PT').set('ROLE_ASM', 'Responsável de Grupo');
        this.dictionary.get('pt-PT').set('ROLE_CIC_P', 'Fornecedor Contact Center');
        this.dictionary.get('pt-PT').set('ROLE_CIC_EP_MANAGER', 'External contact center, provider manager');
        this.dictionary.get('pt-PT').set('ROLE_CIC_EP_USER', 'External contact center, provider user');
        this.dictionary.get('pt-PT').set('ROLE_CIC_NSC_ADMIN', 'Responsável do Contact Center');
        this.dictionary.get('pt-PT').set('ROLE_CIC_NSC_USER', 'Utilizador do Contact Center');
        this.dictionary.get('pt-PT').set('ROLE_NSC', 'Administrador');
        this.dictionary.get('pt-PT').set('ROLE_OUTLET_ADM', 'Gestor Após Venda');
        this.dictionary.get('pt-PT').set('ROLE_S_ADV', 'Consultor de Serviço');
        this.dictionary.get('pt-PT').set('ROLE_S_ASSISTANT', 'Assistente de Serviço');
        this.dictionary.get('pt-PT').set('ROLE_WORKSHOP_MANAGER', 'Gestor de Oficina');
        this.dictionary.get('pt-PT').set('ROLE_WELCOME_TERMINAL', 'Painel de Boas Vindas');
        this.dictionary.get('pt-PT').set('ROLE_RENTING_ADM', 'Renting Admin');
        this.dictionary.get('pt-PT').set('ROLE_RENTING_USER', 'Renting User');
        this.dictionary.get('pt-PT').set('FAST_LANE', 'Serviços Rápidos');

        this.dictionary.get('it-IT').set('ROLE_ASM', 'Aftersales manager');
        this.dictionary.get('it-IT').set('ROLE_CIC_P', 'Contact center, provider');
        this.dictionary.get('it-IT').set('ROLE_CIC_EP_MANAGER', 'External contact center, provider manager');
        this.dictionary.get('it-IT').set('ROLE_CIC_EP_USER', 'External contact center, provider user');
        this.dictionary.get('it-IT').set('ROLE_CIC_NSC_ADMIN', 'National contact center, provider admin');
        this.dictionary.get('it-IT').set('ROLE_CIC_NSC_USER', 'National contact center, provider user');
        this.dictionary.get('it-IT').set('ROLE_NSC', 'Country administrator');
        this.dictionary.get('it-IT').set('ROLE_OUTLET_ADM', 'Outlet admin');
        this.dictionary.get('it-IT').set('ROLE_S_ADV', 'Outlet Service Advisor');
        this.dictionary.get('it-IT').set('ROLE_S_ASSISTANT', 'Outlet Service Assistant');
        this.dictionary.get('it-IT').set('ROLE_WORKSHOP_MANAGER', 'Coordinatore di officina');
        this.dictionary.get('it-IT').set('ROLE_WELCOME_TERMINAL', 'Welcome Terminal');
        this.dictionary.get('it-IT').set('ROLE_RENTING_ADM', 'Renting Admin');
        this.dictionary.get('it-IT').set('ROLE_RENTING_USER', 'Renting User');
        this.dictionary.get('it-IT').set('FAST_LANE', 'Fast Lane');

        this.dictionary.get('es-ES').set('ROLE_ASM', 'Jefe de posventa');
        this.dictionary.get('es-ES').set('ROLE_CIC_P', 'Asistente de Call Center');
        this.dictionary.get('es-ES').set('ROLE_CIC_EP_MANAGER', 'Responsable del Call Center del concesionario');
        this.dictionary.get('es-ES').set('ROLE_CIC_EP_USER', 'Asistente de servicio del concesionario');
        this.dictionary.get('es-ES').set('ROLE_CIC_NSC_ADMIN', 'Call Center');
        this.dictionary.get('es-ES').set('ROLE_CIC_NSC_USER', 'Usuario de Call Center');
        this.dictionary.get('es-ES').set('ROLE_NSC', 'Administrador');
        this.dictionary.get('es-ES').set('ROLE_OUTLET_ADM', 'Responsable de la instalación');
        this.dictionary.get('es-ES').set('ROLE_S_ADV', 'Asesor de servicio');
        this.dictionary.get('es-ES').set('ROLE_S_ASSISTANT', 'Asistente de servicio de la instalación');
        this.dictionary.get('es-ES').set('ROLE_WORKSHOP_MANAGER', 'Jefe de taller');
        this.dictionary.get('es-ES').set('ROLE_WELCOME_TERMINAL', 'Welcome Terminal');
        this.dictionary.get('es-ES').set('ROLE_RENTING_ADM', 'Renting Admin');
        this.dictionary.get('es-ES').set('ROLE_RENTING_USER', 'Renting User');
        this.dictionary.get('es-ES').set('FAST_LANE', 'Fast Lane');

        this.dictionary.get('pt-BR').set('ROLE_ASM', 'Responsável de Grupo');
        this.dictionary.get('pt-BR').set('ROLE_CIC_P', 'Fornecedor Contact Center');
        this.dictionary.get('pt-BR').set('ROLE_CIC_EP_MANAGER', 'External contact center, provider manager');
        this.dictionary.get('pt-BR').set('ROLE_CIC_EP_USER', 'External contact center, provider user');
        this.dictionary.get('pt-BR').set('ROLE_CIC_NSC_ADMIN', 'Responsável do Contact Center');
        this.dictionary.get('pt-BR').set('ROLE_CIC_NSC_USER', 'Utilizador do Contact Center');
        this.dictionary.get('pt-BR').set('ROLE_NSC', 'Administrador');
        this.dictionary.get('pt-BR').set('ROLE_OUTLET_ADM', 'Gestor Após Venda');
        this.dictionary.get('pt-BR').set('ROLE_S_ADV', 'Consultor de Serviço');
        this.dictionary.get('pt-BR').set('ROLE_S_ASSISTANT', 'Assistente de Serviço');
        this.dictionary.get('pt-BR').set('ROLE_WORKSHOP_MANAGER', 'Gestor de Oficina');
        this.dictionary.get('pt-BR').set('ROLE_WELCOME_TERMINAL', 'Painel de Boas Vindas');
        this.dictionary.get('pt-BR').set('ROLE_RENTING_ADM', 'Renting Admin');
        this.dictionary.get('pt-BR').set('ROLE_RENTING_USER', 'Renting User');
        this.dictionary.get('pt-BR').set('FAST_LANE', 'Serviços Rápidos');

        this.dictionary.get('es-MX').set('ROLE_ASM', 'Jefe de posventa');
        this.dictionary.get('es-MX').set('ROLE_CIC_P', 'Asistente de Call Center');
        this.dictionary.get('es-MX').set('ROLE_CIC_EP_MANAGER', 'Responsable del Call Center del concesionario');
        this.dictionary.get('es-MX').set('ROLE_CIC_EP_USER', 'Asistente de servicio del concesionario');
        this.dictionary.get('es-MX').set('ROLE_CIC_NSC_ADMIN', 'Call Center');
        this.dictionary.get('es-MX').set('ROLE_CIC_NSC_USER', 'Usuario de Call Center');
        this.dictionary.get('es-MX').set('ROLE_NSC', 'Administrador');
        this.dictionary.get('es-MX').set('ROLE_OUTLET_ADM', 'Responsable de la instalación');
        this.dictionary.get('es-MX').set('ROLE_S_ADV', 'Asesor de servicio');
        this.dictionary.get('es-MX').set('ROLE_S_ASSISTANT', 'Asistente de servicio de la instalación');
        this.dictionary.get('es-MX').set('ROLE_WORKSHOP_MANAGER', 'Jefe de taller');
        this.dictionary.get('es-MX').set('ROLE_WELCOME_TERMINAL', 'Welcome Terminal');
        this.dictionary.get('es-MX').set('ROLE_RENTING_ADM', 'Renting Admin');
        this.dictionary.get('es-MX').set('ROLE_RENTING_USER', 'Renting User');
        this.dictionary.get('es-MX').set('FAST_LANE', 'Fast Lane');

        /** DATATABLE EMPTY MESSAGE */
        this.dictionary.get('en-IN').set('no_data_to_display', 'No data to display');
        this.dictionary.get('it-IT').set('no_data_to_display', 'Nessun dato da visualizzare');
        this.dictionary.get('es-ES').set('no_data_to_display', 'No hay información para mostrar');
        this.dictionary.get('pt-PT').set('no_data_to_display', 'Nenhum dado para exibir');
        this.dictionary.get('es-MX').set('no_data_to_display', 'No hay información para mostrar');
        this.dictionary.get('pt-BR').set('no_data_to_display', 'Nenhum dado para exibir');

        /** SHIFTS & ABSENCES */
        this.dictionary.get('es-MX').set('never', 'Nunca');
        this.dictionary.get('es-MX').set('After', 'Después');
        this.dictionary.get('es-MX').set('occurrences', 'repeticiones');
        this.dictionary.get('es-MX').set('occurrence', 'repeticione');
        this.dictionary.get('es-MX').set('New shift created', 'Turno Creado');
        this.dictionary.get('es-MX').set('Shift deleted', 'Turno Eliminado');
        this.dictionary.get('es-MX').set('Shift updated', 'Turno Actualizado');
        this.dictionary.get('es-MX').set('Shift assigned', 'Turno asignado');
        this.dictionary.get('es-MX').set('New absence created', 'Ausencia Creada');
        this.dictionary.get('es-MX').set('Absence deleted', 'Ausencia Eliminada');
        this.dictionary.get('es-MX').set('Absence updated', 'Ausencia Actualizada');
        this.dictionary.get('es-MX').set('Absence assigned', 'Ausencia asignada');
        this.dictionary
            .get('es-MX')
            .set('Assignation updated correctly', 'La asignación se ha actualizado correctamente');
        this.dictionary
            .get('es-MX')
            .set('Assignation removed correctly', 'La asignación se ha eliminado correctamente');
        this.dictionary.get('es-MX').set('Resource', 'Se ha asignado a');
        this.dictionary.get('es-MX').set('added correctly', 'correctamente');
        this.dictionary.get('es-MX').set('Resource added correctly', 'Se ha asignado correctamente');

        this.dictionary.get('pt-BR').set('never', 'Nunca');
        this.dictionary.get('pt-BR').set('After', 'Depois');
        this.dictionary.get('pt-BR').set('occurrences', 'Repetições');
        this.dictionary.get('pt-BR').set('occurrence', 'Repetição');
        this.dictionary.get('pt-BR').set('New shift created', 'Novo Turno Criado');
        this.dictionary.get('pt-BR').set('Shift deleted', 'Turno Cancelado');
        this.dictionary.get('pt-BR').set('Shift updated', 'Turno Atualizado');
        this.dictionary.get('pt-BR').set('Shift assigned', 'Turno assinado');
        this.dictionary.get('pt-BR').set('New absence created', 'Ausencia Criada');
        this.dictionary.get('pt-BR').set('Absence deleted', 'Ausência Cancelada');
        this.dictionary.get('pt-BR').set('Absence updated', 'Ausência Atualizada');
        this.dictionary.get('pt-BR').set('Absence assigned', 'Ausência asignada');
        this.dictionary.get('pt-BR').set('Assignation updated correctly', 'Atribuição alterada correctamente');
        this.dictionary.get('pt-BR').set('Assignation removed correctly', 'Atribuição cancelada correctamente');
        this.dictionary.get('pt-BR').set('Resource', 'Se atribuiu a');
        this.dictionary.get('pt-BR').set('added correctly', 'adicionada correctamente');
        this.dictionary.get('pt-BR').set('Resource added correctly', 'Recurso adicionado correctamente');

        this.dictionary.get('pt-PT').set('never', 'Nunca');
        this.dictionary.get('pt-PT').set('After', 'Depois');
        this.dictionary.get('pt-PT').set('occurrences', 'Repetições');
        this.dictionary.get('pt-PT').set('occurrence', 'Repetição');
        this.dictionary.get('pt-PT').set('New shift created', 'Novo Turno Criado');
        this.dictionary.get('pt-PT').set('Shift deleted', 'Turno Cancelado');
        this.dictionary.get('pt-PT').set('Shift updated', 'Turno Atualizado');
        this.dictionary.get('pt-PT').set('Shift assigned', 'Turno assinado');
        this.dictionary.get('pt-PT').set('New absence created', 'Ausencia Criada');
        this.dictionary.get('pt-PT').set('Absence deleted', 'Ausência Cancelada');
        this.dictionary.get('pt-PT').set('Absence updated', 'Ausência Atualizada');
        this.dictionary.get('pt-PT').set('Absence assigned', 'Ausência asignada');
        this.dictionary.get('pt-PT').set('Assignation updated correctly', 'Atribuição alterada correctamente');
        this.dictionary.get('pt-PT').set('Assignation removed correctly', 'Atribuição cancelada correctamente');
        this.dictionary.get('pt-PT').set('Resource', 'Se atribuiu a');
        this.dictionary.get('pt-PT').set('added correctly', 'adicionada correctamente');
        this.dictionary.get('pt-PT').set('Resource added correctly', 'Recurso adicionado correctamente');

        this.dictionary.get('it-IT').set('never', 'Mai');
        this.dictionary.get('it-IT').set('After', 'Dopo');
        this.dictionary.get('it-IT').set('occurrences', 'ripetizioni');
        this.dictionary.get('it-IT').set('occurrence', 'ripetizione');
        this.dictionary.get('it-IT').set('New shift created', 'Nuovo turno creato');
        this.dictionary.get('it-IT').set('Shift deleted', 'Turno cancellato');
        this.dictionary.get('it-IT').set('Shift updated', 'Turno modificato');
        this.dictionary.get('it-IT').set('Shift assigned', 'Turno assegnato');
        this.dictionary.get('it-IT').set('New absence created', 'Nuova assenza creata');
        this.dictionary.get('it-IT').set('Absence deleted', 'Assenza cancellata');
        this.dictionary.get('it-IT').set('Absence updated', 'Assenza modificata');
        this.dictionary.get('it-IT').set('Absence assigned', 'Assenza assegnata');
        this.dictionary.get('it-IT').set('Assignation updated correctly', 'Assegnazione modificata correttamente');
        this.dictionary.get('it-IT').set('Assignation removed correctly', 'Assegnazione rimossa correttamente');
        this.dictionary.get('it-IT').set('Resource', 'Assegnata a');
        this.dictionary.get('it-IT').set('added correctly', 'aggiunto correttamente');
        this.dictionary.get('it-IT').set('Resource added correctly', 'Risorsa aggiunta correttamente');

        this.dictionary.get('es-ES').set('never', 'Nunca');
        this.dictionary.get('es-ES').set('After', 'Después');
        this.dictionary.get('es-ES').set('occurrences', 'repeticiones');
        this.dictionary.get('es-ES').set('occurrence', 'repetición');
        this.dictionary.get('es-ES').set('New shift created', 'Turno creado');
        this.dictionary.get('es-ES').set('Shift deleted', 'Turno eliminado');
        this.dictionary.get('es-ES').set('Shift updated', 'Turno actualizado');
        this.dictionary.get('es-ES').set('Shift assigned', 'Turno asignado');
        this.dictionary.get('es-ES').set('New absence created', 'Ausencia creada');
        this.dictionary.get('es-ES').set('Absence deleted', 'Ausencia eliminada');
        this.dictionary.get('es-ES').set('Absence updated', 'Ausencia actualizada');
        this.dictionary.get('es-ES').set('Absence assigned', 'Ausencia asignada');
        this.dictionary.get('es-ES').set('Assignation updated correctly', 'La asignación se ha actualizado');
        this.dictionary.get('es-ES').set('Assignation removed correctly', 'Se ha eliminado la asignación');
        this.dictionary.get('es-ES').set('Resource', 'Se ha asignado a');
        this.dictionary.get('es-ES').set('added correctly', 'Añadido correctamente');
        this.dictionary.get('es-ES').set('Resource added correctly', 'Se ha asignado correctamente');

        this.dictionary.get('en-IN').set('never', 'never');
        this.dictionary.get('en-IN').set('After', 'After');
        this.dictionary.get('en-IN').set('occurrences', 'occurrences');
        this.dictionary.get('en-IN').set('occurrence', 'occurrence');
        this.dictionary.get('en-IN').set('New shift created', 'New shift created');
        this.dictionary.get('en-IN').set('Shift deleted', 'Shift deleted');
        this.dictionary.get('en-IN').set('Shift updated', 'Shift updated');
        this.dictionary.get('en-IN').set('Shift assigned', 'Shift assigned');
        this.dictionary.get('en-IN').set('New absence created', 'New absence created');
        this.dictionary.get('en-IN').set('Absence deleted', 'Absence deleted');
        this.dictionary.get('en-IN').set('Absence updated', 'Absence updated');
        this.dictionary.get('en-IN').set('Absence assigned', 'Absence assigned');
        this.dictionary.get('en-IN').set('Assignation updated correctly', 'Assignment updated correctly');
        this.dictionary.get('en-IN').set('Assignation removed correctly', 'Assignment removed correctly');
        this.dictionary.get('en-IN').set('Resource', 'Resource');
        this.dictionary.get('en-IN').set('added correctly', 'added correctly');
        this.dictionary.get('en-IN').set('Resource added correctly', 'Resource added correctly');

        /** CALENDAR OPTIONS */
        this.dictionary.get('en-IN').set('week', 'week');
        this.dictionary.get('it-IT').set('week', 'settimana');
        this.dictionary.get('es-ES').set('week', 'semana');
        this.dictionary.get('pt-PT').set('week', 'semana');
        this.dictionary.get('es-MX').set('week', 'semana');
        this.dictionary.get('pt-BR').set('week', 'semana');
        this.dictionary.get('en-IN').set('day', 'day');
        this.dictionary.get('it-IT').set('day', 'giorno');
        this.dictionary.get('es-ES').set('day', 'día');
        this.dictionary.get('pt-PT').set('day', 'dia');
        this.dictionary.get('es-MX').set('day', 'día');
        this.dictionary.get('pt-BR').set('day', 'dia');
        this.dictionary.get('en-IN').set('no_appointment_to_display', 'no appointments to display');
        this.dictionary.get('it-IT').set('no_appointment_to_display', 'nessun appuntamento da mostrare');
        this.dictionary.get('es-ES').set('no_appointment_to_display', 'no hay citas para este periodo');
        this.dictionary.get('pt-PT').set('no_appointment_to_display', 'não há marcações para exibir');
        this.dictionary.get('es-MX').set('no_appointment_to_display', 'no hay citas para este periodo');
        this.dictionary.get('pt-BR').set('no_appointment_to_display', 'não há agendamentos para exibir');
        this.dictionary.get('en-IN').set('today', 'today');
        this.dictionary.get('it-IT').set('today', 'oggi');
        this.dictionary.get('es-ES').set('today', 'hoy');
        this.dictionary.get('pt-PT').set('today', 'hoje');
        this.dictionary.get('es-MX').set('today', 'hoy');
        this.dictionary.get('pt-BR').set('today', 'hoje');

        /** STATUSES */
        this.dictionary.get('en-IN').set('PENDING', 'Pending');
        this.dictionary.get('it-IT').set('PENDING', 'Prenotato');
        this.dictionary.get('es-ES').set('PENDING', 'Pendiente');
        this.dictionary.get('pt-PT').set('PENDING', 'Pendente');
        this.dictionary.get('es-MX').set('PENDING', 'Pendiente');
        this.dictionary.get('pt-BR').set('PENDING', 'Pendente');
        this.dictionary.get('en-IN').set('IN_PROCESS', 'On going');
        this.dictionary.get('it-IT').set('IN_PROCESS', 'In corso');
        this.dictionary.get('es-ES').set('IN_PROCESS', 'En curso');
        this.dictionary.get('pt-PT').set('IN_PROCESS', 'A decorrer');
        this.dictionary.get('es-MX').set('IN_PROCESS', 'En curso');
        this.dictionary.get('pt-BR').set('IN_PROCESS', 'A decorrer');
        this.dictionary.get('en-IN').set('DELIVERED', 'Delivered');
        this.dictionary.get('it-IT').set('DELIVERED', 'Concluso');
        this.dictionary.get('es-ES').set('DELIVERED', 'Entregado');
        this.dictionary.get('pt-PT').set('DELIVERED', 'Entregue');
        this.dictionary.get('es-MX').set('DELIVERED', 'Entregado');
        this.dictionary.get('pt-BR').set('DELIVERED', 'Entregue');
        this.dictionary.get('en-IN').set('CANCELED', 'Canceled');
        this.dictionary.get('it-IT').set('CANCELED', 'Cancellato');
        this.dictionary.get('es-ES').set('CANCELED', 'Cancelado');
        this.dictionary.get('pt-PT').set('CANCELED', 'Cancelado');
        this.dictionary.get('es-MX').set('CANCELED', 'Cancelado');
        this.dictionary.get('pt-BR').set('CANCELED', 'Cancelado');
        this.dictionary.get('en-IN').set('COMPLETED', 'Completed');
        this.dictionary.get('it-IT').set('COMPLETED', 'Riparazione completata');
        this.dictionary.get('es-ES').set('COMPLETED', 'Completado');
        this.dictionary.get('pt-PT').set('COMPLETED', 'Finalizado');
        this.dictionary.get('es-MX').set('COMPLETED', 'Completado');
        this.dictionary.get('pt-BR').set('COMPLETED', 'Finalizado');
        this.dictionary.get('en-IN').set('EXPIRED', 'Expired');
        this.dictionary.get('it-IT').set('EXPIRED', 'Scaduto');
        this.dictionary.get('es-ES').set('EXPIRED', 'Expirado');
        this.dictionary.get('pt-PT').set('EXPIRED', 'Expirou');
        this.dictionary.get('es-MX').set('EXPIRED', 'Expirado');
        this.dictionary.get('pt-BR').set('EXPIRED', 'Expirado');
        this.dictionary.get('en-IN').set('IN_VALIDATION', 'In validation');
        this.dictionary.get('it-IT').set('IN_VALIDATION', 'In validazione');
        this.dictionary.get('es-ES').set('IN_VALIDATION', 'Validando');
        this.dictionary.get('pt-PT').set('IN_VALIDATION', 'Em processamento');
        this.dictionary.get('es-MX').set('IN_VALIDATION', 'Validando');
        this.dictionary.get('pt-BR').set('IN_VALIDATION', 'Em processamento');
        this.dictionary.get('en-IN').set('ISPA_PENDING', 'ISPA Pending');
        this.dictionary.get('it-IT').set('ISPA_PENDING', 'Prenotato ISPA');
        this.dictionary.get('es-ES').set('ISPA_PENDING', 'ISPA Pendiente');
        this.dictionary.get('pt-PT').set('ISPA_PENDING', 'ISPA Pendente');
        this.dictionary.get('es-MX').set('ISPA_PENDING', 'ISPA Pendiente');
        this.dictionary.get('pt-BR').set('ISPA_PENDING', 'ISPA Pendente');

        /** PNEUMATIC TYPOLOGIES */
        this.dictionary.get('en-IN').set('PNEUMATIC_WINTER', 'Winter pneumatic');
        this.dictionary.get('it-IT').set('PNEUMATIC_WINTER', 'Pneumatico invernale');
        this.dictionary.get('es-ES').set('PNEUMATIC_WINTER', 'Neumático de invierno');
        this.dictionary.get('pt-PT').set('PNEUMATIC_WINTER', 'Pneumático de inverno');
        this.dictionary.get('es-MX').set('PNEUMATIC_WINTER', 'Neumático de invierno');
        this.dictionary.get('pt-BR').set('PNEUMATIC_WINTER', 'Pneumático de inverno');
        this.dictionary.get('en-IN').set('PNEUMATIC_SUMMER', 'Summer pneumatic');
        this.dictionary.get('it-IT').set('PNEUMATIC_SUMMER', 'Pneumatico estivo');
        this.dictionary.get('es-ES').set('PNEUMATIC_SUMMER', 'Neumático de verano');
        this.dictionary.get('pt-PT').set('PNEUMATIC_SUMMER', 'Pneumático de verão');
        this.dictionary.get('es-MX').set('PNEUMATIC_SUMMER', 'Neumático de verano');
        this.dictionary.get('pt-BR').set('PNEUMATIC_SUMMER', 'Pneumático de verão');
        this.dictionary.get('en-IN').set('PNEUMATIC_ALL_SEASON', 'All season pneumatic');
        this.dictionary.get('it-IT').set('PNEUMATIC_ALL_SEASON', 'Pneumatico all season');
        this.dictionary.get('es-ES').set('PNEUMATIC_ALL_SEASON', 'Neumático all season');
        this.dictionary.get('pt-PT').set('PNEUMATIC_ALL_SEASON', 'Pneumático all season');
        this.dictionary.get('es-MX').set('PNEUMATIC_ALL_SEASON', 'Neumático all season');
        this.dictionary.get('pt-BR').set('PNEUMATIC_ALL_SEASON', 'Pneumático all season');
        this.dictionary.get('en-IN').set('COMPLETE_WHEEL_WINTER', 'Complete winter wheel');
        this.dictionary.get('it-IT').set('COMPLETE_WHEEL_WINTER', 'Cerchio completo invernale');
        this.dictionary.get('es-ES').set('COMPLETE_WHEEL_WINTER', 'Rueda completa de invierno');
        this.dictionary.get('pt-PT').set('COMPLETE_WHEEL_WINTER', 'Roda completa de inverno');
        this.dictionary.get('es-MX').set('COMPLETE_WHEEL_WINTER', 'Rueda completa de invierno');
        this.dictionary.get('pt-BR').set('COMPLETE_WHEEL_WINTER', 'Roda completa de inverno');
        this.dictionary.get('en-IN').set('COMPLETE_WHEEL_SUMMER', 'Complete summer wheel');
        this.dictionary.get('it-IT').set('COMPLETE_WHEEL_SUMMER', 'Cerchio completo estivo');
        this.dictionary.get('es-ES').set('COMPLETE_WHEEL_SUMMER', 'Rueda completa de verano');
        this.dictionary.get('pt-PT').set('COMPLETE_WHEEL_SUMMER', 'Roda completa de verão');
        this.dictionary.get('es-MX').set('COMPLETE_WHEEL_SUMMER', 'Rueda completa de verano');
        this.dictionary.get('pt-BR').set('COMPLETE_WHEEL_SUMMER', 'Roda completa de verão');

        /* Dirty Fix Packages */
        this.dictionary.get('en-IN').set('SHOW', 'Show');
        this.dictionary.get('it-IT').set('SHOW', 'Mostra');
        this.dictionary.get('es-ES').set('SHOW', 'Ver');
        this.dictionary.get('pt-PT').set('SHOW', 'Mostrar');
        this.dictionary.get('es-MX').set('SHOW', 'Ver');
        this.dictionary.get('pt-BR').set('SHOW', 'Mostrar');
        this.dictionary.get('en-IN').set('HIDE', 'Hide');
        this.dictionary.get('it-IT').set('HIDE', 'Nascondi');
        this.dictionary.get('es-ES').set('HIDE', 'Ocultar');
        this.dictionary.get('pt-PT').set('HIDE', 'Ocultar');
        this.dictionary.get('es-MX').set('HIDE', 'Ocultar');
        this.dictionary.get('pt-BR').set('HIDE', 'Ocultar');

        // CATALOG TYPES
        this.dictionary.get('en-IN').set('ISPAFULL', 'ISPAFULL');
        this.dictionary.get('it-IT').set('ISPAFULL', 'Locale');
        this.dictionary.get('es-ES').set('ISPAFULL', 'Local');
        this.dictionary.get('pt-PT').set('ISPAFULL', 'Local');
        this.dictionary.get('es-MX').set('ISPAFULL', 'Local');
        this.dictionary.get('pt-BR').set('ISPAFULL', 'Local');
        this.dictionary.get('en-IN').set('MANUAL', 'Manual');
        this.dictionary.get('it-IT').set('MANUAL', 'Pacchetti extra');
        this.dictionary.get('es-ES').set('MANUAL', 'Manual');
        this.dictionary.get('pt-PT').set('MANUAL', 'Manual');
        this.dictionary.get('es-MX').set('MANUAL', 'Manual');
        this.dictionary.get('pt-BR').set('MANUAL', 'Manual');
        this.dictionary.get('en-IN').set('PACKAGES', 'Packages');
        this.dictionary.get('it-IT').set('PACKAGES', 'Pacchetti');
        this.dictionary.get('es-ES').set('PACKAGES', 'Paquetes');
        this.dictionary.get('pt-PT').set('PACKAGES', 'Pacotes de serviços');
        this.dictionary.get('es-MX').set('PACKAGES', 'Paquetes');
        this.dictionary.get('pt-BR').set('PACKAGES', 'Pacotes de serviços');
        this.dictionary.get('en-IN').set('FLATRATES', 'Activity labors');
        this.dictionary.get('it-IT').set('FLATRATES', 'Servizi');
        this.dictionary.get('es-ES').set('FLATRATES', 'Manos de obra');
        this.dictionary.get('pt-PT').set('FLATRATES', 'UT do Serviço');
        this.dictionary.get('es-MX').set('FLATRATES', 'Manos de obra');
        this.dictionary.get('pt-BR').set('FLATRATES', 'UT do Serviço');
        this.dictionary.get('en-IN').set('TECHNICAL_CAMPAIGNS', 'Technical Campaigns');
        this.dictionary.get('it-IT').set('TECHNICAL_CAMPAIGNS', 'Campagne Tecniche');
        this.dictionary.get('es-ES').set('TECHNICAL_CAMPAIGNS', 'Campañas técnicas');
        this.dictionary.get('pt-PT').set('TECHNICAL_CAMPAIGNS', 'Ações Técnicas');
        this.dictionary.get('es-MX').set('TECHNICAL_CAMPAIGNS', 'Campañas técnicas');
        this.dictionary.get('pt-BR').set('TECHNICAL_CAMPAIGNS', 'Campanhas técnicas');

        /* MOBILITY TYPES*/
        this.dictionary.get(null).set('MOBILITY_1', 'Courtesy car');
        this.dictionary.get(null).set('MOBILITY_2', 'Pick up & Delivery');
        this.dictionary.get(null).set('MOBILITY_3', 'Taxi');
        this.dictionary.get(null).set('MOBILITY_5', 'DriveNow');
        this.dictionary.get(null).set('MOBILITY_6', 'Bicycle / E-Bike');
        this.dictionary.get(null).set('MOBILITY_7', 'Bus ticket');
        this.dictionary.get(null).set('MOBILITY_8', 'Shuttle');
        this.dictionary.get(null).set('MOBILITY_9', 'Courtesy moto');
        this.dictionary.get('en-IN').set('MOBILITY_1', 'Courtesy car');
        this.dictionary.get('en-IN').set('MOBILITY_2', 'Pick up & Delivery');
        this.dictionary.get('en-IN').set('MOBILITY_3', 'Taxi');
        this.dictionary.get('en-IN').set('MOBILITY_5', 'DriveNow');
        this.dictionary.get('en-IN').set('MOBILITY_6', 'Bicycle / E-Bike');
        this.dictionary.get('en-IN').set('MOBILITY_7', 'Bus ticket');
        this.dictionary.get('en-IN').set('MOBILITY_8', 'Shuttle');
        this.dictionary.get('en-IN').set('MOBILITY_9', 'Courtesy moto');
        this.dictionary.get('es-ES').set('MOBILITY_1', 'Vehículo de sustitución');
        this.dictionary.get('es-ES').set('MOBILITY_2', 'Servicio de recogida y entrega');
        this.dictionary.get('es-ES').set('MOBILITY_3', 'Taxi');
        this.dictionary.get('es-ES').set('MOBILITY_5', 'Servicio de desplazamiento');
        this.dictionary.get('es-ES').set('MOBILITY_6', 'Bicicleta / E-Bike');
        this.dictionary.get('es-ES').set('MOBILITY_7', 'Billete de autobús');
        this.dictionary.get('es-ES').set('MOBILITY_8', 'Tren / Lanzadera');
        this.dictionary.get('es-ES').set('MOBILITY_9', 'Moto de cortesia');
        this.dictionary.get('it-IT').set('MOBILITY_1', 'Auto di cortesia');
        this.dictionary.get('it-IT').set('MOBILITY_2', 'Pick up & Delivery');
        this.dictionary.get('it-IT').set('MOBILITY_3', 'Taxi');
        this.dictionary.get('it-IT').set('MOBILITY_5', 'DriveNow');
        this.dictionary.get('it-IT').set('MOBILITY_6', 'Bicicletta / E-Bike');
        this.dictionary.get('it-IT').set('MOBILITY_7', 'Mezzi pubblici');
        this.dictionary.get('it-IT').set('MOBILITY_8', 'Navetta');
        this.dictionary.get('it-IT').set('MOBILITY_9', 'Moto di cortesia');
        this.dictionary.get('pt-PT').set('MOBILITY_1', 'Veículo de cortesia');
        this.dictionary.get('pt-PT').set('MOBILITY_2', 'Pick up & Delivery');
        this.dictionary.get('pt-PT').set('MOBILITY_3', 'Taxi');
        this.dictionary.get('pt-PT').set('MOBILITY_5', 'DriveNow');
        this.dictionary.get('pt-PT').set('MOBILITY_6', 'Bicicleta / E-Bike');
        this.dictionary.get('pt-PT').set('MOBILITY_7', 'Bilhete de autocarro');
        this.dictionary.get('pt-PT').set('MOBILITY_8', 'Shuttle');
        this.dictionary.get('pt-PT').set('MOBILITY_9', 'Moto de cortesia');
        this.dictionary.get('es-MX').set('MOBILITY_1', 'Vehículo de sustitución');
        this.dictionary.get('es-MX').set('MOBILITY_2', 'Servicio de recogida y entrega');
        this.dictionary.get('es-MX').set('MOBILITY_3', 'Taxi');
        this.dictionary.get('es-MX').set('MOBILITY_5', 'Servicio de desplazamiento');
        this.dictionary.get('es-MX').set('MOBILITY_6', 'Bicicleta / E-Bike');
        this.dictionary.get('es-MX').set('MOBILITY_7', 'Billete de autobús');
        this.dictionary.get('es-MX').set('MOBILITY_8', 'Tren / Lanzadera');
        this.dictionary.get('es-MX').set('MOBILITY_9', 'Moto de cortesia');
        this.dictionary.get('pt-BR').set('MOBILITY_1', 'Veículo de cortesia');
        this.dictionary.get('pt-BR').set('MOBILITY_2', 'Carro de aplicativo');
        this.dictionary.get('pt-BR').set('MOBILITY_3', 'Taxi');
        this.dictionary.get('pt-BR').set('MOBILITY_5', 'DriveNow');
        this.dictionary.get('pt-BR').set('MOBILITY_6', 'Bicicleta / E-Bike');
        this.dictionary.get('pt-BR').set('MOBILITY_7', 'Bilhete de autocarro');
        this.dictionary.get('pt-BR').set('MOBILITY_8', 'Shuttle');
        this.dictionary.get('pt-BR').set('MOBILITY_9', 'Moto de cortesia');

        /* Error message */
        this.dictionary
            .get('en-IN')
            .set('PasswordAlreadyUsed', 'Please, enter new password. It is not possible to use the old one.');
        this.dictionary
            .get('it-IT')
            .set('PasswordAlreadyUsed', 'Por favor, introduce una nueva contraseña. No es posible usar la misma');
        this.dictionary
            .get('es-ES')
            .set('PasswordAlreadyUsed', 'Por favor, introduce una nueva contraseña. No es posible usar la misma.');
        this.dictionary
            .get('pt-PT')
            .set('PasswordAlreadyUsed', 'Por favor, introduce una nueva contraseña. No es posible usar la misma');
        this.dictionary
            .get('es-MX')
            .set('PasswordAlreadyUsed', 'Por favor, introduce una nueva contraseña. No es posible usar la misma.');
        this.dictionary
            .get('pt-BR')
            .set('PasswordAlreadyUsed', 'Por favor, introduce una nueva contraseña. No es posible usar la misma');

        /* Modal message */

        this.dictionary.get('en-IN').set('vehicleNotRecognizedTitle', 'Vehicle not recognized');
        this.dictionary
            .get('en-IN')
            .set(
                'vehicleNotRecognizedText',
                'Your vehicle was not recognized for central system. Please try another time or fill the basic data manually'
            );
        this.dictionary.get('en-IN').set('retry', 'Retry');
        this.dictionary.get('en-IN').set('manual', 'Manual');
        this.dictionary.get('en-IN').set('appointmentWithoutRegistration', 'appointment without registration');

        this.dictionary.get('it-IT').set('vehicleNotRecognizedTitle', 'Veicolo non riconosciuto');
        this.dictionary
            .get('it-IT')
            .set(
                'vehicleNotRecognizedText',
                'Il tuo veicolo non è stato riconosciuto dal sistema. Per favore prova nuovamente o compila i dati manualmente'
            );
        this.dictionary.get('it-IT').set('retry', 'Riprova');
        this.dictionary.get('it-IT').set('manual', 'Manuale');

        this.dictionary.get('es-ES').set('vehicleNotRecognizedTitle', 'Vehículo no reconocido');
        this.dictionary
            .get('es-ES')
            .set(
                'vehicleNotRecognizedText',
                'Su vehículo no fue reconocido por el sistema. Intente otra vez o complete los datos básicos manualmente'
            );
        this.dictionary.get('es-ES').set('retry', 'Reintentar');
        this.dictionary.get('es-ES').set('manual', 'Manual');
        this.dictionary.get('es-ES').set('appointmentWithoutRegistration', 'Cita sin registro');

        this.dictionary.get('pt-PT').set('vehicleNotRecognizedTitle', 'Veículo não reconhecido');
        this.dictionary
            .get('pt-PT')
            .set(
                'vehicleNotRecognizedText',
                'O seu veículo não foi reconhecido pelo sistema. Por favor tente novamente ou preencha os dados manualmente'
            );
        this.dictionary.get('pt-PT').set('retry', 'Tente novamente');
        this.dictionary.get('pt-PT').set('manual', 'Manual');

        this.dictionary.get('es-MX').set('vehicleNotRecognizedTitle', 'Vehículo no reconocido');
        this.dictionary
            .get('es-MX')
            .set(
                'vehicleNotRecognizedText',
                'Su vehículo no fue reconocido por el sistema. Intente otra vez o complete los datos básicos manualmente'
            );
        this.dictionary.get('es-MX').set('retry', 'Reintentar');
        this.dictionary.get('es-MX').set('manual', 'Manual');
        this.dictionary.get('es-MX').set('appointmentWithoutRegistration', 'Cita sin registro');

        this.dictionary.get('pt-BR').set('vehicleNotRecognizedTitle', 'Veículo não reconhecido');
        this.dictionary
            .get('pt-BR')
            .set(
                'vehicleNotRecognizedText',
                'O seu veículo não foi reconhecido pelo sistema. Por favor tente novamente ou preencha os dados manualmente'
            );
        this.dictionary.get('pt-BR').set('retry', 'Tente novamente');
        this.dictionary.get('pt-BR').set('manual', 'Manual');

        this.dictionary.get('en-IN').set('all', 'All');
        this.dictionary.get('it-IT').set('all', 'Tutti');
        this.dictionary.get('es-ES').set('all', 'Todos');
        this.dictionary.get('pt-PT').set('all', 'Todos');
        this.dictionary.get('es-MX').set('all', 'Todos');
        this.dictionary.get('pt-BR').set('all', 'Todos');

        this.dictionary.get('en-IN').set('Warning', 'Warning');
        this.dictionary.get('it-IT').set('Warning', 'Attenzione');
        this.dictionary.get('es-ES').set('Warning', 'Aviso');
        this.dictionary.get('pt-PT').set('Warning', 'Aviso');
        this.dictionary.get('es-MX').set('Warning', 'Aviso');
        this.dictionary.get('pt-BR').set('Warning', 'Aviso');

        this.dictionary.get('en-IN').set('lead_time_document', 'lead_time_document_en');
        this.dictionary.get('it-IT').set('lead_time_document', 'lead_time_document_it');
        this.dictionary.get('es-ES').set('lead_time_document', 'lead_time_document_es');
        this.dictionary.get('pt-PT').set('lead_time_document', 'lead_time_document_pt');
        this.dictionary.get('es-MX').set('lead_time_document', 'lead_time_document_mx');
        this.dictionary.get('pt-BR').set('lead_time_document', 'lead_time_document_br');

        this.dictionary.get('en-IN').set('ASSIGNED_TO_TSP', 'Assigned to TSP');
        this.dictionary.get('en-IN').set('ASSIGNED_TO_DEALER', 'Assigned to dealer');
        this.dictionary.get('en-IN').set('CONTACTING', 'Contacting');
        this.dictionary.get('en-IN').set('POSTPONED', 'Postponed');
        this.dictionary.get('en-IN').set('CANCELLED', 'Cancelled');
        this.dictionary.get('en-IN').set('INFORMATION_SENT', 'Information sent');
        this.dictionary.get('en-IN').set('ESCALATED_TIMEOUT_ASSIGNED', 'ESCALATED_TIMEOUT_ASSIGNED');
        this.dictionary.get('en-IN').set('ESCALATED_TIMEOUT_POSTPONED', 'ESCALATED_TIMEOUT_POSTPONED');
        this.dictionary.get('en-IN').set('ESCALATED_TIMEOUT_CONTACTING', 'ESCALATED_TIMEOUT_CONTACTING');
        this.dictionary.get('en-IN').set('ESCALATED', 'ESCALATED');
        this.dictionary.get('en-IN').set('INVALID_TICKET', 'Ticket invalido');
        this.dictionary.get('en-IN').set('EMAIL_SENT', 'Email First pending');

        this.dictionary.get('es-ES').set('ASSIGNED_TO_TSP', 'Pendiente');
        this.dictionary.get('es-ES').set('ASSIGNED_TO_DEALER', 'Cita creada');
        this.dictionary.get('es-ES').set('CONTACTING', 'Contactado');
        this.dictionary.get('es-ES').set('POSTPONED', 'Pospuesto');
        this.dictionary.get('es-ES').set('CANCELLED', 'Cancelado');
        this.dictionary.get('es-ES').set('INFORMATION_SENT', 'Enviado email');
        this.dictionary.get('es-ES').set('ESCALATED_TIMEOUT_ASSIGNED', 'Escalado sin gestionar');
        this.dictionary.get('es-ES').set('ESCALATED_TIMEOUT_POSTPONED', 'Escalado pospuesto');
        this.dictionary.get('es-ES').set('ESCALATED_TIMEOUT_CONTACTING', 'Escalado contactado');
        this.dictionary.get('es-ES').set('ESCALATED', 'Escalado');
        this.dictionary.get('es-ES').set('INVALID_TICKET', 'Ticket invalido');
        this.dictionary.get('es-ES').set('EMAIL_SENT', 'Pendiente Email First');

        this.dictionary.get('pt-PT').set('ASSIGNED_TO_TSP', 'Pendente');
        this.dictionary.get('pt-PT').set('ASSIGNED_TO_DEALER', 'Marcação criada');
        this.dictionary.get('pt-PT').set('CONTACTING', 'Em contacto');
        this.dictionary.get('pt-PT').set('POSTPONED', 'Adiado');
        this.dictionary.get('pt-PT').set('CANCELLED', 'Cancelado');
        this.dictionary.get('pt-PT').set('INFORMATION_SENT', 'Email enviado');
        this.dictionary.get('pt-PT').set('ESCALATED_TIMEOUT_ASSIGNED', 'Escalado após atribuido');
        this.dictionary.get('pt-PT').set('ESCALATED_TIMEOUT_POSTPONED', 'Escalado após adiado');
        this.dictionary.get('pt-PT').set('ESCALATED_TIMEOUT_CONTACTING', 'Escalado após em contacto');
        this.dictionary.get('pt-PT').set('ESCALATED', 'Escalado');
        this.dictionary.get('pt-PT').set('INVALID_TICKET', 'Ticket inválido');
        this.dictionary.get('pt-PT').set('EMAIL_SENT', 'Pendente Email First');

        this.dictionary.get('it-IT').set('ASSIGNED_TO_TSP', 'Prenotato');
        this.dictionary.get('it-IT').set('ASSIGNED_TO_DEALER', 'Appuntamento creato');
        this.dictionary.get('it-IT').set('CONTACTING', 'Contattato');
        this.dictionary.get('it-IT').set('POSTPONED', 'Posticipato');
        this.dictionary.get('it-IT').set('CANCELLED', 'Cancellato');
        this.dictionary.get('it-IT').set('INFORMATION_SENT', 'Email inviato');
        this.dictionary.get('it-IT').set('ESCALATED_TIMEOUT_ASSIGNED', 'ESCALATED_TIMEOUT_ASSIGNED');
        this.dictionary.get('it-IT').set('ESCALATED_TIMEOUT_POSTPONED', 'ESCALATED_TIMEOUT_POSTPONED');
        this.dictionary.get('it-IT').set('ESCALATED_TIMEOUT_CONTACTING', 'ESCALATED_TIMEOUT_CONTACTING');
        this.dictionary.get('it-IT').set('ESCALATED', 'ESCALATED');
        this.dictionary.get('it-IT').set('INVALID_TICKET', 'INVALID_TICKET');
        this.dictionary.get('it-IT').set('EMAIL_SENT', 'Prenotato Email First');

        this.dictionary.get('es-MX').set('ASSIGNED_TO_TSP', 'Pendiente');
        this.dictionary.get('es-MX').set('ASSIGNED_TO_DEALER', 'Cita creada');
        this.dictionary.get('es-MX').set('CONTACTING', 'Contactado');
        this.dictionary.get('es-MX').set('POSTPONED', 'Pospuesto');
        this.dictionary.get('es-MX').set('CANCELLED', 'Cancelado');
        this.dictionary.get('es-MX').set('INFORMATION_SENT', 'Enviado email');
        this.dictionary.get('es-MX').set('ESCALATED_TIMEOUT_ASSIGNED', 'Escalado sin gestionar');
        this.dictionary.get('es-MX').set('ESCALATED_TIMEOUT_POSTPONED', 'Escalado pospuesto');
        this.dictionary.get('es-MX').set('ESCALATED_TIMEOUT_CONTACTING', 'Escalado contactado');
        this.dictionary.get('es-MX').set('ESCALATED', 'Escalado');
        this.dictionary.get('es-MX').set('INVALID_TICKET', 'Ticket invalido');
        this.dictionary.get('es-MX').set('EMAIL_SENT', 'Pendiente Email First');

        this.dictionary.get('pt-BR').set('ASSIGNED_TO_TSP', 'Pendente');
        this.dictionary.get('pt-BR').set('ASSIGNED_TO_DEALER', 'Agendamento criado');
        this.dictionary.get('pt-BR').set('CONTACTING', 'Em contacto');
        this.dictionary.get('pt-BR').set('POSTPONED', 'Adiado');
        this.dictionary.get('pt-BR').set('CANCELLED', 'Cancelado');
        this.dictionary.get('pt-BR').set('INFORMATION_SENT', 'Email enviado');
        this.dictionary.get('pt-BR').set('ESCALATED_TIMEOUT_ASSIGNED', 'Escalado após atribuido');
        this.dictionary.get('pt-BR').set('ESCALATED_TIMEOUT_POSTPONED', 'Escalado após adiado');
        this.dictionary.get('pt-BR').set('ESCALATED_TIMEOUT_CONTACTING', 'Escalado após em contacto');
        this.dictionary.get('pt-BR').set('ESCALATED', 'Escalado');
        this.dictionary.get('pt-BR').set('INVALID_TICKET', 'Ticket inválido');
        this.dictionary.get('pt-BR').set('EMAIL_SENT', 'Pendente Email First');

        this.dictionary.get('en-IN').set('OPERATION_COMPLETED_SUCCESSFULLY', 'Operation completed successfully');
        this.dictionary.get('it-IT').set('OPERATION_COMPLETED_SUCCESSFULLY', 'Operazione completata con successo');
        this.dictionary.get('es-ES').set('OPERATION_COMPLETED_SUCCESSFULLY', 'La operación se realizó con éxito');
        this.dictionary.get('pt-PT').set('OPERATION_COMPLETED_SUCCESSFULLY', 'Operação concluída com sucesso.');
        this.dictionary.get('es-MX').set('OPERATION_COMPLETED_SUCCESSFULLY', 'La operación se realizó con éxito');
        this.dictionary.get('pt-BR').set('OPERATION_COMPLETED_SUCCESSFULLY', 'Operação concluída com sucesso.');

/* Extra/info */
        this.dictionary.get('en-IN').set('WALK_IN_CUSTOMER', 'Walk-In Customer');
        this.dictionary.get('it-IT').set('WALK_IN_CUSTOMER', 'Prenota ignorando timeslot disponibili');
        this.dictionary.get('es-ES').set('WALK_IN_CUSTOMER', 'Sin cita previa');
        this.dictionary.get('pt-PT').set('WALK_IN_CUSTOMER', 'Cliente sem marcação');
        this.dictionary.get('es-MX').set('WALK_IN_CUSTOMER', 'Sin cita previa');
        this.dictionary.get('pt-BR').set('WALK_IN_CUSTOMER', 'Cliente passante');

        this.dictionary.get('en-IN').set('WAIT_IN_OUTLET', 'Wait in outlet');
        this.dictionary.get('it-IT').set('WAIT_IN_OUTLET', 'Attendo la riconsegna presso il Centro Service');
        this.dictionary.get('es-ES').set('WAIT_IN_OUTLET', 'Esperar en la instalación');
        this.dictionary.get('pt-PT').set('WAIT_IN_OUTLET', 'Esperar no Ponto de Serviço Autorizado');
        this.dictionary.get('es-MX').set('WAIT_IN_OUTLET', 'Esperar en la instalación');
        this.dictionary.get('pt-BR').set('WAIT_IN_OUTLET', 'Aguardar na Concessionária');

        this.dictionary.get('en-IN').set('COURTESY_CAR', 'Courtesy Car');
        this.dictionary.get('it-IT').set('COURTESY_CAR', 'Veicolo di cortesia');
        this.dictionary.get('es-ES').set('COURTESY_CAR', 'Vehículo de sustitución');
        this.dictionary.get('pt-PT').set('COURTESY_CAR', 'Veículo de cortesia');
        this.dictionary.get('es-MX').set('COURTESY_CAR', 'Vehículo de sustitución');
        this.dictionary.get('pt-BR').set('COURTESY_CAR', 'Veículo de cortesia');

        this.dictionary.get('en-IN').set('COURTESY_MOTO', 'Courtesy Moto');
        this.dictionary.get('it-IT').set('COURTESY_MOTO', 'Moto di cortesia');
        this.dictionary.get('es-ES').set('COURTESY_MOTO', 'Moto de sustitución');
        this.dictionary.get('pt-PT').set('COURTESY_MOTO', 'Moto de cortesia');
        this.dictionary.get('es-MX').set('COURTESY_MOTO', 'Moto de sustitución');
        this.dictionary.get('pt-BR').set('COURTESY_MOTO', 'Motocicleta de cortesia');


        this.dictionary.get('en-IN').set('FAST_APPOINTMENT', 'Fast appointment');
        this.dictionary.get('it-IT').set('FAST_APPOINTMENT', 'Appuntamento rapido');
        this.dictionary.get('es-ES').set('FAST_APPOINTMENT', 'Cita rápida');
        this.dictionary.get('pt-PT').set('FAST_APPOINTMENT', 'Marcação Rápida');
        this.dictionary.get('es-MX').set('FAST_APPOINTMENT', 'Cita rápida');
        this.dictionary.get('pt-BR').set('FAST_APPOINTMENT', 'Agendamento Rápido');

        this.dictionary.get('en-IN').set('MOBILE', 'Mobile');
        this.dictionary.get('it-IT').set('MOBILE', 'Mobile');
        this.dictionary.get('es-ES').set('MOBILE', 'Móvil');
        this.dictionary.get('pt-PT').set('MOBILE', 'ConnectedDrive');
        this.dictionary.get('es-MX').set('MOBILE', 'Mobile');
        this.dictionary.get('pt-BR').set('MOBILE', 'ConnectedDrive');


        this.dictionary.get('en-IN').set('REMOTE_KEY_READ', 'RKR');
        this.dictionary.get('it-IT').set('REMOTE_KEY_READ', 'RKR');
        this.dictionary.get('es-ES').set('REMOTE_KEY_READ', 'RKR');
        this.dictionary.get('pt-PT').set('REMOTE_KEY_READ', 'RKR');
        this.dictionary.get('es-MX').set('REMOTE_KEY_READ', 'RKR');
        this.dictionary.get('pt-BR').set('REMOTE_KEY_READ', 'RKR');

        this.dictionary.get('en-IN').set('MOBILITY_VALIDATED', 'Mobility Validated');
        this.dictionary.get('it-IT').set('MOBILITY_VALIDATED', 'Mobilità Validata');
        this.dictionary.get('es-ES').set('MOBILITY_VALIDATED', 'Movilidad validada');
        this.dictionary.get('pt-PT').set('MOBILITY_VALIDATED', 'Mobility Validated');
        this.dictionary.get('es-MX').set('MOBILITY_VALIDATED', 'Movilidad validada');
        this.dictionary.get('pt-BR').set('MOBILITY_VALIDATED', 'Mobilidade Validada');

        this.dictionary.get('en-IN').set('MANAGE_VIEW', 'Manage View');
        this.dictionary.get('it-IT').set('MANAGE_VIEW', 'Gestisci vista');
        this.dictionary.get('es-ES').set('MANAGE_VIEW', 'Gestionar la vista');
        this.dictionary.get('pt-PT').set('MANAGE_VIEW', 'Ajustar vista');
        this.dictionary.get('es-MX').set('MANAGE_VIEW', 'Gestionar la vista');
        this.dictionary.get('pt-BR').set('MANAGE_VIEW', 'Ajustar vista');

        //CALENDAR MANAGE VIEW OPTIONS
        this.dictionary.get('en-IN').set('SERVICES', 'Services');
        this.dictionary.get('it-IT').set('SERVICES', 'Manutenzione e Riparazione');
        this.dictionary.get('es-ES').set('SERVICES', 'Servicios');
        this.dictionary.get('pt-PT').set('SERVICES', 'Serviços');
        this.dictionary.get('es-MX').set('SERVICES', 'Servicios seleccionados');
        this.dictionary.get('pt-BR').set('SERVICES', 'Serviços');

        this.dictionary.get('en-IN').set('MOBILITY_SERVICES', 'Mobility Services');
        this.dictionary.get('it-IT').set('MOBILITY_SERVICES', 'Servizi di mobilità');
        this.dictionary.get('es-ES').set('MOBILITY_SERVICES', 'Servicios de movilidad');
        this.dictionary.get('pt-PT').set('MOBILITY_SERVICES', 'Serviços de mobilidade');
        this.dictionary.get('es-MX').set('MOBILITY_SERVICES', 'Servicios de movilidad');
        this.dictionary.get('pt-BR').set('MOBILITY_SERVICES', 'Serviços de mobilidade');

        this.dictionary.get('en-IN').set('EXTRA', 'Info');
        this.dictionary.get('it-IT').set('EXTRA', 'Extra');
        this.dictionary.get('es-ES').set('EXTRA', 'Información');
        this.dictionary.get('pt-PT').set('EXTRA', 'Informação');
        this.dictionary.get('es-MX').set('EXTRA', 'Información');
        this.dictionary.get('pt-BR').set('EXTRA', 'Informação');

        this.dictionary.get('en-IN').set('FRUS', 'FRUs');
        this.dictionary.get('it-IT').set('FRUS', 'UL');
        this.dictionary.get('es-ES').set('FRUS', 'UTs');
        this.dictionary.get('pt-PT').set('FRUS', 'UTs');
        this.dictionary.get('es-MX').set('FRUS', 'UTs');
        this.dictionary.get('pt-BR').set('FRUS', 'UTs');

        //service Advisors
        this.dictionary.get('en-IN').set('SERVICE_ADVISORS', 'Service Advisors');
        this.dictionary.get('it-IT').set('SERVICE_ADVISORS', 'Consulenti');
        this.dictionary.get('es-ES').set('SERVICE_ADVISORS', 'Asesores de servicio');
        this.dictionary.get('pt-PT').set('SERVICE_ADVISORS', 'Consultores de serviço');
        this.dictionary.get('es-MX').set('SERVICE_ADVISORS', 'Asesores de servicio');
        this.dictionary.get('pt-BR').set('SERVICE_ADVISORS', 'Consultores de serviço');

        this.dictionary
            .get('en-IN')
            .set('GCDM_CHANGE_ACTIVATION', 'Activation successful. Please login with your new credentials.');
        this.dictionary
            .get('it-IT')
            .set(
                'GCDM_CHANGE_ACTIVATION',
                'Attivazione finalizzata correttamente. Per favore, effettua un login con le nuove credenziali.'
            );
        this.dictionary
            .get('es-ES')
            .set('GCDM_CHANGE_ACTIVATION', 'Activación correcta. Por favor inicia sesión con tus credenciales.');
        this.dictionary
            .get('pt-PT')
            .set('GCDM_CHANGE_ACTIVATION', 'Ativação correta. Por favor, faça login com as novas credenciais.');
        this.dictionary
            .get('es-MX')
            .set('GCDM_CHANGE_ACTIVATION', 'Activación correcta. Por favor inicia sesión con tus credenciales.');
        this.dictionary
            .get('pt-BR')
            .set('GCDM_CHANGE_ACTIVATION', 'Ativação correta. Por favor, faça login com as novas credenciais.');

       this.dictionary.get('en-IN').set('ROLE_GROUP_MANAGER', 'Group Manager');
       this.dictionary.get('es-ES').set('ROLE_GROUP_MANAGER', 'Responsable de grupo');
       this.dictionary.get('es-MX').set('ROLE_GROUP_MANAGER', 'Responsable de grupo');
       this.dictionary.get('pt-PT').set('ROLE_GROUP_MANAGER', 'Gestor de utilizadores');
       this.dictionary.get('pt-BR').set('ROLE_GROUP_MANAGER', 'Gerente de grupo');
       this.dictionary.get('it-IT').set('ROLE_GROUP_MANAGER', 'Group Manager');


        this.dictionary.get('it-IT').set('in', 'consegna');
        this.dictionary.get('it-IT').set('out', 'ritiro');
        this.dictionary.get('it-IT').set('in+out', 'consegna+ritiro');
        this.dictionary.get('it-IT').set('dropin', 'Consegna spontenea');
        this.dictionary.get('it-IT').set('checkin', 'Consegna senza appuntamento');
        this.dictionary.get('it-IT').set('out+in', 'ritiro+consegna');
        this.dictionary.get('it-IT').set('multi-in', 'multi+in');

        this.dictionary.get('it-IT').set('red', 'Rosso');
        this.dictionary.get('it-IT').set('green', 'Verde');
        this.dictionary.get('it-IT').set('white', 'Bianco');

        this.dictionary.get('it-IT').set('Diagnosys', 'Diagnosi');
        this.dictionary.get('it-IT').set('BodyAndPaint', 'Carrozzeria');
        this.dictionary.get('it-IT').set('Maintenance', 'Manutenzione');
        this.dictionary.get('it-IT').set('Tires', 'Pneumatici');
        this.dictionary.get('it-IT').set('Other', 'Altro');

        this.dictionary.get('en-IN').set('OUTLET_NOT_FOUND_TITLE', 'Outlet not found');
        this.dictionary.get('it-IT').set('OUTLET_NOT_FOUND_TITLE', 'Sede non trovata');
        this.dictionary.get('es-ES').set('OUTLET_NOT_FOUND_TITLE', 'Taller no disponivel');
        this.dictionary.get('pt-PT').set('OUTLET_NOT_FOUND_TITLE', 'Ponto de serviço não válido"');
        this.dictionary.get('es-MX').set('OUTLET_NOT_FOUND_TITLE', 'Taller no disponivel');
        this.dictionary.get('pt-BR').set('OUTLET_NOT_FOUND_TITLE', 'Ponto de serviço não válido"');

        this.dictionary.get('en-IN').set('OUTLET_NOT_FOUND_TEXT', 'Please select the outlet manually');
        this.dictionary.get('it-IT').set('OUTLET_NOT_FOUND_TEXT', 'Per favore, selezionare la sede manualmente');
        this.dictionary.get('es-ES').set('OUTLET_NOT_FOUND_TEXT', 'Por favor, selecciona el taller manualmente');
        this.dictionary.get('pt-PT').set('OUTLET_NOT_FOUND_TEXT', 'Por favor selecione o ponto de serviço manualmente');
        this.dictionary.get('es-MX').set('OUTLET_NOT_FOUND_TEXT', 'Por favor, selecciona el taller manualmente');
        this.dictionary.get('pt-BR').set('OUTLET_NOT_FOUND_TEXT', 'Por favor selecione o ponto de serviço manualmente');

        this.dictionary.get('en-IN').set('OTHER_EGR', 'Complimentary check of EGR radiator');
        this.dictionary.get('it-IT').set('OTHER_EGR', 'Controllo gratuito tenuta radiatore EGR');
        this.dictionary.get('es-ES').set('OTHER_EGR', 'Chequeo gratuito del radiador EGR');
        this.dictionary.get('pt-PT').set('OTHER_EGR', 'Verificação gratuita do radiador EGR');
        this.dictionary.get('es-MX').set('OTHER_EGR', 'Chequeo gratuito del radiador EGR');
        this.dictionary.get('pt-BR').set('OTHER_EGR', 'Ispeção gratuita do radiador EGR');

        this.dictionary.get('en-IN').set('ITV', 'Inspección técnica (estipulada por ley)');

        this.dictionary.get('es-ES').set('escalatedTimeoutContacting', 'LEAD ESCALADO CONTACTANDO');
        this.dictionary.get('es-ES').set('escalatedTimeoutPostponed', 'LEAD ESCALADO POSTPUESTO');
        this.dictionary.get('es-ES').set('escalatedTimeoutAssigned', 'LEAD ESCALADO ASIGNADO');
        this.dictionary.get('es-ES').set('assignedToTsp', 'LEAD SIN GESTIONAR');
        this.dictionary.get('es-ES').set('escalated', 'LEAD ESCALADO');
        this.dictionary.get('es-ES').set('contacting', 'CONTACTANDO LEAD');
        this.dictionary.get('es-ES').set('postponed', 'LEAD POSPUESTO');



        this.dictionary.get('es-MX').set('escalatedTimeoutContacting', 'LEAD ESCALADO CONTACTANDO');
        this.dictionary.get('es-MX').set('escalatedTimeoutPostponed', 'LEAD ESCALADO POSTPUESTO');
        this.dictionary.get('es-MX').set('escalatedTimeoutAssigned', 'LEAD ESCALADO ASIGNADO');
        this.dictionary.get('es-MX').set('assignedToTsp', 'LEAD SIN GESTIONAR');
        this.dictionary.get('es-MX').set('escalated', 'LEAD ESCALADO');
        this.dictionary.get('es-MX').set('contacting', 'CONTACTANDO');
        this.dictionary.get('es-MX').set('postponed', 'POSPUESTO');

        this.dictionary.get('pt-BR').set('escalatedTimeoutContacting', 'LEAD ESCALADO EM CONTACTO');
        this.dictionary.get('pt-BR').set('escalatedTimeoutPostponed', 'LEAD ESCALADO ADIADO');
        this.dictionary.get('pt-BR').set('escalatedTimeoutAssigned', 'LEAD ESCALADO ATRIBUÍDO');
        this.dictionary.get('pt-BR').set('assignedToTsp', 'TICKET NOVO');
        this.dictionary.get('pt-BR').set('escalated', 'Adiamento vencido');
        this.dictionary.get('pt-BR').set('contacting', 'PROCESSANDO');
        this.dictionary.get('pt-BR').set('postponed', 'ADIADO');

        this.dictionary.get('pt-PT').set('escalatedTimeoutContacting', 'LEAD ESCALADO EM CONTACTO');
        this.dictionary.get('pt-PT').set('escalatedTimeoutPostponed', 'LEAD ESCALADO ADIADO');
        this.dictionary.get('pt-PT').set('escalatedTimeoutAssigned', 'LEAD ESCALADO ATRIBUÍDO');
        this.dictionary.get('pt-PT').set('assignedToTsp', 'Assignado a TSP');
        this.dictionary.get('pt-PT').set('escalated', 'LEAD ESCALADO');
        this.dictionary.get('pt-PT').set('contacting', 'Em contacto');
        this.dictionary.get('pt-PT').set('postponed', 'Adiado');


        this.dictionary.get('en-IN').set('escalatedTimeoutContacting', 'Escalated Timeout Contacting');
        this.dictionary.get('en-IN').set('escalatedTimeoutPostponed', 'Escalated Timeout Postponed');
        this.dictionary.get('en-IN').set('escalatedTimeoutAssigned', 'Escalated Timeout Assigned');
        this.dictionary.get('en-IN').set('assignedToTsp', 'Assigned to Tsp');
        this.dictionary.get('en-IN').set('escalated', 'Escalated');
        this.dictionary.get('en-IN').set('contacting', 'Contacting');
        this.dictionary.get('en-IN').set('postponed', 'Postponed');

        this.dictionary.get('pt-BR').set('other', 'outro');
        this.dictionary.get('pt-PT').set('other', 'outro');
        this.dictionary.get('es-ES').set('other', 'otro');
        this.dictionary.get('es-MX').set('other', 'otro');
        this.dictionary.get('en-IN').set('other', 'other');

        this.dictionary.get('pt-BR').set('front-tyre-service', 'Verificar pneus delanteiros');
        this.dictionary.get('pt-PT').set('front-tyre-service', 'Verifique os pneus dianteiros');
        this.dictionary.get('es-ES').set('front-tyre-service', 'Revisión neumáticos delanteros');
        this.dictionary.get('es-MX').set('front-tyre-service', 'Revisión neumáticos delanteros');
        this.dictionary.get('en-IN').set('front-tyre-service', 'Check front tires');
        this.dictionary.get('it-IT').set('front-tyre-service', 'Revisione gomme anteriori');

        this.dictionary.get('pt-BR').set('rear-tyre-service', 'Verificar pneus traseiros');
        this.dictionary.get('pt-PT').set('rear-tyre-service', 'Verifique os pneus traseiros');
        this.dictionary.get('es-ES').set('rear-tyre-service', 'Revisión neumáticos traseros');
        this.dictionary.get('es-MX').set('rear-tyre-service', 'Revisión neumáticos traseros');
        this.dictionary.get('en-IN').set('rear-tyre-service', 'Check rear tires');
        this.dictionary.get('it-IT').set('rear-tyre-service', 'Revisione gomme anteriori');

        this.dictionary.get('pt-BR').set('PrimaryBatteryReplace', 'Substituição da bateria primária');
        this.dictionary.get('pt-PT').set('PrimaryBatteryReplace', 'Substituição da bateria primária');
        this.dictionary.get('es-ES').set('PrimaryBatteryReplace', 'Sustitución batería principal');
        this.dictionary.get('es-MX').set('PrimaryBatteryReplace', 'Sustitución batería principal');
        this.dictionary.get('en-IN').set('PrimaryBatteryReplace', 'Primary Battery Replace');
        this.dictionary.get('it-IT').set('PrimaryBatteryReplace', 'Sostituzione della batteria primaria');

        this.dictionary.get('pt-BR').set('others', 'outros');
        this.dictionary.get('pt-PT').set('others', 'outros');
        this.dictionary.get('es-ES').set('others', 'otros');
        this.dictionary.get('es-MX').set('others', 'otros');
        this.dictionary.get('en-IN').set('others', 'others');

        this.dictionary.get('pt-BR').set('noAvailableTimeSlot', 'Não existe horário disponível para os próximos 3 meses');
        this.dictionary.get('pt-PT').set('noAvailableTimeSlot', 'Não existe horário disponível para os próximos 3 meses');
        this.dictionary.get('es-ES').set('noAvailableTimeSlot', 'No encuentra intervalo de tiempo disponible');
        this.dictionary.get('es-MX').set('noAvailableTimeSlot', 'No encuentra intervalo de tiempo disponible');
        this.dictionary.get('en-IN').set('noAvailableTimeSlot', 'No available timeslots found for the next 3 months');
        this.dictionary.get('it-IT').set('noAvailableTimeSlot', 'Nessun orario disponibile per i prossimi 3 mesi');

        this.dictionary.get('es-ES').set('noAvailableDana', 'Sin disponibilidad a consecuencia de la DANA');

        /* LEAD API OC POPUP */
        this.dictionary.get('en-IN').set('availableNotificationOC', 'Your vehicle has sent a notification that several services are required. ');
        this.dictionary.get('it-IT').set('availableNotificationOC', 'Your vehicle has sent a notification that several services are required. ');
        this.dictionary.get('es-ES').set('availableNotificationOC', 'Su vehículo ha enviada una notificación con el aviso de que ciertos servicios con necesarios. ');
        this.dictionary.get('pt-PT').set('availableNotificationOC', 'Seu veículo enviou uma notificação que varios serviços são necessários.');
        this.dictionary.get('es-MX').set('availableNotificationOC', 'Su vehículo ha enviada una notificación con el aviso de que ciertos servicios con necesarios. ');
        this.dictionary.get('pt-BR').set('availableNotificationOC', 'Seu veículo enviou uma notificação que varios serviços são necessários. ');

        this.dictionary.get('pt-BR').set('availableNotificationOCMoreInfo', 'Mostrar mais informações');
        this.dictionary.get('pt-PT').set('availableNotificationOCMoreInfo', 'Mostrar mais informações');
        this.dictionary.get('es-ES').set('availableNotificationOCMoreInfo', 'Mostrar mas informacion');
        this.dictionary.get('es-MX').set('availableNotificationOCMoreInfo', 'Mostrar mas informacion');
        this.dictionary.get('en-IN').set('availableNotificationOCMoreInfo', 'Show more information');
        this.dictionary.get('it-IT').set('availableNotificationOCMoreInfo', 'Show more information');

        /* HEARTBEAT EXTERNAL SYSTEM */
        this.dictionary.get('pt-PT').set('GCDM-Customer', 'GCDM - Login para cliente online');
        this.dictionary.get('pt-BR').set('GCDM-Customer', 'GCDM - Login para cliente online');
        this.dictionary.get('es-ES').set('GCDM-Customer', 'GCDM - Iniciar sesión como cliente online');
        this.dictionary.get('es-MX').set('GCDM-Customer', 'GCDM - Iniciar sesión como cliente online');
        this.dictionary.get('it-IT').set('GCDM-Customer', 'GCDM - Login per Online customer');
        this.dictionary.get('en-IN').set('GCDM-Customer', 'GCDM - Login Online Customer');

		this.dictionary.get('pt-PT').set('GCDM-Backoffice', 'GCDM - Backoffice gerenciamento marcações online');
        this.dictionary.get('pt-BR').set('GCDM-Backoffice', 'GCDM - Backoffice gerenciamento marcações online');
        this.dictionary.get('es-ES').set('GCDM-Backoffice', 'GCDM - Backoffice gestión de citas en línea');
        this.dictionary.get('es-MX').set('GCDM-Backoffice', 'GCDM - Backoffice gestión de citas en línea');
        this.dictionary.get('it-IT').set('GCDM-Backoffice', 'GCDM - Backoffice gestione appuntamenti online');
        this.dictionary.get('en-IN').set('GCDM-Backoffice', 'GCDM - Backoffice manage online appointment');

        this.dictionary.get('it-IT').set('miniOilSpecialPriceTitle', 'Prezzo Speciale Mini Oil Inclusive');
        this.dictionary.get('it-IT').set('miniOilSpecialPriceText', 'Scegli il vantaggio speciale ed esclusivo che abbiamo riservato a te e alle tua MINI: il pacchetto MINI Oil inclusive 3 anni/40.000 km a partire dal primo utilizzo* a 149€ IVA inclusa fino al 31/12/2024, anziché al prezzo attuale di 210€ IVA inclusa. Desideri aggiungere il servizio?');
        this.dictionary.get('it-IT').set('miniOilSpecialPriceTextGDPR', 'Il trattamento dei Tuoi dati è conforme a quanto previsto dal GDPR (Regolamento UE 2016/679).');
        this.dictionary.get('it-IT').set('miniOilSpecialPriceTextGDPR2', 'Se desideri consultare nuovamente l’informativa, puoi recarti sul sito ');

        this.dictionary.get('it-IT').set('miniOilSpecialPriceTextONLINE', 'Se non desidera ricevere ulteriori comunicazioni acceda al ');

        this.dictionary.get('pt-PT').set('FastLane-WarningText', 'Somente será considerado Serviço Rápido caso não se detecte nenhum problema, que torne impossível a realização do serviço em menos de 120 minutos!');
        this.dictionary.get('pt-BR').set('FastLane-WarningText', 'Somente será considerado Serviço Rápido caso não se identifique nenhum problema que torne impossível a realização do serviço em menos de 120 minutos!');
        this.dictionary.get('es-ES').set('FastLane-WarningText', 'El servicio de Fast Lane solo se podrá realizar si no se detecta algún trabajo adicional durante la recepción del vehículo que impida su devolución en 120 minutos');
        this.dictionary.get('es-MX').set('FastLane-WarningText', 'El servicio de Fast Lane solo se podrá realizar si no se detecta algún trabajo adicional durante la recepción del vehículo que impida su devolución en 120 minutos');
        this.dictionary.get('it-IT').set('FastLane-WarningText', 'Il servizio Fast Lane è utilizzabile per i servizi di manutenzione ordinaria della durata massima stimata di 120 minuti.Eventuali imprevisti o riparazioni aggiuntive che richiedano più tempo saranno gestite dal Centro BMW Service secondo le necessità del caso specifico.');
        this.dictionary.get('en-IN').set('FastLane-WarningText', 'Fast Lane will only be possible if during the service consultation phase is not found an issue that makes impossible to return the car in less than 2 hours.');

        this.dictionary.get('pt-PT').set('shareBoxDataModified', 'Alguns dados foram modificados. Deseja modificar os dados do cliente?');
        this.dictionary.get('pt-BR').set('shareBoxDataModified', 'Alguns dados foram modificados. Deseja modificar os dados do cliente?');
        this.dictionary.get('es-ES').set('shareBoxDataModified', 'Algunos datos han sido cambiados. ¿Quieres modificar los datos del cliente?');
        this.dictionary.get('es-MX').set('shareBoxDataModified', 'Algunos datos han sido cambiados. ¿Quieres modificar los datos del cliente?');
        this.dictionary.get('it-IT').set('shareBoxDataModified', 'Alcuni dati sono stati modificati. Vuoi procedere con la modifica dei dati del cliente?');
        this.dictionary.get('en-IN').set('shareBoxDataModified', 'Some data was modified. Do you want to update your customer data?');

        this.dictionary.get('pt-PT').set('shareBoxDataAdded', 'Alguns informações foram adicionadas. Deseja alterar os dados dos cliente?');
        this.dictionary.get('pt-BR').set('shareBoxDataAdded', 'Alguns informações foram adicionadas. Deseja alterar os dados dos cliente?');
        this.dictionary.get('es-ES').set('shareBoxDataAdded', 'Se ha agregado alguna información. ¿Quieres cambiar los datos del cliente?');
        this.dictionary.get('es-MX').set('shareBoxDataAdded', 'Se ha agregado alguna información. ¿Quieres cambiar los datos del cliente?');
        this.dictionary.get('it-IT').set('shareBoxDataAdded', 'Alcune informazioni sono state aggiunte. Vuoi modificare i dati del cliente?');
        this.dictionary.get('en-IN').set('shareBoxDataAdded', 'Some data was added. Do you want to update your customer data?');

        this.dictionary.get('pt-PT').set('shareBoxDataMissing', 'Não é possível sincronizar os datos. Por favor, preencha');
        this.dictionary.get('pt-BR').set('shareBoxDataMissing', 'Não é possível sincronizar os datos. Por favor, preencha');
        this.dictionary.get('es-ES').set('shareBoxDataMissing', 'No se pueden sincronizar datos. Por favor agregue los datos que faltan');
        this.dictionary.get('es-MX').set('shareBoxDataMissing', 'No se pueden sincronizar datos. Por favor agregue los datos que faltan');
        this.dictionary.get('it-IT').set('shareBoxDataMissing', 'Non è possibile sincronizzare i dati mancante. Per favore, aggiungi i dati manca');
        this.dictionary.get('en-IN').set('shareBoxDataMissing', 'Cannot synchronize data missing. Please add missing data information');

        this.dictionary.get('pt-PT').set('NO_AVAILABLE_RESERVATIONS', 'Todos as caixas são ocupadas');
        this.dictionary.get('pt-BR').set('NO_AVAILABLE_RESERVATIONS', 'Todos as caixas são ocupadas');
        this.dictionary.get('es-ES').set('NO_AVAILABLE_RESERVATIONS', 'Todos las caijas son reservadas');
        this.dictionary.get('es-MX').set('NO_AVAILABLE_RESERVATIONS', 'Todos las caijas son reservadas');
        this.dictionary.get('it-IT').set('NO_AVAILABLE_RESERVATIONS', 'Nessun box libero rimasto');
        this.dictionary.get('en-IN').set('NO_AVAILABLE_RESERVATIONS', 'All boxes are reserved');

        this.dictionary.get('pt-PT').set('MISSING_DATA', 'Faltam informações para completar a operação');
        this.dictionary.get('pt-BR').set('MISSING_DATA', 'Faltam informações para completar a operação');
        this.dictionary.get('es-ES').set('MISSING_DATA', 'Falta información para completar la operación');
        this.dictionary.get('es-MX').set('MISSING_DATA', 'Falta información para completar la operación');
        this.dictionary.get('it-IT').set('MISSING_DATA', 'Non è possibile procedere per mancanza di informazioni obbligatorie. Per favore, aggiungere i dati mancanti');
        this.dictionary.get('en-IN').set('MISSING_DATA', 'Missing information to complete the operation');

        this.dictionary.get('pt-PT').set('NO_RESERVATIONS', 'Nehuma caixa está configurada para este ponto de serviço');
        this.dictionary.get('pt-BR').set('NO_RESERVATIONS', 'Nehuma caixa está configurada para este ponto de serviço');
        this.dictionary.get('es-ES').set('NO_RESERVATIONS', 'No hay cajas configuradas para este establecimiento');
        this.dictionary.get('es-MX').set('NO_RESERVATIONS', 'No hay cajas configuradas para este establecimiento');
        this.dictionary.get('it-IT').set('NO_RESERVATIONS', 'Nessun box configurato per questo outlet');
        this.dictionary.get('en-IN').set('NO_RESERVATIONS', 'No boxes are configured for the outlet');

        this.dictionary.get('pt-PT').set('UNAUTHORIZED_OPERATION', 'Não autorizado para completar esta operação');
        this.dictionary.get('pt-BR').set('UNAUTHORIZED_OPERATION', 'Não autorizado para completar esta operação');
        this.dictionary.get('es-ES').set('UNAUTHORIZED_OPERATION', 'No estás autorizado a completar esta operación');
        this.dictionary.get('es-MX').set('UNAUTHORIZED_OPERATION', 'No estás autorizado a completar esta operación');
        this.dictionary.get('it-IT').set('UNAUTHORIZED_OPERATION', 'Non sei autorizzato a completare questa operazione');
        this.dictionary.get('en-IN').set('UNAUTHORIZED_OPERATION', 'Not Authorized to complete this operation');

        this.dictionary.get('pt-PT').set('mobilityToValidate', 'Serviço Mobilidade a validar');
        this.dictionary.get('pt-BR').set('mobilityToValidate', 'Serviço Mobilidade a validar');
        this.dictionary.get('es-ES').set('mobilityToValidate', 'Movilidad a validar');
        this.dictionary.get('es-MX').set('mobilityToValidate', 'Movilidad a validar');
        this.dictionary.get('it-IT').set('mobilityToValidate', 'Servizio Mobilità da validare');
        this.dictionary.get('en-IN').set('mobilityToValidate', 'Mobility To Validate');

        this.dictionary.get('pt-PT').set('DROPOFF', 'Dropoff');
        this.dictionary.get('pt-BR').set('DROPOFF', 'Dropoff');
        this.dictionary.get('es-ES').set('DROPOFF', 'Dropoff');
        this.dictionary.get('es-MX').set('DROPOFF', 'Dropoff');
        this.dictionary.get('it-IT').set('DROPOFF', 'Self Check In');
        this.dictionary.get('en-IN').set('DROPOFF', 'Dropoff');

        this.dictionary.get('pt-PT').set('TELESERVICES', 'Teleservices');
        this.dictionary.get('pt-BR').set('TELESERVICES', 'Teleservices');
        this.dictionary.get('es-ES').set('TELESERVICES', 'Teleservices');
        this.dictionary.get('es-MX').set('TELESERVICES', 'Teleservices');
        this.dictionary.get('it-IT').set('TELESERVICES', 'Teleservices');
        this.dictionary.get('en-IN').set('TELESERVICES', 'Teleservices');

        this.dictionary.get('pt-PT').set('PICK_DELIVERY', 'Pick-up / Entrega');
        this.dictionary.get('pt-BR').set('PICK_DELIVERY', 'Pick-up / Entrega');
        this.dictionary.get('es-ES').set('PICK_DELIVERY', 'Recogida / Entrega');
        this.dictionary.get('es-MX').set('PICK_DELIVERY', 'Recogida / Entrega');
        this.dictionary.get('it-IT').set('PICK_DELIVERY', 'Pick-up / Delivery');
        this.dictionary.get('en-IN').set('PICK_DELIVERY', 'Pick-up / Delivery');

        this.dictionary.get('pt-PT').set('SLOT_LOCKED', 'Reservado');
        this.dictionary.get('pt-BR').set('SLOT_LOCKED', 'Reservado');
        this.dictionary.get('es-ES').set('SLOT_LOCKED', 'Reservado');
        this.dictionary.get('es-MX').set('SLOT_LOCKED', 'Reservado');
        this.dictionary.get('it-IT').set('SLOT_LOCKED', 'Riservato');
        this.dictionary.get('en-IN').set('SLOT_LOCKED', 'Reserved');

        this.dictionary.get('pt-PT').set('SLOT_AVAILABLE', 'Disponível');
        this.dictionary.get('pt-BR').set('SLOT_AVAILABLE', 'Disponível');
        this.dictionary.get('es-ES').set('SLOT_AVAILABLE', 'Disponible');
        this.dictionary.get('es-MX').set('SLOT_AVAILABLE', 'Disponible');
        this.dictionary.get('it-IT').set('SLOT_AVAILABLE', 'Disponibile');
        this.dictionary.get('en-IN').set('SLOT_AVAILABLE', 'Available');

    }

    public transform(value: string): string {
        let result = value;
        const key = `${this.language}-${this.country}`;
        if (this.dictionary.get(key) && this.dictionary.get(key).get(value)) {
            result = this.dictionary.get(key).get(value);
        } else if (this.dictionary.get(null).get(value)) {
            result = this.dictionary.get(null).get(value);
        }

        return result;
    }
}
