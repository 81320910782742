import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { KpiService } from './kpi.service';
import { Logger } from '../../logger.service';
import { SearchSettings, TssbHistoricData, TssbDetailData } from '../../../shared/entities';

@Injectable()
export class KpiTssbService extends KpiService {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/oas-as/tssb', http, logger);
    }

    public tssbAssignedToDealerCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/assignedToDealers/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public tssbCancelledCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/cancelled/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public tssbPostonedCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/postponed/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public tssbEscalatedCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/escalated/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public tssbInformationSentCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/informationSent/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public tssbAssignedToTspCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/assignedToTsp/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public tssbContactingCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/contacting/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public tssbEmailFirstCount(searchSettings?: SearchSettings): Promise<number> {
        return this.http
            .post(`${this.actionUrl}/emailFirst/count`, searchSettings)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public tssbDetailData(searchSettings?: SearchSettings): Promise<TssbDetailData[]> {
        return this.http
            .post(`${this.actionUrl}/detailData`, searchSettings)
            .toPromise()
            .then(response => response as TssbDetailData[])
            .catch(error => this.handleError(error));
    }

    public TssbHistoricData(searchSettings?: SearchSettings): Promise<TssbHistoricData[]> {
        return this.http
            .post(`${this.actionUrl}/historic`, searchSettings)
            .toPromise()
            .then(response => response as TssbHistoricData[])
            .catch(error => this.handleError(error));
    }
}
