import { dataAnalytics } from './shared/data-analytics';

export interface TracyData {
    component: {
        componentId: string;
        componentVersion: string;
    };

    page: {
        pageId?: string;
        destinationUrl?: string;
        language: string;
        geoRegion: string;
        brand: string;
        referringUrl?: string;
        sysEnv: string;
        variant?: string;
        currencyCode?: string;
    };

    event: {
        type: string;
        eventAction: string;
        effect: string;
        cause: string;
        timeStamp: string;
        subCategory1: string;
        requestedURL: string;
    };

    product: {
        productID?: string;
        productType?: string;
        productName?: string;
        manufacturer?: string;
        modelCode?: string;
        vehicleSelection?: string;
        yearOfRegistration?: string;
        subCategory1?: string;
    };

    user: {
        profileId?: string;
        language: string;
        returningStatus: string;
        stateProvince: string;
        country: string;
        loggedInStatus?: string;
    };

    dealer: {
        dealerId: string;
        dealerName: string;
        outletID: string;
        selectionState: string;
    };
}

export class SciDataAnalytics {
    public tracyData: TracyData;
    constructor(environment: string) {
        this.tracyData = {
            component: {
                componentId: 'na',
                componentVersion: 'na',
            },

            page: {
                language: 'na',
                geoRegion: 'na',
                brand: 'na',
                sysEnv: environment,
                currencyCode: 'na',
            },

            event: {
                cause: 'na',
                effect: 'na',
                eventAction: 'na',
                requestedURL: 'na',
                subCategory1: 'na',
                timeStamp: dataAnalytics.timeStamp,
                type: 'na',
            },

            dealer: {
                dealerId: 'na',
                dealerName: 'na',
                selectionState: 'na',
                outletID: 'na',
            },

            user: {
                returningStatus: 'na',
                stateProvince: 'na',
                country: 'na',
                language: 'na',
            },

            product: {
                productID: 'na',
                productType: 'na',
                productName: 'na',
                manufacturer: 'na',
                modelCode: 'na',
                vehicleSelection: 'na',
                yearOfRegistration: 'na',
                subCategory1: 'owned',
            },
        };
    }
}
