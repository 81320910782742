import { environment } from "../../environments/environment";
import { Mobility } from "../shared/entities";
import { Utils } from "./utils";

/**
 * A utility class for the mobility class
 */
export class MobilityUtils {

    /**
     * Check if the array of mobilities contains a mobility for pickup and delivery service
     * @param mobilities 
     * @returns a boolean indicating if the mobilities contains the pickup and delivery mobility 
     */
    public static isPickupAndDelivery(mobilities: Mobility[]): boolean {
        return Utils.isNotEmptyArray(mobilities) &&
               Utils.isNotNullOrUndefined(mobilities[0].mobilityType) &&
               mobilities[0].mobilityType.id === environment.pickupServiceId;
    }
}
