import { Injectable } from '@angular/core';
import { TechnicalSettingType } from './entities';

@Injectable()
export class TechnicalSettingConfiguration {
    private technicalSettings = new Map<TechnicalSettingType, string>();

    constructor() {}

    public setTechnicalSetting(type: TechnicalSettingType, value: string): void {
        this.technicalSettings.set(type, value);
    }
    public getTechnicalSetting(type: TechnicalSettingType): string {
        return this.technicalSettings.get(type);
    }
}
