<body>
    <div class="d-flex align-items-center justify-content-center flex-column">
        <br />
        <p style="font-size: 32px;">Regulamento dos Serviços Digitais (RSD), ou
            Digital Services Act (DSA)</p>
            <br />
        <div>
            <p style="text-align: center;">A BMW Portugal
                Lda. (adiante apenas designada por “BMW”, “nós”, “nosso”), enquanto empresa do Grupo BMW, com a respetiva
                “casa-mãe” BMW AG, disponibiliza no presente website serviços intermediários, nos termos estabelecidos no artigo
                3.º alínea g) do Regulamento dos Serviços Digitais (adiante apenas “RSD”), pelo que, aplicam-se as seguintes
                informações:</p>
        </div><br />
    </div>
    <div class="bordered-div"><br />
        <h3>1. Pontos de contacto para a comunicação no
            âmbito do RSD (artigos 11.º e 12.º do RSD)</h3><br />
            <p>O
                nosso ponto de contacto central para as autoridades dos Estados-Membros, Comissão e Conselho de
                Administração referidos no artigo 61.º do RSD (artigo 11.º parágrafo 1 do RSD) e para os destinatários
                dos nossos serviços (artigo 12.º parágrafo 1 do RSD) é:</p>
            <p>BMW Portugal Lda.</p>
            <p>Sede: Lagoas Park, Ed.
                11, 2.º Piso, 2740-270 Porto Salvo</p>
            <p>
                Titular do número único de identificação de pessoa coletiva e de matrícula na Conservatória do Registo
                Comercial de Cascais 506698521.</p>
            <p>E-mail: <a
                    href="mailto:dsa.pt@bmwgroup.com" target="_blank">dsa.pt@bmwgroup.com</a></p>
            <p><br /></p>
            <p>Telefone: 808 200 807 (custo equivalente
                ao de uma chamada local do serviço fixo telefónico). A nossa linha de apoio ao cliente está disponível
                de 2ª a 6ª feira das 9h00 às 18h00.</p>
            <p><br /></p>
            <p>O utilizador pode comunicar connosco nos
                idiomas português ou inglês.</p>
        <p><br /></p>
    </div>
    <div class="bordered-div"><br />
        <h3>2. Relatórios de transparência (artigo 15.º
            do RSD)</h3><br />
        <p>Em
            conformidade com o artigo 15.º parágrafo 1 do RSD, somos obrigados a disponibilizar publicamente, uma
            vez por ano, relatórios de transparência sobre qualquer moderação de conteúdos em que participemos. O
            relatório estará disponível aqui no momento oportuno.</p>
        <p><br /></p>
    </div>
    <div class="bordered-div"><br />
        <h3>3. Mecanismos de notificação e ação (artigo
            16.º do RSD)</h3><br />
            <p>Nos
                termos do artigo 16.º do RSD, as pessoas singulares e coletivas terão a possibilidade de submeter
                notificações sobre informações que considerem ser conteúdos ilegais nas páginas deste website,
                nomeadamente, “Viaturas Disponíveis”, “Usados de Seleção BMW”, “BMW ConnectedDrive Store” e “Marcação de
                Serviços”.</p>
            <p>Para tal, basta enviar uma mensagem de
                    e-mail para <a href="mailto:dsa.pt@bmwgroup.com" class="a"
                    target="_blank">dsa.pt@bmwgroup.com</a><a href="mailto:dsa.pt@bmwgroup.com" class="s1"
                    target="_blank">.</a></p>
            <p>Caso pretenda submeter uma
                notificação deste tipo, inclua os seguintes pontos na sua notificação:</p>
        <ol type="a">
            <li>
                <p>
                    uma explicação suficientemente fundamentada das razões pelas quais se alega que as informações
                    em causa são conteúdos ilegais;</p>
            </li>
            <li>
                <p>
                    uma indicação clara da localização eletrónica exata dessas informações, como o URL ou URLs
                    exatos, ou, se necessário, informações adicionais que permitam a identificação do conteúdo
                    ilegal adaptadas ao tipo de conteúdo e ao tipo específico de serviço;</p>
            </li>
            <li>
                <p>
                    o seu nome e endereço de e-mail (a menos que se trate de informações que considere estarem
                    relacionadas com uma infração penal que envolva abuso sexual, exploração sexual, pornografia
                    infantil, contacto com crianças para fins sexuais ou instigação, auxílio ou cumplicidade ou
                    tentativa de cometer tais infrações).</p>
                <p>
                    Nestes casos ou noutros casos, em que pretenda submeter uma notificação sem possibilidade de
                    identificação, pode contactar para o telefone 808 200 807 (custo equivalente ao de uma chamada
                    local do serviço fixo telefónico). A nossa linha de apoio ao cliente está disponível de 2ª a 6ª
                    feira das 9h00 às 18h00, devendo indicar, desde logo, no início da chamada telefónica, a
                    intenção de submissão de notificação anónima.</p>
            </li>
            <li>
                <p>
                    uma declaração que confirme a sua convicção de boa-fé de que as informações e alegações contidas
                    na notificação são exatas e completas.</p>
                <p>
                    Todas e quaisquer notificações serão tratadas de forma atempada, diligente, não arbitrária e
                    objetiva; notificaremos, sem demora injustificada, a pessoa ou entidade que apresentou a
                    notificação sobre a nossa decisão e indicaremos quaisquer possíveis recursos legais.</p>
                <p><br /></p>
            </li>
        </ol>
    </div>
    <div class="bordered-div"><br />
        <h3>4. Informações sobre
            as restrições de conteúdo em relação à utilização do serviço (artigo 14.º parágrafo 1 frase 3 do RSD),
            sobre o nosso sistema interno de tratamento de reclamações (artigo 20.º do RSD) e sobre as opções de
            resolução extrajudicial de litígios (artigo 21.º do RSD)</h3><br />
        <p>As
            informações abaixo indicadas e relacionadas com o nosso sistema interno de tratamento de reclamações
            (artigo 20.º do RSD) e com as opções de resolução extrajudicial de litígios (artigo 21.º do RSD)
            aplicam-se apenas aos destinatários dos serviços da BMW que são considerados “plataformas online” na
            aceção (de acordo com a alínea i) do art. 3º do RSD, “Plataforma online” é um serviço de alojamento
            virtual que, a pedido de um destinatário do serviço, armazene e difunda informações ao público, a menos
            que essa atividade seja um elemento menor e meramente acessório de outro serviço ou uma funcionalidade
            menor do serviço principal e que, por razões objetivas e técnicas, não possa ser utilizado sem esse
            outro serviço, e que a integração desse elemento ou dessa funcionalidade no outro serviço não constitua
            uma forma de contornar a aplicabilidade do presente regulamento. As plataformas online disponibilizadas
            no presente website são: “Viaturas Disponíveis”, “Usados de Seleção BMW”, “BMW ConnectedDrive Store” e
            “Marcação de Serviços”.</p>
        <p>Podemos tomar determinadas decisões restritivas relativamente ao conteúdo ou às contas dos destinatários
            dos nossos serviços intermediários (incluindo indivíduos e entidades que submetem a notificação) na
            aceção do RSD, se considerarmos que os destinatários violaram a lei ou os nossos termos e condições
            gerais de negócio e utilização para o respetivo serviço intermediário (doravante: “Termos e
            Condições&quot;). Por exemplo, podemos decidir (i) restringir ou bloquear a visibilidade do conteúdo do
            destinatário, (ii) suspender ou terminar a prestação da totalidade ou de parte dos nossos serviços aos
            destinatários, (iii) suspender ou encerrar a conta do destinatário, (iv) restringir as oportunidades de
            rentabilização do conteúdo do destinatário, ou (v) recusar às empresas a utilização dos seus
            Marketplaces online se não conseguirmos identificar (localizar) essas empresas, tal como exigido pelo
            RSD. Também podemos decidir não dar seguimento a uma notificação submetida por um destinatário com base
            em conteúdos potencialmente ilegais ou que violem os nossos Termos e Condições.</p>
        <h5>- Sistema interno
            de tratamento de reclamações:</h5>
        <p>Se os destinatários dos nossos serviços não concordarem com essa decisão, podem apresentar uma
            reclamação contra essa decisão da BMW através do nosso sistema interno de tratamento de reclamações. As reclamações
                podem ser apresentadas sem custos via e-mail enviado para <a
                href="mailto:dsa.pt@bmwgroup.com" class="a" target="_blank">dsa.pt@bmwgroup.com</a>, no
            prazo de 6 meses a contar da receção da decisão contestada. Se necessitarmos de mais informações
            para o processamento da reclamação, os nossos funcionários podem ter de contactar o autor da
            reclamação. As reclamações são tratadas de forma atempada, não discriminatória, diligente e não
            arbitrária, sob a supervisão de pessoal qualificado. Sempre que uma reclamação contiver
            fundamentos suficientes para considerarmos que a nossa decisão de não dar seguimento à
            notificação é infundada ou que as informações a que a reclamação se refere não são ilegais nem
            incompatíveis com os nossos termos e condições, ou contiver informações que indiquem que o
            comportamento do autor da reclamação não justifica a medida tomada, revogaremos a nossa decisão
            sem demora injustificada. Assim que tivermos tomado uma decisão, esta será comunicada ao autor
            da reclamação sem demora injustificada.</p>
        <p><br /></p>
        <h5>- Resolução extrajudicial de litígios perante organismos de resolução extrajudicial de litígios
            autorizados:</h5>
            <p>Para
                resolver litígios relacionados com decisões tomadas no âmbito do nosso sistema interno de tratamento de
                reclamações, existe, entre outras coisas, a possibilidade de procurar uma resolução extrajudicial de
                litígios junto de um “organismo certificado de resolução extrajudicial de litígios”, tal como definido
                no artigo 21.º do RSD. Os organismos de resolução extrajudicial de litígios certificados são organismos
                imparciais e independentes, expressamente certificados pelos Estados-Membros da UE e que estão em
                condições, devido às suas capacidades e competências, de analisar os litígios que lhes são apresentados.
                A BMW cooperará com o organismo de resolução extrajudicial de litígios em conformidade com as
                disposições legais. A BMW não está, contudo, vinculada às decisões do organismo de resolução
                extrajudicial de litígios.</p>
            <p>Serão
                comunicados aos destinatários do serviço mais pormenores sobre qualquer resolução extrajudicial de
                litígios, em alguns casos juntamente com eventuais decisões passíveis de recurso.</p>
            <p>As
                informações precedentes não restringem os direitos dos destinatários dos serviços de fazer valer os seus
                direitos contra a BMW em tribunal.</p>
        <p><br /></p>
    </div>
    <div class="bordered-div"><br />
        <h3>5. Medidas e proteção contra a utilização
            abusiva (artigo 23.º do RSD)</h3><br />
        <p>Suspenderemos a prestação dos nossos serviços aos destinatários do serviço que forneçam frequentemente
            conteúdos manifestamente ilegais, e fá-lo-emos durante um período de tempo razoável após aviso prévio.
            Além disso, suspenderemos, durante um período de tempo razoável após a emissão de um aviso prévio, o
            tratamento de notificações e reclamações apresentadas através dos mecanismos de notificação e ação ou
            dos sistemas internos de tratamento de reclamações por indivíduos ou entidades ou por autores das
            reclamações que apresentem frequentemente notificações e reclamações manifestamente infundadas. Ao
            decidirmos a suspensão do serviço, avaliaremos, caso a caso e de forma atempada, diligente e objetiva,
            se o destinatário, indivíduo, entidade ou autor da reclamação se envolveu numa utilização abusiva, tendo
            em conta todos os factos e circunstâncias relevantes que se depreendem das informações de que dispomos.
            Tais circunstâncias - que serão tidas em conta para determinar se houve utilização abusiva e qual a
            duração adequada de qualquer suspensão - incluem, pelo menos, o seguinte:</p>
        <ol type="a">
            <li>
                <p>o
                número absoluto de elementos de conteúdo manifestamente ilegal que foram apresentados num
                determinado período de tempo;</p></li>
            <li>
                <p>
                    a proporção relativa do número total de elementos de informação fornecidos ou de
                    notificações apresentadas num determinado prazo;</p>
            </li>
            <li>
                <p>
                    a gravidade das utilizações abusivas, incluindo a natureza do conteúdo ilegal e a
                    natureza das suas consequências;</p>
            </li>
            <li>
                <p>
                    sempre que seja possível identificar, as intenções prosseguidas pelo destinatário do
                    serviço, o indivíduo, a entidade ou o autor da reclamação.</p>
            </li>
            <p><br /></p>
        </ol>
    </div>
    <div class="bordered-div"><br />
        <h3>6. Obtenção de autocertificações
        </h3><br />
        <p>A BMW
            realizará todos os esforços para obter, até 16 de fevereiro de 2025, por parte de todos os comerciantes que
            ofereçam produtos e/ou serviços nas plataformas online, a sua autocertificação nos termos do artigo 30.º
            parágrafo 1 e) do RSD, nos termos do qual esses comerciantes se comprometem a oferecer apenas produtos e
            serviços que cumpram as regras aplicáveis do direito da União.</p>
    </div>

    <div class="bordered-div"><br />
        <h3>7. Informações de Mercado</h3><br />
        <p class="italic-text">
            De acordo com o artigo 24.º, n.º 2 da Lei relativa aos Serviços Digitais, o número médio mensal de 
            utilizadores ativos na Plataforma de marcações online na União Europeia é inferior a 3.000. Para 
            determinar este valor, avaliámos estatisticamente os utilizadores ativos mensais dos últimos seis meses, 
            tendo em consideração os requisitos técnicos e de proteção de dados, e a partir daí calculámos a média 
            mensal a partir de 17/04/2024.
        </p>
    </div>
    
</body>