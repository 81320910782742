import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventNotification, EventNotificationDTO, EventBackupNotificationSearchDto } from '../../shared/entities';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class EventNotificationService {
  private actionUrl: string;

  constructor(protected http: HttpClient) {
    this.actionUrl = `${environment.apiUrl}/event-notification`;
  }

  /**
   * Retreive a list of notification based on given event type in the arguments
   * @param eventNotificationSearch the event type
   * @returns an observable containing the list of notifications
   */
  public list(eventNotificationSearch: EventBackupNotificationSearchDto): Observable<Array<EventNotificationDTO>> {
    return this.http
        .post<Array<EventNotificationDTO>>(`${this.actionUrl}/search`, eventNotificationSearch);
  }

  /**
   * Create a new email notification
   * @param eventNotificationDto the body that contains the details of the new notification
   * @returns an observable of the created notification
   */
  public create(eventNotificationDto: EventNotification): Observable<EventNotificationDTO> {
    return this.http
        .post<EventNotificationDTO>(`${this.actionUrl}`, eventNotificationDto);
  }

  update(eventNotification: EventNotification) {
    return this.http
        .put<EventNotificationDTO>(`${this.actionUrl}`, eventNotification);
  }

  /**
   * Delete an event notification by th egiven id
   * @param id The id of the event notification to delete
   * @returns 
   */
  public delete(id: number): Observable<void> {
    return this.http
        .delete<void>(`${this.actionUrl}/${id}`);
  }

}
