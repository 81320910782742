import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './../../auth.service';
import { Utils } from '../../../core/utils';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'oas-activate-user',
    templateUrl: './activate-user.component.html',
})
export class ActivateUserComponent implements OnInit {
    constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService) {}

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            const token = params['token'];
            this.authService
                .activateUser(token)
                .then(() => this.router.navigate(['/welcome']))
                .catch((error: HttpErrorResponse) => {
                    this.router.navigate(['/welcome']);
                    Utils.showErrorPopup(error);
                });
        });
    }
}
