import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TechnicalSettingDTO, TechnicalSettingType } from '../../shared/entities';
import { Logger } from '../logger.service';
import { AbstractRestService } from './generic.service';

@Injectable()
export class TechnicalSettingService extends AbstractRestService<TechnicalSettingDTO, TechnicalSettingType> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/technical-setting', http, logger);
    }

    public async findByName(name: string): Promise<TechnicalSettingDTO> {
        const url = `${this.actionUrl}/findByName/${name}`;

        try {
            const response = await this.http.get(url).toPromise();
            return response as TechnicalSettingDTO;
        } catch (error) {
            return await this.handleError(error);
        }
    }


}
