import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { VehicleModelService } from '../../core/api/vehicle/vehicle-model.service';
import { OasValidators } from '../../core/validator/oas-validators';
import { StyleSwitcherService } from '../../core/style-switcher.service';
import { Vehicle, VehicleBrand, VehicleModel } from '../../shared/entities';
import { Utils } from '../../core/utils';
import { dataAnalytics } from '../../shared/data-analytics';
import { TracyService } from '../../shared/tracy.service';

@Component({
    selector: 'oas-vehicle-form-manual',
    templateUrl: './vehicle-form-manual.component.html',
    styleUrls: ['./vehicle-form-manual.component.scss'],
})
export class VehicleFormManualComponent implements OnInit {
    @Input() public plate: string;
    @Input() public kilometers: number;
    @Input() public hideBackButton: boolean;
    public vehicleForm: UntypedFormGroup;
    public submitted: boolean;
    public vehicleModels: VehicleModel[];

    private selectedVehicleModel: VehicleModel;

    @Output() public vehicleEmitter = new EventEmitter<Vehicle>();
    @Output() public backButtonClicked = new EventEmitter<void>();
    @Input() public remoteKeyRead: boolean;

    constructor(
        private vehicleModelService: VehicleModelService,
        private formBuilder: UntypedFormBuilder,
        private styleSwitcherService: StyleSwitcherService,
        private tracyService: TracyService
    ) {
        this.setDataAnalytics();
    }

    ngOnInit(): void {
        this.retrieveVehicleModels();
        this.vehicleForm = this.formBuilder.group({
            plate: [
                { value: '', disabled: !Utils.isNullOrUndefined(this.plate) },
                [Validators.required, OasValidators.notNullValue],
            ],
            mileage: [
                !Utils.isNullOrUndefined(this.kilometers) ? this.kilometers : '',
                [Validators.required, OasValidators.notNullValue],
            ],
            model: ['', [Validators.required, OasValidators.notNullValue]],
        });
    }

    private retrieveVehicleModels(): void {
        const vehicleBrands = new Set<VehicleBrand>();
        vehicleBrands.add(this.styleSwitcherService.brand.getValue().toUpperCase() as VehicleBrand);

        if (vehicleBrands.has(<VehicleBrand>'BMW')) {
            vehicleBrands.add(<VehicleBrand>'BMW_I');
        }
        this.vehicleModelService.getAllByVehicleBrands(vehicleBrands).then(vehicles => {
            this.vehicleModels = vehicles;
        });
    }

    public onSelectModel(vehicleModel: VehicleModel): void {
        this.selectedVehicleModel = vehicleModel;
    }

    public isPlateFormDisabled(): boolean {
        return this.vehicleForm.controls.plate.disabled;
    }

    public onContinueButtonClicked() {
        this.submitted = true;

        if (this.vehicleForm.valid && !Utils.isNullOrUndefined(this.selectedVehicleModel)) {
            const vehicle: Vehicle = {
                plate: !this.isPlateFormDisabled() ? this.vehicleForm.controls.plate.value.toUpperCase() : this.plate,
                model: this.selectedVehicleModel,
                mileage: this.vehicleForm.controls['mileage'].value,
            };

            this.vehicleEmitter.emit(vehicle);
        }
    }

    public onBackButtonClicked() {
        this.backButtonClicked.next();
    }
    private setDataAnalytics() {

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasGuestVehicleLicenseeModel,
            dataAnalytics.page.variant.real
        );
        this.tracyService.setUserInformationIntoDataAnalytics('na');

        this.tracyService.sendPageView(dataAnalytics.referenceName.guestVehicleModel);
    }
}
