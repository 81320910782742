import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { moment } from 'ngx-bootstrap/chronos/test/chain';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { OutletAbsence } from '../../../backoffice/shared/outlet/outlet-absences/outlet-absence.model';
import { OutletEventResource } from '../../../backoffice/shared/outlet/outlet-event-form/outlet-event.model';
import { ResourceCalendar } from '../../../backoffice/shared/outlet/outlet-schedule/resource-calendar.model';
import { TimeslotAppointment } from '../../../backoffice/shared/outlet/outlet-schedule/TimeslotAppointment.model';
import { OutletShift } from '../../../backoffice/shared/outlet/outlet-shifts/outlet-shift.model';
import { ResourceType } from '../../../backoffice/shared/outlet/outlet-shifts/resource-type.enum';
import { Utils } from '../../../core/utils';
import { AbstractRestService } from '../generic.service';
import { AuthService } from './../../../auth/auth.service';
import {
    Activity,
    ActivityCategory,
    AvailabilitySlot,
    BackOfficeNotificationDTO,
    BaseResponse,
    BasicResponse,
    BrandedTeamCapacityBean,
    CCQuotaCalendar,
    CheckQuotaRequest,
    Country,
    HATEOASResponse,
    Outlet,
    OutletMobilityType,
    OutletSkills,
    Page,
    SearchSettings,
    SearchSlotBean,
    SetCourtesyVehicleQuotaResponse,
    SetWorkTeamQuotaRequest,
    SetWorkTeamQuotaItem,
    Slot,
    Vehicle,
    VehicleBrand,
    SetMechanicQuotaWithBrandRequest,
    Appointment,
    OutletNextAvailableSlot,
} from './../../../shared/entities';
import { Logger } from './../../logger.service';
@Injectable({
    providedIn: 'root'
})
export class OutletService extends AbstractRestService<Outlet, string> {
    public selectedOutlet: Outlet;
    constructor(protected http: HttpClient, protected logger: Logger, private authService: AuthService) {
        super('/outlet', http, logger);
    }

    public getByOutletId(outletId: string): Promise<Outlet> {
        const url = `${this.actionUrl}/${outletId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Outlet)
            .catch(error => this.handleError(error));
    }

    public getNextFreeSlot(searchSlotBean: SearchSlotBean): Promise<BaseResponse<Date>> {
        const url = `${this.actionUrl}/next-free-slot/` + this.authService.getSelectedRole();

        const nextAvailableSlotDTO = {
            serviceAdvisorLogin: searchSlotBean.serviceAdvisorLogin,
            startDate: new Date(),
            appointment: {
                'activityList': searchSlotBean.appointment.activityList,
                'fastLane': searchSlotBean.appointment.fastLane,
                'outlet': {
                    'id': searchSlotBean.appointment.outlet.id,
                    'capacityGroupingType': searchSlotBean.appointment.outlet.capacityGroupingType,
                    'useOnlineQuota': searchSlotBean.appointment.outlet.useOnlineQuota,
                    'switchedToMacrocategory': searchSlotBean.appointment.outlet.switchedToMacrocategory,
                    'timeZone': searchSlotBean.appointment.outlet.timeZone,
                    'workingDays': searchSlotBean.appointment.outlet.workingDays,
                    'cicNscDelayEnabled': searchSlotBean.appointment.outlet.cicNscDelayEnabled,
                    'cicEpDelayEnabled': searchSlotBean.appointment.outlet.cicEpDelayEnabled,
                    'country': {
                        'code': searchSlotBean.appointment.outlet.country.code
                    }
                },
                'vehicleVin': searchSlotBean.appointment.vehicleVin,
                'vehicleBrand': searchSlotBean.appointment.vehicleBrand,
                'vehicle': {
                    'model': {
                        'brand': searchSlotBean.appointment.vehicle.model.brand
                    }
                }
            },
            checkOnline: searchSlotBean.checkOnline,
            fastLaneSlotDuration: searchSlotBean.fastLaneSlotDuration,
        };

        return this.http
            .post(url, nextAvailableSlotDTO)
            .toPromise()
            .then(response => {
                return response as BaseResponse<Date>;
            })
            .catch(error => this.handleErrorWithPopup(error));
    }

    public getOutletNextFreeSlot(appointment: Appointment, outlet: Outlet): Promise<BaseResponse<OutletNextAvailableSlot>> {
        const url = `${this.actionUrl}/next-free-slot`;

        const nextAvailableSlotDTO = {
            startDate: new Date(),
            appointment: {
                'activityList': appointment.activityList,
                'fastLane': appointment.fastLane,
                'outlet': {
                    'id': outlet.id,
                    'capacityGroupingType': outlet.capacityGroupingType,
                    'useOnlineQuota': outlet.useOnlineQuota,
                    'switchedToMacrocategory': outlet.switchedToMacrocategory,
                    'timeZone': outlet.timeZone,
                    'workingDays': outlet.workingDays,
                    'cicNscDelayEnabled': outlet.cicNscDelayEnabled,
                    'cicEpDelayEnabled': outlet.cicEpDelayEnabled,
                    'country': {
                        'code': outlet.country.code
                    }
                },
                'vehicleVin': appointment.vehicleVin,
                'vehicleBrand': appointment.vehicleBrand,
                'vehicle': {
                    'model': {
                        'brand': appointment.vehicle.model.brand
                    }
                }
            }
        };

        return this.http
            .post(url, nextAvailableSlotDTO)
            .toPromise()
            .then(response => {
                return response as BaseResponse<OutletNextAvailableSlot>;
            })
            .catch(error => error);
    }

    public getFreeSlots(searchSlotBean: SearchSlotBean): Promise<Slot[]> {
        const url = `${this.actionUrl}/free-slot/` + this.authService.getSelectedRole();

        searchSlotBean.appointment = {
            'activityList': searchSlotBean.appointment.activityList,
            'fastLane': searchSlotBean.appointment.fastLane,
            'outlet': {
                'id': searchSlotBean.appointment.outlet.id,
                'capacityGroupingType': searchSlotBean.appointment.outlet.capacityGroupingType,
                'useOnlineQuota': searchSlotBean.appointment.outlet.useOnlineQuota,
                'switchedToMacrocategory': searchSlotBean.appointment.outlet.switchedToMacrocategory,
                'timeZone': searchSlotBean.appointment.outlet.timeZone,
                'workingDays': searchSlotBean.appointment.outlet.workingDays,
                'cicNscDelayEnabled': searchSlotBean.appointment.outlet.cicNscDelayEnabled,
                'cicEpDelayEnabled': searchSlotBean.appointment.outlet.cicEpDelayEnabled,
                'country': {
                    'code': searchSlotBean.appointment.outlet.country.code
                }
            },
            'vehicleVin': searchSlotBean.appointment.vehicleVin,
            'vehicleBrand': searchSlotBean.appointment.vehicleBrand,
            'vehicle': {
                'model': {
                    'brand': searchSlotBean.appointment.vehicle.model.brand
                }
            }
        };

        return this.http
            .post(url, searchSlotBean)
            .toPromise()
            .then(response => {
                return response;
            })
            .then(response => {
                return response as Slot[];
            })
            .catch(error => this.handleError(error));
    }

    public getFreeReturnSlots(searchSlotBean: SearchSlotBean): Promise<Slot[]> {
        const url = `${this.actionUrl}/free-return-slot/` + this.authService.getSelectedRole();
        return this.http
            .post(url, searchSlotBean)
            .toPromise()
            .then(response => {
                return response;
            })
            .then(response => {
                return response as Slot[];
            })
            .catch(error => this.handleError(error));
    }

    public getAllBySkills(activityCategoryIds: string[]): Promise<Outlet[]> {
        const url = `${this.actionUrl}/by-skills`;

        return this.http
            .post(url, activityCategoryIds)
            .toPromise()
            .then(response => response as Outlet[])
            .catch(error => this.handleError(error));
    }

    public getAllByCountryAndVehicleBrands(countryId: string, vehicleBrands: Set<VehicleBrand>): Promise<Outlet[]> {
        const url = `${this.actionUrl}/by-country/${countryId}`;
        let params = new HttpParams();
        params = params.set('vehicleBrands', Array.from(vehicleBrands).join(','));

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as Outlet[])
            .catch(error => this.handleError(error));
    }

    public updateMobilityType(outletId: string, outletMobilityTypeList: OutletMobilityType[]): Promise<void> {
        const url = `${this.actionUrl}/${outletId}/mobility-type`;
        return this.http
            .put(url, outletMobilityTypeList)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    /**
     * @deprecated
     */
    public getQuotaCategory(outletId: string, dates: Date[]): Promise<Slot[]> {
        const url = `${this.actionUrl}/${outletId}/quota-category`;
        return this.http
            .post(url, dates)
            .toPromise()
            .then(response => response as Slot[])
            .catch(error => this.handleError(error));
    }

    public getQuotaTeam(outletId: string, dates: Date[], vehicleBrand?: string): Promise<BrandedTeamCapacityBean[]> {
        let url = `${this.actionUrl}/${outletId}/quota-team`;

        if (vehicleBrand) {
            url = `${url}/by-brand/${vehicleBrand}`;
        }

        return this.http
            .post(url, dates)
            .toPromise()
            .then(response => response as Map<VehicleBrand, BrandedTeamCapacityBean[]>)
            .catch(error => this.handleError(error));
    }

    public setQuota(
        outletId: string,
        brand: VehicleBrand,
        setWorkTeamQuotaItems: SetWorkTeamQuotaItem[],
        workingHours: number,
        year: number,
        months: number[],
        daysOfWeek: number[],
        day: number

    ): Promise<void> {
        const url = `${this.actionUrl}/${outletId}/set-quota`;
        const quotaWithBrandRequest: SetMechanicQuotaWithBrandRequest = {
            brand,
            setWorkTeamQuotaItems: setWorkTeamQuotaItems,
            workingHours,
            year,
            months,
            daysOfWeek,
            day
        }
        return this.http
            .post(url, quotaWithBrandRequest)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public checkCourtesyCarQuota(setQuotaRequests: CheckQuotaRequest[]): Promise<SetCourtesyVehicleQuotaResponse> {
        const url = `${this.actionUrl}/check-courtesy-car-quota`;
        return this.http
            .post(url, setQuotaRequests)
            .toPromise()
            .then(response => response as SetCourtesyVehicleQuotaResponse)
            .catch(error => this.handleError(error));
    }

    public setCourtesyCarQuota(outletId: string, setQuotaRequests: SetWorkTeamQuotaRequest[]): Promise<void> {
        const url = `${this.actionUrl}/${outletId}/set-courtesy-car-quota`;
        return this.http
            .post(url, setQuotaRequests)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public getCourtesyCarCalendarQuota(outletId: string, fromDate: Date, toDate: Date, ignoreLocked = true): Promise<CCQuotaCalendar[]> {
        // tslint:disable-next-line:max-line-length
        const url = `${this.actionUrl}/${outletId}/courtesy-car/quota/calendar/from/${moment(fromDate).format(
            'YYYY-MM-DD'
        )}/to/${moment(toDate).format('YYYY-MM-DD')}`;
        const urlWithParams = `${url}?ignoreLocked=${ignoreLocked}`;
        return this.http
            .get(urlWithParams)
            .toPromise()
            .then(response => response as CCQuotaCalendar[])
            .catch(error => this.handleError(error));
    }

    public getCourtesyCarList(outletId: string): Promise<Vehicle[]> {
        const url = `${this.actionUrl}/${outletId}/courtesy-car`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Vehicle[])
            .catch(error => this.handleError(error));
    }

    public setCapacityGroupingType(outlet: Outlet, outletId: string): Promise<void> {
        const url = `${this.actionUrl}/${outletId}/capacity-grouping-type`;
        return this.http
            .put(url, outlet)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public requestSwitchToMacrocategory(outletId: string): Promise<void> {
        const url = `${this.actionUrl}/${outletId}/request-switch-to-macrocategory`;
        return this.http
            .get(url)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public getOutletSkills(outletId: string): Promise<ActivityCategory[]> {
        const url = `${this.actionUrl}/${outletId}/skills`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as ActivityCategory[])
            .catch(error => this.handleError(error));
    }

    public updateOutletSkills(outletId: string, skillIds: string[]): Promise<void> {
        const url = `${this.actionUrl}/${outletId}/skills`;
        return this.http
            .put(url, skillIds)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public getOneByDealer(dealerOriginalId: string, outletNumber: string): Promise<Outlet> {
        const url = `${this.actionUrl}/by-dealer/${dealerOriginalId}/outlet-number/${outletNumber}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Outlet)
            .catch(error => this.handleError(error));
    }

    public async getOneProxy(outletId: string): Promise<Outlet> {
        const url = `${this.actionUrl}/proxy/${outletId}`;

        try {
            const response = await this.http.get(url).toPromise();
            return response as Outlet;
        } catch (error) {
            return await this.handleError(error);
        }
    }

    /** @deprecated */
    public getOneByOutletCodeNum(originalId: string, outletNumber: string): Promise<Outlet> {
        const url = `${this.actionUrl}/by-code-number/${originalId}/outlet-number/${outletNumber}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Outlet)
            .catch(error => this.handleError(error));
    }

    public getAllByDealer(dealerId: string, fetchOnlyActive: boolean = false): Promise<Outlet[]> {
        let url = `${this.actionUrl}/by-dealer/${dealerId}`;

        if(fetchOnlyActive){
            url+= '?active=true'
        }

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Outlet[])
            .catch(error => this.handleError(error));
    }

   public getAllByDealers(dealersId: string[], fetchOnlyActive: boolean = false): Promise<Outlet[]> {
      let url = `${this.actionUrl}/by-dealers/${dealersId}`;

      if(fetchOnlyActive){
         url+= '?active=true'
      }

      return this.http
         .get(url)
         .toPromise()
         .then(response => response as Outlet[])
         .catch(error => this.handleError(error));
   }

    public getManageableByLoggedUser(fetchFor?: string): Promise<Outlet[]> {
        let result: Observable<any>;
        const url = `${this.actionUrl}/by-logged-user/`;

        if (fetchFor) {
            const params = new HttpParams().set('fetch', fetchFor);
            result = this.http.get(url, { params });
        } else {
            result = this.http.get(url);
        }

        return result
            .toPromise()
            .then(response => response as Outlet[])
            .catch(error => this.handleError(error));
    }

    getUserOutlets(fetchFor?: string): Observable<Outlet[]> {
        const url = `${this.actionUrl}/by-logged-user/`;
        return this.http.get<Outlet[]>(url);
    }
    public checkDcomHealth(outletId: string): Promise<BasicResponse> {
        const url = `${this.actionUrl}/dcom/health/${outletId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as BasicResponse)
            .catch(error => this.handleError(error));
    }

    public getCheckDcomHealthInterval(): Promise<number> {
        const url = `${this.actionUrl}/dcom/health/interval`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public checkUniqueOriginalId(originalId: string): Promise<boolean> {
        const url = `${this.actionUrl}/exists`;
        const searchSetting: SearchSettings = {
            filters: [
                {
                    field1: 'originalId',
                    filterOperator: 'EQUAL',
                    value1: originalId,
                },
            ],
        };

        return this.http
            .post(url, searchSetting)
            .toPromise()
            .then(response => response as boolean)
            .catch(error => this.handleError(error));
    }

    public checkUniqueOutletNumber(dealerId: string, outletNumber: string): Promise<boolean> {
        const url = `${this.actionUrl}/exists`;
        const searchSetting: SearchSettings = {
            filters: [
                {
                    field1: 'dealerId',
                    filterOperator: 'EQUAL',
                    value1: dealerId,
                },
                {
                    field1: 'outletNumber',
                    filterOperator: 'EQUAL',
                    value1: outletNumber,
                },
            ],
        };

        return this.http
            .post(url, searchSetting)
            .toPromise()
            .then(response => response as boolean)
            .catch(error => this.handleError(error));
    }

    public addBackOfficeNotification(backOfficeNotificationDTO: BackOfficeNotificationDTO): Promise<boolean> {
        const url = `${this.actionUrl}/${backOfficeNotificationDTO.outletId}/notification`;

        return this.http
            .post(url, backOfficeNotificationDTO)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public updateBackOfficeNotification(backOfficeNotificationDTO: BackOfficeNotificationDTO): Promise<boolean> {
        const url = `${this.actionUrl}/${backOfficeNotificationDTO.outletId}/notification/update`;

        return this.http
            .post(url, backOfficeNotificationDTO)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public getBackOfficeNotification(paramOutletId: string): Promise<BackOfficeNotificationDTO[]> {
        const url = `${this.actionUrl}/${paramOutletId}/notification`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as BackOfficeNotificationDTO[])
            .catch(error => this.handleError(error));
    }

    public deleteBackOfficeNotification(paramOutletId: string, paramEmailId: string): Promise<void> {
        const url = `${this.actionUrl}/${paramOutletId}/notification/${paramEmailId}`;

        return this.http
            .delete(url, { responseType: 'text' })
            .toPromise()
            .catch(error => this.handleError(error));
    }

    public getByIdForWorkshopCapacity(outletId: string): Promise<Outlet> {
        const url = `${this.actionUrl}/${outletId}/for-workshop-capacity`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Outlet)
            .catch(error => this.handleError(error));
    }

    public isOutletMMAllowed(outletId: string): Promise<boolean> {
        const url = `${this.actionUrl}/outlet/mm-allowed/` + outletId;

        return this.http
            .post(url, outletId)
            .toPromise()
            .then(response => response as boolean)
            .catch(error => this.handleError(error));
    }

    public getAvailability(outletId: string, dateFrom: Date, dateTo: Date): Promise<AvailabilitySlot[]> {
        const url = `${this.actionUrl}/availability/` + outletId;
        const params = new HttpParams()
            .set('dateFrom', Utils.getDateFromDateTime(dateFrom))
            .set('dateTo', Utils.getDateFromDateTime(dateTo));

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as AvailabilitySlot[])
            .catch(error => this.handleError(error));
    }

    public getMinutesAvailables(activity: Activity): Promise<number[]> {
        const url = `${this.actionUrl}/time-slot/minutes-available`;

        return this.http
            .post(url, activity)
            .toPromise()
            .then(response => response as number[])
            .catch(error => this.handleError(error));
    }

    public getDelays(outletId: string): Promise<OutletSkills[]> {
        const url = `${this.actionUrl}/delay/get-all`;

        return this.http
            .post(url, outletId)
            .toPromise()
            .then(response => response as OutletSkills[])
            .catch(error => this.handleError(error));
    }

    public updateDelays(outletSkillList: OutletSkills[]): Promise<void> {
        const url = `${this.actionUrl}/delay/update`;

        return this.http
            .post(url, outletSkillList)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public getDelay(outletListId: string, skillsId: string): Promise<number> {
        const url = `${this.actionUrl}/delay/get-by-skill`;

        return this.http
            .post(url, { outletListId, skillsId })
            .toPromise()
            .then(response => response as number)
            .catch(error => this.handleError(error));
    }

    public createOutletRenting(renting: Outlet) {
        const url = `${this.actionUrl}/create-renting`;

        return this.http
            .post(url, renting)
            .toPromise()
            .then(response => response as Outlet)
            .catch(error => this.handleError(error));
    }

    public getOutletsRenting(country: Country) {
        const url = `${this.actionUrl}/get-outlets-renting`;

        return this.http
            .post(url, country)
            .toPromise()
            .then(response => response as Outlet[])
            .catch(error => this.handleError(error));
    }

    public updateOutletRenting(renting: Outlet) {
        const url = `${this.actionUrl}/update-outlet-renting`;

        return this.http
            .post(url, renting)
            .toPromise()
            .then(response => response as Outlet)
            .catch(error => this.handleError(error));
    }

    public getOutletCapacityEngingGroups(outletId: string) {
        const url = `${this.actionUrl}/${outletId}/capacity-engine-group`;

        return this.http
            .get(url);
    }

    public getOutletResourcesCalendars(
        resources: OutletEventResource[],
        startDate: Date = null,
        endDate: Date = null
    ): Observable<ResourceCalendar[]> {
        const url = `${environment.apiUrl}/calendar`;

        let calendarFilter = {
            resources: resources,
            startDateTime: startDate ? moment.utc(startDate).format() : startDate,
            endDateTime: endDate ? moment.utc(endDate).format() : endDate,
        };

        return this.http
            .post<ResourceCalendar[]>(url, calendarFilter);
    }

    public getTimeSlotAppointment(taskId: number): Observable<TimeslotAppointment> {
        const url = `${environment.apiUrl}/calendar/timeslotappointment/${taskId}`;
        return this.http
            .get<TimeslotAppointment>(url);
    }

    createOutletAbsence(absence: OutletAbsence): Observable<OutletAbsence> {
        const url = `${environment.apiUrl}/absences`;

        return this.http
            .post<OutletAbsence>(url, absence);
    }

    getOutletAbsences(outletId: string, page: Page): Observable<HATEOASResponse<OutletAbsence>> {
        const url = `${environment.apiUrl}/absences`;

        let params = new HttpParams();

        params = params.append('parentResource.id', outletId);
        params = params.append('parentResource.resourceType', ResourceType[ResourceType.OUTLET]);
        params = params.append('page.pageSize', page.pageSize.toString());
        params = params.append('page.pageNumber', page.pageNumber.toString());

        return this.http
            .get<HATEOASResponse<OutletAbsence>>(url, { params });
    }

    updateOutletAbsence(absenceId: number, absence: OutletAbsence): Observable<OutletAbsence> {
        const url = `${environment.apiUrl}/absences/${absenceId}`;

        return this.http
            .put<OutletAbsence>(url, absence);
    }

    deleteAbsence(absenceId): Observable<any> {
        const url = `${environment.apiUrl}/absences/${absenceId}`;

        return this.http
            .delete(url);
    }

    assignAbsence(absenceId: number, resources: OutletEventResource[]): Observable<any> {
        const url = `${environment.apiUrl}/absences/assignation`;

        return this.http
            .put(url, { absenceId, resources });
    }

    unassignAbsence(absenceId: number, resources: OutletEventResource[]): Observable<any> {
        const url = `${environment.apiUrl}/absences/unassignation`;

        return this.http
            .put(url, { absenceId, resources });
    }

    createOutletShift(shift: OutletShift): Observable<number> {
        const url = `${environment.apiUrl}/workshifts`;

        return this.http
            .post<number>(url, shift);
    }

    getOutletShifts(parentResourceId: number, page: Page): Observable<HATEOASResponse<OutletShift>> {
        const url = `${environment.apiUrl}/workshifts`;

        let params = new HttpParams();

        params = params.append('parentResource.id', parentResourceId.toString());
        params = params.append('parentResource.resourceType', ResourceType[ResourceType.OUTLET_GROUP]);
        params = params.append('page.pageSize', page.pageSize.toString());
        params = params.append('page.pageNumber', page.pageNumber.toString());

        return this.http
            .get<HATEOASResponse<OutletShift>>(url, { params });
    }

    updateOutletShift(shiftId: number, shift: OutletShift): Observable<OutletShift> {
        const url = `${environment.apiUrl}/workshifts/${shiftId}`;

        return this.http
            .put<OutletShift>(url, shift);
    }

    deleteShift(shiftId): Observable<any> {
        const url = `${environment.apiUrl}/workshifts/${shiftId}`;

        return this.http
            .delete(url);
    }

    assignWorkShift(assignation): Observable<number> {
        const url = `${environment.apiUrl}/workshifts/assignation`;

        return this.http
            .post<number>(url, assignation);
    }

    updateAssignationWorkShift(assignation): Observable<any> {
        const url = `${environment.apiUrl}/workshifts/assignation/${assignation.id}`;

        return this.http
            .put(url, { startDate: assignation.startDate, endDate: assignation.endDate });
    }

    unassignWorkShift(assignationId: number): Observable<any> {
        const url = `${environment.apiUrl}/workshifts/assignation/${assignationId}`;

        return this.http
            .delete(url);
    }
}
