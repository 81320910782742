import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { Utils } from '../../../core/utils';
import { TranslatePipe } from '../../../shared/pipe/translate.pipe';
import { AuthService } from '../../auth.service';

@Component({
    selector: 'oas-change-activation-user',
    templateUrl: './change-activation-user.component.html',
})
export class ChangeActivationUserComponent implements OnInit {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        protected translatePipe: TranslatePipe
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            const token = params['token'];
            this.authService
                .changeActivation(token)
                .then(() => {
                    swal.fire(
                        {
                            title: this.translatePipe.transform('OPERATION_COMPLETED_SUCCESSFULLY'),
                            text: this.translatePipe.transform('GCDM_CHANGE_ACTIVATION'),
                            icon: 'success',
                        },
                    );
                    this.router.navigate(['/welcome']);
                })
                .catch((error: HttpErrorResponse) => {
                    this.router.navigate(['/welcome']);
                    Utils.showErrorPopup(error);
                });
        });
    }
}
