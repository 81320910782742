import { User } from '../../shared/entities';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoggedUser {
    private userSource = new BehaviorSubject<User>({});
    currentUser = this.userSource.asObservable();

    constructor() {}

    setUser(loggedUser: User) {
        this.userSource.next(loggedUser);
    }
}
