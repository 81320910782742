import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusinessSettingType, Setting } from '../../shared/entities';
import { Logger } from '../logger.service';
import { AbstractRestService } from './generic.service';

@Injectable()
export class BusinessSettingService extends AbstractRestService<BusinessSettingType, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/business-setting', http, logger);
    }

    public getSetting(businessSettingType: BusinessSettingType): Promise<Setting> {
        const url = `${this.actionUrl}/${businessSettingType}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Setting)
            .catch(error => this.handleError(error));
    }

    public setSetting(setting: Setting): Promise<Setting> {
        const url = `${this.actionUrl}/set`;
        return this.http
            .post(url, setting)
            .toPromise()
            .catch(error => this.handleError(error));
    }
}
