import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { ActivityService } from '../../../core/api/activity/activity.service';
import { AttachmentService } from '../../../core/api/attachment/attachment.service';
import { AlertWarningComponent } from '../../../shared/alert/alert-warning.component';
import { Attachment } from '../../../shared/attachment/entity';

import {
    Activity,
    ActivityCategory,
    ActivityPackage,
    Appointment,
    Vehicle,
    FixedPrice,
} from './../../../shared/entities';
import { CountryConfigurationService } from '../../../../configuration/country-configuration.service';

import { CountryConfiguration } from '../../../country-configuration';
@Component({
    selector: 'oas-activity-price',
    templateUrl: './activity-price.component.html',
    styleUrls: ['./activity.component.scss'],
})
export class ActivityPriceComponent implements OnInit {
    @Input() public vehicle: Vehicle;
    @Input() public activities: Activity[];
    @Input() public appointment: Appointment;
    @Input() fastLaneSelected = false;
    @Input() waitInOutletSelected = false;
    @Input() public preselectedActivityPackageIds: String[];

    @Output() private categoryDurationMapChange = new EventEmitter<Map<string, number>>();
    @Output() fastLaneSelectedChange = new EventEmitter<boolean>();
    @Output() waitInOutletSelectedChange = new EventEmitter<boolean>();
    @Output() attachmentsChange = new EventEmitter<string[]>();

    @ViewChild('warningFastLane') warningFastLane: AlertWarningComponent;

    public activityCategories: ActivityCategory[];
    public loading = false;
    public searchActivity = new UntypedFormControl();
    public totalDuration = 0;
    public fastLaneThreshold = environment.fastLaneThreshold;
    public attachmentsUrl: string = environment.attachmentsUrl;
    public attchmentsEnabled: boolean = environment.attachmentsEnabled;
    public attachments: Attachment[];

    public fixedPriceList: FixedPrice[];

    public sameDates: boolean;
    public hideFastLane:boolean;

    private categoryDurationMap = new Map<string, number>();

    countryConfiguration: CountryConfiguration;

    constructor(
        private activityService: ActivityService,
        private attachmentService: AttachmentService,
        countryConfigurationService: CountryConfigurationService
    ) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
    }

    public ngOnInit() {
        this.hideFastLane = this.appointment.outlet.skills.some(s =>s.id === 'FAST_LANE')

        this.attachments = [];

        this.retrieveFixedPrices();

        if (this.appointment.id) {
            this.attachmentService.getAppointmentAttachments(this.appointment.id).then(
                attachments =>
                    (this.attachments = attachments.map((attachment, index, array) => {
                        return {
                            loading: false,
                            path: attachment,
                            name: this.getAttachmentName(attachment),
                            fullName: this.getAttachmentFullName(attachment),
                        } as Attachment;
                    }))
            );
        }
    }

    public updateTotalDuration(): void {
        this.totalDuration = this.activities.reduce((ac, cv) => (ac += cv.duration), 0);
        if (this.fastLaneSelected && this.totalDuration > this.fastLaneThreshold) {
            this.selectFastLane();
        }
    }

    private retrieveFixedPrices(): void {
        this.loading = true;
        this.activityService
            .getFixedPriceByOutlet(this.appointment.outlet.id, this.vehicle.model.id)
            .then(fixedPriceList => {
                this.fixedPriceList = fixedPriceList;
                this.compareDates();
                fixedPriceList.forEach(availablePkg => {
                    this.manageActivityDurationMap(availablePkg);
                    this.managePreselectedActivities(availablePkg);
                });
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
    }

    private manageActivityDurationMap(fixedprice: FixedPrice): void {
        const mActivity = this.activities.find(act => act.aprilActivityId === fixedprice.flatrate);
        if (mActivity) {
            if (!mActivity.activityType.activityDuration) {
                // FIXME: Check it
                // mActivity.activityType = availablePkg;
            }
            this.updateCategoryDurationMap('FIXEDPRICES', mActivity.activityType.activityDuration);
        }
    }

    private updateCategoryDurationMap(key: string, newValue: number): void {
        const oldValue = this.categoryDurationMap.has(key) ? this.categoryDurationMap.get(key) : 0;
        const value = oldValue + newValue;

        if (value === 0) {
            this.categoryDurationMap.delete(key);
        } else {
            this.categoryDurationMap.set(key, value);
        }

        if (this.fastLaneSelected && !this.isFastLaneAllowed()) {
            this.selectFastLane();
        }

        this.categoryDurationMapChange.emit(this.categoryDurationMap);
    }

    private managePreselectedActivities(fixedprice: FixedPrice): void {
        if (this.preselectedActivityPackageIds.includes(fixedprice.id)) {
            this.onSelect(fixedprice, true);
        }
    }

    private onSelect(fixedprice: FixedPrice, checked: boolean): void {
        let idCnt = 'FIXEDPRICES';
        if (checked) {
            // add activity
            this.activities.push({
                name: fixedprice.name,
                activityType: {
                    id: '000-00-' + idCnt,
                    activityPackageId: idCnt,
                    activityDuration: fixedprice.activityDuration,
                    activityCategory: {
                        id: fixedprice.id,
                    },
                },
                activityExtradata: { price: fixedprice.price, endValidityTime: fixedprice.endValidityTime },
                duration: fixedprice.activityDuration,
                aprilActivityId: fixedprice.flatrate,
                workTeam: {
                    workTeamType: {
                        id: 'Maintenance',
                    },
                },
            });
            this.updateCategoryDurationMap(idCnt, fixedprice.activityDuration);
        } else {
            // remove activity
            const activityToRemove = this.activities.find(
                act => act.catalogType !== 'TECHNICAL_CAMPAIGNS' && act.aprilActivityId === fixedprice.flatrate
            );

            const indexToRemove = this.activities.indexOf(activityToRemove);
            this.activities.splice(indexToRemove, 1);
            this.updateCategoryDurationMap(idCnt, -fixedprice.activityDuration);
        }
        this.updateTotalDuration();
    }

    /**
     * ngFor traking by id
     * @param activityPackage
     */
    public trackByActivityPackage(activityPackage: ActivityPackage) {
        return activityPackage.id;
    }

    public isAlreadySelected(fixedprice: FixedPrice): boolean {
        const activityFound = this.activities.find(
            act => act.catalogType !== 'TECHNICAL_CAMPAIGNS' && act.aprilActivityId === fixedprice.flatrate
        );

        if (activityFound && !activityFound.activityType.descriptionLocalized) {
            activityFound.activityType.descriptionLocalized = fixedprice.name;
        }
        return activityFound != null;
    }

    public filterActivityPackages(fixedprice: FixedPrice): boolean {
        const filter: string = this.searchActivity.value.toLowerCase();
        return fixedprice.name.toLowerCase().indexOf(filter) >= 0;
    }

    public selectFastLane() {
        this.fastLaneSelected = !this.fastLaneSelected;
        this.fastLaneSelectedChange.emit(this.fastLaneSelected);
        if (!this.fastLaneSelected && this.waitInOutletSelected) {
            this.selectWaitInOutlet();
        }
    }

    public selectWaitInOutlet() {
        this.waitInOutletSelected = !this.waitInOutletSelected;
        this.waitInOutletSelectedChange.emit(this.waitInOutletSelected);
    }

    public isFastLaneAllowed(): boolean {
        return (
            !this.appointment.technicalCampaignsAvailable &&
            (this.categoryDurationMap.size === 0 ||
                (this.categoryDurationMap.size === 1 && this.categoryDurationMap.has('SERVICE')))
        );
    }

    public fileChangeListener(event: any) {
        if ((event.target.files as FileList).length === 1) {
            const fileName = event.target.files[0].name.replace(/\s/g, '_');

            const attachment = {
                loading: true,
                name: fileName,
                fullName: null,
                path: null,
            };
            this.attachments.push(attachment);
            let promise: Promise<string>;
            if (this.appointment.id) {
                promise = this.attachmentService.uploadAttachment(this.appointment.id, event.target.files[0], fileName);
            } else {
                promise = this.attachmentService.uploadAttachmentTemp(event.target.files[0], fileName);
            }

            promise
                .then(att => {
                    const index = this.attachments.findIndex(
                        attach => attach.name === attachment.name && attach.loading
                    );
                    this.attachments[index] = Object.assign(
                        {},
                        {
                            ...attachment,
                            loading: false,
                            path: att,
                            fullName: this.getAttachmentFullName(att),
                        }
                    );

                    this.attachmentsChange.emit(this.attachments.map(attach => attach.path));
                })
                .catch(() => {
                    const index = this.attachments.findIndex(att => att.name === attachment.name);
                    this.attachments.splice(index, 1);
                });
        }
    }

    public deleteAttachment(attachment: Attachment) {
        attachment.loading = true;

        let promise: Promise<void>;
        if (this.appointment.id) {
            promise = this.attachmentService.deleteAttachment(this.appointment.id, attachment.path);
        } else {
            promise = this.attachmentService.deleteAttachmentTemp(attachment.fullName);
        }

        promise.then(() => {
            this.removeAttachment(attachment);
            this.attachmentsChange.emit(this.attachments.map(attachment => attachment.fullName));
        });
    }

    public removeAttachment(attachment: Attachment) {
        const index = this.attachments.findIndex(att => att.fullName === attachment.fullName);
        this.attachments.splice(index, 1);
    }

    public getAttachmentFullName(attachment: string) {
        const sp = attachment.split('/');
        return sp[sp.length - 1];
    }

    public getAttachmentName(attachment: string) {
        const name = this.getAttachmentFullName(attachment);
        return name.split('|')[1];
    }

    public showFastLaneWarning(checked: boolean) {
        if (checked && this.countryConfiguration.countryCode != 'ES') {
            this.warningFastLane.show();
        }
    }

    public compareDates() {
        let lastIniDate = null;
        let lastEndDate = null;
        this.sameDates = true;

        this.fixedPriceList.forEach(fixedPrice => {
            if (
                lastIniDate != null &&
                lastEndDate != null &&
                (fixedPrice.startValidityTime.getTime() != lastIniDate.getTime() ||
                    fixedPrice.endValidityTime.getTime() != lastEndDate.getTime())
            ) {
                this.sameDates = false;
            }
            lastIniDate = fixedPrice.startValidityTime;
            lastEndDate = fixedPrice.endValidityTime;
        });
    }
}
