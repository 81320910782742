<div class="col-md-12 container">
    <p *ngIf="countryConfiguration.countryCode !== 'MX'" class="summary__tos" i18n="@@dataAppointment">
        &#10033;These data will be used only for the establishment of the appointment. They will not be stored or used in
        marketing campaigns.
    </p>
    <p class="summary__tos" i18n="@@agreement">Please, read terms and conditions</p>
    <input type="checkbox" id="accept_tos" (change)="setTermsAndConditions($event.currentTarget.checked)" class="accept-input"/>
    <label for="accept_tos">
        <span i18n="@@acceptTermsOf">I accept Terms of  </span>
        <a [href]="privacyPolicyLink" target="_blank">
            <span *ngIf="brand === 'BMW' || brand === 'BMW_I'" i18n="@@bmwPrivacyPolicy">BMW’s Privacy Policy</span>
            <span *ngIf="brand === 'MINI'" i18n="@@miniPrivacyPolicy">MINI’s Privacy Policy</span>
            <span *ngIf="brand === 'BMW_MOTORRAD'" i18n="@@bmwMotorradPrivacyPolicy">BMW Motorrad’s Privacy Policy</span>
        </a>
        <span i18n="@@acceptTermsOfEnd">BMW</span>
    </label>
</div>
