import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OasValidators } from '../../../core/validator/oas-validators';
import { AppComponent } from './../../../app.component';
import { AuthService } from './../../../auth/auth.service';
import { CountryService } from './../../../core/api/country/country.service';
import { Country, Customer, CustomerType, GeoMarker, LocationComponentType, Outlet } from './../../../shared/entities';
import { CountryConfiguration } from '../../../country-configuration';
import { Utils } from '../../../core/utils';

import { dataAnalytics } from '../../../shared/data-analytics';
import { TracyService } from '../../../shared/tracy.service';
import { CountryConfigurationService } from '../../../../configuration/country-configuration.service';

export interface TurnableIntoModel {
    turnIntoModel(): Customer;
    isValid(): boolean;
}

@Component({
    selector: 'oas-customer-form',
    templateUrl: './customer-form.component.html',
    styleUrls: ['./customer-form.component.scss'],
})
export class CustomerFormComponent implements OnInit, TurnableIntoModel {
    @Input() public customer: Customer;
    @Input() public outlet: Outlet;
    @Input() public submitted: boolean;
    @Input() public hideAddress: boolean;
    @Input() public ignoreErrorWhenUserAlreadyExistInGcdm: boolean;

    @ViewChild('editAddressModal') public editAddressModal: any;

    public customerForm: UntypedFormGroup;
    public originalEmail: string;
    private selectedAddress: GeoMarker;
    public countryList: Country[];
    public selectedCountryCode: string;
    public customerOri: Customer = {};
    public countryConfiguration: CountryConfiguration;
    public isStartFillingForm = false;
    private emailAlreadyAssociatedToGcdmAccount: boolean;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private countryService: CountryService,
        private authService: AuthService,
        public appComponent: AppComponent,
        private tracyService: TracyService,
        countryConfigurationService: CountryConfigurationService
    ) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
    }

    public ngOnInit() {
        this.selectedCountryCode = this.countryConfiguration.countryCode;
        this.countryService
            .getAll()
            .then(countryList => {
                this.countryList = countryList.sort((a, b) => {
                    // non-anonymous as you ordered...
                    return b.englishName < a.englishName
                        ? 1 // if b should come earlier, push a to end
                        : b.englishName > a.englishName
                        ? -1 // if b should come later, push a to begin
                        : 0; // a and b are equal
                });
            })
            .catch(() => {});
        this.createForm();
        if (this.customer && this.customer.id) {
            this.originalEmail = this.customer.email;
            if (Utils.validAddress(this.customer.address, this.customer.gcdmCustomer)) {
                this.selectedAddress = this.customer.address;
            }
            this.customerOri = Object.assign({}, this.customer);
            for (const i in this.customerForm.controls) {
                this.customerForm.controls[i].markAsTouched();
            }
        }
    }

    public createForm() {
        this.customerForm = this.formBuilder.group({
            name: [this.customer.name, [Validators.required, OasValidators.getLatinValidator()]],
            surname: [this.customer.surname, [Validators.required, OasValidators.getLatinValidator()]],
            secondSurname: [this.customer.secondSurname, OasValidators.getLatinValidator()],
            vat: this.customer.vatNumber,
            phone: [this.customer.phoneNumber, this.countryConfiguration.eitherPhoneOrEmailEnabled
                ? [OasValidators.getMobilePhoneValidator()]
                : [Validators.required, OasValidators.getMobilePhoneValidator()]],
            email: [this.customer.email, this.countryConfiguration.eitherPhoneOrEmailEnabled
            ? [OasValidators.email]
            : [Validators.required, OasValidators.email]],
            stairInt: [
                Utils.validAddress(this.customer.address, this.customer.gcdmCustomer)
                    ? this.customer.address.stairInt
                    : '',
                OasValidators.getLatinValidator(),
            ],
            address: [
                Utils.validAddress(this.customer.address, this.customer.gcdmCustomer)
                    ? this.customer.address.address
                    : '',
                OasValidators.getLatinValidator(),
            ],
        });
        if (this.countryConfiguration.appointmentFastCustomerCreationGenderEnabled) {
            this.customerForm.addControl('gender', new UntypedFormControl(!Utils.isNullOrUndefined(this.customer.gender) ? this.customer.gender : '', Validators.required));
        }
    }

    isPhoneRequired(): boolean {
        return !this.customerForm.get('email').value 
        && this.countryConfiguration.eitherPhoneOrEmailEnabled;
    }
    
    isEmailRequired(): boolean {
        return !this.customerForm.get('phone').value
        && this.countryConfiguration.eitherPhoneOrEmailEnabled;
    }

    get customerAddress() {
        return this.customerForm.get('address').value + ' ' + this.customerForm.get('stairInt').value;
    }

    private retrieveLocality(address: GeoMarker): string {
        let locality;
        if (address) {
            for (const locationComponent of address.locationComponentList) {
                if (locationComponent.label === <LocationComponentType>'locality') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_3') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_4') {
                    locality = locationComponent.longName;
                    break;
                } else if (locationComponent.label === <LocationComponentType>'administrative_area_level_5') {
                    locality = locationComponent.longName;
                    break;
                }
            }
        }

        return locality;
    }

    public onBlurEmail(value: string): void {
        if (this.customerForm.get('email').valid && value !== this.originalEmail && !this.outlet) {
            this.authService.checkUniqueUsername(value, 'online').subscribe(response => {
                this.emailAlreadyAssociatedToGcdmAccount = !!response;
                if (response && !this.ignoreErrorWhenUserAlreadyExistInGcdm) {
                    this.customerForm.get('email').setErrors({ oasUsernameValidator: true });
                }
            });
        }
    }

    public onAddressChange(address: GeoMarker) {
        this.customerForm.get('address').setValue(address.address);
        if (address.zipCode) {
            this.customerForm.get('zipCode').setValue(address.zipCode);
        }
        this.customerForm.get('country').setValue(address.country.code);
        this.customerForm.get('town').setValue(this.retrieveLocality(address));

        this.selectedAddress = address;
    }

    public turnIntoModel(): Customer {
        this.customer.name = this.customerForm.get('name').value;
        this.customer.surname = this.customerForm.get('surname').value;
        this.customer.secondSurname = this.customerForm.get('secondSurname').value;
        if (this.customerForm.get('gender')) {
            this.customer.gender = this.customerForm.get('gender').value;
        }
        this.customer.email = this.customerForm.get('email').value;
        this.customer.phoneNumber = this.customerForm.get('phone').value;
        this.customer.vatNumber = this.customerForm.get('vat').value;
        if (
            this.selectedAddress != null &&
            this.selectedAddress != undefined &&
            this.selectedAddress.country != null &&
            this.selectedAddress.country != undefined &&
            this.selectedAddress.country.code != null &&
            this.selectedAddress.country.code != undefined
        ) {
            this.customer.nationality = { code: this.selectedAddress.country.code };
        } else {
            this.customer.nationality = { code: this.countryConfiguration.countryCode };
        }
        this.customer.customerType = <CustomerType>'PERSON';

        if (!Utils.isNullOrUndefined(this.customer.addressList) && this.customer.addressList.length > 0) {
            this.selectedAddress.id = Utils.getDefaultOrFirstAddress(this.customer.addressList).id;
            this.selectedAddress.default = true;
        }

        if (!this.hideAddress && !Utils.isNullOrUndefined(this.selectedAddress)) {
            this.customer.addressList = Utils.setDefaultOrFirstAddress(this.customer.addressList, this.selectedAddress);
        }

        return this.customer;
    }

    public isValid(): boolean {
        return this.customerForm.valid;
    }

    public isInValid(): boolean {
        return this.customerForm.invalid;
    }

    public markAsTouched(): void {
        for (const i of Object.keys(this.customerForm.controls)) {
            this.customerForm.controls[i].markAsTouched();
        }
    }

    public manageNewAddress(newAddress: GeoMarker) {
        this.customerForm.get('address').setValue(newAddress.address);
        this.customerForm.get('stairInt').setValue(newAddress.stairInt);
        this.selectedAddress = newAddress;
        this.customerOri.address = newAddress;
    }

    public onAddressKeyPress(): any {
        return false;
    }

    dataAnalyticsVisitorStartFillingForm() {
        if (!this.isStartFillingForm) {
            this.tracyService.sendEvent(dataAnalytics.event.referenceName.registrationFormFill);
        }
        this.isStartFillingForm = true;
    }

    public openEditAddressModal() {
        if (!this.customerForm.get('address').disabled || !this.customerForm.get('stairInt').disabled) {
            this.appComponent.openModal('oas-maps-edit', this.editAddressModal, 'your-vehicles-modal modal-lg');
        }
    }

    public isEmailAlreadyAssociatedToGcdmAccount(): boolean {
        return this.emailAlreadyAssociatedToGcdmAccount;
    }
}
