import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { AuthService } from '../../auth/auth.service';
import { BusinessSettingService } from '../../core/api/business-setting.service';
import { StyleSwitcherService } from '../../core/style-switcher.service';
import { Utils } from '../../core/utils';
import { CountryConfiguration } from '../../country-configuration';
import { dataAnalytics } from '../../shared/data-analytics';
import {BusinessSettingType, VehicleBrand} from '../../shared/entities';
import { TracyService } from '../../shared/tracy.service';
import { AlertWarningComponent } from '../alert/alert-warning.component';
import { AppComponent } from './../../app.component';
import {LeadConfigurationService} from "../../core/api/tssb/lead-configuration.service";

@Component({
    selector: 'oas-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginForm: UntypedFormGroup;
    public invalidCredential = false;
    public accountLocked = false;
    public generalError = false;
    public multirole = false;
    public roles: string[];
    public roleFormControl = new UntypedFormControl('', Validators.required);
    public username;
    public showForm = false;
    public language: string;
    public countryConfiguration: CountryConfiguration;
    public externalSystemErrorMessage;
    public loginPageBannerTitleSetting: BusinessSettingType = 'LOGIN_PAGE_BANNER_TITLE';
    public loginPageBannerTextSetting: BusinessSettingType = 'LOGIN_PAGE_BANNER_TEXT';
    public loginPageBannerTitle: string;
    public loginPageBannerText: string;
    public local: string;
    public currentBrand: string;
    @ViewChild(AlertWarningComponent) public warnningAlert: AlertWarningComponent;
    @ViewChild('tokenOrAppointmentInvalid') public tokenOrAppointmentInvalid: TemplateRef<any>;

    @ViewChild('alertPasswordExpiredTitleTmpl') private alertPasswordExpiredTitleTmpl: TemplateRef<any>;
    @ViewChild('alertPasswordExpiredTextTmpl') private alertPasswordExpiredTextTmpl: TemplateRef<any>;

    constructor(
        private appComponent: AppComponent,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        private businessSettingService: BusinessSettingService,
        private styleSwitcherService: StyleSwitcherService,
        private router: Router,
        private route: ActivatedRoute,
        private tracyService: TracyService,
        countryConfigurationService: CountryConfigurationService,
        private leadConfiguration: LeadConfigurationService
    ) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
        this.route.params.subscribe(params => {
            this.authService.setFixedOutlet(params['outletId']);
            this.authService.setTicketId(params['tssbId']);
        });
        this.local = this.countryConfiguration.locale;
        this.setDataAnalytics();
    }

    public ngOnInit() {
        this.currentBrand = this.appComponent.brand;
        if (this.authService.isAuthorized()) {
            const user = JSON.parse(sessionStorage.getItem('user'));
            if (user.role.length > 1) {
                this.multirole = true;
                this.roles = user.role.map(rantedAuthority => rantedAuthority.authority);
            }
        }
        this.route.queryParamMap.subscribe(params => {
            if (params.get('outlet')) {
                sessionStorage.setItem('preselectedOutletInfo', params.get('outlet'));
            }
        });

        this.leadConfiguration.getConfiguration("LEAD_CCM_OUTLET_FILTER").then(response => {
           sessionStorage.setItem("leadCcmOutletFilter", response.value);
        });
        this.leadConfiguration.getConfiguration("LEAD_CCM_ENABLE").then(response => {
           sessionStorage.setItem("leadCcmEnable", response.value);
        });

        this.createForm();
        this.getBannerInformation();

        this.businessSettingService
            .getSetting(<BusinessSettingType>'AUTOMATIC_SELECTION_CBS_99999')
            .then(result => (this.countryConfiguration.automaticSelectionCbs99999 = result.value === 'true'));

        this.appComponent.isWelcomeTerminal = true;
    }

    ngAfterViewInit(){
        this.handleErrorOnUpdateAppointmentWitoutRegistration()

    }

    ngOnDestroy() {
        this.appComponent.isWelcomeTerminal = false;
    }

    private createForm() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    public changeShowForm() {
        this.showForm = true;
        this.setDataAnalyticsForAdvantagesPopup();
    }

    public closeModal() {
        this.showForm = false;
    }

    public storeLoggedUserIdIntoUserPreference(user: any) {
        const userPreference = Utils.retrieveUserPreference();
        if (!userPreference || userPreference.userId !== user.id) {
            Utils.overwriteExistingUserPreferenceWithNewOne(user.id);
        }
    }

    public login(username: string, password: string): void {
        this.resetErrorMessages();
        this.appComponent.loading = true;
        this.authService
            .signIn(username, password)
            .then((user: any) => {
                this.appComponent.loading = false;
                if (this.authService.isAuthorized()) {
                    this.resetErrorMessages();
                    this.storeLoggedUserIdIntoUserPreference(user);
                    if (user.role.length > 1) {
                        this.roles = user.role.map(grantedAuthority => grantedAuthority.authority);
                        this.multirole = true;
                    } else {
                        this.router.navigate([this.authService.getDashboardPath()]);
                    }
                }
            })
            .catch((error: HttpErrorResponse) => {
                this.appComponent.loading = false;
                /*  #TODO
                    The returned message SHOULD already contains the message that we want to show,
                    in this way we can avoid useless and complex logic.
                */

                if (error.status === 401) {
                    this.invalidCredential = true;
                } else if (error.status === 400) {
                    if (!Utils.isNullOrUndefined(error.error.stackTrace) && error.error.stackTrace.includes('AccountPasswordExpired')) {
                        this.warnningAlert.titleTmpl = this.alertPasswordExpiredTitleTmpl;
                        this.warnningAlert.contentTmpl=this.alertPasswordExpiredTextTmpl;
                        this.warnningAlert.show();
                    } else if (
                        error.error.message === 'AuthenticationFailedAppException' ||
                        (!Utils.isNullOrUndefined(error.error.stackTrace) &&
                            error.error.stackTrace.includes('Invalid authentication request'))
                    ) {
                        this.invalidCredential = true;
                    } else if (
                        error.error.message === 'User inactive' ||
                        error.error.message === 'AccountLockedAppException'
                    ) {
                        this.accountLocked = true;
                    }
                } else if (error.status >= 400 && error.status < 500) {
                    this.generalError = true;
                } else if (error.status === 500) {
                    this.externalSystemErrorMessage = error.error.errors[0].message;
                }
            });
    }

    public isBmw(): boolean {
        return !window.location.hostname.includes('.mini.') && !(this.appComponent.brand === <VehicleBrand>'MINI');
    }

    public goToDasboard(): void {
        this.authService.selectRole(this.roleFormControl.value);
        this.router.navigate([this.authService.getDashboardPath()]);
    }

    public checkEmailFirstURL(): boolean {
        return this.authService.getTicketId() != null && this.authService.getTicketId() != '';
    }

    public goToRegister() {
        const path = this.checkEmailFirstURL() ? '../../registration' : '../registration';
        this.router.navigate([path]);
    }

    public goToForgotPassword() {
        const path = this.checkEmailFirstURL() ? '../../forgot-password' : '../forgot-password';
        this.router.navigate([path]);
    }

    public goToQuickAppointment() {
        const path = this.checkEmailFirstURL() ? '../../quick-appointment' : '../quick-appointment';
        this.router.navigate([path]);
    }

    public resetErrorMessages() {
        this.invalidCredential = this.accountLocked = this.generalError = false;
        this.externalSystemErrorMessage = null;
    }

    public getBannerInformation() {
        this.getBannerTitleInformation();
        this.getBannerTextInformation();
    }

    public getBannerTitleInformation() {
        this.businessSettingService.getSetting(this.loginPageBannerTitleSetting).then(response => {
            this.loginPageBannerTitle = response.value;
        });
    }

    public getBannerTextInformation() {
        this.businessSettingService.getSetting(this.loginPageBannerTextSetting).then(response => {
            const currentBrand = this.styleSwitcherService.brand.getValue();
            let loginPageBannerText = response.value;
            loginPageBannerText = loginPageBannerText
                .split('{BRAND}')
                .join(currentBrand.toUpperCase().replace('_', ' '));
            loginPageBannerText = loginPageBannerText.split('{brand}').join(currentBrand.replace('_', '-'));
            this.loginPageBannerText = loginPageBannerText;
        });
    }

    public isAppointmentWithoutRegistrationEnabled(): boolean {
        return this.countryConfiguration[this.styleSwitcherService.brand.getValue().toUpperCase()].quickAppointment;
    }

    private setDataAnalyticsForAdvantagesPopup() {
        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasGuestVehicle,
            dataAnalytics.page.variant.virtual
        );
        this.tracyService.setUserInformationIntoDataAnalytics('na');

        this.tracyService.sendPageView(dataAnalytics.referenceName.advantagesPopup);
    }

    private setDataAnalytics() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasLogin,
            dataAnalytics.page.variant.real
        );
        this.tracyService.setUserInformationIntoDataAnalytics('na');

        this.tracyService.sendPageView(dataAnalytics.referenceName.login);
    }

    public dataAnalyticsNewAccountEvent() {
        this.tracyService.sendEvent(dataAnalytics.event.referenceName.loginFormStartIntetion);
    }

    private handleErrorOnUpdateAppointmentWitoutRegistration(){
        const {tokenOrAppointmentInvalid} = this.route.snapshot.queryParams
        if(tokenOrAppointmentInvalid){
            this.warnningAlert.title=""
            this.warnningAlert.contentTmpl=this.tokenOrAppointmentInvalid
            this.warnningAlert.show()
        }
       //navigate again to login in ordre to remove query Param
        this.router.navigate([])
    }

   getBackgroundImage() {
      switch (this.countryConfiguration.locale) {
         case 'it-IT':
            return '/assets/images/covers/cover_login_desktop_v2.jpg';
         case 'es-ES':
            return '/assets/images/covers/cover_login_desktop_v2.jpg';
         case 'es-MX':
            return '/assets/images/covers/cover_login_desktop_v2.jpg';
         case 'en-IN':
            return '/assets/images/covers/cover_login_desktop_v2.jpg';
         case 'pt-PT':
            return '/assets/images/covers/cover_login_desktop_v2.jpg';
         case 'pt-BR':
            return '/assets/images/covers/cover_login_desktop_v2.jpg';
         default:
            return '/assets/images/covers/cover_login_desktop_v2.jpg';
      }
   }

   showNewBackgroundImage(){
      return ['it-IT', 'es-ES', 'pt-PT'].includes(this.local);
   }
}
