import { Injectable } from '@angular/core';
import { Utils } from '../../core/utils';
import { ConfigurationColumn, ConfigurationTable, Outlet, StatusType, User } from '../../shared/entities';

@Injectable()
export class UserDataService {
    private logged: User;
    private manageableOutlets: Outlet[];
    private configurationTableMap: { [index: string]: ConfigurationTable };
    private welcomeTerminalColum: {
        fullName: boolean;
        mobilityService: boolean;
        services: boolean;
    } = { fullName: false, mobilityService: false, services: false };

    public setLogged(user: User): void {
        if (user.outletList == null && user.dealerList.length > 0) {
            user.outletList = user.dealerList[0].outletList;
        }
        this.logged = user;
    }

    public getLogged(): User {
        return this.logged;
    }

    public setManageableOutlets(outlets: Outlet[]): void {
        this.manageableOutlets = outlets.filter(outlet => outlet.status === <StatusType>'active');
    }

    public getManageableOutlets(): Outlet[] {

        if(Utils.isNullOrUndefined(this.manageableOutlets)){
            return [];
        }
        this.manageableOutlets.sort((a, b) => {
            if (a.businessName < b.businessName) {
                return -1;
            } else if (a.businessName > b.businessName) {
                return 1;
            } else {
                return 0;
            }
        });

        return this.manageableOutlets;
    }

    public setConfigurationTableMap(configurationTableMap: { [index: string]: ConfigurationTable }) {
        this.configurationTableMap = configurationTableMap;
    }

    public getConfigurationTableMap(): { [index: string]: ConfigurationTable } {
        return this.configurationTableMap;
    }

    public getConfigurationTable(tableName: string): ConfigurationTable {
        if (!this.configurationTableMap[tableName]) {
            this.configurationTableMap[tableName] = {
                name: tableName,
                configurationColumnMap: {},
            };
        }

        return this.configurationTableMap[tableName];
    }

    public setConfigurationTable(tableName: string, configurationTable: ConfigurationTable) {
        this.configurationTableMap[tableName] = configurationTable;
    }

    public getConfigurationColumn(
        tableName: string,
        columnName: string,
        createColumnIfNotExists = false
    ): ConfigurationColumn {
        const configTable = this.getConfigurationTable(tableName);

        if (createColumnIfNotExists && !configTable.configurationColumnMap[columnName]) {
            configTable.configurationColumnMap[columnName] = {
                name: columnName,
            };
        }

        return configTable.configurationColumnMap[columnName];
    }

    public clear(): void {
        this.logged = null;
        this.manageableOutlets = null;
        this.configurationTableMap = null;
    }

    public getWelcomeTerminalTable() {
        return this.welcomeTerminalColum;
    }

    public setWelcomeTerminalTable(fullName: boolean, mobilityService: boolean, services: boolean) {
        this.welcomeTerminalColum = { fullName, mobilityService, services };
    }
}
