import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppointmentCategoryDTO, UpdateOutletAppointmentCategory } from '../../../shared/entities';
import { Logger } from '../../logger.service';
import { AbstractRestService } from '../generic.service';

@Injectable()
export class AppointmentCategoriesService extends AbstractRestService<AppointmentCategoryDTO, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/appointment-categories', http, logger);
    }

    public async assignAppointmentCategoryToAppointments(appointmentIds: string[], categoryId: number): Promise<void> {
        const params = new HttpParams().set('appointmentIds', appointmentIds.join(','));
        try {
            await this.http
                .put(`${this.actionUrl}/${categoryId}`, null, { params: params })
                .toPromise();
        } catch (error) {
            return await this.handleError(error);
        }
    }

    public getAllByOutlet(outletId: string): Promise<AppointmentCategoryDTO[]> {
        const url = `${this.actionUrl}/${outletId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as AppointmentCategoryDTO[])
            .catch(error => this.handleError(error));
    }

    public saveAll(outletId: string, updateOutletAppointmentCategory: UpdateOutletAppointmentCategory[]): Promise<UpdateOutletAppointmentCategory[]> {
        const url = `${this.actionUrl}/${outletId}/appointment-category`;
        return this.http
            .put(url,updateOutletAppointmentCategory)
            .toPromise()
            .then(response => response as UpdateOutletAppointmentCategory[])
            .catch(error => this.handleError(error));
    }

}


