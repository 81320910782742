import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertErrorComponent } from '../../../shared/alert/alert-error.component';
import { AlertSuccessComponent } from '../../../shared/alert/alert-success.component';
import {
    Customer,
    CustomerType,
    GenderType,
    UserPreRegistration,
    UserPreRegistrationVehicle,
    Vehicle,
} from '../../../shared/entities';
import { AppComponent } from './../../../app.component';
import { AuthService } from './../../../auth/auth.service';
import { CustomerFormComponent, TurnableIntoModel } from '../form/customer-form.component';
import { CountryConfiguration } from '../../../country-configuration';
import { PreRegistrationService } from '../../../core/api/pre-registration/pre-registration.service';
import { Utils } from '../../../core/utils';
import { CountryConfigurationService } from '../../../../configuration/country-configuration.service';

import { dataAnalytics } from '../../../shared/data-analytics';
import { TracyService } from '../../../shared/tracy.service';
import { MatStepper } from '@angular/material/stepper';
import { UserFormCreateComponent } from '../form/user-form-create.component';

const CUSTOMER_DATA_STEP = 0;
const VEHICLE_DATA_STEP = 1;
const CREDENTIALS_DATA_STEP = 2;

@Component({
    selector: 'oas-customer-create-wizard',
    templateUrl: './customer-create-wizard.component.html',
    styleUrls: ['./customer-create-wizard.component.scss'],
})
export class CustomerCreateWizardComponent implements OnInit, AfterViewInit {
    @ViewChild('alertError') private alertError: AlertErrorComponent;
    @ViewChild('alertSuccess') private alertSuccess: AlertSuccessComponent;
    @ViewChild('userForm') private userForm: UserFormCreateComponent;

    UserFormCreateComponent
    @ViewChild(MatStepper) public wizard: MatStepper;
    @ViewChild(CustomerFormComponent) public customerForm: CustomerFormComponent;

    public errorResponse: HttpErrorResponse;
    public submitted = false;
    public customer: Customer;
    public recaptcha = new UntypedFormControl(null, Validators.required);
    public isAgree = false;
    private gcdmErrors;

    private preRegistrationToken: string;
    public loadingPreRegistrationData: boolean;
    public userPreRegistrationVehicle: UserPreRegistrationVehicle;
    private preRegistration: boolean;
    public vehicleForm: UntypedFormGroup;

    public countryConfiguration: CountryConfiguration;
    public vehicleModel: string;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private appComponent: AppComponent,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private preRegistrationService: PreRegistrationService,
        private tracyService: TracyService,
        countryConfigurationService: CountryConfigurationService
    ) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
        this.setDataAnalytics();
        this.dataAnalyticsPageloadEvent();
    }

    public ngOnInit(): void {
        this.gcdmErrors = this.route.snapshot.data.externalSystemIsKo.errors;

        this.customer = this.createCustomer();

        this.route.queryParams.subscribe(params => {
            const token = params['preRegistrationToken'];
            if (!Utils.isNullOrUndefined(token)) {
                this.preFillFormWithPreRegistrationData(token);
            }
        });

        this.vehicleForm = this.formBuilder.group({
            model: ['', Validators.required],
            plate: ['', Validators.required],
            mileage: [''],
        });

        this.vehicleForm.disable();
    }

    private doublecheckFields(result: UserPreRegistration) {
        if (Utils.isNullOrUndefined(result.customer.name)) {
            this.customerForm.customerForm.get('name').markAsTouched();
        }

        if (Utils.isNullOrUndefined(result.customer.surname)) {
            this.customerForm.customerForm.get('surname').markAsTouched();
        }

        if (Utils.isNullOrUndefined(result.customer.gender)) {
            this.customerForm.customerForm.get('gender').markAsTouched();
        }

        if (Utils.isNullOrUndefined(result.customer.phoneNumber)) {
            this.customerForm.customerForm.get('phone').markAsTouched();
        }
    }

    private preFillFormWithPreRegistrationData(token: string) {
        this.preRegistrationToken = token;
        this.loadingPreRegistrationData = true;
        this.preRegistrationService
            .getOne(token)
            .then(result => {
                this.userPreRegistrationVehicle = result.vehicle;
                this.customer.favouriteOutletId = result.favouriteOutletId;
                this.customerForm.customerForm.reset({
                    email: { value: result.email, disabled: true },
                });

                if (!Utils.isNullOrUndefined(result.customer)) {
                    this.customerForm.customerForm.patchValue({
                        name: result.customer.name,
                        surname: result.customer.surname,
                        gender:
                            result.customer.gender !== <GenderType>'UNSPECIFIED'
                                ? result.customer.gender
                                : <GenderType>'',
                        phone: result.customer.phoneNumber,
                        vat: result.customer.vatNumber,
                    });
                    if (!Utils.isNullOrUndefined(result.customer.address)) {
                        this.customerForm.manageNewAddress(result.customer.address);
                    }
                    this.doublecheckFields(result);
                }

                if (!Utils.isNullOrUndefined(result.vehicle)) {
                    if (result.vehicle.model.modelLabel.indexOf(result.vehicle.brand, 0) === -1) {
                        this.vehicleModel = result.vehicle.brand + '' + result.vehicle.model.modelLabel;
                    } else {
                        this.vehicleModel = result.vehicle.model.modelLabel;
                    }

                    this.vehicleForm.enable();
                    this.vehicleForm.get('model').disable();
                    this.vehicleForm.get('plate').disable();
                    this.vehicleForm.patchValue({
                        model: this.vehicleModel,
                        plate: result.vehicle.plate,
                        mileage: result.vehicle.mileage,
                    });
                }
                this.preRegistration = true;
                this.loadingPreRegistrationData = false;
            })
            .catch((error: HttpErrorResponse) => {
                this.loadingPreRegistrationData = false;
                this.alertError.showHttpError(error).then(() => this.goToLoginPage());
            });
    }

    public ngAfterViewInit(): void {
        if (this.gcdmErrors.length > 0) {
            this.alertError.text = this.gcdmErrors[0].message;
            this.alertError.show().then(() => this.goToLoginPage());
        }
    }

    public goToLoginPage(): void {
        this.router.navigate(['/welcome']);
    }

    private createCustomer(): Customer {
        const customer: Customer = {};
        customer.language = { id: this.countryConfiguration.language };
        customer.gender = <GenderType>'';
        customer.userApp = {};
        customer.customerType = <CustomerType>'PERSON';

        return customer;
    }

    public register(): void {
        this.appComponent.loading = true;
        this.authService
            .signUp(this.customer, this.preRegistrationToken)
            .then(response => {
                if (response.errors.length > 0) {
                    this.alertError.text = response.errors[0].message;
                    this.alertError.show();
                } else {
                    this.alertSuccess.show();
                    this.setDataAnalyticsForRegistrationComplete();
                    this.setAlertDataAnalytics();
                    this.dataAnalyticsVisitorCompletedRegistrationPopup();
                }
                this.appComponent.loading = false;
                this.router.navigate(['/welcome']);
            })
            .catch((errorResponse: HttpErrorResponse) => {
                this.errorResponse = errorResponse;
                this.appComponent.loading = false;
                this.alertError.show();
            });
    }

    public resolved(captchaResponse: string) {
        this.customer.captcha = captchaResponse;
    }

    public firstStepProceedClick(customerForm: TurnableIntoModel) {
        this.submitted = true;

        if (customerForm.isValid()) {
            this.customer = customerForm.turnIntoModel();

            if (this.vehicleForm.disabled) {
                this.wizard.selectedIndex = CREDENTIALS_DATA_STEP;
            } else {
                this.wizard.selectedIndex = VEHICLE_DATA_STEP;
            }
        }
    }

    public secondStepProceed() {
        if (this.vehicleForm.valid) {
            const newVehicle: Vehicle = {
                plate: this.userPreRegistrationVehicle.plate,
                vin: this.userPreRegistrationVehicle.vin,
                mileage: this.userPreRegistrationVehicle.mileage,
                model: this.userPreRegistrationVehicle.model,
            };

            this.customer.vehicleList = [newVehicle];

            this.wizard.selectedIndex = CREDENTIALS_DATA_STEP;
        }
    }

    public secondStepSkip() {
        this.customer.vehicleList = undefined;
        this.wizard.selectedIndex = CREDENTIALS_DATA_STEP;
    }

    public thirdStepBack() {
        if (this.vehicleForm.disabled) {
            this.wizard.selectedIndex = CUSTOMER_DATA_STEP;
        } else {
            this.wizard.selectedIndex = VEHICLE_DATA_STEP;
        }
    }

    public thirdStepForward() {
        const hideenDiv: HTMLElement = document.getElementsByClassName('login-form__section')[0] as HTMLElement;
        hideenDiv.style.visibility = 'hidden';
    }

    public fourthStepBack() {
        const hideenDiv: HTMLElement = document.getElementsByClassName('login-form__section')[0] as HTMLElement;
        hideenDiv.style.visibility = 'visible';
    }

    public goToWizardStep(step: number, formToReset: UntypedFormGroup) {
        this.customer = this.createCustomer();
        formToReset.reset();
        this.wizard.selectedIndex = +step;
    }

    public showVehicleImageInPreRegistration(): boolean {
        return (
            this.preRegistration && this.wizard.selectedIndex === VEHICLE_DATA_STEP
        );
    }

    private setDataAnalytics() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasRegistration,
            dataAnalytics.page.variant.real
        );

        this.tracyService.setUserInformationIntoDataAnalytics('na');

        this.tracyService.sendPageView(dataAnalytics.referenceName.registration);
    }

    private dataAnalyticsPageloadEvent() {
        this.tracyService.sendEvent(dataAnalytics.event.referenceName.registrationFormStart);
    }
    public dataAnalyticsVisitorCompletedFirstStep() {
        // this.tracyService.sendEvent('local > registration');
    }

    public dataAnalyticsVisitorCompletedThirdStep() {
        // this.tracyService.sendEvent('local > registration');
    }
    public dataAnalyticsVisitorCompletedRegistration() {
        this.tracyService.sendEvent(dataAnalytics.event.referenceName.registrationFormFinishIntention);
    }
    public dataAnalyticsVisitorGoBackEvent(isPassStep?: boolean) {
        if (isPassStep) {
            this.tracyService.sendEvent(
                dataAnalytics.event.referenceName.registrationComeBackClicked,
                dataAnalytics.event.subCategory1.passwordConfirmation
            );
        } else {
            this.tracyService.sendEvent(
                dataAnalytics.event.referenceName.registrationComeBackClicked,
                dataAnalytics.event.subCategory1.confirmationRegistration
            );
        }
    }

    public onStepChange(event): void {
        if(event.selectedIndex === 2) {
            this.userForm.userForm.get('username').setValue(this.customer.email);
        }
    }

    private setDataAnalyticsForRegistrationComplete() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );
        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasRegistrationComplete,
            dataAnalytics.page.variant.real
        );
        this.tracyService.sendPageView(dataAnalytics.referenceName.registrationComplete);
    }
    private setAlertDataAnalytics() {
        this.tracyService.setAlertDataAnalytics(
            dataAnalytics.activityMap.region.oas,
            dataAnalytics.activityMap.link.registrationComplete
        );
    }

    private dataAnalyticsVisitorCompletedRegistrationPopup() {
        this.tracyService.sendEvent(dataAnalytics.event.referenceName.registrationCompleteFormFinished);
    }
}
