import { Injectable } from '@angular/core';
// import { CalendarComponent } from 'ap-angular2-fullcalendar';
import { BehaviorSubject } from 'rxjs';
import { CalendarEvent } from '../backoffice/shared/calendar/calendar-event.model';
import { Utils } from '../core/utils';
import { CalendarEvent as Calender, User } from '../shared/entities';

@Injectable()
export class TimeSloteSelectionService {
    selectedSlot$: BehaviorSubject<{
        event: Calender;
        advisorName: User;
        component: any;
        // component: CalendarComponent;
        fastLanName?: string;
    }> = new BehaviorSubject<{
        event: Calender;
        advisorName: User;
        component: any;
        // component: CalendarComponent;
        fastLanName: string;
    }>(null);
    availabiltySelectedEvent$: BehaviorSubject<{
        startDate: Date;
        endDate: Date;
        title: string;
    }> = new BehaviorSubject<{
        startDate: Date;
        endDate: Date;
        title: string;
    }>(null);
    events: Calender[] = [];
    serviceAdvisors: User[] = [];
    component: any;
    // component: CalendarComponent;
    calenderDate$: BehaviorSubject<{ calenderDate: Date; component: any }> = new BehaviorSubject<{
        calenderDate: Date;
        component: any;
        // component: CalendarComponent;
    }>(null);
    constructor() {}

    pushSelectedSlot(calenderEvent: CalendarEvent, advisorName: string, isFastLane?: boolean, fastLanName?: string) {
        let advisor: User = this.defaultAdvisor(advisorName);
        if (this.events.length > 0) {
            const event = this.events.find(e => e.slot.slotStart.getTime() === calenderEvent.startDate.getTime());
            if (!Utils.isNullOrUndefined(event)) {
                if (Utils.isNullOrUndefined(advisor)) {
                    advisor = this.defaultAdvisor(event.slot.title);
                }
                this.selectedSlot$.next({
                    event,
                    advisorName: !Utils.isNullOrUndefined(advisor)
                        ? advisor
                        : { displayName: event.slot.fastLaneSlots[0].saName },
                    component: this.component,
                    fastLanName: isFastLane ? fastLanName : '',
                });
                this.pushSelectedAvailabiltySlot(
                    calenderEvent.startDate,
                    calenderEvent.endDate,
                    !isFastLane ? advisorName : fastLanName
                );
            }
        }
    }
    defaultAdvisor(advisorName: string) {
        let advisor: User = null;
        if (this.serviceAdvisors.length > 0) {
            advisor = this.serviceAdvisors.find(
                advisor => advisor.displayName === advisorName || advisor.username === advisorName
            );
        }
        return advisor;
    }
    getSelectedSlot() {
        return this.selectedSlot$.asObservable();
    }
    pushSelectedAvailabiltySlot(startDate: Date, endDate: Date, title: string) {
        return this.availabiltySelectedEvent$.next({ startDate, endDate, title });
    }
    getSelectedAvailabiltySlot() {
        return this.availabiltySelectedEvent$.asObservable();
    }
    destroyAvailabiltySlotSelection() {
        return this.availabiltySelectedEvent$.next(null);
    }
    setEvents(events: Calender[]) {
        this.events = events;
    }
    setAdvisors(users: User[]) {
        this.serviceAdvisors = users;
    }
    setComponent(component: any) {
        this.component = component;
    }
    pushCalenderDate(date: Date) {
        this.calenderDate$.next({ calenderDate: date, component: this.component });
    }
    getCalenderDate() {
        return this.calenderDate$.asObservable();
    }
}
