import { Injectable } from '@angular/core';
import { AbstractRestService } from '../generic.service';
import {
    TssbTicket,
    SearchSettings,
    TssbCountAndMaxPriority,
    TssbTicketLog,
    TssbTicketLogsSummary,
    TssbTicketCustomerData,
    TssbTicketReasonDictionary,
    BaseResponse,
    TssbCheckboxFilter,
    TssbSearch,
} from '../../../shared/entities';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Logger } from '../../logger.service';
// import { moment } from 'ngx-bootstrap/chronos/test/chain';
import * as moment from 'moment';
@Injectable()
export class TssbService extends AbstractRestService<TssbTicket, number> {
    protected countTssb: TssbCountAndMaxPriority;

    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/tssb-tickets', http, logger);
    }

    public getTssbTickets(
        tssbCheckboxFilter?: TssbCheckboxFilter,
        tssbCountFilter?: TssbCheckboxFilter
    ): Promise<TssbSearch> {
        const url = `${this.actionUrl}`;

        const filters = [tssbCheckboxFilter, tssbCountFilter];

        return this.http
            .post(url, filters)
            .toPromise()
            .then(response => response as TssbSearch)
            .catch(error => this.handleError(error));
    }

    public getCountAndMaxPriority(searchSettings?: SearchSettings): Promise<TssbCountAndMaxPriority> {
        const url = `${this.actionUrl}/count-and-max-priority`;

        return this.http
            .post(url, searchSettings)
            .toPromise()
            .then(response => response as TssbCountAndMaxPriority)
            .catch(error => this.handleError(error));
    }

    public async updateCountAndMaxPriorityAsync(searchSettings?: SearchSettings): Promise<void> {
        const url = `${this.actionUrl}/count-and-max-priority`;

        try {
            const response = await this.http.post(url, searchSettings).toPromise();
            this.countTssb = response;
        } catch (error) {
            return await this.handleError(error);
        }
    }

    public getTssbTicketWithStaticData(ticketId: number): Promise<BaseResponse<TssbTicket>> {
        const url = `${this.actionUrl}/${ticketId}`;

        return this.http
            .post(url, null)
            .toPromise()
            .then(response => response as BaseResponse<TssbTicket>)
            .catch(error => this.handleError(error));
    }

    public cancelTicket(ticketId: number, reasonId: number, customReason: string): Promise<TssbTicket> {
        const url = `${this.actionUrl}/${ticketId}/cancel`;
        const params = new HttpParams().append('reasonId', reasonId + '').append('customReason', customReason);

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as TssbTicket)
            .catch(error => this.handleError(error));
    }

    public postponeTicket(
        ticketId: number,
        reasonId: number,
        customReason: string,
        dueDate: Date
    ): Promise<TssbTicket> {
        const url = `${this.actionUrl}/${ticketId}/postpone`;
        const params = new HttpParams()
            .append('reasonId', reasonId + '')
            .append('customReason', customReason)
            .append('dueDate', moment(dueDate).format('YYYY-MM-DDTHH:mm:ss'));

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as TssbTicket)
            .catch(error => this.handleError(error));
    }

    public sendEmail(ticketId: number, email: string): Promise<TssbTicket> {
        const url = `${this.actionUrl}/${ticketId}/contact/email`;
        const params = new HttpParams().append('email', email);

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as TssbTicket)
            .catch(error => this.handleError(error));
    }

    public reassignDealer(ticketId: number, partnerNumber: string, outletId: string): Promise<TssbTicket> {
        const url = `${this.actionUrl}/${ticketId}/reassign-dealer`;
        const params = new HttpParams().append('partnerNumber', partnerNumber).append('outletId', outletId);

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as TssbTicket)
            .catch(error => this.handleError(error));
    }

    public getTicketLogs(ticketId: number): Promise<TssbTicketLog[]> {
        const url = `${this.actionUrl}/${ticketId}/logs`;

        return this.http
            .post(url, null)
            .toPromise()
            .then(response => response as TssbTicketLog[])
            .catch(error => this.handleError(error));
    }

    public getTicketLogsSummary(ticketId: number): Promise<TssbTicketLogsSummary> {
        const url = `${this.actionUrl}/${ticketId}/logs/summary`;

        return this.http
            .post(url, null)
            .toPromise()
            .then(response => response as TssbTicketLogsSummary)
            .catch(error => this.handleError(error));
    }

    public updateCustomerData(
        ticketId: number,
        customerData?: TssbTicketCustomerData
    ): Promise<TssbTicketCustomerData> {
        const url = `${this.actionUrl}/${ticketId}/update-customer-data`;

        return this.http
            .post(url, customerData)
            .toPromise()
            .then(response => response as TssbTicketCustomerData)
            .catch(error => this.handleError(error));
    }

    public updateRemark(ticketId: number, remark?: string): Promise<boolean> {
        const url = `${this.actionUrl}/${ticketId}/update-remark`;

        return this.http
            .post(url, remark.length > 0 ? remark : ' ')
            .toPromise()
            .then(response => response as boolean)
            .catch(error => this.handleError(error));
    }

    public updateCount() {
        this.getCountAndMaxPriority()
            .then(response => {
                response as TssbCountAndMaxPriority;
                this.countTssb = response;
            })
            .catch(error => this.handleError(error));
    }

    public getCountTssb() {
        return this.countTssb;
    }

    public setCountTssb(countTssb: TssbCountAndMaxPriority) {
        this.countTssb = countTssb;
    }

    public getTssbTicketReason(status: string): Promise<TssbTicketReasonDictionary[]> {
        const url = `${this.actionUrl}/reasons`;

        return this.http
            .post(url, status)
            .toPromise()
            .then(response => response as TssbTicketReasonDictionary[])
            .catch(error => this.handleError(error));
    }

    public getTssbTicketReasonDescription(status: string, id: number): Promise<TssbTicketReasonDictionary[]> {
        const url = `${this.actionUrl}/reasons/${id}`;

        return this.http
            .post(url, status)
            .toPromise()
            .then(response => response as TssbTicketReasonDictionary[])
            .catch(error => this.handleError(error));
    }

    public getTicketIdFromVinAppointment(vin: string): Promise<string> {
        const url = `${this.actionUrl}/id-from-vin-appointment`;
        return this.http
            .post(url, vin)
            .toPromise()
            .then(response => response as string)
            .catch(error => this.handleError(error));
    }

    public findLastByVinForCustomer(vehicleVin: string): Promise<TssbTicket> {
        const url = `${this.actionUrl}/last-by-vin-for-customer`;
        const requestBody = {
            vehicleVin
        };
        return this.http
            .post(url, requestBody)
            .toPromise()
            .then()
            .catch(error => this.handleError(error));
    }
}
