import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../../auth/auth.service';
import { StyleSwitcherService } from '../../../core/style-switcher.service';
import { Utils } from '../../../core/utils';

@Component({
    selector: 'oas-pre-registration-checkbox',
    templateUrl: './pre-registration-checkbox.component.html',
    styleUrls: ['./pre-registration-checkbox.component.scss'],
})
export class PreRegistrationCheckboxComponent implements OnInit {
    @Input() public titleTmpl: TemplateRef<any>;

    @Input()
    get email(): string {
        return this._email;
    }
    set email(email: string) {
        this._email = email;
    }
    private _email: string;

    @Output() valueUpdated = new EventEmitter<boolean>();

    public sendPreRegistrationEmail = new UntypedFormControl();
    public loaded$ = new BehaviorSubject<boolean>(false);
    public emailAlreadyAssociatedToGcdmAccount$ = new BehaviorSubject<boolean>(undefined);

    constructor(private authService: AuthService, private styleSwitcherService: StyleSwitcherService) {}

    ngOnInit() {
        this.checkUniqueEmail();
    }

    public checkUniqueEmail(): void {
        if (!Utils.isNullOrUndefined(this.email)) {
            this.authService.checkUniqueUsername(this.email, 'online').subscribe(response => {
                this.loaded$.next(true);
                this.emailAlreadyAssociatedToGcdmAccount$.next(!!response);
                this.sendPreRegistrationEmail.setValue(!response);
                this.emitValue();
            });
        }
    }

    public getCurrentBrandFromStyle(): string {
        return this.styleSwitcherService.brand.getValue();
    }

    public updateEmail(newEmail: string): void {
        this.email = newEmail;
        this.checkUniqueEmail();
    }

    public emitValue(): void {
        this.valueUpdated.emit(this.sendPreRegistrationEmail.value);
    }
}
