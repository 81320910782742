import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppointmentLinkService } from '../../core/api/appointment-link/appointment-link.service';
import { OutletService } from '../../core/api/outlet/outlet.service';
import { VehicleService } from '../../core/api/vehicle/vehicle.service';
import { Appointment, Customer } from '../entities';
@Injectable()
export class AppointmentResolverService {
    constructor(
        private router: Router,
        private appointmentLinkService: AppointmentLinkService,
        private outletService: OutletService,
        private vehiculeService:VehicleService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Appointment> {
        const uuid = route.params.uuid;
        const appointmentId = route.params.appointmentId;
        return this.appointmentLinkService
            .getOneByTypeAndUuidAndAppointmentId('UPDATE', uuid, appointmentId)
            .then(async (result) => {
                const appointment = this.mappingTool(result.appointment);
                route.data = { appointment: { payload: appointment }, customer: null, uuid, appointmentId };
                this.outletService.getOutletSkills(appointment.outlet.id).then(res => {
                    appointment.outlet.skills = res;
                });
                /**
                 * If appointment vehicule has RKR acces
                 */
                const vin = result.appointment && result.appointment.vehicle && result.appointment.vehicle.vin
                if(vin && !state.url.includes("RKR")){
                    const RKR=await this.vehiculeService.getVehicleRemoteKeyRead(vin,undefined)
                    if(RKR)
                    this.router.navigate([state.url],{
                        queryParams:{RKR}}
                        )
                }
                 return appointment;
            })
            .catch(error=>{
                this.router.navigate(["/welcome"],{queryParams:{tokenOrAppointmentInvalid:true}})
                return null
            })
    }
    mappingTool(appointment: Appointment) {
        const customer: Customer = {
            name: appointment.customerName,
            surname: appointment.customerSurname,
            phoneNumber: appointment.customerPhoneNumber,
            vatNumber: appointment.customerVatNumber,
            customerType: appointment.customerType,
            email: appointment.customerEmail,
            gender: appointment.contactAppointment.gender,
            businessName: appointment.customerBusinessName,
            secondSurname: null,
            displayName: `${appointment.customerName} ${appointment.customerSurname}`,
            nationality: { code: 'IT' },
            address: {},
            vehicleList: [appointment.vehicle],
        };
        appointment.customer = customer;
        if(appointment.mobilityList.length>0)
        appointment.mobilityList[0].mobilityType.descriptionLocalized =
            appointment.mobilityList[0].mobilityType.labelList[0].description;

            return appointment;
    }
}
