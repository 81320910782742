import { Injectable } from '@angular/core';
import { MessageAudit } from '../../../shared/entities';
import { AbstractRestService } from '../generic.service';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../../logger.service';

@Injectable()
export class MessageService extends AbstractRestService<MessageAudit, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/message', http, logger);
    }

    public getAllByAppointment(appointmentId: string): Promise<MessageAudit[]> {
        return this.http
            .get(`${this.actionUrl}/by-appointment/${appointmentId}`)
            .toPromise()
            .then(response => response as MessageAudit[])
            .catch(error => this.handleError(error));
    }
}
