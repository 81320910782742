import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { formatDate } from 'ngx-bootstrap/chronos';
import { Observable } from 'rxjs';
import { APPOINTMENT_WO_REGISTRATION_MOCK } from '../../mocks/appointment-wo-registration.mock';
import { AbstractRestService } from '../generic.service';
import {
   ActivityPackage,
   ActivityType,
   Appointment,
   AppointmentCustomStatus,
   FlatratesRequest,
   PackagesRequest,
   Vehicle,
   AppointmentSearch,
   AppointmentFilters,
   MultimediaResource,
   MmEmailModel,
   ActivityDay,
   OutletAllSearch,
   BaseResponse, AppointmentHistory, AppointmentCountForServiceAdvisorToday,
   ContactAppointment,
} from './../../../shared/entities';
import { Logger } from './../../logger.service';
@Injectable()
export class AppointmentService extends AbstractRestService<Appointment, string> {
    public phoneRegexp: RegExp = /(?:(?:\+|00)34(?:[6-7][0-9]{8}|[8-9][1-9][0-9]{7})|(?:\+|00)376(?:[3-46][0-9]{5}|[7-8][0-9]{5})|(?:\+|00)351[2-9][0-9]{8}|(?:\+|00)33[1-79][0-9]{8}|(?:\+|00)49(?:[2-9][0-9]{9,10}|1[5-7][0-9]{8,9})|(?:\+|00)44(?:0?)(?:[1-2][0-9]{8,9}|7[1-57-9][0-9]{8}|7624[0-9]{6})|(?:\+|00)350(?:[2][0-9]{7}|5[46-8][0-9]{6})|(?:\+|00)(?!(?:34|376|351|33|49|44|350|39))[0-9]{4,16})/;
    public mailRegexp: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/appointment', http, logger);
    }

    public book(appointment: Appointment): Promise<string> {
        return this.http
            .post(`${this.actionUrl}`, appointment, { responseType: 'text' })
            .toPromise()
            .then(response => response as string)
            .catch(error => this.handleError(error));
    }

    public getLaborActivitiesByVehicle(flatratesRequest: FlatratesRequest): Promise<BaseResponse<ActivityPackage[]>> {
        const url = `${this.actionUrl}/flatrates`;
        return this.http
            .post(url, flatratesRequest)
            .toPromise()
            .then(response => response as BaseResponse<ActivityPackage[]>)
            .catch(error => this.handleError(error));
    }

    public getFlatPackActivitiesByVehicle(
        flatratesRequest: FlatratesRequest
    ): Promise<BaseResponse<ActivityPackage[]>> {
        const url = `${this.actionUrl}/flatrates-packages`;
        return this.http
            .post(url, flatratesRequest)
            .toPromise()
            .then(response => response as BaseResponse<ActivityPackage[]>)
            .catch(error => this.handleError(error));
    }

    public getPackagesActivitiesByVehicle(packagesRequest: PackagesRequest): Promise<BaseResponse<ActivityType[]>> {
        const url = `${this.actionUrl}/packages`;
        return this.http
            .post(url, packagesRequest)
            .toPromise()
            .then(response => response as BaseResponse<ActivityType[]>)
            .catch(error => this.handleError(error));
    }

    public getAllByOutlet(outletId: string, filters?: AppointmentFilters): Promise<AppointmentSearch> {
        const url = `${this.actionUrl}/by-outlet/${outletId}`;
        return this.http
            .post(url, filters)
            .toPromise()
            .then(response => response as AppointmentSearch)
            .catch(error => this.handleError(error));
    }

    public getAllByOutlets(outletsIds: string[], filters?: AppointmentFilters): Promise<AppointmentSearch> {
        const url = `${this.actionUrl}/by-outlets`;
        const params: OutletAllSearch = {
            outletList: outletsIds,
            filters: filters,
        };
        return this.http
            .post(url, params)
            .toPromise()
            .then(response => response as AppointmentSearch)
            .catch(error => this.handleError(error));
    }

    public getAllByOutletWithDates(outletId: string, filters?: AppointmentFilters): Promise<Appointment[]> {
        const url = `${this.actionUrl}/by-outlet-dates/${outletId}`;
        return this.http
            .post(url, filters)
            .toPromise()
            .then(response => response as Appointment[])
            .catch(error => this.handleError(error));
    }

    public getAllByDealer(dealerId: string): Promise<Appointment[]> {
        return this.http
            .get(`${this.actionUrl}/by-outlet/${dealerId}`)
            .toPromise()
            .then(response => response as Appointment[])
            .catch(error => this.handleError(error));
    }

    public getNextByVehicle(vehicleList: Vehicle[]): Promise<Appointment[]> {
        return this.http
            .post(`${this.actionUrl}/courtesy-car/list`, vehicleList)
            .toPromise()
            .then(response => response as Appointment[])
            .catch(error => this.handleError(error));
    }

    public getOne(id: string, fetchAttributes?: string[]): Promise<Appointment> {
        let result: Observable<any>;
        const url = `${this.actionUrl}/${id}`;

        if (fetchAttributes) {
            const params = new HttpParams().set(
                'fetch',
                fetchAttributes.reduce((prevVal, currVal) => `${prevVal}-${currVal}`)
            );
            result = this.http.get(url, { params });
        } else {
            result = this.http.get(url);
        }

        return result
            .toPromise()
            .then(response => response as Appointment)
            .catch(error => this.handleError(error));
    }

    public getAppointment(id: string, fetchAttributes?: string[]): Promise<BaseResponse<Appointment>> {
        let result: Observable<any>;
        const url = `${this.actionUrl}/${id}`;

        if (fetchAttributes) {
            const params = new HttpParams().set(
                'fetch',
                fetchAttributes.reduce((prevVal, currVal) => `${prevVal}-${currVal}`)
            );
            result = this.http.get(url, { params });
        } else {
            result = this.http.get(url);
        }

        return result
            .toPromise()
            .then(response => response as BaseResponse<Appointment>)
            .catch(error => this.handleError(error));
    }

    public changeCourtesyCarReturnTime(appointment: string, activityDays: ActivityDay[]): Promise<void> {
        return this.http
            .post(`${this.actionUrl}/${appointment}/return-courtesy-car-to-outlet`, activityDays)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public changeCourtesyVehicleDates(appointment: string, activityDays: ActivityDay[]): Promise<void> {
        return this.http
            .post(`${this.actionUrl}/${appointment}/change-courtesy-vehicle-dates`, activityDays)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public start(id: string, attendedBy: string): Promise<void> {
        const url = `${this.actionUrl}/${id}/start`;
        return this.http
            .put(url, attendedBy)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public complete(id: string): Promise<void> {
        const url = `${this.actionUrl}/${id}/complete`;
        return this.http
            .post(url, null)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public delivered(id: string): Promise<void> {
        const url = `${this.actionUrl}/${id}/delivered`;
        return this.http
            .post(url, null)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public validate(id: string): Promise<void> {
        const url = `${this.actionUrl}/${id}/validate`;
        return this.http
            .post(url, null)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public getAllBySadv(sadvId: string): Promise<Appointment[]> {
        return this.http
            .get(`${this.actionUrl}/by-sadv/${sadvId}`)
            .toPromise()
            .then(response => response as Appointment[])
            .catch(error => this.handleError(error));
    }

    public loadAppointmentCountForSadvToday(outletId: string,selectedDate: Date): Promise<AppointmentCountForServiceAdvisorToday[]> {
        let date = formatDate(selectedDate, 'YYYY-MM-DD', 'en_US')
        return this.http
            .get(`${this.actionUrl}/by-outlet-date/${outletId}/${date}`)
            .toPromise()
            .then(response => response as AppointmentCountForServiceAdvisorToday[])
            .catch(error => this.handleError(error));
    }

    public update(entity: Appointment, id: string, notifyCustomer?: boolean): Promise<void> {
        const url = `${this.actionUrl}/${id}`;
        // const params = new HttpParams().set('notifyCustomer', notifyCustomer ? 'true' : 'false');

        return this.http
            .put(url, entity)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }
    /**
     * Checking if user can update the appointement or not.
     * @param uuid
     * @param idAppointement
     */
    public checkIfTokenAndAppointementISValid(uuid: string, idAppointement: string): Promise<Appointment> {
        // const url = `${this.actionUrl}/check-if-token-is-valid/${uuid}/${idAppointement}`;

        // return this.http
        //     .get(url)
        //     .toPromise()
        //     .then(() => null)
        //     .catch(error => this.handleError(error));
       return  new Promise((resolve,reject)=>{
            resolve(APPOINTMENT_WO_REGISTRATION_MOCK)
        })
    }

    public removeCourtesyCarActivity(id: string): Promise<void> {
        const url = `${this.actionUrl}/${id}/courtesy-car/activity`;
        return this.http
            .delete(url)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public getCustomStatus(id: string): Promise<AppointmentCustomStatus[]> {
        return this.http
            .get(`${this.actionUrl}/${id}/custom-status`)
            .toPromise()
            .then(response => response as AppointmentCustomStatus[])
            .catch(error => this.handleError(error));
    }

    public updateAppointmentCustomStatus(id: string, customStatusId: number): Promise<AppointmentCustomStatus[]> {
        return this.http
            .post(`${this.actionUrl}/${id}/update-custom-status/${customStatusId}`, null)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public getCustomStatusByCountry(countryId: string): Promise<AppointmentCustomStatus[]> {
        return this.http
            .get(`${this.actionUrl}/custom-status/by-country/${countryId}`)
            .toPromise()
            .then(response => response as AppointmentCustomStatus[])
            .catch(error => this.handleError(error));
    }

    public getCustomStatusByStatus(status: string): Promise<AppointmentCustomStatus[]> {
        return this.http
            .get(`${this.actionUrl}/custom-status/by-status/${status}`)
            .toPromise()
            .then(response => response as AppointmentCustomStatus[])
            .catch(error => this.handleError(error));
    }

    public getCustomStatusByStatusAndCountry(status: string, countryId: string): Promise<AppointmentCustomStatus[]> {
        return this.http
            .get(`${this.actionUrl}/custom-status/by-status/${status}/by-country/${countryId}`)
            .toPromise()
            .then(response => response as AppointmentCustomStatus[])
            .catch(error => this.handleError(error));
    }

    public addCustomStatus(appointmentCustomStatus: AppointmentCustomStatus): Promise<void> {
        return this.http
            .post(`${this.actionUrl}/custom-status`, appointmentCustomStatus)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public updateCustomStatus(appointmentCustomStatus: AppointmentCustomStatus): Promise<void> {
        return this.http
            .put(`${this.actionUrl}/custom-status`, appointmentCustomStatus)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public fastAppointmentToAppointment(appointmentId: string, customerDmsId: string, plate: string): Promise<void> {
        return this.http
            .put(`${this.actionUrl}/fast/${appointmentId}/customerDms/${customerDmsId}`, plate)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public async isFastConvertibleToNormal(appointmentId: string): Promise<boolean> {
        try {
            const response = await this.http.get(`${this.actionUrl}/${appointmentId}/convert`).toPromise();
            return response as boolean;
        } catch (error) {
            return await this.handleError(error);
        }
    }

    public bookQuickAppointment(appointment: Appointment): Promise<void> {
        return this.http
            .post(`${this.actionUrl}/quick-appointment`, appointment)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public loadLastAppointmentByUser(customerId: String): Promise<Appointment> {
        return this.http
            .get(`${this.actionUrl}/last-by-user/${customerId}`)
            .toPromise()
            .then(response => response as Appointment)
            .catch(error => this.handleError(error));
    }

    public getCourtesyVehicleContract(appointmentId: string): Promise<Blob> {
        const url = `${this.actionUrl}/${appointmentId}/courtesy-vehicle-contract`;
        return this.http
            .get(url, { responseType: 'blob' })
            .toPromise()
            .then(response => response as Blob)
            .catch(error => this.handleError(error));
    }
    public countIspaAppointment(): Promise<Number> {
        return this.http
            .get(`${this.actionUrl}/count-ispaAppointment`)
            .toPromise()
            .then()
            .catch(error => this.handleError(error));
    }

    public associateResource(resourcesToAssociate: MultimediaResource[], appointmentId: string): Promise<void> {
        const url = `${this.actionUrl}/associate-resources/${appointmentId}`;

        return this.http
            .post(url, resourcesToAssociate)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public disassociateResource(idsToDisassociate: number[]): Promise<void> {
        const url = `${this.actionUrl}/disassociate-resources`;

        return this.http
            .post(url, idsToDisassociate)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public sendMultimediaResourceMail(emailModel: MmEmailModel): Promise<void> {
        const url = `${this.actionUrl}/multimedia-mail`;

        return this.http
            .post(url, emailModel)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public updateMultimediaResource(resourceToUpdate: MultimediaResource): Promise<void> {
        const url = `${this.actionUrl}/update-resource`;

        return this.http
            .post(url, resourceToUpdate)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public getResourceFromAppointment(appointmentId: string): Promise<MultimediaResource[]> {
        const url = `${this.actionUrl}/get-resources/${appointmentId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as MultimediaResource[])
            .catch(error => this.handleError(error));
    }

    public getQuickAppointmentFromTssb(tssbId: string): Promise<BaseResponse<Appointment>> {
        const url = `${this.actionUrl}/quick-appointment-tssb/${tssbId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as BaseResponse<Appointment>)
            .catch(error => this.handleError(error));
    }

    public getAppointmetsByPlate(plate: string): Promise<Appointment[]> {
        const url = `${this.actionUrl}/by-plate`;

        return this.http
            .post(url, plate)
            .toPromise()
            .then(response => response as Appointment[])
            .catch(error => this.handleError(error));
    }

    public getAppointmentHistory(plate: string): Promise<Appointment[]> {
        const url = `${this.actionUrl}/appointment-history`;
        return this.http
            .post(url, plate)
            .toPromise()
            .then(response => response as Appointment[])
            .catch(error => this.handleError(error));
    }

    public getAppointmentHistoryFleet(idFleet: number): Promise<Appointment[]> {
        const url = `${this.actionUrl}/appointment-history-fleet`;
        return this.http
            .post(url, idFleet)
            .toPromise()
            .then(response => response as Appointment[])
            .catch(error => this.handleError(error));
    }

    deleteWithCustomStatus(id: String, customStatusId: number): Promise<void> {
        const url = `${this.actionUrl}/${id}/${customStatusId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public fastAppointmentMigrationDateToConsumeCapacity(): Promise<Date> {
        const url = `${this.actionUrl}/fast-appointment-migration-date-consume-capacity`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Date)
            .catch(error => this.handleError(error));
    }

   public getAppointmentDetails(appointmentId: string): Promise<AppointmentHistory[]> {
      return this.http
         .get(`${this.actionUrl}/appointment-details/${appointmentId}`)
         .toPromise()
         .then(response => response as AppointmentHistory[])
         .catch(error => this.handleError(error));
   }

   public updateContactAppointment(contactAppointment: ContactAppointment): Promise<any> {
    return this.http
       .put(`${this.actionUrl}/contact/`, contactAppointment)
       .toPromise();
 } 
}
