<div class="dashboard-header wizard__dashboard-header">
    <ng-container *ngTemplateOutlet="header"> </ng-container>
</div>

<div class="container dashboard-content vehicle-data-content" [ngClass]="tcModalClosed ? 'tc_modal_closed':'tc_modal_opened'">
    <div class="col-md-6">
        <h2 class="vehicle-title" i18n="@@vehicleDataStep">
            Vehicle data
        </h2>
    </div>
    <div class="col-md-12">
        <oas-vehicle-form
            #oasVehicleForm
            *ngIf="!manualStep"
            [tcModalClosed]="tcModalClosedSubject | async"
            [vehicle]="vehicle"
            [searchButtonEnabled]="true"
            [hideErrorPopup]="true"
            [hideDataStep]="true"
            [lyAllowVehicleThatHaveSameBrandOfTheWebsite]="true"
            [formClass]="'vehicle-form-container'"
            [showLabelsOverInput]="true"
            (errorsWithExternalSystem)="vehicleNotFoundFromExternalSystem($event)"
            (vehicleNotFound)="vehicleNotFoundFromExternalSystem($event)"
            (vehicleEmitter)="updateVehicle($event)"
            (fiscalCodeChange)="updateCustomerFiscalCode($event)"
        ></oas-vehicle-form>

        <oas-vehicle-form-manual
            *ngIf="manualStep"
            [plate]="plate"
            [kilometers]="kilometers"
            [hideBackButton]="hideBackButtonForManualStep"
            (vehicleEmitter)="updateVehicle($event)"
            (backButtonClicked)="onBackButtonClickedFromManualForm()"
        ></oas-vehicle-form-manual>
    </div>
</div>

<ng-template #termsAndConditionsTemplate>
    <div class="modal-body">
        <button
                type="button"
                class="close"
                style="opacity: 100;"
                aria-label="Close"
                (click)="goToLogin()"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        <oas-terms-and-conditions
            [countryCode]="countryConfiguration.countryCode"
            (onAgree)="onAgree()"
        ></oas-terms-and-conditions>
    </div>
</ng-template>

<ng-template #header>
    <div class="row no-gutters">
        <div class="container-your-dashboard-and-logo">
            <div class="container-your-dashboard">
                <h1 *ngIf="isMini; else notMini" class="dashboard-header__title" i18n="@@newRequestStepT">
                    Select your vehicle
                </h1>
                <ng-template #notMini>
                    <h1 class="dashboard-header__title" i18n="@@newRequestStepTT">
                        Select your vehicle
                    </h1>
                </ng-template>
            </div>
            <div class="container-logo">
                <oas-logo></oas-logo>
            </div>
        </div>
        <div class="col-md-4">
            <div class="dashboard-header__title__wrapper">
                <a (click)="goToLogin()" class="dashboard-header__dismiss-request">
                    <i class="fa fa-chevron-left"> </i>
                    <span i18n="@@dismissRequestQuestion">Dismiss this request?</span>
                </a>
            </div>
        </div>
        <div class="col-md-4 col-md-offset-0 col-sm-8 col-sm-offset-2">
            <div class="dashboard-header__hero-image--wrapper">
                <oas-vehicle-picture-ucp
                    [vin]="appointment.vehicle.vin"
                    [vehicleSeriesId]="appointment.vehicle.model?.series?.id"
                ></oas-vehicle-picture-ucp>
            </div>
        </div>
    </div>

    <ng-container *ngTemplateOutlet="wizardSteps"> </ng-container>
</ng-template>

<ng-template #wizardSteps>
    <div class="wizard-steps header__wizard-steps" [ngClass]="tcModalClosed ? 'tc_modal_closed':'tc_modal_opened'">
        <div class="container">
            <div class="row row-eq-height">
                <div class="col-md-4 col-sm-4 col-xs-4">
                    <div class="wizard-steps__step">
                        <div class="wizard-steps__step-name">
                            <div class="wizard-steps__step-name--inner">
                                <div class="text-center">
                                    <span class="bmw bmw-wizard_ico_service icon-lg"></span>
                                </div>
                                <div i18n="@@services">Services</div>
                            </div>
                        </div>
                        <div class="wizard-steps__step-description"></div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4">
                    <div class="wizard-steps__step">
                        <div class="wizard-steps__step-name">
                            <div class="wizard-steps__step-name--inner">
                                <div class="text-center">
                                    <i class="bmw bmw-wizard_ico_dealer icon-lg"></i>
                                </div>
                                <div i18n="@@dealer">Dealer</div>
                            </div>
                        </div>
                        <div class="wizard-steps__step-description">
                            <ng-container *ngIf="preselectedOutlet">
                                <div class="row" *ngIf="preselectedOutlet">
                                    {{ preselectedOutlet?.businessName }}
                                </div>
                                <div class="row">{{ preselectedOutlet?.address.address }}</div>
                                <div class="row">{{ preselectedOutlet?.address.stairInt }}</div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-4">
                    <div class="wizard-steps__step">
                        <div class="wizard-steps__step-name">
                            <div class="wizard-steps__step-name--inner">
                                <div class="text-center">
                                    <i class="bmw bmw-wizard_ico_schedule icon-lg"></i>
                                </div>
                                <div i18n="@@schedule">Schedule</div>
                            </div>
                        </div>
                        <div class="wizard-steps__step-description"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
