<body>
    <div class="d-flex align-items-center justify-content-center flex-column">
        <br />
        <p style="font-size: 32px;">Regolamento sui servizi digitali
            (“DSA”).</p>
            <br />
        <div>
            <p style="text-align: center;">Nella misura in
                cui BMW Italia S.p.A. (“BMW”, “noi”, “ci”, “nostro/i”) offre servizi di intermediazione ai sensi dell’art. 3
                lett. g) DSA su questo sito web, valgono le seguenti informazioni:</p>
        </div><br />
    </div>
    <div class="bordered-div"><br />
        <h3>1. Punto di contatto per
            le comunicazioni relative al DSA (artt. 11 e 12 DSA)</h3><br />
        <p>Il punto di contatto unico per le autorità
                degli Stati membri, per la Commissione e per il Comitato di cui all&#39;art. 61 del DSA (art. 11,
                par. 1, DSA) e per gli utenti dei nostri servizi (art. 12, par. 1, DSA) è <a
                href="mailto:DSA.it@bmw.it" target="_blank">DSA.it@bmw.it</a></p>
        <p>L&#39;utente può comunicare
            con noi in italiano e in lingua inglese.</p>
        <p><br /></p>
    </div>
    <div class="bordered-div"><br />
        <h3>2. Relazioni sulla trasparenza (art. 15
            DSA)</h3><br />
        <p>Ai sensi
            dell&#39;art. 15, par. 1 DSA, siamo tenuti a pubblicare una volta all&#39;anno relazioni sulla
            trasparenza della moderazione dei contenuti da noi effettuata.</p>
        <p><br /></p>
    </div>
    <div class="bordered-div"><br />
        <h3>3. Meccanismo di segnalazione e azione
            (art. 16 DSA)</h3><br />
        <p>Ai sensi dell&#39;art. 16 DSA, le persone e
                gli enti hanno la possibilità di segnalare informazioni su contenuti che ritengono illegali sul
                presente sito. È possibile farlo inviando un’e-mail a <a
                href="mailto:DSA.it@bmw.it" class="a" target="_blank">DSA.it@bmw.it</a> indicando i seguenti
            elementi:</p>
        <ol type="a">
            <li>
                <p>
                    una spiegazione sufficientemente motivata delle ragioni per cui si ritiene che le informazioni
                    in questione costituiscano contenuti illegali;</p>
            </li>
            <li>
                <p>
                    un’indicazione chiara della posizione elettronica esatta in cui tali informazioni sono
                    memorizzate, come l&#39;indirizzo o gli indirizzi URL precisi o, se necessario, altre
                    informazioni pertinenti per l&#39;identificazione del contenuto illegale in relazione alla
                    natura del contenuto e alla natura specifica del servizio;</p>
            </li>
            <li>
                <p>
                    Il nome e l&#39;indirizzo e-mail dell&#39;utente (tranne nel caso di informazioni che
                    l&#39;utente ritiene si riferiscano a un reato relativo all&#39;abuso sessuale, allo
                    sfruttamento sessuale, alla pedopornografia, al contatto con bambini per scopi sessuali o
                    all&#39;incitamento, al favoreggiamento o al tentativo di commettere tali reati). In questi o in
                    altri casi in cui si desidera inviare una segnalazione senza possibilità di essere identificati,
                    è possibile ricorrere al seguente numero di telefono 0251610111 specificando che si vuole
                    procedere ad una segnalazione anonima ai sensi del Regolamento DSA;</p>
            </li>
            <li>
                <p>
                    una dichiarazione in cui l&#39;utente afferma di ritenere in buona fede che le informazioni e i
                    riferimenti contenuti nella segnalazione siano accurati e completi.</p>
                <p><br /></p>
                <p>Elaboreremo tutte
                    le segnalazioni senza indebito ritardo in modo attento, imparziale e oggettivo; informeremo
                    immediatamente la persona o l&#39;ente segnalante della nostra decisione e degli eventuali
                    rimedi legali disponibili, salvo che la segnalazione sia stata effettuata in forma anonima.</p>
                <p><br /></p>
            </li>
        </ol>
    </div>
    <div class="bordered-div"><br />
        <h3>4. Informazioni sulle
            limitazioni relative ai contenuti dell&#39;utente (art. 14, par. 1, frase 3, DSA), sul nostro sistema
            interno di gestione dei reclami (art. 20 DSA) e sulle possibilità di risoluzione extragiudiziale delle
            controversie (art. 21 DSA)</h3><br />
        <p>Le
            <i>seguenti informazioni si riferiscono al nostro sistema interno di gestione dei reclami (art. 20 DSA)
                e alle opzioni di risoluzione extragiudiziale delle controversie (art. 21 DSA), con riferimento
                esclusivamente agli utenti dei servizi BMW qualificabili come &quot;piattaforme online&quot; ai
                sensi del DSA.</i></p>
        <p>Nello
            svolgimento delle nostre attività di mediazione, qualora ritenessimo che gli utenti abbiano violato la
            legge o i nostri termini e condizioni d’uso (di seguito: “<b>Condizioni</b>”), potremmo prendere alcune
            decisioni volte a restringere i contenuti o gli account degli utenti (compresi gli account delle persone
            e degli enti segnalanti) ai sensi del DSA.</p>
        <p>Ad
            esempio, possiamo decidere di (i) limitare o sospendere la visibilità dei contenuti dell&#39;utente,
            (ii) sospendere o interrompere in tutto o in parte la fornitura dei nostri servizi agli utenti, (iii)
            sospendere o chiudere l&#39;account utente, (iv) limitare le opportunità di monetizzazione per i
            contenuti dell&#39;utente o (v) negare ai proprietari di attività commerciali l&#39;uso della
            piattaforma a meno che non sia possibile indentificare (tracciare) tali proprietari come richiesto dal
            DSA.</p>
        <p>Potremmo
            anche decidere di non agire in base ad una segnalazione inviata da degli utenti qualora ritenessimo che
            i contenuti non siano potenzialmente illegali o non violino le nostre Condizioni.</p>
        <h5>- Sistema interno di
            gestione dei reclami:</h5>
        <p>Se
            gli utenti non sono d&#39;accordo con la decisione assunta, possono presentare gratuitamente un reclamo
            contro questa decisione di BMW tramite il nostro sistema interno di gestione dei reclami.</p>
        <p>I reclami possono essere presentati entro 6
                mesi dal ricevimento della decisione impugnata all&#39;indirizzo <a
                href="mailto:DSA.it@bmw.it" class="a" target="_blank">DSA.it@bmw.it.</a> Qualora avessimo
            bisogno di ulteriori informazioni per gestire il reclamo, i denuncianti possono essere contattati dal
            nostro personale addetto a tal fine.</p>
        <p>I
            reclami verranno trattati in modo tempestivo, non discriminatorio, attento e imparziale sotto la
            supervisione di personale qualificato. Non appena avremo preso una decisione, questa sarà immediatamente
            comunicata al denunciante.</p>
        <p><br /></p>
        <h5>- Risoluzione
            extragiudiziale delle controversie dinanzi a organismi competenti approvati:</h5>
        <p>Al fine
            di risolvere le controversie in relazione a decisioni prese nell&#39;ambito del nostro sistema interno
            di gestione dei reclami, esiste, tra l&#39;altro, la possibilità di ricorrere alla risoluzione
            extragiudiziale delle controversie dinanzi ad un organismo di risoluzione extragiudiziale delle
            controversie autorizzato ai sensi dell&#39;art. 21 del DSA.</p>
        <p>Gli
            organismi accreditati di risoluzione extragiudiziale delle controversie sono organismi imparziali e
            indipendenti espressamente autorizzati dagli Stati membri dell&#39;UE che, in virtù delle loro capacità
            e competenze, sono in grado di esaminare le controversie che vengono loro sottoposte.</p>
        <p>BMW
            collaborerà con l&#39;organismo di risoluzione extragiudiziale delle controversie nell&#39;ambito dei
            requisiti di legge; tuttavia, BMW non è vincolata alle decisioni dell&#39;organo di risoluzione
            extragiudiziale delle controversie.</p>
        <p>
            Ulteriori dettagli su qualsiasi risoluzione extragiudiziale delle controversie saranno forniti agli
            utenti, se del caso, insieme a eventuali decisioni impugnabili.</p>
        <p>Le
            informazioni di cui sopra non limitano i diritti degli utenti di far valere le proprie istanze nei
            confronti di BMW nelle sedi giudiziarie che riterranno opportune.</p>
        <p><br /></p>
    </div>
    <div class="bordered-div"><br />
        <h3>5. Misure e protezione contro gli abusi
            (art. 23 DSA)</h3><br />
        <p>Previa
            diffida, sospendiamo la fornitura dei nostri servizi per un periodo di tempo ragionevole agli utenti che
            mettono a disposizione in modo ricorrente contenuti manifestamente illegali. Inoltre, previa diffida
            sospendiamo per un periodo di tempo ragionevole il trattamento delle notifiche e dei reclami ricevuti
            attraverso le procedure di notifica e correzione o i sistemi interni di gestione dei reclami di persone,
            enti o denuncianti che presentano frequentemente segnalazioni o reclami manifestamente infondati. Nel
            decidere in merito alla sospensione, valutiamo caso per caso in modo tempestivo, attento e obiettivo se
            l&#39;utente, la persona, l&#39;ente o il denunciante è coinvolto in un uso improprio, tenendo conto di
            tutti i fatti e le circostanze pertinenti che emergono dalle informazioni a nostra disposizione. Tali
            circostanze, che prendiamo in considerazione per valutare se c&#39;è stato un uso improprio e la durata
            ragionevole di un&#39;eventuale sospensione, includono, come minimo:</p>
        <ol type="a">
            <li>
                <p>
                    il numero assoluto di contenuti manifestamente illegali forniti in un determinato periodo di
                    tempo;</p>
            </li>
            <li>
                <p>
                    la loro quota relativa nel numero totale di singole informazioni fornite o comunicate in un
                    determinato periodo;</p>
            </li>
            <li>
                <p>la gravità dei
                    casi di abuso, compresa la natura del contenuto illegale e le sue conseguenze;</p>
            </li>
            <li>
                <p>
                    le intenzioni perseguite dall&#39;utente, dalla persona, dall&#39;entità o dal denunciante, se
                    tali intenzioni possono essere accertate.</p>
                <p><br /></p>
            </li>
        </ol>
    </div>
    <div class="bordered-div"><br />
        <h3>6. Ottenimento delle autocertificazioni
        </h3><br />
        <p>Entro il
            16.2.2025, BMW si adopererà per ottenere da tutti i venditori che offrono prodotti e/o servizi sulle piattaforme
            online accessibili su questo sito web la loro autocertificazione ai sensi dell&#39;articolo 30, paragrafo 1,
            lettera e) del DSA, in cui si impegnano a offrire solo prodotti e servizi conformi alle disposizioni applicabili
            del diritto dell&#39;Unione.</p>
    </div>
    <div class="bordered-div"><br />
        <h3>7. Informazioni sul mercato
        </h3><br />
        <p>Il numero medio mensile di utenti attivi del {{brand?.replace("_", " ")}} Service Check-In nell'Unione Europea ai sensi dell’Articolo 24 (2) 
            della Legge sui Servizi Digitali è inferiore a 50.000 (utilizzando il rispettivo valore soglia).<br/>
            Per determinare questo valore, abbiamo valutato statisticamente gli utenti attivi per ciascuno degli ultimi 
            sei mesi, tenendo conto dei requisiti tecnici e di protezione dei dati, e da cui abbiamo calcolato la media mensile. Al 29 febbraio 2024.</p>
    </div>
    
</body>