import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { OasValidators } from '../../../core/validator/oas-validators';
import { UserFormComponent } from './user-form.component';

@Component({
    selector: 'oas-user-form-create',
    templateUrl: './user-form-create.component.html',
})
export class UserFormCreateComponent extends UserFormComponent implements OnInit {
    public confirmPassword: string;

    constructor(protected formBuilder: UntypedFormBuilder, protected authService: AuthService) {
        super(formBuilder, authService);
    }

    public ngOnInit() {
        this.createForm();
    }

    private createForm() {
        this.userForm = this.formBuilder.group(
            {
                username: [{ value: '', disabled: true }, Validators.required, this.validateUniqueUsername],
                password: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(this.minLength),
                        Validators.maxLength(this.maxLength),
                        (pw: AbstractControl) =>
                            OasValidators.customerCompliantPassword(pw, this.getUsernameFromFormGroup(this.userForm)),
                    ]),
                ],
                confirmPassword: [''],
            },
            {
                validator: this.matchPassword,
                updateOn: 'blur',
            }
        );
    }

    private validateUniqueUsername = (control: AbstractControl) =>
        this.authService.checkUniqueUsername(control.value, 'online').subscribe(response => {
            if (response) {
                return { oasUsernameValidator: true };
            } else {
                return null;
            }
        });
}
