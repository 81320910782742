import { CustomerModule } from './../customer/customer.module';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthService } from './auth.service';
import { AuthInterceptor } from './auth.interceptor';
import { AuthRoutingModule } from './auth-routing.module';
import { ActivateUserComponent } from './registration/activate-user/activate-user.component';
import { BrandStyleSwitcherComponent } from './brand-style-switcher.component';
// BMWDS-302
import { OnlineModule } from './../online/online.module';
import { ChangeActivationUserComponent } from './registration/change-activation-user/change-activation-user.component';

@NgModule({
    imports: [CustomerModule, OnlineModule, AuthRoutingModule],
    declarations: [ActivateUserComponent, BrandStyleSwitcherComponent, ChangeActivationUserComponent],
    providers: [
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
})
export class AuthModule {}
