<div class="horizontal-scroll" style="margin-right: 35px;" *ngIf="listCcm?.length > 0 else noServicesToDisplay">
   <table class="table table-bordered table-striped">
      <thead>
      <tr>
         <th class="col-md-2" i18n="@@description">Description</th>
         <th class="col-md-2" i18n="@@urgency">Urgency</th>
         <th class="col-md-1" i18n="@@km">KM</th>
         <th class="col-md-1" i18n="@@timestamp">Timestamp</th>
         <th class="col-md-6" i18n="@@longText">Long text</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let event of listCcm">
         <tr *ngFor="let ccm of event.ccms; let i=index">
            <td class="col-md-2" style="text-align: center" *ngIf="i==0" [rowSpan]="i == 0 ? event.ccms.length : 0">
               {{event.text != null && event.text.length > 0 ? event.text : event.identifier}}
            </td>
            <td class="col-md-2" style="text-align: center" *ngIf="i==0" [rowSpan]="i == 0 ? event.ccms.length : 0">
               <span
                  class="cbs-state"
                  [style.color]="event.urgency == 40 ? 'RED' : 'YELLOW'"
               >
                  ●
               </span>&nbsp;
               <span *ngIf="event.urgency == 40" i18n="@@urgent">Urgent</span>
               <span *ngIf="event.urgency != 40" i18n="@@notUrgent">Not urgent</span>
            </td>
            <td class="col-md-1" style="text-align: center">
                  {{ccm.mileage}}&nbsp;<span i18n="@@km">KM</span>
            </td>
            <td class="col-md-1" style="text-align: center">
                  {{ccm.eventTimestamp | datex:'dd/MM/yyyy' }}<br>at {{ccm.eventTimestamp | datex: 'shortTime' }}
            </td>
            <td class="col-md-6">
               <b>{{ccm.shortText}}</b><br>{{ccm.longText}}
            </td>
         </tr>
      </ng-container>
      </tbody>
   </table>
</div>

<ng-template #noServicesToDisplay>
   <div class="row">
      <div class="col-md-12 cbs-service_no-item" i18n="@@noServicesToDisplay">No services to display</div>
   </div>
</ng-template>
