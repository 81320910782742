import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { DateHttpInterceptor } from './date-http.interceptor';
import { PageNotFoundComponent } from './not-found.component';
import { TechnicalSettingConfiguration } from './shared/technical-setting-configuration';

@NgModule({
    // keep AppRoutingModule after all Routing Modules (e.g. CoreModule may have its own CoreRoutingModule)
    imports: [
        BrowserModule, 
        CoreModule, 
        AuthModule, 
        AppRoutingModule
    ],
    declarations: [AppComponent, PageNotFoundComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DateHttpInterceptor,
            multi: true,
        },
        BsModalService,
        Title,
        TechnicalSettingConfiguration,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    // Diagnostic only: inspect router configuration
    constructor(router: Router) {}
}
