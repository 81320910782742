import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { CountryConfigurationService } from '../../../../configuration/country-configuration.service';
import { environment } from '../../../../environments/environment';
import { OutletMobilityBaseComponent } from '../../../backoffice/shared/appointment/outlet-mobility-base.component';
import { DictionaryService } from '../../../core/api/dictionary.service';
import { Utils } from '../../../core/utils';
import { AlertErrorComponent } from '../../../shared/alert/alert-error.component';
import { dataAnalytics } from '../../../shared/data-analytics';
import { TracyService } from '../../../shared/tracy.service';
import { AppComponent } from './../../../app.component';
import { MobilityService } from './../../../core/api/mobility/mobility.service';
import { AlertWarningComponent } from './../../../shared/alert/alert-warning.component';
import {
   CourtesyVehicleWarning, Customer, GeoMarker,


   Mobility,
   Outlet,
   OutletMobilityType,
   VehicleBrand
} from './../../../shared/entities';
import * as moment from 'moment';
import { MobilityUtils } from '../../../core/mobility-utils';


@Component({
    selector: 'oas-outlet-mobility',
    templateUrl: './outlet-mobility.component.html',
    styleUrls: ['./outlet-mobility.component.scss'],
})
export class OutletMobilityComponent extends OutletMobilityBaseComponent implements OnInit {
    @Input() public showMobilityAddressAlert = false;
    @Input() public vehicleBrand: VehicleBrand;
    @Input() public outlet: Outlet;

    @ViewChild('addressDetailTemplate') addressDetailTemplate: TemplateRef<any>;
    @ViewChild('editPickAddressModal') editPickAddressModal: TemplateRef<any>;
    @ViewChild('editDeliveryAddressModal') editDeliveryAddressModal: TemplateRef<any>;
    @ViewChild(AlertWarningComponent) private alertWarning: AlertWarningComponent;
    // @ViewChild('mobilityAddressEmptyTitleTmpl') private mobilityAddressEmptyTitleTmpl: TemplateRef<any>;
    // @ViewChild('mobilityAddressEmptyTextTmpl') private mobilityAddressEmptyTextTmpl: TemplateRef<any>;
    @ViewChild('mobilityPickupAndDeliveryTitleTmpl') private mobilityPickupAndDeliveryTitleTmpl: TemplateRef<any>;
    @ViewChild('mobilityPickupAndDeliveryTextTmpl') private mobilityPickupAndDeliveryTextTmpl: TemplateRef<any>;
    @ViewChild('errorPickupDelivery') private errorPickupDelivery: AlertErrorComponent;
    @ViewChild(AlertWarningComponent) private distanceLimitExceeded: AlertWarningComponent;
    @ViewChild('distanceLimitExceededTitleTmpl') private distanceLimitExceededTitleTmpl: TemplateRef<any>;
    @ViewChild('distanceLimitExceededTextTmpl') private distanceLimitExceededTextTmpl: TemplateRef<any>;

    public outletMobilityTypes: OutletMobilityType[];
    public errorPickupDeliveryTitle;
    public errorPickupDeliveryText;
    public local: string;
    public servicesBannerText = new BehaviorSubject<SafeHtml>(undefined);
    public courtesyVehicleWarning: CourtesyVehicleWarning = {};

    constructor(
        public appComponent: AppComponent,
        private mobilityService: MobilityService,
        public formBuilder: UntypedFormBuilder,
        private dictionaryService: DictionaryService,
        private _sanitizer: DomSanitizer,
        private tracyService: TracyService,
        protected countryConfigurationService: CountryConfigurationService,
    ) {
        super(formBuilder, countryConfigurationService, '00:00', '00:00');
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
    }

    public ngOnInit() {
        this.fillServicesBanner();
        this.initPickupAndDelivery();
        this.getCourtesyVehicleWarningTitle();
        this.getCourtesyVehicleWarningText();
    }

    private getCourtesyVehicleWarningTitle() {
        this.dictionaryService.findByName(this.vehicleBrand === 'BMW_I' || this.vehicleBrand === 'BMWi' ? 'COURTESY_VEHICLE_WARNING_TITLE_BMW' : 'COURTESY_VEHICLE_WARNING_TITLE_' + this.vehicleBrand).then(response => {
            this.courtesyVehicleWarning.title = response.value;
        })
    }

    private getCourtesyVehicleWarningText() {
        this.dictionaryService.findByName(this.vehicleBrand === 'BMW_I' || this.vehicleBrand === 'BMWi' ? 'COURTESY_VEHICLE_WARNING_DESCRIPTION_BMW' : 'COURTESY_VEHICLE_WARNING_DESCRIPTION_' + this.vehicleBrand).then(response => {
            this.courtesyVehicleWarning.description = response.value;
        })
    }

    public setCourtesyVehicleWarningValue(courtesyVehicleWarning: CourtesyVehicleWarning) {
        this.alertWarning.title = courtesyVehicleWarning.title;
        this.alertWarning.text = courtesyVehicleWarning.description;
    }

    public ngAfterViewInit(): void {
        const errorPickupDeliveryTitlemsg = this.errorPickupDelivery.title;
        const errorPickupDeliveryTextmsg = this.errorPickupDelivery.text;
        this.errorPickupDeliveryTitle = errorPickupDeliveryTitlemsg;
        this.errorPickupDeliveryText = errorPickupDeliveryTextmsg;
    }

    public init(outlet: Outlet, customer: Customer): void {
            this.retrieveAvailableMobilityTypes(outlet);
            this.sourceAddress = { country: {} };
            this.destinationAddress = { country: {} };
            this.setDataAnalytics(outlet);
        }

    private retrieveAvailableMobilityTypes(outlet: Outlet): void {
        this.appComponent.loading = true;
        this.mobilityService
            .getAvailableMobilityTypes(outlet.id)
            .then(outletMobilityTypes => {
                this.outletMobilityTypes = outletMobilityTypes;
                this.mobilities.forEach(selectedMobility => {
                    if (
                        !this.outletMobilityTypes.find(
                            outletMobilityType =>
                                outletMobilityType.mobilityType.id === selectedMobility.mobilityType.id
                        )
                    ) {
                        const indexToRemove = this.mobilities.indexOf(selectedMobility);
                        this.mobilities.slice(indexToRemove, 1);
                    }
                });
                // automaticaly open PickupAndDelivery modal if the mobility is for pickup and delivery service
                if(MobilityUtils.isPickupAndDelivery(this.mobilities)) {
                    this.appComponent.openModalConfig('address', this.addressDetailTemplate, this.config);
                }
                this.appComponent.loading = false;
            })
            .catch(() => {
                this.appComponent.loading = false;
            });
    }

    public closeModal(cancel: boolean = false): void {
        this.appComponent.closeModal('address');
        this.sourceAddress = this._sourceAddress;
        this.destinationAddress = this._destinationAddress;

        const index = this.mobilities.findIndex(
            mobility => mobility.mobilityType.id === environment.pickupServiceId
        );

        if(cancel || (!this.sourceForm.get('enabled').value && !this.destinationForm.get('enabled').value)) {
            this.mobilities.splice(index, 1);
        } else {

            this.mobilities[index].pickup = this.sourceForm.get('enabled').value;
            if(this.sourceForm.get('enabled').value) {
                this.mobilities[index].address = this.sourceAddress;
                this.mobilities[index].pickupHour = +moment(this.sourceForm.get('time').value, 'HH:mm').format('HH');
                this.mobilities[index].pickupMinute = +moment(this.sourceForm.get('time').value, 'HH:mm').format('mm');
            } else {
                this.mobilities[index].address = null;
                this.mobilities[index].pickupHour = 0;
                this.mobilities[index].pickupMinute = 0;
            }

            this.mobilities[index].delivery = this.destinationForm.get('enabled').value;
            if(this.destinationForm.get('enabled').value) {
                this.mobilities[index].deliveryAddress = this.destinationAddress;
                this.mobilities[index].deliveryHour = +moment(this.destinationForm.get('time').value, 'HH:mm').format('HH');
                this.mobilities[index].deliveryMinute = +moment(this.destinationForm.get('time').value, 'HH:mm').format('mm');
            } else {
                this.mobilities[index].deliveryAddress = null;
                this.mobilities[index].deliveryHour = 0;
                this.mobilities[index].deliveryMinute = 0;
            }    
        }
    }

    public onSelect(outletMobilityType: OutletMobilityType): void {

        const index = this.mobilities.findIndex(
            mobility => mobility.mobilityType.id === outletMobilityType.mobilityType.id
        );
        if (index > -1) {
            this.mobilities.splice(index, 1);
        } else {
            this.mobilities = [];
            const mobility: Mobility = { mobilityType: outletMobilityType.mobilityType };
            if (outletMobilityType.mobilityType.id === environment.pickupServiceId) {
                mobility.pickup = this.sourceForm.get('enabled').value;
                mobility.address = this.sourceAddress;
                mobility.pickupHour = this.sourceForm.get('time').value;
                mobility.pickupMinute = this.sourceForm.get('time').value;
                mobility.delivery = this.destinationForm.get('enabled').value;
                mobility.deliveryAddress = this.destinationAddress;
                mobility.deliveryHour = this.destinationForm.get('time').value;
                mobility.deliveryMinute = this.destinationForm.get('time').value;
                this.appComponent.openModalConfig('address', this.addressDetailTemplate, this.config);
                this.setDataAnalyticsPickUpDate();

                // Fix body paddingRigth
                let paddingModal = document.querySelectorAll('body');
                if (paddingModal.length > 0) {
                    paddingModal[0].style.paddingRight = '0px';
                }
            }
            if (outletMobilityType.mobilityType.id === environment.courtesyCarServiceId || outletMobilityType.mobilityType.id === environment.courtesyMotoServiceId) {
                this.setCourtesyVehicleWarningValue(this.courtesyVehicleWarning);
                this.alertWarning.show();
            }
            this.mobilities.push(mobility);
        }
        this.mobilitiesChange.emit(this.mobilities);
    }

    public isSelected(outletMobilityType: OutletMobilityType): boolean {

        return this.mobilities.find(mobility => mobility.mobilityType.id === outletMobilityType.mobilityType.id)
            ? true
            : false;
    }

    public trackByOutletMobilityType(i: number, outletMobilityType: OutletMobilityType): number {
        return outletMobilityType.mobilityType.id;
    }

    public validatorAddressPickupDelivery() {
        let error = false;
        if (this.sourceForm.get('enabled').value && !Utils.isNullOrUndefined(this._sourceAddress.locationComponentList)) {
            this._sourceAddress.locationComponentList.forEach(location => {
                if (location.shortName == null) {
                    error = true;
                }
            });
        }
        if (this.destinationForm.get('enabled').value && !Utils.isNullOrUndefined(this._destinationAddress.locationComponentList)) {
            this._destinationAddress.locationComponentList.forEach(location => {
                if (location.shortName == null) {
                    error = true;
                }
            });
        }
        return error;
    }

    public saveAddress(): void {
        // TODO: Remove validatorAddressPickupDelivery method if these filters are working correctly
        if (!Utils.isNullOrUndefined(this._sourceAddress.locationComponentList)) {
            this._sourceAddress.locationComponentList = this._sourceAddress.locationComponentList.filter(
                location => location.shortName != null
            );
        }
        if (!Utils.isNullOrUndefined(this._destinationAddress.locationComponentList)) {
            this._destinationAddress.locationComponentList = this._destinationAddress.locationComponentList.filter(
                location => location.shortName != null
            );
        }

        if (this.validatorAddressPickupDelivery()) {
            this.errorPickupDelivery.text = this.errorPickupDeliveryText;
            this.errorPickupDelivery.title = this.errorPickupDeliveryTitle;
            this.errorPickupDelivery.show();
        } else if(this.outlet.distanceActiveFlag
           && (
              (Math.round(OutletMobilityComponent.haversineFormula(this._sourceAddress, this.outlet.address)) > this.outlet.distancePickupDelivery && this.sourceForm.get('enabled').value)
               || (Math.round(OutletMobilityComponent.haversineFormula(this._destinationAddress, this.outlet.address)) > this.outlet.distancePickupDelivery && this.destinationForm.get('enabled').value)
           )){
           this.closeModal(true);
           this.distanceLimitExceeded.contentTmpl = this.distanceLimitExceededTextTmpl;
           this.distanceLimitExceeded.titleTmpl = this.distanceLimitExceededTitleTmpl;
           this.distanceLimitExceeded.show();
        } else {
            this.sourceAddress = this._sourceAddress;
            this.destinationAddress = this._destinationAddress;
            this.closeModal();

            if (this.sourceForm.get('enabled').value || this.destinationForm.get('enabled').value) {
                this.alertWarning.titleTmpl = this.mobilityPickupAndDeliveryTitleTmpl;
                this.alertWarning.contentTmpl = this.mobilityPickupAndDeliveryTextTmpl;
                this.alertWarning.show();
            }
        }
    }

    public atLeastOnePrice(): boolean {
        if (!this.outletMobilityTypes || !this.outletMobilityTypes.length) {
            return false;
        }
        return !!this.outletMobilityTypes.find(el => el.priced);
    }

    public openEditAddress(type: string) {
        if (type === 'source') {
            if (this.sourceForm.get('enabled').value) {
                this.appComponent.openModal('oas-maps-edit', this.editPickAddressModal, 'your-vehicles-modal modal-lg');
            }
        } else {
            if (this.destinationForm.get('enabled').value) {
                this.appComponent.openModal(
                    'oas-maps-edit',
                    this.editDeliveryAddressModal,
                    'your-vehicles-modal modal-lg'
                );
            }
        }
    }

    public showCampaingBanner(): boolean {
        return (
            !Utils.isNullOrUndefined(this.outletMobilityTypes) &&
            this.vehicleBrand === 'BMW_MOTORRAD' &&
            this.countryConfiguration[this.vehicleBrand].longLifeCareMobilityBanner &&
            !Utils.isNullOrUndefined(this.outletMobilityTypes.find(a => a.mobilityType.id === 2))
        );
    }

    private fillServicesBanner() {
        this.dictionaryService.findByName('MOBILITY_BANNER_DESCRIPTION_BMW_MOTORRAD').then(response => {
            if (response) {
                this.servicesBannerText.next(this._sanitizer.bypassSecurityTrustHtml(response.value));
            }
        });
    }

    private setDataAnalytics(outlet: Outlet) {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );
        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasMobilityServiceSelection,
            dataAnalytics.page.variant.virtual
        );
        this.tracyService.setUserInformationIntoDataAnalytics('na');
        this.tracyService.sendPageView(dataAnalytics.referenceName.additionalService);
    }
    private setDataAnalyticsPickUpDate() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );
        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.LoasPickUpDateAndDelivery,
            dataAnalytics.page.variant.virtual
        );
        this.tracyService.setUserInformationIntoDataAnalytics('na');
        this.tracyService.sendPageView(dataAnalytics.referenceName.pickUpDateAndDelivery);
    }

    private static haversineFormula(geoMarker1: GeoMarker, geoMarker2: GeoMarker){
       let R = 6371.0710; // Radius of the Earth in KM
       let radianLatitude1 = geoMarker1.longitude * (Math.PI/180); // Convert degrees to radians
       let radianLatitude2 = geoMarker2.longitude* (Math.PI/180); // Convert degrees to radians
       let differenceLatitude = radianLatitude2-radianLatitude1; // Radian difference (latitudes)
       let differenceLongitude = (geoMarker2.latitude-geoMarker1.latitude) * (Math.PI/180); // Radian difference (longitudes)

       return 2 * R * Math.asin(Math.sqrt(Math.sin(differenceLatitude/2)*Math.sin(differenceLatitude/2)+Math.cos(radianLatitude1)*Math.cos(radianLatitude2)*Math.sin(differenceLongitude/2)*Math.sin(differenceLongitude/2)));
    }
}
