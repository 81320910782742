import { Component, ViewChild } from '@angular/core';
import { countryConfiguration } from '../../../configuration/country.configuration';
import { Utils } from '../../core/utils';
import { CountryConfiguration } from '../../country-configuration';
import { BusinessSettingType, CalendarEvent, Outlet, User } from '../entities';
import { TranslatePipe } from '../pipe/translate.pipe';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

// import { CalendarComponent } from 'ap-angular2-fullcalendar';
import { OutletService } from '../../core/api/outlet/outlet.service';
import * as moment from 'moment';
import { CountryConfigurationService } from '../../../configuration/country-configuration.service';
import { BusinessSettingService } from '../../core/api/business-setting.service';
import { CalendarOptions, EventInput, FormatterInput } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import timeGrigPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction';
@Component({
    template: ''
})
export abstract class OutletAgenda {
    protected retrieveFirstTimeslotAvaiable: boolean;

    @ViewChild('calendar') calendarView: FullCalendarComponent; // the #calendar in the template
    public events: CalendarEvent[];
    // public calendarEvents: EventInput[] = [];

    protected fromDate: Date;
    protected toDate: Date;
    protected view: any;
    protected element: any;

    public serviceAdvisors: User[];
    public hoverServiceAdvisor: User;
    public selectedEventSadvList: User[];
    public selectedEvent: CalendarEvent | EventInput;
    public selectedOutlet: Outlet;
    public prevButtonDisabled: boolean = false;

    minDate: Date = Utils.subDays(new Date(), 1);
    protected maxDate: Date;

    public countryConfiguration: CountryConfiguration;

    public bsConfig: Partial<BsDatepickerConfig>;
    public showBannerEmptySlot: boolean;
    public businessSettingType: BusinessSettingType;

    public calendarPlugins = [timeGrigPlugin];

    private minTime = {
        hour: 7,
        minute: 0
    };
    private maxTime = {
        hour: 21,
        minute: 0
    };
    private headerOptions = {
        left: 'today',
        center: 'title',
        right: 'timeGridWeek,timeGridDay'
    };
    private calendarLang = {
        code: countryConfiguration.language
    };
    private customButtons = {
        today: {
            click: () => {
                this.calendarView.getApi().gotoDate(new Date());
                this.viewRender();
            },
            text: Utils.capitalize(this.translatePipe.transform('today'))
        },
        timeGridWeek: {
            click: () => {
                this.calendarView.getApi().changeView('timeGridWeek')
                //this.calendarView.getApi().gotoDate(this.calendarView.getApi().state.dateProfile.currentRange.start);
                this.viewRender();
            },
            text: Utils.capitalize(this.translatePipe.transform('week'))

        },
        timeGridDay: {
            click: () => {
                this.calendarView.getApi().changeView('timeGridDay');
                if(Utils.dayGreaterThan(new Date(), this.calendarView.getApi().getCurrentData().currentDate)) {
                    this.calendarView.getApi().gotoDate(new Date());
                }
                this.viewRender();
            },
            text: Utils.capitalize(this.translatePipe.transform('day'))
        }
    };
    public readonly timeZone = "UTC";// Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    public sadvColors = [
        "rgb(33, 131, 211, 0.5)",
        "rgb(57, 119, 98, 0.5)",
        "rgb(40, 50, 192, 0.5)",
        "rgb(74, 153, 126, 0.5)",
        "rgb(69, 111, 20, 0.5)",
        "rgb(77, 153, 126, 0.5)",
        "rgb(27, 2, 144, 0.5)",
        "rgb(98, 145, 117, 0.5)",
        "rgb(51, 14, 235, 0.5)",
        "rgb(167, 213, 197, 0.5)",
        "rgb(46, 54, 87, 0.5)",
        "rgb(40, 69, 50, 0.5)",
        "rgb(46, 3, 245, 0.5)",
        "rgb(59, 101, 73, 0.5)",
        "rgb(77, 134, 97, 0.5)",
        "rgb(74, 153, 100, 0.5)",
        "rgb(130, 183, 148, 0.5)",
        "rgb(16, 96, 18, 0.5)",
        "rgb(23, 140, 26, 0.5)",
        "rgb(30, 184, 34, 0.5)",
        "rgb(44, 220, 49, 0.5)",
        "rgb(88, 228, 92, 0.5)",
        "rgb(255, 255, 0, 0.5)"
    ];
    public multiColor = "rgb(150, 206, 181,0.5)";
    public timeFormat: FormatterInput = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };

    public calendarOptions: CalendarOptions = {
        displayEventEnd: false,
        height: 'auto',
        initialView: 'timeGridWeek',
        timeZone: this.timeZone,
        headerToolbar: this.headerOptions,
        plugins: this.calendarPlugins,
        eventClick: this.handleDateClick.bind(this),
        allDaySlot: false,
        firstDay: 1,
        slotMinTime: this.minTime,
        slotMaxTime: this.maxTime,
        slotLabelFormat: this.timeFormat,
        locale: this.calendarLang,
        visibleRange: {start: new Date(), end: null},
        customButtons: this.customButtons,
        slotEventOverlap: false,
        selectOverlap: false,
        eventOverlap: false,
        eventDidMount: this.styleCalendarPreviousDays.bind(this),
        displayEventTime: true,
        eventBorderColor: 'none'
    }

    constructor(
        protected translatePipe: TranslatePipe,
        protected outletService: OutletService,
        protected countryConfigurationService: CountryConfigurationService,
        protected businessSettingService: BusinessSettingService
    ) {
        this.businessSettingType = 'MAX_MONTHS_IN_FUTURE_TO_RETRIEVE_SLOT';
        this.businessSettingService.getSetting(this.businessSettingType).then(setting => {
            this.calendarOptions.visibleRange['end'] = Utils.addMonths(new Date(), Number(setting.value));
        });
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
        this.bsConfig = Object.assign(
            {},
            {
                containerClass: 'theme-dark-blue',
                locale: this.countryConfiguration.locale,
                minDate: new Date(),
                rangeInputFormat: this.countryConfiguration.dateInputRangeFormat,
                dateInputFormat: 'DD/MM/YYYY',
            }
        );
    }

    /**
     * Initialize dates and retrieve slots
     */
    abstract viewRender();

    abstract eventClick(event: CalendarEvent, jsEvent, view);

    protected abstract getFreeSlotAndFillCalendar();
    
    public abstract handleDateClick(arg: any);

    protected triggerGotoDateMainCalendarEvent(date: Date) {
        if (moment(new Date(date)).isBetween(this.fromDate, Utils.subDays(this.toDate, 1), 'days', '[]')) {
            this.calendarView.getApi().gotoDate(Utils.toUTCDate(new Date(date)));
            this.getFreeSlotAndFillCalendar();
        } else {
            this.calendarView.getApi().gotoDate(Utils.toUTCDate(new Date(date)));
            this.viewRender();
        }
    }

    public previousView() {
        if(!this.prevButtonDisabled) {
            this.calendarView.getApi().prev();
            this.viewRender();
        }
    }

    public nextView() {
        this.calendarView.getApi().next();
        this.viewRender();
    }

    /**
     * Removes all events from the calendar.
     * This method clears the existing events by emptying the 'calendarEvents' array.
     * 
     * @returns {void}
     */
    public removeAllEvents(): void {
        this.calendarOptions.events = [];
    }

     /**
     * add background color to previous days apply opacity to date
     * 
     * @returns {void}
     */
     private styleCalendarPreviousDays(): void {
        //check if the today button is disabled, if so, we need to disable the previous days titles
        if(document.getElementsByClassName('fc-today-button')[0].hasAttribute('disabled')) {
            const daysTitles = Array.from(document.getElementsByClassName('fc-col-header-cell'));
            daysTitles.forEach(element => {
                if(element){
                    const isPreviousDay = new Date(element.getAttribute('data-date')) < new Date() && !Utils.isSameDay(new Date(element.getAttribute('data-date')), new Date());
                    if(isPreviousDay) {
                        element.querySelector('div').style.background = '#f2f2f2';
                        element.querySelector('div').querySelector('a').style.opacity = '0.5';
                    }
                }
            });
        }
    }

    protected formatTimeSlotLabel(): void {
        setTimeout(()=> {
            const slotsLabels = document.querySelectorAll('.fc-event-time');
            if(slotsLabels.length) {
                slotsLabels.forEach((el) => {
                    const elText = el.textContent.split(':')[0];
                    if(elText.length === 1) {
                        const text = el.textContent;
                        el.innerHTML = '0' + text;
                    }
                    
                });
            }
        }, 10)
    }
}
