import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'oas-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {

  public isAccepted: boolean;
  public displayReadMore: boolean = true;
  @Output() onAgree = new EventEmitter<void>();
  @Input() countryCode: string;

  constructor() { }

  ngOnInit() { 
  }

  public readMore(): void {
    const el1 = document.querySelector<HTMLElement>('.terms-container');
    el1.style.overflowY = 'auto';
    el1.style.overflowX = 'hidden';
    this.displayReadMore = false;

    // const el2 = document.querySelector<HTMLElement>('.read-more-button');
    // el2.style.backgroundColor = 'transparent';
  }

  public accept(isAccepted: boolean): void {
    this.isAccepted = isAccepted;
  }

  public continue(): void {
    if (this.isAccepted) {
      this.onAgree.emit();
    }
  }

}
