import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Logger } from './../core/logger.service';
import { AuthService } from './auth.service';
import { CountryConfiguration } from '../country-configuration';
import { StyleSwitcherService } from './../core/style-switcher.service';
import { tap } from 'rxjs/operators';
import { CountryConfigurationService } from '../../configuration/country-configuration.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    public countryConfiguration: CountryConfiguration;
    constructor(
        private injector: Injector,
        private logger: Logger,
        private styleSwitcher: StyleSwitcherService,
        countryConfigurationService: CountryConfigurationService
    ) {
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
    }

    private authService: AuthService;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authService = this.injector.get(AuthService);

        this.logger.debug(request.url);
        const headers = {
            Authorization: `${this.authService.getToken()}`,
            AuthorizationGCDM: `${this.authService.getTokenOauth()}`,
            Locale: this.countryConfiguration.locale,
        };
        if (this.styleSwitcher.brand.getValue()) {
            headers['Brand'] = this.styleSwitcher.brand.getValue();
        }
        request = request.clone({
            setHeaders: headers,
        });

        return next.handle(request).pipe(
            tap(
                () => {},
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status !== 401) {
                           return;
                        }

                        this.authService.logout();
                        this.authService.redirectToLogin(err);
                    }
                }
            )
        );
    }
}
