import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AlertConfirmComponent } from '../../../../shared/alert/alert-confirm.component';

@Component({
    selector: 'oas-user-form-delete-account',
    templateUrl: './user-form-delete-account.component.html',
    styleUrls: ['./user-form-delete-account.component.scss'],
})
export class UserFormDeleteAccountComponent implements OnInit {
    @ViewChild(AlertConfirmComponent) private alertConfirm: AlertConfirmComponent;
    @ViewChild('alertTitleTmpl') private alertTitleTmpl: TemplateRef<any>;
    @ViewChild('alertContentTmpl') private alertContentTmpl: TemplateRef<any>;
    @Output() public deleteAccountClicked = new EventEmitter<void>();

    constructor() {}

    ngOnInit() {}

    public deleteAccount(): void {
        this.alertConfirm.titleTmpl = this.alertTitleTmpl;
        this.alertConfirm.contentTmpl = this.alertContentTmpl;

        this.alertConfirm.show().then(result => {
            if (result.value) {
                this.deleteAccountClicked.emit();
            }
        });
    }
}
