<div class="row">
    <div class="col-md-6">
        <h2 class="assistance-title" i18n="@@selectAssistance">Select the assistance</h2>
    </div>
    <div class="col-md-6">
        <div class="assistance-search--wrapper wizard__assistance-search--wrapper">
            <div class="input-group assistance-search">
                <input
                    [formControl]="searchActivity"
                    type="text"
                    class="form-control"
                    i18n-placeholder="@@searchFor"
                    placeholder="Search for..."
                />
                <span class="clear-input">
                    <button
                        *ngIf="searchActivity.value"
                        (click)="searchActivity.reset()"
                        class="btn btn-default button--clear"
                    >
                        <i class="fa fa-times"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
</div>
<div class="assistance-tabs">
    <div class="loader-container">
        <ngx-loading [show]="loading"></ngx-loading>
        <tabset justified="true">
            <tab [active]="searchActivity.value" *ngIf="searchActivity.value" customClass="assistance-tabs__tab">
                <ng-template tabHeading>
                    <div class="assistance-tabs__tab--inner">
                        <i class="fa fa-search assistance-tabs__tab-image"></i>
                        <p class="assistance-tabs__tab-service-name" i18n="@@searchResults">
                            Search results
                        </p>
                    </div>
                </ng-template>
            </tab>
            <div class="col-md-4 col-sm-6 col-xs-12" *ngFor="let fixedprice of fixedPriceList">
                <div class="assistance-service" (click)="onSelect(fixedprice, !isAlreadySelected(fixedprice))">
                    <i class="bmw bmw-ico_generic icon-active assistance-service__icon icon-lg"></i>
                    <p class="assistance-service__name">
                        {{ fixedprice.name }}
                    </p>
                    <span class="assistance-service__name text-align: right"> {{ fixedprice.price }} </span>
                    <div class="ui-checkbox assistance-service__checkbox">
                        <input type="checkbox" [checked]="isAlreadySelected(fixedprice)" />
                        <label> </label>
                    </div>
                </div>
            </div>
        </tabset>
    </div>

    <div class="fastlane-description">
        <div *ngIf="sameDates">
            <span i18n="@@textSameDatesCampaign">* This prices will be valid for the duration of the campaign: </span
            ><br />
            <span i18n="@@startDate"> Start Date</span
            ><span
                >: {{ this.fixedPriceList[0].startValidityTime.getDate() }}/{{
                    this.fixedPriceList[0].startValidityTime.getMonth() + 1
                }}/{{ this.fixedPriceList[0].startValidityTime.getYear() + 1900 }}</span
            ><br />
            <span i18n="@@endDate"> End Date</span
            ><span
                >: {{ this.fixedPriceList[0].endValidityTime.getDate() }}/{{
                    this.fixedPriceList[0].endValidityTime.getMonth() + 1
                }}/{{ this.fixedPriceList[0].endValidityTime.getYear() + 1900 }}</span
            >
        </div>
        <div *ngIf="!sameDates">
            <span i18n="@@textDistinctDatesCampaign"
                >* Please, check with your Authorized Outlet the availability of this campaign.</span
            >
        </div>
        <br />
    </div>
    <div class="col-md-12"></div>
    <div *ngIf="totalDuration <= fastLaneThreshold && isFastLaneAllowed(); else noSelectableFastLaneTmpl" class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6" *ngIf="hideFastLane"> 
                    <div class="assistance-service" (click)="selectFastLane()">
                        <i class="bmw bmw-generic_ico_fastlane icon-active assistance-service__icon icon-lg"></i>
                        <p class="assistance-service__name">
                            <span i18n="@@fastLane">Fast lane</span>
                            <span>
                                {vehicle?.model?.brand, select, BMW {BMW} BMW_I {BMWi} MINI {MINI} BMW_MOTORRAD
                                {MOTORRAD}}*</span
                            >
                        </p>
                        <div class="ui-checkbox assistance-service__checkbox">
                            <input
                                type="checkbox"
                                [checked]="fastLaneSelected"
                                (change)="showFastLaneWarning($event.target.checked)"
                            />
                            <label> </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="assistance-service" (click)="selectWaitInOutlet()" [hidden]="!fastLaneSelected">
                        <i class="fa fa-coffee icon-active assistance-service__icon icon-lg"></i>
                        <p class="assistance-service__name text-left" i18n="@@waitInOutlet">
                            Wait in outlet
                        </p>
                        <div class="ui-checkbox assistance-service__checkbox">
                            <input type="checkbox" [checked]="waitInOutletSelected" />
                            <label> </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 fastlane-description" *ngIf="hideFastLane">
                    *
                    <span i18n="@@fastlaneDescription"
                        >A {{ vehicle?.model?.brand | translate }} Fast Lane Service is completed from start to finish
                        within just 90 minutes whilst you wait at your local BMW Centre.
                    </span>
                    <br />
                    <a
                        *ngIf="countryConfiguration.showFastLaneLink"
                        i18n-href="@@fastlaneInfoLink"
                        href="https://www.bmw.co.uk/bmw-ownership/servicing-and-repairs/servicing/fast-lane-service"
                        target="_blank"
                        i18n="@@fastlaneInfo"
                        >For more information, click here</a
                    >
                </div>
            </div>
        </div>
        <!-- TODO: Implement logic -->
        <div class="col-md-6">
            <ng-container *ngTemplateOutlet="notesAndAdditionalTmpl"></ng-container>
        </div>
    </div>
    <ng-template #noSelectableFastLaneTmpl>
        <div class="row">
            <div class="col-md-12">
                <ng-container *ngTemplateOutlet="notesAndAdditionalTmpl"></ng-container>
            </div>
        </div>
    </ng-template>
    <ng-template #notesAndAdditionalTmpl>
        <div class="activity__notes">
            <h4 style="display: inline;" i18n="@@notesAndAdditional">
                Notes & Additional Requests
            </h4>
            <!--         <label *ngIf="attchmentsEnabled" class="btn">
            <i class="fa fa-paperclip"></i>
            <input class="picture-selector_input-file" type="file" (change)="fileChangeListener($event)">
         </label> -->
            <div>
                <textarea
                    name="additional_requests"
                    [(ngModel)]="appointment.note"
                    id=""
                    cols="30"
                    rows="5"
                    i18n-placeholder="@@typeHere"
                    placeholder="type here..."
                    maxlength="500"
                ></textarea>

                <div class="row" *ngFor="let attachment of attachments">
                    <div class="col-md-12">
                        <div class="attachments-container">
                            <a
                                *ngIf="!attachment.loading; else attachmentName"
                                [href]="attachmentsUrl + '/' + attachment.path"
                                target="_blank"
                            >
                                {{ attachment.name }}
                            </a>
                            <ng-template #attachmentName>
                                <span>{{ attachment.name }}</span>
                            </ng-template>

                            <progressbar
                                *ngIf="attachment.loading"
                                max="100"
                                [value]="100"
                                [striped]="true"
                                [animate]="true"
                            >
                            </progressbar>
                            <label
                                *ngIf="!attachment.loading && attchmentsEnabled"
                                class="btn"
                                (click)="deleteAttachment(attachment)"
                            >
                                <i class="fa fa-close"></i>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<oas-alert-warning
    #warningFastLane
    i18n-title="@@fastLane"
    title="Fast Lane"
    i18n-text="@@fastLaneCheckedWarning"
    text="Fast Lane will only be possible if during the service consultation phase is not found an issue that makes impossible to return the car in less than 90 minutes."
>
</oas-alert-warning>
