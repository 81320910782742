<div *ngIf="vehicles" class="swiper-container-wrapper dashboard-header__hero-image--wrapper">

    <swiper-container 
        #swiper 
        [config]="swiperConfig"
        (slidechange)="onSwiperIndexChange($event)"
    >
        <swiper-slide *ngFor="let vehicle of vehicles; trackBy: trackByVehicle" class="swiper-slide">
            <div class="dashboard-header__hero-image-title">
                <h3>{{ vehicle.model?.modelLabel }}</h3>
                <h4>{{ vehicle.plate }}</h4>
            </div>
            <oas-vehicle-picture-ucp [vin]="vehicle.vin"
                [vehicleSeriesId]="vehicle?.model?.series?.id"></oas-vehicle-picture-ucp>
            <p class="swiper-slide--title" *ngIf="vehicle.lastMaintenance">
                <strong i18n="@@lastMaintenance">Last maintenance</strong>
                {{ vehicle.lastMaintenance | datex: 'longDate' }}
            </p>
        </swiper-slide>
    </swiper-container>
    <div *ngIf="vehicles.length > 1" class="swiper-button-prev swiper-button-white" (click)="slidePrev()"></div>
    <div *ngIf="vehicles.length > 1" class="swiper-button-next swiper-button-white" (click)="slideNext()"></div>
</div>