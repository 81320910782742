import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Logger } from '../../logger.service';
import { environment } from '../../../../environments/environment';
import { ApiUtils } from '../utils/utils';
import { Vehicle } from '../../../shared/entities';

@Injectable()
export class _3sService {
    private actionUrl: string;

    constructor(protected http: HttpClient, protected logger: Logger) {
        this.actionUrl = `${environment.apiUrl}/3s`;
    }

    public getVinByPlate(plate: string): Promise<Vehicle> {
        const url = `${this.actionUrl}/vehicle/by-plate/${plate}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Vehicle)
            .catch(error => ApiUtils.handleError(error, this.logger));
    }

    public getPlateByVin(vin: string): Promise<Vehicle> {
        const url = `${this.actionUrl}/vehicle/by-vin/${vin}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Vehicle)
            .catch(error => ApiUtils.handleError(error, this.logger));
    }
}
