import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AppointmentsCountByOutlet,
    SearchSettings,
    UsersDetailsOfscLicenses,
    UsersOfscLicenses,
} from '../../../shared/entities';
import { Logger } from '../../logger.service';
import { KpiService } from './kpi.service';

@Injectable()
export class KpiAppointmentService extends KpiService {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/oas-as/appointments', http, logger);
    }

    private async getCount(urlPart: string, searchSettings?: SearchSettings): Promise<number> {
        try {
            const response = await this.http
                .post(`${this.actionUrl}${urlPart}/count`, searchSettings, { responseType: 'text' })
                .toPromise();
            return +response;
        } catch (error) {
            return await this.handleError(error);
        }
    }

    public getNscCount(searchSettings?: SearchSettings): Promise<number> {
        return this.getCount('/nsc', searchSettings);
    }

    public getOnlineWebsiteCount(searchSettings?: SearchSettings): Promise<number> {
        return this.getCount('/online/website', searchSettings);
    }

    public getOnlineMobileCount(searchSettings?: SearchSettings): Promise<number> {
        return this.getCount('/online/mobile', searchSettings);
    }

    public getOnsiteCount(searchSettings?: SearchSettings): Promise<number> {
        return this.getCount('/onsite', searchSettings);
    }
    public getBaseCount(searchSettings?: SearchSettings): Promise<number> {
        return this.getCount('/base', searchSettings);
    }

    public async getSourceCountByOutlet(searchSettings?: SearchSettings): Promise<AppointmentsCountByOutlet[]> {
        try {
            const response = await this.http
                .post(`${this.actionUrl}/source/count/byOutlet`, searchSettings)
                .toPromise();
            return response as AppointmentsCountByOutlet[];
        } catch (error) {
            return await this.handleError(error);
        }
    }

    public getModifiedCount(searchSettings?: SearchSettings): Promise<number> {
        return this.getCount('/modified', searchSettings);
    }

    public async getOfscLicenses(searchSettings?: SearchSettings): Promise<UsersOfscLicenses[]> {
        try {
            const response = await this.http.post(`${this.actionUrl}/users/ofsc`, searchSettings).toPromise();
            return response as UsersOfscLicenses[];
        } catch (error) {
            return await this.handleError(error);
        }
    }

    public async getExOfscLicenses(searchSettings?: SearchSettings): Promise<UsersDetailsOfscLicenses[]> {
        try {
            const response = await this.http.post(`${this.actionUrl}/users/eofsc`, searchSettings).toPromise();
            return response as UsersDetailsOfscLicenses[];
        } catch (error) {
            return await this.handleError(error);
        }
    }

    public async getDdNscAppointments(searchSettings?: SearchSettings): Promise<Blob> {
        try {
            const response = await this.http
                .post(`${this.actionUrl}/dd/nsc`, searchSettings, { responseType: 'blob' })
                .toPromise();
            return response as Blob;
        } catch (error) {
            const jsonError = await (new Response(error.error)).json();
            return await this.handleError(jsonError);
        }
    }

    public getNationalCallCenterCount(searchSettings?: SearchSettings): Promise<number> {
        return this.getCount('/onsite/national-call-center', searchSettings);
    }
    public async getNscAppointmentsCount(searchSettings?: SearchSettings): Promise<string> {
        try {
            const response = await this.http
                .post(`${this.actionUrl}/validate-appointments-count/nsc`, searchSettings, { responseType: 'text' })
                .toPromise();
            return response as string;
        } catch (error) {
            return await this.handleError(error);
        }
    }
}
