import { Router } from '@angular/router';
import { CountryConfigurationService } from '../../configuration/country-configuration.service';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuardTssb {
  
    constructor(private countryConfigurationService: CountryConfigurationService, private router: Router, private authService: AuthService) {}
  
    canActivate() {
        const locale: string = this.countryConfigurationService.getCountryConfiguration().locale;
        const disableTssbCountries = ['es-ES', 'pt-BR'];
        if (!disableTssbCountries.includes(locale)) {
          // all ok, proceed navigation to routed component
          return true;
        }
        else {
          this.router.navigate([this.authService.getDashboardPath()]);
        }
    }
}
