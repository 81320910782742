import { Injectable } from '@angular/core';
import { ScriptStore } from './script-store';

declare var document: any;

@Injectable()
export class ScriptService {
    private scripts: any = {};

    constructor() {
        ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src,
            };
        });
    }

    load(persist: boolean, ...scripts: string[]) {
        const promises: any[] = [];
        scripts.forEach(script => promises.push(this.loadScript(persist, script)));
        return Promise.all(promises);
    }

    loadScript(persist: boolean, name: string) {
        return new Promise((resolve, reject) => {
            // resolve if already loaded
            if (this.scripts[name].loaded) {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            } else {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                if (script.readyState) {
                    // IE
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = persist;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {
                    // Others
                    script.onload = () => {
                        this.scripts[name].loaded = persist;

                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }
}
