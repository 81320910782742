import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SrpCampaignConfiguration } from '../../../shared/entities';
import { Logger } from '../../logger.service';
import { AbstractRestService } from '../generic.service';

@Injectable()
export class SrpCampaignService extends AbstractRestService<SrpCampaignConfiguration, number> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/srp-campaign-config', http, logger);
        this.actionUrl = `${environment.apiUrl}/srp-campaign-config`;
    }

    updateAll(configurations: SrpCampaignConfiguration[]): Observable<SrpCampaignConfiguration[]> {
        return this.http.post<SrpCampaignConfiguration[]>(this.actionUrl, configurations);
    }
}
