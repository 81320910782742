import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Customer, GeoMarker, Vehicle, VehicleBrand } from '../../../shared/entities';
import { Utils } from '../../../core/utils';
import { StyleSwitcherService } from '../../../core/style-switcher.service';
import { CountryConfiguration } from '../../../country-configuration';
import { CountryConfigurationService } from '../../../../configuration/country-configuration.service';
import { ConsentService } from '../../../core/api/consent/consent.service';

@Component({
    selector: 'oas-customer-create-resume',
    templateUrl: './customer-create-resume.component.html',
    styleUrls: ['./customer-create-resume.component.scss'],
})
export class CustomerCreateResumeComponent implements OnInit {
    @Input() public customer: Customer;
    @Output('accept') public acceptEvent = new EventEmitter<boolean>();
    public vehicleModel: string;
    public brand: VehicleBrand;
    public isPrivacyPolicyAccepted: boolean;
    public privacyPolicyLink: string;
    public isTermsAndConditionsAccepted: boolean;
    public termsAndConditionsUrl: string;
    public countryConfiguration: CountryConfiguration;

    constructor(
        private styleSwitcherService: StyleSwitcherService,
        private consentService: ConsentService,
        private countryConfigurationService: CountryConfigurationService
    ) {
        this.countryConfiguration = this.countryConfigurationService.getCountryConfiguration();
    }

    ngOnInit() {
        this.brand = <VehicleBrand> this.styleSwitcherService.brand.getValue().toUpperCase();
        this.privacyPolicyLink = this.countryConfiguration[this.brand].footer.privacyPolicyLink;
        
        this.consentService.getLastUnacceptedTermsAndConditionsUrl().then(termsAndConditionsUrl => {
            if (termsAndConditionsUrl) {
                this.termsAndConditionsUrl = termsAndConditionsUrl
            } else {
                this.isTermsAndConditionsAccepted = true;
            }
        });
    }

    public getDefaultOrFirstAddress(addressList: GeoMarker[]): GeoMarker {
        return Utils.getDefaultOrFirstAddress(addressList);
    }

    public getVehicleModel(vehicleList: Vehicle[]): string {
        if ((vehicleList)[0].model.modelLabel.indexOf((vehicleList)[0].model.brand, 0) == -1) {
            return (vehicleList)[0].model.brand + '' + (vehicleList)[0].model.modelLabel;
        } else {
            return (vehicleList)[0].model.modelLabel;
        }
    }

    public acceptPrivacyPolicy(isPrivacyPolicyAccepted: boolean): void {
        this.isPrivacyPolicyAccepted = isPrivacyPolicyAccepted;
        this.acceptEvent.emit(this.isPrivacyPolicyAccepted && this.isTermsAndConditionsAccepted);
    }

    public acceptTermsAndConditions(isTermsAndConditionsAccepted: boolean): void {
        this.isTermsAndConditionsAccepted = isTermsAndConditionsAccepted;
        this.acceptEvent.emit(this.isPrivacyPolicyAccepted && this.isTermsAndConditionsAccepted);
    }
}
