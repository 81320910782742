import { Component, EventEmitter, Output } from '@angular/core';
import { Appointment } from './entities';

@Component({
    template: ''
})
export abstract class ResumeAppointmentComponent {
    @Output() public goToWizardStep = new EventEmitter<number>();
    public appointment: Appointment;

    public goToStep(stepIndex: number) {
        this.appointment = null;
        this.goToWizardStep.emit(stepIndex);
    }
}
