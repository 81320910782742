import { Injectable } from '@angular/core';
import { AbstractRestService } from '../generic.service';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../../logger.service';
import { Fleet, Renting, FleetVehicle } from '../../../shared/entities';

@Injectable()
export class FleetService extends AbstractRestService<Fleet, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/fleet', http, logger);
    }

    public create(fleet: Fleet): Promise<void> {
        return this.http
            .post(`${this.actionUrl}`, fleet)
            .toPromise()
            .then(response => response as any)
            .catch(error => this.handleError(error));
    }

    public getFleetsList(renting: Renting): Promise<Fleet[]> {
        const url = `${this.actionUrl}/fleet-list`;
        return this.http
            .post(url, renting)
            .toPromise()
            .then(response => response as Fleet[])
            .catch(error => this.handleError(error));
    }

    public getVehiclesFleetList(fleet: Fleet): Promise<FleetVehicle[]> {
        const url = `${this.actionUrl}/vehicles`;
        return this.http
            .post(url, fleet)
            .toPromise()
            .then(response => response as FleetVehicle[])
            .catch(error => this.handleError(error));
    }

    public deleteFleet(fleetid: number): Promise<void> {
        const url = `${this.actionUrl}/delete`;
        return this.http
            .post(url, fleetid)
            .toPromise()
            .then(response => response as any)
            .catch(error => this.handleError(error));
    }

    public loadFleet(fleet: Blob): Promise<void> {
        const url = `${this.actionUrl}/load-fleet`;
        return this.http
            .post(url, fleet)
            .toPromise()
            .then(response => response as any)
            .catch(error => this.handleError(error));
    }

    public getFleetById(id: number) {
        const url = `${this.actionUrl}/search-fleet`;
        return this.http
            .post(url, id)
            .toPromise()
            .then(response => response as Fleet)
            .catch(error => this.handleError(error));
    }

    public updateFleet(fleet: Fleet) {
        const url = `${this.actionUrl}/update-fleet`;
        return this.http
            .post(url, fleet)
            .toPromise()
            .then(response => response as any)
            .catch(error => this.handleError(error));
    }
}
