<form [formGroup]="addressForm">
    <!-- Address & stair/int -->
    <div class="row">
        <div class="col-md-8">
            <div class="ui-input-wrapper">
                <input
                    oasAddressAutocomplete
                    #searchAddress
                    type="text"
                    class="ui-input login-form__input registration__icon-address"
                    i18n-placeholder="@@address"
                    placeholder="Address"
                    formControlName="address"
                    (addressChange)="onAddressChange($event)"
                />
            </div>
        </div>
        <div class="col-md-4">
            <div class="ui-input-wrapper">
                <input
                    type="text"
                    class="ui-input login-form__input registration__icon--empty"
                    i18n-placeholder="@@stairInt"
                    placeholder="Stair/Int"
                    formControlName="stairInt"
                />
            </div>
        </div>
    </div>
    <!-- Address Validation Errors -->
    <div class="row">
        <div class="col-md-6">
            <ng-container
                *ngTemplateOutlet="requiredValidationError; context: { control: addressForm.get('address') }"
            ></ng-container>
        </div>
    </div>
    <!-- Zip Code & Town -->
    <div class="row">
        <div class="col-md-6">
            <div class="ui-input-wrapper">
                <input
                    type="text"
                    class="ui-input login-form__input registration__icon--empty"
                    i18n-placeholder="@@zipCode"
                    placeholder="ZIP Code"
                    formControlName="zipCode"
                />
            </div>
        </div>
        <div class="col-md-6">
            <div class="ui-input-wrapper">
                <input
                    type="text"
                    class="ui-input login-form__input registration__icon--empty"
                    i18n-placeholder="@@town"
                    placeholder="Town"
                    formControlName="town"
                />
            </div>
        </div>
    </div>
    <!-- Zip Code & town Validation Errors -->
    <div class="row">
        <div class="col-md-6">
            <ng-container
                *ngTemplateOutlet="requiredValidationError; context: { control: addressForm.get('zipCode') }"
            ></ng-container>
        </div>
        <div class="col-md-6">
            <ng-container
                *ngTemplateOutlet="requiredValidationError; context: { control: addressForm.get('town') }"
            ></ng-container>
        </div>
    </div>

    <!-- Country -->
    <div class="row">
        <div class="col-md-12">
            <div class="ui-input-wrapper">
                <!-- <input type="text" class="ui-input login-form__input registration__icon--empty" i18n-placeholder="@@country" placeholder="Country"
               formControlName="country"> -->
                <div class="ui-select-wrapper">
                    <select
                        class="ui-select login-form__input"
                        formControlName="country"
                        placeholder="Country"
                        i18n-placeholder="@@country"
                    >
                        <option value="" disabled selected hidden i18n="@@country">Country</option>
                        <option *ngFor="let country of countryList" [value]="country.code">
                            {{ country.englishName }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <!-- Country Validation Errors -->
    <div class="row">
        <div class="col-md-6">
            <ng-container
                *ngTemplateOutlet="requiredValidationError; context: { control: addressForm.get('country') }"
            ></ng-container>
        </div>
    </div>
    <!-- google map -->
    <div *ngIf="showMap && isMapLoaded" class="row ui-margin-bottom">
        <google-map
            [options]="mapOptions"
            (mapClick)="clickMoveMap($event)"
        >
            <map-marker
                [options]="markerOptions"                
                (mapDragend)="clickMoveMap($event)"
            ></map-marker>
        </google-map>
    </div>
    <div class="row">
        <div class="text-center">
            <button
                type="button"
                class="btn btn-primary ui-button"
                i18n="@@saveAddress"
                [disabled]="addressForm.invalid || readOnly || addressInvalid()"
                (click)="saveAddress()"
            >
                Save address
            </button>
        </div>
    </div>
</form>

<ng-template #requiredValidationError let-control="control">
    <div
        *ngIf="control.invalid && (control.dirty || control.touched) && control.errors.required"
        class="ui-input-wrapper"
    >
        <div class="errors">
            <p i18n="@@required">Required!</p>
        </div>
    </div>
</ng-template>
