<div *ngIf="showCampaingBanner()" class="services-banner-container">
    <span [innerHTML]="servicesBannerText | async"></span>
</div>

<h2 class="assistance-title" i18n="@@selectAdditionalService">Select an additional service</h2>
<div class="additional-services">
    <div class="row">
        <div *ngFor="let outletMobilityType of outletMobilityTypes; trackBy: trackByOutletMobilityType"
            class="col-md-4 col-sm-4">
            <div class="additional-service-online--parent">
                <div class="additional-service" [class.additional-service--selected]="isSelected(outletMobilityType)"
                    (click)="onSelect(outletMobilityType)">
                    <i class="bmw bmw-{{ outletMobilityType.mobilityType.iconClass }} icon-lg"></i>
                    <p>
                        {{ outletMobilityType.mobilityType.descriptionLocalized }}
                        <span *ngIf="outletMobilityType.priced">*</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="atLeastOnePrice()" class="row">
        <div class="col-md-12">
            <div class="assistance-priced-services">
                *
                <span i18n="@@pricedService">Chargeable service</span>
            </div>
        </div>
    </div>
</div>

<ng-template #addressDetailTemplate>
    <div class="modal-header services__modal-header">
        <button type="button" class="close" aria-label="Close" (click)="closeModal(true)">
            <i class="fa fa-window-close"></i>
        </button>
        <h4 class="modal-title" i18n="@@pickupService">Pickup service</h4>
    </div>
    <div class="modal-body">
        <form [formGroup]="sourceForm">
            <div class="address-type">
                <div class="ui-checkbox text-left">
                    <input #pickupCheck formControlName="enabled" type="checkbox"
                        (change)="formGroupSource($event.target.checked)" />
                    <label i18n="@@pickupAddress">Pick-up address</label>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="addressForm; context: { form: sourceForm, type: 'source' }"></ng-container>
        </form>

        <hr />

        <form [formGroup]="destinationForm">
            <div class="address-type">
                <div class="ui-checkbox text-left">
                    <input formControlName="enabled" type="checkbox"
                        (change)="formGroupDestination($event.target.checked)" />
                    <label i18n="@@deliveryAddress">Delivery address</label>
                </div>
            </div>
            <ng-container *ngTemplateOutlet="addressForm; context: { form: destinationForm, type: 'destination' }">
            </ng-container>
            <hr />

            <div class="row">
                <div class="col-md-12">
                    <div class="ui-input-wrapper">
                        <div class="text-center">
                            <button [disabled]=" (sourceForm.get('enabled').value && sourceForm.invalid) || (destinationForm.get('enabled').value && destinationForm.invalid) || isInvalidForm()"
                                class="btn btn-primary ui-button" (click)="saveAddress()" i18n="@@save">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #requiredValidationError let-control="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched) && control.errors.required"
        class="ui-input-wrapper">
        <div class="errors">
            <p i18n="@@required">Required!</p>
        </div>
    </div>
</ng-template>

<ng-template #addressForm let-form="form" let-type="type">
    <form [formGroup]="form">
        <!-- Address -->
        <div (click)="openEditAddress(type)" class="address-pick">
            <div class="ui-input-wrapper address">
                <input type="text" class="ui-input login-form__input registration__icon-address"
                    i18n-placeholder="@@address" placeholder="Address" formControlName="address" />
            </div>
            <div class="ui-input-wrapper stair-int">
                <input type="text" class="ui-input login-form__input registration__icon--empty"
                    i18n-placeholder="@@stairInt" placeholder="Stair/Int" formControlName="stairInt" />
            </div>
            <i class="fa fa-map-marker text-primary"></i>
        </div>
        <!-- Address Validation Errors -->
        <div class="row">
            <div class="col-md-6">
                <ng-container *ngTemplateOutlet="requiredValidationError; context: { control: form.get('address') }">
                </ng-container>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #editPickAddressModal>
    <div class="modal-header services__modal-header">
        <button type="button" class="close" aria-label="Close" (click)="appComponent.closeModal('oas-maps-edit')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" i18n="@@pickupAddress">Pick-up address</h4>
    </div>
    <div class="modal-body">
        <oas-address-pick [showMap]="false" [address]="_sourceAddress" (newAddress)="manageNewPickAddress($event)">
        </oas-address-pick>
    </div>
</ng-template>

<ng-template #editDeliveryAddressModal>
    <div class="modal-header services__modal-header">
        <button type="button" class="close" aria-label="Close" (click)="appComponent.closeModal('oas-maps-edit')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" i18n="@@deliveryAddress">Delivery address</h4>
    </div>
    <div class="modal-body">
        <oas-address-pick [showMap]="false" [address]="_destinationAddress"
            (newAddress)="manageNewDeliveryAddress($event)"></oas-address-pick>
    </div>
</ng-template>

<oas-alert-warning></oas-alert-warning>
<oas-alert-error #errorPickupDelivery i18n-title="@@incorrectPickupDeliveryAddressTitle"
    title="Incorrect Pick-up or Delivery Address" i18n-text="@@incorrectPickupDeliveryAddressText"
    text="Select a valid address from the list"></oas-alert-error>

<ng-template #mobilityAddressEmptyTitleTmpl>
    <span i18n="@@mobilityAddressEmptyTitle">Select address for PickUp Service!</span>
</ng-template>

<ng-template #mobilityAddressEmptyTextTmpl>
    <span i18n="@@mobilityAddressEmptyText">Select address for PickUp Service!</span>
</ng-template>

<ng-template #mobilityPickupAndDeliveryTitleTmpl>
    <span i18n="@@mobilityPickupAndDelivery">Pick up & Delivery</span>
</ng-template>

<ng-template #mobilityPickupAndDeliveryTextTmpl>
    <span i18n="@@mobilityPickupConfirmation">This service will be confirmed by the dealer contacting you</span>
</ng-template>

<ng-template #distanceLimitExceededTitleTmpl>
   <span i18n="@@mobilityServiceUnavailableTitle">Mobility service unavailable</span>
</ng-template>

<ng-template #distanceLimitExceededTextTmpl>
   <span i18n="@@mobilityServiceUnavailableText">We regret to inform you that this mobility service is not available for the outlet selected</span>
</ng-template>

