import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../../auth/auth.service';
import { OasValidators } from '../../../../core/validator/oas-validators';
import { JwtAuthenticationRequest } from '../../../../shared/entities';
import { UserFormComponent } from '../user-form.component';

@Component({
    selector: 'oas-user-form-edit',
    templateUrl: './user-form-edit.component.html',
    styleUrls: ['./user-form-edit.component.scss'],
})
export class UserFormEditComponent extends UserFormComponent implements OnInit {
    @Input() public edit: JwtAuthenticationRequest;
    constructor(protected formBuilder: UntypedFormBuilder, protected authService: AuthService) {
        super(formBuilder, authService);
    }

    ngOnInit() {
        this.userForm = this.formBuilder.group(
            {
                username: [{ value: '', disabled: true }],
                oldPassword: ['', Validators.required],
                password: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(this.minLength),
                        Validators.maxLength(this.maxLength),
                        (pw: AbstractControl) =>
                            OasValidators.customerCompliantPassword(pw, this.getUsernameFromFormGroup(this.userForm)),
                    ]),
                ],
                confirmPassword: [''],
            },
            {
                validator: this.matchPassword,
                updateOn: 'blur',
            }
        );
    }
}
