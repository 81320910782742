<div class="condition-container">
    <h2>
        TERMINI E CONDIZIONI
    </h2>

    <h4>
        Informazioni generali e accettazione
    </h4>

    <p>
        Questo sito web è gestito e messo a disposizione da BMW Italia S.p.A. con sede in San Donato Milanese (MI), Via
        della Unione Europea 1, 20097, con Partita IVA IT12532500159 ed e-mail <a
            href="mailto:bmw@bmw.it">bmw@bmw.it</a>
    </p>
    <p>
        Le presenti informazioni legali sono efficaci nei confronti di qualsiasi visitatore del sito (“l’Utente”). Prima
        di utilizzare il sito ed i relativi servizi, pertanto, l’Utente deve <span class="text-bold">leggere attentamente i presenti termini e
        condizioni</span>. L’accesso e l’utilizzo del sito comportano l’accettazione senza riserve delle stesse e l’impegno da
        parte dell’Utente di attenersi sempre al loro rigoroso rispetto.
    </p>
    <p>
        Le informazioni contenute nel sito sono soggette a continuo aggiornamento. L'Utente è tenuto a leggere
        attentamente i presenti termini e condizioni ogni volta che desidera accedere alle informazioni contenute nel
        sito, poiché potrebbero aver subito modifiche.
    </p>

    <h4>
        Diritto d’autore
    </h4>

    <p>
        © Copyright 2020 BMW Italia S.p.A. Tutti i diritti riservati dal proprietario. Tutti i testi, le immagini ed i
        grafici, sono soggetti a copyright e altri tipi di protezione della proprietà intellettuale. Questo sito
        potrebbe contenere anche immagini soggette a copyright di terze parti. L’Utente riconosce espressamente la
        titolarità dei diritti di proprietà intellettuale relativi al contenuto di questo sito e non può intervenire sul
        medesimo, né agire in violazione dei diritti di proprietà intellettuale di BMW Italia S.p.A. e delle terze parti
        che hanno autorizzato BMW Italia S.p.A. medesima ad utilizzarli. È vietato l’utilizzo di quanto contenuto nel
        sito, la copia per uso o distribuzione commerciale, nonché l'utilizzo su altri siti. È ammessa la riproduzione su
        supporto cartaceo o informatico del contenuto del sito, a condizione che tale riproduzione sia strettamente
        limitata ad un uso personale, BMW Italia S.p.A. si riserva il diritto di monitorare i contenuti del sito e,
        conformemente alla politica di miglioramento continuo dei prodotti e servizi, di modificarne o eliminarne
        qualsiasi elemento a propria discrezione. L’Utente conviene che qualora una parte dei presenti termini e
        condizioni risulti inapplicabile, la parte rimanente rimarrà pienamente valida ed applicabile.
    </p>

    <h4>
        Informazioni sui prezzi
    </h4>

    <p>
        BMW Italia S.p.A. si impegna a garantire l'accuratezza, la completezza e l’attualità delle informazioni
        contenute in questo sito che è stato attentamente esaminato. Tuttavia, BMW Italia S.p.A. non garantisce né
        assicura l'esattezza o la completezza delle informazioni fornite sul sito, né garantisce che l'utilizzo di
        questo sito non possa essere interrotto o privo di errori e che il sito, o i server di cui si avvale, non
        possano avere virus o bachi. Potrebbero pertanto
        esserci inesattezze o errori nei prezzi e / o nei prodotti e servizi mostrati, che sono puramente indicativi. È
        quindi consigliabile che l’Utente, ove ne sia interessato, si informi presso il suo Concessionario BMW di
        fiducia per ricevere informazioni e dati più aggiornati. Tutti i prezzi di prodotti e servizi BMW Italia S.p.A.
        eventualmente indicati sulla presente pagina web sono prezzi di vendita consigliati e non vincolanti per il
        Concessionario che potrebbe proporne diversi. I prezzi indicati sul sito sono in vigore al momento della
        pubblicazione e possono essere soggetti a modifiche ed aggiornamenti senza preavviso. Le offerte e le promozioni
        saranno soggette, ove opportuno, al rispetto delle loro specifiche condizioni; in ogni caso l'utente dovrà
        contattare il relativo Concessionario.
    </p>


    <h4>
        Informazioni sui servizi
    </h4>

    <p>
        Il sito è stato realizzato per consentire all’utente di prendere online l’appuntamento per le attività di
        manutenzione / assistenza sul proprio veicolo. BMW Italia S.p.A. e i suoi dipendenti, funzionari o dirigenti, i
        membri della rete commerciale ed i loro impiegati non potranno essere ritenuti responsabili circa temporanee
        indisponibilità del servizio.
    </p>

    <h4>
        Responsabilità
    </h4>

    <p>
        L’Utente si impegna a non effettuare alcuna operazione che possa compromettere il regolare funzionamento del
        sito e nel suo utilizzo si impegna a: - rispettare le leggi e le norme nazionali e internazionali vigenti,
        nonché i diritti di terzi; - non danneggiare l’immagine di BMW Italia S.p.A. a qualunque titolo e in qualsiasi
        forma. In base alle circostanze, BMW Italia S.p.A. può interrompere, limitare, sospendere, proibire, in tutto o
        in parte, l’accesso al sito, senza preavviso né indennizzo alcuno, qualora l’Utente tenga un comportamento o
        svolga attivitàvietate dalla normativa applicabile o in aperta violazione dei presenti termini e condizioni.
    </p>

    <p>
        BMW Italia S.p.A. non è responsabile per danni di alcun tipo che possono derivare dalla disponibilità e dalla
        continuità tecnica del funzionamento del sito. In particolare, BMW Italia S.p.A. non garantisce che l'accesso a
        questo sito sarà ininterrotto o privo di errori,e che il sito, o i server di cui si avvale, non possano avere
        virus o bachi. In nessun caso, salvi i casi di dolo o colpa grave, BMW Italia S.p.A. sarà responsabile nei
        confronti dell’Utente per perdite o danni di qualsiasi tipo derivanti dall'accesso e dall'uso del sito
        e dall'utilizzo di informazioni contenute nel sito, inclusi ma non limitati a quelli causati ai sistemi
        informatici o quelli causati dall'introduzione di virus.
    </p>

    <p>
        BMW Italia S.p.A. non sarà responsabile per danni o perdite a qualsivoglia titolo e di qualsivoglia tipo subiti
        dall’Utente sulla base dell’accesso al sito, o al suo mancato accesso, e delle informazioni ivi contenute o per,
        a titolo esemplificativo e non esaustivo, possibili inesattezze, omissioni o errori del suo contenuto; perdita
        di dati, opportunità commerciali o profitti, nell'ambito delle eventuali relazioni commerciali instaurate o
        instaurande dall'Utente stesso anche con soggetti terzi tramite l'utilizzo del sito; ritardo, malfunzionamento,
        sospensione o interruzione della accessibilità al Sito; ritardo, malfunzionamento, sospensione o interruzione
        del sito; difettosità di funzionamento delle apparecchiature di connessione utilizzate dall'Utente; erronea
        utilizzazione dei servizi da parte dell'Utente.
    </p>

    <p>
        BMW Italia S.p.A. non garantisce, né si assume la responsabilità che le informazioni disponibili su questo sito
        riproducano esattamente i documenti pubblicati su carta, né risponderà di eventuali informazioni diffuse da
        terzi in cui appare citata come fonte.
    </p>

    <p>
        BMW Italia S.p.A. non è responsabile in alcun modo per quei contenuti, attività commerciali, prodotti e servizi
        inclusi che possono essere visualizzati tramite collegamenti elettronici, direttamente o indirettamente,
        attraverso questo sito. BMW Italia S.p.A. non sarà in alcun modo responsabile per il risultato ottenuto tramite
        detti collegamenti o per le conseguenze derivanti dall'accesso da parte degli utenti ad essi.
    </p>

    <h4>
        Marchi
    </h4>

    <p>
        Salvo diversa indicazione, tutti i marchi visualizzati su questo sito web sono marchi registrati di BMW AG.
    </p>

    <h4>
        Licenze
    </h4>

    <p>
        BMW Italia S.p.A. ha lavorato per creare un sito web innovativo che possa essere fruito durante l'utilizzo, la
        cui proprietà intellettuale, inclusi brevetti, marchi e diritti d'autore, è debitamente registrata e legalmente
        protetta. Questo sito non fornisce licenze sulla nostra proprietà intellettuale o sulla proprietà intellettuale
        di terze parti incorporate in esso. La copia o l'uso di tutto o parte del contenuto di questo sito web, il suo
        collegamento a un altro sito
        web al di fuori di BMW Italia S.p.A. così come l'uso dei marchi BMW, MINI e BMW Motorrad in tutte le varianti
        grafiche che appaiono su questo sito web, senza la previa ed espressa autorizzazione di BMW Italia S.p.A., sarà
        considerato appropriazione indebita.
    </p>

    <h4>
        Diritto applicabile e foro competente
    </h4>

    <p>
        I presenti termini e condizioni sono regolati dalla legge italiana e dovranno essere interpretatiesclusivamente
        in base alla stessa. Tutte le controversie derivanti dagli stessi, comprese quelle relative alla sua validità,
        interpretazione, esecuzione e risoluzione saranno di competenza, in via esclusiva, del Foro di Milano.
    </p>
</div>