import { CUSTOM_ELEMENTS_SCHEMA, NgModule,NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RecaptchaModule, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { RecaptchaSettings } from 'ng-recaptcha';import { SharedModule } from './../shared/shared.module';
import { CustomerFormComponent } from './account/form/customer-form.component';
import { FavouriteDealerComponent } from './delaer/favourite-dealer.component';
import { CustomerWizardComponent } from './account/customer-wizard.component';
import { CustomerEditWizardComponent } from './account/edit/customer-edit-wizard.component';
import { CustomerCreateWizardComponent } from './account/create/customer-create-wizard.component';
import { CustomerCreateResumeComponent } from './account/create/customer-create-resume.component';
import { environment } from './../../environments/environment';
import { ResetPasswordComponent } from './account/form/reset-password.component';
import { ForgotPasswordComponent } from './account/form/forgot-password.component';
import { VehicleFormComponent } from './vehicle/vehicle-form.component';
import { CompanyFormComponent } from './account/form/company-form.component';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { itLocale } from 'ngx-bootstrap/locale';
import { esLocale } from 'ngx-bootstrap/locale';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { VehicleFormCourtesyComponent } from './vehicle/vehicle-form-courtesy.component';
import { CustomerFormWithoutRegistrationTermsComponent } from './account/form/customer-form-without-registration-terms.component';
import { VehicleFormManualComponent } from './vehicle/vehicle-form-manual.component';
import { UserFormEditComponent } from './account/form/user-form-edit/user-form-edit.component';
import { UserFormCreateComponent } from './account/form/user-form-create.component';
import { UserFormEditEmailComponent } from './account/form/user-form-edit-email/user-form-edit-email.component';
import { UserFormDeleteAccountComponent } from './account/form/user-form-delete-account/user-form-delete-account.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AppointmentCancelLinkComponent } from './appointment-cancel-link/appointment-cancel-link.component';
import { BackofficeResetPasswordComponent } from './account/form/backoffice-reset-password.component';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatStepperModule } from '@angular/material/stepper'; 
import { BsModalRef } from 'ngx-bootstrap/modal';


// Locale need to be lowercase
defineLocale('it-it', itLocale);
defineLocale('es-es', esLocale);
defineLocale('pt-pt', ptBrLocale);
defineLocale('en-in', enGbLocale);
defineLocale('es-mx', esLocale);
defineLocale('pt-br', ptBrLocale);

@NgModule({
    imports: [
        GoogleMapsModule,
        SharedModule,
        MatStepperModule,
        RecaptchaFormsModule,
        RecaptchaModule,
        BsDatepickerModule,
        RouterModule,
        AlertModule.forRoot(),
        FormsModule
    ],
    exports: [
        FavouriteDealerComponent,
        CustomerCreateWizardComponent,
        CustomerEditWizardComponent,
        ResetPasswordComponent,
        CustomerFormComponent,
        CompanyFormComponent,
        VehicleFormComponent,
        VehicleFormManualComponent,
        VehicleFormCourtesyComponent,
        CustomerFormWithoutRegistrationTermsComponent,
        BackofficeResetPasswordComponent,
    ],
    declarations: [
        CustomerFormComponent,
        UserFormCreateComponent,
        FavouriteDealerComponent,
        CustomerWizardComponent,
        CustomerEditWizardComponent,
        CustomerCreateWizardComponent,
        CustomerCreateResumeComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        VehicleFormComponent,
        VehicleFormManualComponent,
        VehicleFormCourtesyComponent,
        CompanyFormComponent,
        CustomerFormWithoutRegistrationTermsComponent,
        UserFormEditComponent,
        UserFormEditEmailComponent,
        UserFormDeleteAccountComponent,
        AppointmentCancelLinkComponent,
        BackofficeResetPasswordComponent,
    ],
    providers: [
        BsModalRef,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.captchaKey,
            } as RecaptchaSettings,
        },
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class CustomerModule {}
