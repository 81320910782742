import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';



@Injectable()
export class DateHttpInterceptor implements HttpInterceptor {
    iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
    localDateTime = /^\d{4}-\d\d-\d\dT\d\d:\d\d(:\d\d(\.\d+)?)?$/;
    localDate = /^\d{4}-\d\d-\d\d$/;

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(
            (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.convertToDate(event.body);
                }
            },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                    }
                }
            }
        ));
    }

    private convertToDate(body) {
        if (body === null || body === undefined) {
            return body;
        }

        if (typeof body !== 'object') {
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (this.isLocalDateTime(value)) {
                // in Safari, the date must contain the timezone offset suffix in order to avoid errors
                body[key] = new Date(value + 'Z');
            } else if (this.isIso8601(value)) {
                body[key] = new Date(value);
            } else if (this.isLocalDate(value)) {
                body[key] = new Date(value + 'T00:00Z');
            } else if (typeof value === 'object') {
                this.convertToDate(value);
            }
        }
    }

    private isIso8601(value): boolean {
        if (value === null || value === undefined) {
            return false;
        }

        return this.iso8601.test(value);
    }

    private isLocalDate(value): boolean {
        if (value === null || value === undefined) {
            return false;
        }

        return this.localDate.test(value);
    }

    private isLocalDateTime(value): boolean {
        if (value === null || value === undefined) {
            return false;
        }

        return this.localDateTime.test(value);
    }
}
