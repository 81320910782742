import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CustomerService } from '../../core/api/customer/customer.service';
import { Customer } from '../../shared/entities';
import { AuthService } from './../../auth/auth.service';

@Injectable()
export class AppointmentCustomerResolverService {
    constructor(
        private router: Router,
        private customerService: CustomerService,
        private authService: AuthService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Customer> {
        if (this.authService.getFixedOutlet()) {
            return this.customerService
                .getLoggedWithFixedOutlet(this.authService.getFixedOutlet(), [
                    'favouriteOutlet',
                    'favouriteOutlet.skills',
                ])
                .then(customer => {
                    if (customer) {
                        return customer;
                    } else {
                        // id not found
                        this.router.navigate([this.authService.getDashboardPath()]);
                        return null;
                    }
                });
        } else {
            return this.customerService.getLogged(['favouriteOutlet', 'favouriteOutlet.skills']).then(customer => {
                if (customer) {
                    return customer;
                } else {
                    // id not found
                    this.router.navigate([this.authService.getDashboardPath()]);
                    return null;
                }
            });
        }
    }
}
