import { Logger } from './../../logger.service';
import { HttpClient } from '@angular/common/http';
import { Country } from './../../../shared/entities';
import { Injectable } from '@angular/core';
import { AbstractRestService } from '../generic.service';

@Injectable()
export class CountryService extends AbstractRestService<Country, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/country', http, logger);
    }

    public getTimeZones(countryCode?: string): Promise<string[]> {
        const url = `${this.actionUrl}/time-zones` + (countryCode ? '/' + countryCode : '');

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as string[])
            .catch(error => this.handleError(error));
    }

    public getAll(): Promise<Country[]> {
        const url = `${this.actionUrl}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as Country[])
            .catch(error => this.handleError(error));
    }
}
