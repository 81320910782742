import { CountryConfiguration } from '../app/country-configuration';
import { RoleType } from '../app/shared/entities';
export let mexicoCountryConfiguration: CountryConfiguration = {
    language: 'es',
    countryCode: 'MX',
    welcomeTerminalAppointmentIdenabled: false,
    appointmentCountForSadvInDashboard: false,
    showCarShadowImageInClientSearch:true,
    locale: 'es-MX',
    nscCicSearchOnDMS: true,
    phoneNumberRegex: /^(([+]|00)52)?[0-9]{10}$/,
    mobileNumberRegex: [/^(([+]|00)52)?[0-9]{10}$/],
    vatRegex: /^(?=.{9}$)\d*[a-zA-Z]\d{0,31}$/g,
    fiscalCodeRegex: null,
    dsaFilePath: 'assets/pdf/MX/DSA_Notice.pdf',
    showDSALink: false,
    BMW: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.com.mx/content/dam/bmw/marketMX/bmw_com_mx/Descargas/Aviso-de-Privacidad/2022/octubre/Aviso%20de%20Privacidad%20Integral%20Clientes%20y%20Clientes%20Potenciales%20BMW.pdf.asset.1667520367457.pdf',
            legalNoteLink: 'https://www.bmw.com.mx/es/footer/footer-section/privacy-policy.html',
            contactLink: 'mailto:atencionbmw@bmw.com.mx',
            textHomeLink: 'BMW.COM.MX',
            homeLink: 'https://www.bmw.com.mx',
            vatNumber: '',
        },
        srpCampaignFootNote:
            '<span>Precios Cerrados con un descuento mínimo garantizado del 20%. Incluye Aceite Genuino, Recambios Originales, mano de obra e impuestos.</span>',
        tssbInfoUrl: undefined,
        supportChatAvailable: false,
        quickAppointment: true,
        ePaas: {
            enabled: false,
            tenantId: 'servicio_bmw_com_mx',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: true,
        remoteKeyReadLink: '',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    MINI: {
        footer: {
            privacyPolicyLink: 'https://www.mini.com.mx/es_MX/home/footer/aviso-de-privacidad.html',
            legalNoteLink: 'https://www.mini.com.mx/es_MX/home/footer/Legal.html',
            contactLink: 'mailto:atencionbmw@bmw.com.mx',
            textHomeLink: 'MINI.COM.MX',
            homeLink: 'https://www.mini.com.mx/es_MX/home.html',
            vatNumber: '',
        },
        srpCampaignFootNote:
            '<span>Precios Cerrados con un descuento mínimo garantizado del 20%. Incluye Aceite Genuino, Recambios Originales, mano de obra e impuestos.</span>',
        tssbInfoUrl: undefined,
        supportChatAvailable: false,
        quickAppointment: true,
        ePaas: {
            enabled: false,
            tenantId: 'cita-online-taller_mini_es',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: true,
        remoteKeyReadLink: '',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    BMW_MOTORRAD: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.com.mx/content/dam/bmw/marketMX/bmw_com_mx/Descargas/Aviso-de-Privacidad/2022/octubre/Aviso%20de%20Privacidad%20Integral%20Clientes%20y%20Clientes%20Potenciales%20BMW.pdf.asset.1667520367457.pdf',
            legalNoteLink: 'https://www.bmw-motorrad.com.mx/es/public-pool/content-pool/legalnotice.html',
            contactLink: 'mailto:atencionbmw@bmw.com.mx',
            textHomeLink: 'BMW-MOTORRAD.COM.MX',
            homeLink: 'https://www.bmw-motorrad.com.mx/es/home.html',
            vatNumber: '',
        },
        srpCampaignFootNote:
            '<span>Precios Cerrados con un descuento mínimo garantizado del 20%. Incluye Aceite Genuino, Recambios Originales, mano de obra e impuestos.</span>',
        tssbInfoUrl: undefined,
        supportChatAvailable: false,
        quickAppointment: false,
        longLifeCareMobilityBanner: false,
        ePaas: {
            enabled: false,
            tenantId: 'cita-online-taller_bmw-motorrad_es',
        },
        remoteKeyRead: true,
        remoteKeyReadLink: '',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    BMW_I: {
        footer: {
            privacyPolicyLink: 'https://www.bmw.com.mx/content/dam/bmw/marketMX/bmw_com_mx/Descargas/Aviso-de-Privacidad/2022/octubre/Aviso%20de%20Privacidad%20Clientes%20y%20Clientes%20Potenciales%20BMW.pdf.asset.1667520404830.pdf',
            legalNoteLink: 'https://www.bmw.es/es/footer/metanavigation/legal-disclaimer/aviso-legal.html',
            contactLink: 'mailto:rclientes@bmw.es',
            textHomeLink: 'BMW.ES',
            homeLink: 'https://www.bmw.es/es/index.html',
            vatNumber: 'Número de identificación fiscal - A-28713642',
        },
        srpCampaignFootNote:
            '<span>Precios Cerrados con un descuento mínimo garantizado del 20%. Incluye Aceite Genuino, Recambios Originales, mano de obra e impuestos.</span>',
        tssbInfoUrl: undefined,
        supportChatAvailable: false,
        quickAppointment: true,
        ePaas: {
            enabled: true,
            tenantId: 'cita-online-taller_bmw_es',
        },
        longLifeCareMobilityBanner: false,
        remoteKeyRead: true,
        remoteKeyReadLink: '',
        enableTugaTicket: false,
        fastLaneEnabled: true,
    },
    tssb: {
        enable: true,
        enableRecentPendingAppointmentCheckboxByRoles: new Map<RoleType, boolean>([
            [<RoleType>'ROLE_CIC_EP_MANAGER', false],
            [<RoleType>'ROLE_CIC_EP_USER', false],
            [<RoleType>'ROLE_S_ADV', false],
            [<RoleType>'ROLE_S_ASSISTANT', false],
            [<RoleType>'ROLE_CIC_NSC_ADMIN', false],
            [<RoleType>'ROLE_CIC_NSC_USER', false],
            [<RoleType>'ROLE_ASM', false],
            [<RoleType>'ROLE_RENTING_ADM', false],
            [<RoleType>'ROLE_RENTING_USER', false],
        ]),
        enableEmail: true,
    },
    emailQuickAppointment: 'no-reply-oas-es@bmw.es',
    showAllCapacityTeams: false,
    waitInOutlet: false,
    mobilityStepAvailability: false,
    enableToros: false,
    editCustomer: true,
    vatNumberValidator: '',
    dcomHealth: true,
    showFastLaneLink: false,
    // Quick appointment REFACTOR
    latitude: 19.439402149291933,
    longitude: -99.14138800206804,
    createVehicleFiscalCode: false,
    dcomValidation: true,
    mapsRadius: 30000,
    mapsZoom: 10,
    updateDMS: false,
    maxDelay: 72,
    daysOfWeek: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    enableRenting: false,
    enableNscReport: true,
    nscReportFilename: 'APPOINTMENTS_DETAILS_FILE_NAME',
    enableSMS: false,
    sendSMS: false,
    automaticSelectionCbs99999: true,
    enableBsi: true,
    hideAutomaticVehicleRecognitionStepIntoAppointmentWithoutRegistration: true,
    enableKiosk: false,
    enablePPVSync: false,
    enableTjekvik: false,
    enableSharebox: false,
    enableSPOA: false,
    enableVinLookup: false,
    preRegistration: {
        enablePreRegistrationFromDms: true,
        enablePreRegistrationFromFastAppointment: true,
        hideCheckBoxWithoutRegistration: false,
        forceSendEmailWithoutRegistration: false,
    },
    showVinOnlineAppointmentResume: true,
    showFiscalNumberVehicleForm: false,
    servicesBannerEnableWithoutCampaign: true,
    vehicleFormSmartRegistrationType: 'MANUAL',
    showAppointmentWithoutRegistrationVehicleForm: false,
    fastAppointmentMigrationToConsumeCapacity: false,
    dateInputRangeFormat: 'DD/MM/YYYY',
    enableLeadApi: true,
    currencyCode: 'MXN',
    enableEmail: true,
    sendEmail: true,
    leadApiOutlets: ['OUTL_28407', 'OUTL_46754', 'OUTL_20169', 'OUTL_22046', 'OUTL_22192', 'OUTL_45042', 'OUTL_22195', 'OUTL_22196', 'OUTL_22197', 'OUTL_22202', 'OUTL_22203', 'OUTL_44639', 'OUTL_22204', 'OUTL_23675', 'OUTL_32676', 'OUTL_24892', 'OUTL_26014', 'OUTL_31406', 'OUTL_33159', 'OUTL_34808', 'OUTL_36834', 'OUTL_38387', 'OUTL_38387', 'OUTL_41363', 'OUTL_41418', 'OUTL_43485', 'OUTL_43614', 'OUTL_45249', 'OUTL_43734', 'OUTL_43955', 'OUTL_44788', 'OUTL_44789', 'OUTL_44790', 'OUTL_44861', 'OUTL_44791', 'OUTL_44792', 'OUTL_47920', 'OUTL_49507', 'OUTL_49507', 'OUTL_49817', 'OUTL_50078', 'OUTL_50868', 'OUTL_50868', 'OUTL_50919', 'OUTL_51117', 'OUTL_52059'],
    leadApiOutletFilterEnable: true,
    leadTssbAsmEnable: true,
    appointmentFastCustomerCreationGenderEnabled:false,
    eitherPhoneOrEmailEnabled:false,
    showOnlineWorkTeam: true,
    workingDaysEnabled:false,
    dropDownLeadStatuses: [
        'assignedToTsp',
        'contacting',
        'postponed'
    ],
    availabilityFilter: false,
    awp: {
        url: 'https://awp.bmwgroup.com/servicedemand?',
        vinParameter: 'Vin=',
        icon: {
            enabled: false,
            url: 'awp:/start?app=vehicledetails&vin=${vin}',
        }
    },
    enableLeadBattery: false,
    enableFastLaneResume: false,
    enableAwpLinkCustomerSearch: false,
    enableAppointmentShareBox: false,
    enableTuga: false
};
