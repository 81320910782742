import { Injectable } from '@angular/core';

import { AuthGuard } from './auth-guard.service';

@Injectable()
export class AuthGuardRentingProfile extends AuthGuard {
    protected checkRole(): boolean {
        return this.authService.isRentingAdmProfile() || this.authService.isRentingUserProfile();
    }
}
