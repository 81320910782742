import { Component } from '@angular/core';

@Component({
  selector: 'oas-terms-and-conditions-es',
  templateUrl: './terms-and-conditions-es.component.html',
  styleUrls: ['../../terms-and-conditions.component.scss']
})
export class TermsAndConditionsEsComponent {

}
