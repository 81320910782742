import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {TssbLeadTireEvent, TssbTicket} from "../../entities";
import {AlertSuccessComponent} from "../../alert/alert-success.component";
import {Utils} from "../../../core/utils";

@Component({
   selector: 'oas-battery-lead-api',
   templateUrl: './battery-lead-api.component.html',
   styleUrls: ['./battery-lead-api.component.scss']
})
export class BatteryLeadApiComponent implements OnInit{
   @Input() tssbTicket: TssbTicket;
   @Input() online: boolean = false;
   @Input() showAdd: boolean = false;
   public listBattery: TssbLeadTireEvent[] = [];
   private selectedBattery: String[] = [];
   @Output() public selectedTiresList = new EventEmitter<String[]>();
   @ViewChild('alertSuccess') public alertSuccess: AlertSuccessComponent;
   constructor(){

   }

   private leadAutoSelectTires(): void {
      if (
         !Utils.isNullOrUndefined(this.tssbTicket) &&
         !Utils.isNullOrUndefined(this.tssbTicket.id) &&
         !Utils.isNullOrUndefined(this.tssbTicket.tssbStaticTicketData.keyData.batteryEvents) &&
         !Utils.isNullOrUndefined(this.tssbTicket.tssbStaticTicketData.keyData.batteryEvents.length > 0)
      ) {
         this.listBattery = this.tssbTicket.tssbStaticTicketData.keyData.batteryEvents;
         if(this.online){
            this.listBattery
               .forEach(batteryEvent => {
                  if(batteryEvent.urgency != null){
                     this.onSelect(batteryEvent, true);
                  }
               });
         }
      }
   }

   public onSelect(tireEvent: TssbLeadTireEvent, checked: boolean): void {
      if (checked) {
         this.selectedBattery.push(tireEvent.identifier);
      } else {
         this.selectedBattery.splice(this.selectedBattery.indexOf(tireEvent.identifier), 1);
      }
      this.selectedTiresList.emit(this.selectedBattery);
   }

   ngOnInit(): void {
      this.leadAutoSelectTires();
   }

   public isAlreadySelected(tire: TssbLeadTireEvent): boolean {
      return this.selectedBattery.includes(tire.identifier);
   }
}
