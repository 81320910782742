import { Injectable } from '@angular/core';

import {
    Mechanic,
    MechanicCapacityByBrand,
    VehicleBrand,
    SetMechanicQuotaWithBrandRequest,
    SetWorkTeamQuotaItem,
} from '../../../shared/entities';
import { AbstractRestService } from '../generic.service';
import { Logger } from '../../logger.service';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class MechanicService extends AbstractRestService<Mechanic, number> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/mechanics', http, logger);
    }

    public findPlainByOutlet(outletId: string): Promise<Mechanic[]> {
        const url = `${this.actionUrl}/by-outlet`;

        const params = new HttpParams().set('outletId', outletId);

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as Mechanic[])
            .catch(error => this.handleError(error));
    }

    public findForPersonalDataManagement(outletId: string): Promise<Mechanic[]> {
        return this.findPlainByOutlet(outletId);
    }

    public getCapacity(mechanicId: number, dateFrom: Date, dateTo: Date): Promise<MechanicCapacityByBrand[]> {
        const url = `${this.actionUrl}/${mechanicId}/capacity`;

        const params = new HttpParams()
            .set('dateFrom', dateFrom.toISOString().substr(0, 10)) // send only the date info (not the time)
            .set('dateTo', dateTo.toISOString().substr(0, 10)); // send only the date info (not the time)

        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => response as Mechanic[])
            .catch(error => this.handleError(error));
    }

    public setCapacity(
        mechanicId: number,
        brand: VehicleBrand,
        setWorkTeamQuotaItems: SetWorkTeamQuotaItem[],
        workingHours: number,
        year: number,
        months: number[],
        daysOfWeek: number[],
        day: number
    ): Promise<void> {
        const url = `${this.actionUrl}/${mechanicId}/capacity`;
        const quotaWithBrandRequest: SetMechanicQuotaWithBrandRequest = {
            brand,
            setWorkTeamQuotaItems: setWorkTeamQuotaItems,
            workingHours,
            year,
            months,
            daysOfWeek,
            day
        };
        return this.http
            .put(url, quotaWithBrandRequest)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }
}
