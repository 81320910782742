import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractRestService } from '../generic.service';
import { WorkTeam, WorkTeamType } from '../../../shared/entities';
import { Logger } from '../../logger.service';

@Injectable()
export class WorkTeamService extends AbstractRestService<WorkTeam, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/work-team', http, logger);
    }

    public getAllWorkTeamType(): Promise<WorkTeamType[]> {
        const url = `${this.actionUrl}/type`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as WorkTeamType[])
            .catch(error => this.handleError(error));
    }
}
