import { CustomerModule } from './../customer/customer.module';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { environment } from './../../environments/environment';
import { SharedModule } from './../shared/shared.module';
import { OnlineRoutingModule } from './online-routing.module';
import { DashboardComponent } from './dashboard.component';
import { ActivityComponent } from './appointment/activity/activity.component';
import { ActivityPriceComponent } from './appointment/activity/activity-price.component';
import { AppointmentListComponent } from './appointment/appointment-list.component';
import { VehicleCreateEditComponent } from './vehicle/vehicle-create-edit.component';
import { VehicleCarouselComponent } from './vehicle/vehicle-carousel.component';
import { OnlineAppointmentWizardComponent } from './appointment/online-appointment-wizard.component';
import { AppointmentResumeComponent } from './appointment/appointment-resume.component';
import { OutletAgendaComponent } from './appointment/outlet/outlet-agenda.component';
import { OutletMapsComponent } from './appointment/outlet/outlet-maps.component';
import { OutletMobilityComponent } from './appointment/outlet/outlet-mobility.component';
import { AppointmentResolverService } from './appointment/appointment-resolver.service';
import { VehicleResolverService } from './vehicle/vehicle-resolver.service';
import { VehicleListComponent } from './vehicle/vehicle-list.component';
import { BrandStyleSwitcherComponent } from './brand-style-switcher.component';
import { AppointmentWithoutRegistrationComponent } from './quick-appointment/appointment-without-registration.component';
import { RecaptchaModule, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { RecaptchaSettings } from 'ng-recaptcha';
// import { MultimediaModule } from 'multimedia/src/app/multimedia/multimedia.module';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { UpdateAppointWoregistrResolverService } from './update-appoint-woregistr-resolver.service';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TermsAndConditionsEsComponent } from './terms-and-conditions/terms-and-conditions-templates/terms-and-conditions-es/terms-and-conditions-es.component';
import { TermsAndConditionsItComponent } from './terms-and-conditions/terms-and-conditions-templates/terms-and-conditions-it/terms-and-conditions-it.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatStepperModule } from '@angular/material/stepper'; 
import { register } from 'swiper/element/bundle'; 

register();

@NgModule({
    imports: [
        GoogleMapsModule,
        FullCalendarModule,
        SharedModule,
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        MatStepperModule,
        CustomerModule,
        OnlineRoutingModule,
        RecaptchaFormsModule,
        RecaptchaModule,
        BsDropdownModule
    ],
    exports: [
        FullCalendarModule,
    ],
    declarations: [
        DashboardComponent,
        ActivityComponent,
        ActivityPriceComponent,
        AppointmentListComponent,
        VehicleCreateEditComponent,
        VehicleCarouselComponent,
        OnlineAppointmentWizardComponent,
        AppointmentResumeComponent,
        OutletAgendaComponent,
        OutletMapsComponent,
        OutletMobilityComponent,
        VehicleListComponent,
        BrandStyleSwitcherComponent,
        AppointmentWithoutRegistrationComponent,
        TermsAndConditionsComponent,
        TermsAndConditionsEsComponent,
        TermsAndConditionsItComponent,
    ],
    providers: [
        BsModalRef,
        AppointmentResolverService,
        VehicleResolverService,
        UpdateAppointWoregistrResolverService,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.captchaKey,
            } as RecaptchaSettings,
        }
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class OnlineModule {}
