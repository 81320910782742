import { Injectable } from '@angular/core';
import { AuthGuard } from './auth-guard.service';

@Injectable()
export class AuthGuardBackoffice extends AuthGuard {
    protected checkLogin(url: string): boolean {
        let result = super.checkLogin(url);

        if (result && url === '/backoffice') {
            // can go through children auth guard
            result = true;
            this.router.navigate([this.authService.getDashboardPath()]);
        }

        return result;
    }

    protected checkRole(): boolean {
        return !this.authService.isCustomer();
    }
}
