<div class="horizontal-scroll" style="margin-right: 35px;" *ngIf="listBattery?.length > 0 else noServicesToDisplay">
   <table class="table table-bordered table-striped">
      <thead>
      <tr>
         <th class="col-md-3" i18n="@@description">Description</th>
         <th class="col-md-2" i18n="@@batteryType">Battery type</th>
         <th class="col-md-1" i18n="@@wearAge">Wear Age</th>
         <th class="col-md-2" i18n="@@wearPercentage">Wear Percentage</th>
         <th class="col-md-2" i18n="@@healthClass">Health Class</th>
         <th class="col-md-2" i18n="@@stateOfCharge">state Of Charge</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let event of listBattery">
         <td class="col-md-3" style="text-align: center">
            <ng-container>
               {{event?.identifier | translate}}
           </ng-container>
         </td>
        
         <td class="col-md-2" style="text-align: center">
            <div>{{ event.capacity }} Ah {{ event.batteryType }}</div>
         </td>
         <td class="col-md-1" style="text-align: center">
            <div>{{ event.wearAgeComponent }}</div>
         </td>
         <td class="col-md-2" style="text-align: center">
            <div>{{ event.wearLifeTimePercentage }}%</div>
         </td>
         <td class="col-md-2" style="text-align: center">
            <div>{{ event.healthClass }}</div>
         </td>
         <td class="col-md-2" style="text-align: center">
            <div>{{ event.stateOfChargeHealthClass }}</div>
         </td>
         
      </tr>
      </tbody>
   </table>
   <div class="col-md-12 d-flex justify-content-center" *ngIf="showAdd">
      <div class="col-md-3">
         <button class="btn btn-primary btn-block ui-button" (click)="broadcastTireServices()" i18n="@@add">Add
         </button>
      </div>
   </div>
</div>

<ng-template #noServicesToDisplay>
   <div class="row">
      <div class="col-md-12 cbs-service_no-item" i18n="@@noServicesToDisplay">No services to display</div>
   </div>
</ng-template>
