<div class="login-form__section confirmation_bloc">
    <h2 class="summary__customer-info__title confirmation_title" i18n="@@confirmRegistration">Confirm registration</h2>

    <div class="flex-row">
        <div class="col-md-12 flex-column">
            <div class="summary__customer-info__field flex-row">
                <img alt="" src="assets/images/ic_name-register.svg" />
                {{ customer?.name }} {{ customer?.surname }}
            </div>

            <div class="summary__customer-info__field flex-row">
                <img alt="" src="assets/images/ic_mail-register.svg" />
                {{ customer?.email }}
            </div>

            <div class="summary__customer-info__field flex-row">
                <img alt="" src="assets/images/ic_cell-register.svg" />
                {{ customer?.phoneNumber }}
            </div>

            <div *ngIf="getDefaultOrFirstAddress(customer?.addressList)?.address != null" class="summary__customer-info__field flex-row">
                <img alt="" src="assets/images/ic_address-register.svg" />
                {{ getDefaultOrFirstAddress(customer?.addressList)?.address }}
            </div>
            <br />
            <ng-container *ngIf="customer.vehicleList">
                <div class="summary__customer-info__field">
                    <i class="fa fa-car" aria-hidden="true"></i>
                    {{ getVehicleModel(customer.vehicleList) }} ({{ (customer?.vehicleList)[0]?.plate }})
                </div>
            </ng-container>
        </div>
    </div>

    <p class="summary__tos" i18n="@@agreement">
        Please, read terms and condition
    </p>
    <div class="ui-checkbox summary__tos-accept">
        <input type="checkbox" id="accept_privacy" (change)="acceptPrivacyPolicy($event.target.checked)" />
        <label for="accept_privacy">
            <span i18n="@@acceptTermsOf">I accept Terms of </span>
            <a [href]="privacyPolicyLink" target="_blank">
                <span *ngIf="brand === 'BMW' || brand === 'BMW_I'" i18n="@@bmwPrivacyPolicy">BMW’s Privacy Policy</span>
                <span *ngIf="brand === 'MINI'" i18n="@@miniPrivacyPolicy">MINI’s Privacy Policy</span>
                <span *ngIf="brand === 'BMW_MOTORRAD'" i18n="@@bmwMotorradPrivacyPolicy">BMW Motorrad’s Privacy Policy</span>
            </a>
            <span i18n="@@acceptTermsOfEnd">BMW</span>
        </label>
    </div>
    
    <div *ngIf="termsAndConditionsUrl" class="ui-checkbox summary__tos-accept">
        <input type="checkbox" id="accept_tos"  (change)="acceptTermsAndConditions($event.target.checked)"/> 
        <label for="accept_tos">
            <span i18n="@@acceptSCI"> I have read and I accept the Service Check-In </span>
                <a
                    [href]="termsAndConditionsUrl"
                    target="_blank"
                    i18n="@@termsAndConditionsRegistration">
                    Terms and Conditions
                </a>
        </label>
    </div>
</div>
