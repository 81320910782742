import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardCustomer } from './../auth/auth-guard-customer.service';
import { OnlineAppointmentWizardComponent } from './appointment/online-appointment-wizard.component';
import { DashboardComponent } from './dashboard.component';
import { AppointmentCustomerResolverService } from './appointment/appointment-customer-resolver.service';
import { DashboardCustomerResolverService } from './dashboard-customer-resolver.service';
import { VehicleCreateEditComponent } from './vehicle/vehicle-create-edit.component';
import { CustomerEditWizardComponent } from '../customer/account/edit/customer-edit-wizard.component';
import { AppointmentResolverService } from './appointment/appointment-resolver.service';
import { VehicleResolverService } from './vehicle/vehicle-resolver.service';
import { CustomerEditResolverService } from './customer-edit-resolver.service';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuardCustomer],
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                resolve: {
                    customer: DashboardCustomerResolverService,
                },
                component: DashboardComponent,
            },
            {
                path: 'account-edit',
                resolve: {
                    customer: CustomerEditResolverService,
                },
                component: CustomerEditWizardComponent,
            },
            {
                path: 'dashboard/:customerId/vehicle',
                resolve: {
                    vehicle: VehicleResolverService,
                },
                component: VehicleCreateEditComponent,
            },
            {
                path: 'appointment/vehicle/:vehicleId',
                resolve: {
                    customer: AppointmentCustomerResolverService,
                },
                component: OnlineAppointmentWizardComponent,
            },
            {
                path: 'appointment/:appointmentId',
                resolve: {
                    appointment: AppointmentResolverService,
                },
                component: OnlineAppointmentWizardComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AppointmentCustomerResolverService, DashboardCustomerResolverService, CustomerEditResolverService],
})
export class OnlineRoutingModule {}
