import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InfoBoxBsi, VehicleBrand } from '../../shared/entities';
import { Logger } from '../logger.service';
import { AbstractRestService } from './generic.service';

@Injectable()
export class InfoBoxBsiService extends AbstractRestService<InfoBoxBsi, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/info-box-bsi', http, logger);
    }

    public getInfoBoxBsiValue(vehicleBrand: VehicleBrand): Promise<InfoBoxBsi> {
        const url = `${this.actionUrl}/${vehicleBrand}`;
        return this.http
            .get(url)
            .toPromise()
            .then(response => response as InfoBoxBsi)
            .catch(error => this.handleError(error));
    }
}
