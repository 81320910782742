import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { VehicleService } from '../../core/api/vehicle/vehicle.service';
import { AuthService } from '../../auth/auth.service';
import { StyleSwitcherService } from '../../core/style-switcher.service';
import { VehicleBrand } from '../entities';
import { Utils } from 'app/core/utils';

@Component({
    selector: 'oas-vehicle-picture-ucp',
    templateUrl: './vehicle-picture-ucp.component.html',
})
export class VehiclePictureUcpComponent implements OnInit, OnChanges {
    @Input() public vin: string;
    @Input() public vehicleSeriesId: string;
    @Input() appointmentVehicleBrand: string;
    vehicleBrand: VehicleBrand;
    isMoto: boolean;

    public showSpinner = false;
    public vehiclePictureUrl: SafeUrl;
    public isShadowImg: boolean;
    
    constructor(
        private vehicleService: VehicleService,
        private sanitizer: DomSanitizer,
        private authService: AuthService,
        private styleSwitcherService: StyleSwitcherService
    ) {}

    public ngOnInit() {
        this.isMoto = this.styleSwitcherService.isBrandSet()
                        && this.styleSwitcherService.brand.getValue().toLowerCase() === 'bmw_motorrad' || this.vehicleBrand === 'BMW_MOTORRAD' ||
                        this.appointmentVehicleBrand === 'BMW_MOTORRAD'; 
        
        if(this.vin){
            this.vehicleService.getVehicleInformationFromVin(this.vin).then(vehicle => {
                if(Utils.isNotNullOrUndefined(vehicle)) {
                    this.vehicleBrand = vehicle?.payload?.model?.brand;
                    this.retrieveVehiclePictureUrl();
                }
            });
        } else{
            this.retrieveVehiclePictureUrl();
        }
        
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.vin && !changes.vin.isFirstChange()) {
            this.retrieveVehiclePictureUrl();
        } else if (changes.vehicleSeriesId && !changes.vehicleSeriesId.isFirstChange()) {
            // this.vehiclePictureUrl = `${environment.imgUrl}/${this.vehicleSeriesId}.png`;
            this.retrieveVehiclePictureUrl();
        }
    }

    public hideSpinner() {
        this.showSpinner = false;
    }

    private retrieveVehiclePictureUrl(): void {
        this.showSpinner = true;

        this.vehicleService
            .getVehiclePictureUrlFromUCP(this.vin, this.vehicleSeriesId, this.authService.getSelectedRole(), this.isMoto)
            .then(response => {
                this.hideSpinner();
                this.vehiclePictureUrl = this.sanitizer.bypassSecurityTrustUrl(response);
                
                console.log("received " +response +" from UCP")
                if(response.includes("DEFAULT.png")){
                    this.isShadowImg = true;
                    console.log("URL contains image shadow, dont show it")
                }
            })
            .catch(() => {
                this.hideSpinner();
            });
    }

    public updateUrl(image) {
        this.retrieveVehiclePictureUrl();
    }
}
