<div class="row dealer__search-row">
    <div class="col-md-4">
        <h2 class="assistance-title" i18n="@@selectDealer">Select a dealer</h2>
        <h6 *ngIf="enableAvailabilityFilter" class="assistance-title" i18n="@@orderBy">Order By</h6>
        <select *ngIf="enableAvailabilityFilter" class="form-control" (change)="changeOutletFilter($event)">
            <option i18n="@@availability" value="TIME_SLOT" [selected]="defaultFilter === 'TIME_SLOT'">Availability</option>
           <option i18n="@@default" value="BUSINESS_NAME" [selected]="defaultFilter === 'BUSINESS_NAME'">Default</option>
        </select>
    </div>
    <div class="col-md-8">
        <div class="row">
            <div class="col-md-12">
                <div class="switcher-buttons dealer-search__switcher-buttons">
                    <i class="fa fa-sliders"></i>
                    <button
                        class="btn button--clear switcher-button dealer-search__switcher-button"
                        [class.dealer-search__switcher-button--active]="searchType === 'address'"
                        (click)="onSearchTypeChange('address')"
                        i18n="@@searchByAddress"
                    >
                        Search by address
                    </button>
                    <button
                        class="btn button--clear switcher-button dealer-search__switcher-button btn-search-by-dealer"
                        [class.dealer-search__switcher-button--active]="searchType === 'outlet'"
                        (click)="onSearchTypeChange('outlet')"
                        i18n="@@searchByDealer"
                    >
                        Search by dealer
                    </button>
                </div>
            </div>
        </div>
        <div class="row dealer__search-radius-row">
            <div class="col-md-7">
                <div
                    class="input-group assistance-search dealer__assistance-search"
                    [style.display]="searchType === 'address' ? 'inline-block' : 'none'"
                >
                    <input
                        #searchAddress
                        type="text"
                        class="form-control"
                        i18n-placeholder="@@searchForLocation"
                        placeholder="Search for location"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        [formControl]="searchAddressControl"
                    />
                    <span class="clear-input">
                        <button
                            *ngIf="searchAddressControl.value && !detectIE()"
                            class="btn btn-default button--clear"
                            (click)="searchAddressControl.reset()"
                        >
                            <i class="fa fa-times"></i>
                        </button>
                    </span>
                </div>
                <div
                    class="input-group assistance-search dealer__assistance-search"
                    [style.display]="searchType === 'outlet' ? 'inline-block' : 'none'"
                >
                    <input
                        #searchOutlet
                        type="text"
                        class="form-control ui-typeahead"
                        i18n-placeholder="@@searchOutlet"
                        placeholder="Search for outlet"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        [formControl]="searchOutletControl"
                        [typeahead]="outlets"
                        [typeaheadItemTemplate]="customItemSearch"
                        typeaheadOptionField="businessName"
                        (typeaheadOnSelect)="typeaheadOnSelect($event)"
                    />
                    <span class="clear-input">
                        <button
                            *ngIf="searchOutletControl.value && !detectIE()"
                            class="btn btn-default button--clear"
                            (click)="searchOutletControl.reset()"
                        >
                            <i class="fa fa-times"></i>
                        </button>
                    </span>
                </div>
            </div>
            <div *ngIf="showSlider" class="col-md-5 alignSlider">
                <div class="dealer__search-radius--wrapper">
                    <div class="dealer__search-radius__slider--wrapper">
                        <nouislider
                            [connect]="[true, false]"
                            [min]="0.0"
                            [max]="100"
                            [step]="10.0"
                            [keyboard]="true"
                            [formControl]="radiusControl"
                            (update)="setRadius($event)"
                        ></nouislider>
                    </div>
                    <div class="dealer__search-radius__text--wrapper">
                        <div class="dealer__search-radius__text">{{ radiusControl.value }} Km</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="dealer-results__wrapper">
            <div class="row row-md-eq-height">
                <div class="col-md-4">
                    <div class="dealer-list__wrapper dealer-list-wrapper-align">
                        <ng-container *ngFor="let out of outlets; trackBy: trackByOutlet">
                            <div
                                *ngIf="out.visible"
                                class="dealer-info"
                                [class.dealer-info--selected]="outlet?.id === out.id"
                                (click)="onSelect(out)"
                            >
                               <div class="d-flex align-items-center w-100">
                                  <img src="assets/images/ic_your-dealer.svg" alt="" class="dealer-info__icon" />
                                  <p>
                                     <strong>{{ out.businessName }}</strong
                                     >, <br />
                                     {{outletCityByAddress(out.address)}} - {{ out.address.address }} <br />
                                     {{ out.address.stairInt }}
                                  </p>
                                  <!-- <p style="float: left" *ngIf="enableAvailabilityFilter && !out.firstAvailableTimeslot && !noAvailableTimeslotOutlets.includes(out.id)"><i class="fa icon-size fa-spinner fa-spin"></i></p> -->
                               </div>
                               <div class="d-flex w-100" *ngIf="enableAvailabilityFilter && out.firstAvailableTimeslot">
                                  <span class="px-20 m-10 text-12 text-bold bg-white">{{out.firstAvailableTimeslot | datex: 'EEE dd/MM'}}</span>
                                  <span class="px-40 m-8 text-bold text-12 bg-green">{{out.firstAvailableTimeslot | datex: 'HH:mm'}}</span>
                               </div>
                               <div class="d-flex w-100" *ngIf="enableAvailabilityFilter && !out.firstAvailableTimeslot && noAvailableTimeslotOutlets.includes(out.id) && !danaOutlets.includes(out.id)">
                                    <div class=" no-available-timeslot-icon swal2-icon swal2-info">i</div>
                                    <span class="px-5 m-8 text-bold text-12 bg-light-red">{{'noAvailableTimeSlot' | translate}}</span>
                               </div>
                               <div class="d-flex w-100" *ngIf="enableAvailabilityFilter && !out.firstAvailableTimeslot && noAvailableTimeslotOutlets.includes(out.id) && danaOutlets.includes(out.id)">
                                <div class=" no-available-timeslot-icon swal2-icon swal2-info">i</div>
                                <span class="px-5 m-8 text-bold text-12 bg-light-red">{{'noAvailableDana' | translate}}</span>
                           </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="dealer-results__map">
                        <google-map [options]="mapOptions" #map *ngIf="displayMap" (mapClick)="closeInfoWindow()" (zoomChanged)="getMapZoom()">
                            <map-circle
                                #circle
                                [options]="circleOptions"
                                (radiusChanged)="toggleOutletVisibility()"
                                (centerChanged)="toggleOutletVisibility()"
                            ></map-circle>
                            <map-marker
                                #markerElement="mapMarker"
                                *ngFor="let out of outlets; trackBy: trackByOutlet"
                                [options]="{
                                    position: {lat: out.address.latitude, lng: out.address.longitude },
                                    title: out.businessName
                                }"
                                (mapClick)="onSelect(out);openInfoWindow(markerElement, out.businessName)"
                            >

                            </map-marker>
                            <map-info-window #window  [options]="infoWindowOptions">
                                <div style="text-align: center !important;vertical-align: middle !important;"> 
                                    {{markerTitle}}
                                </div>
                            </map-info-window>
                        </google-map>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #customItemSearch let-model="item">
    <div class="typeahead-item">
        <p>
            {{ model.businessName }} <ng-container *ngIf="!isCustomer"> - {{ model.originalId }}</ng-container>
        </p>
        <p class="outlet-address">
            {{outletCityByAddress(model.address)}} - {{ model.address.address }}
            <br />
            {{ model.address.stairInt }}
        </p>
    </div>
</ng-template>

<ngx-loading [show]="isFstAvailableLoading" style="z-index: 9999; position: fixed;" [config]="{ fullScreenBackdrop: true }"></ngx-loading>
