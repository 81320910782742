import {Component, Input, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { AlertConfirmComponent } from '../alert/alert-confirm.component';
import { dataAnalytics } from '../../shared/data-analytics';
import { TracyService } from '../../shared/tracy.service';

@Component({
    selector: 'oas-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
    @ViewChild('alertConfirmLogout') private alertConfirmLogout: AlertConfirmComponent;
    @Input() public isWhiteColor: boolean = false;

    constructor(private authService: AuthService, private router: Router, private tracyService: TracyService) { }

    public logout() {
        this.setDataAnalytics();
        this.showLogoutPopupWithAnalytics();
    }

    private setDataAnalytics() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasLogout,
            dataAnalytics.page.variant.real
        );
        this.tracyService.sendPageView(dataAnalytics.referenceName.logout);
    }

    private showLogoutPopupWithAnalytics() {
        let redirectPage = this.authService.isCustomer() ? '/welcome' : '/backoffice/login';
        let isCustomer = this.authService.isCustomer();
        this.alertConfirmLogout.show().then(result => {
            if (result.value) {
               this.authService.logout();
               if(isCustomer){
                  let url = window.location.hostname.includes("-int") || window.location.hostname.includes("-dev")
                     ? 'https://customer-i.bmwgroup.com/oneid/#/' : 'https://customer.bmwgroup.com/oneid/#/';
                  window.open(`${url}logout?client=oas&redirectUrl=${encodeURIComponent("https://"+window.location.href.split("/")[2])}`, '_self');
               }
               const path =
                    this.authService.getFixedOutlet() != null && this.authService.getFixedOutlet() != ''
                        ? '/dda/' + this.authService.getFixedOutlet()
                        : redirectPage;
                this.router.navigate([path]);
            }
        });

        this.setAlertDataAnalytics();
    }

    private setAlertDataAnalytics() {
        this.tracyService.setAlertDataAnalytics(
            dataAnalytics.activityMap.region.oas,
            dataAnalytics.activityMap.link.confirmLogout,
            dataAnalytics.activityMap.link.cancelLogout
        );
    }
}
