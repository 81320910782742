<h2 class="assistance-title" i18n="@@selectStaffMemberAndDate">Select a staff member and a date</h2>
<div class="assistance-tabs service-advisor__tabs tab-margin">
    <tabset justified="true">
        <tab customClass="service-advisor__tab" (selectTab)="onSelectServiceAdvisor()">
            <ng-template tabHeading>
                <div class="service-advisor__tab--inner">
                    <div class="service-advisor__tab-image service-advisor__tab-image--empty">
                        <i class="fa fa-users"></i>
                    </div>
                </div>
            </ng-template>
        </tab>
        <tab
            *ngFor="let serviceAdvisor of serviceAdvisors; let i = index"
            customClass="service-advisor__tab"
            (selectTab)="onSelectServiceAdvisor(serviceAdvisor)"
        >
            <ng-template tabHeading>
                <div class="service-advisor__tab--inner" id="{{serviceAdvisor.username}}">
                    <div
                        *ngIf="!serviceAdvisor.profilePic || !serviceAdvisor.privacyAgreement; else picture"
                        class="service-advisor__tab-image service-advisor__tab-image--empty service-advisor__color-{{
                            i
                        }}"
                        (mouseenter)="hoverServiceAdvisor = serviceAdvisor"
                        [tooltip]="serviceAdvisor.privacyAgreement ? tolTemplate : null"
                        placement="bottom"
                    >
                        {{ serviceAdvisor.name[0] + serviceAdvisor.surname[0] | uppercase }}
                    </div>
                    <ng-template #picture>
                        <div
                            class="service-advisor__tab-image service-advisor__tab-image--empty service-advisor__color-{{
                                i
                            }}"
                            (mouseenter)="hoverServiceAdvisor = serviceAdvisor"
                            [tooltip]="serviceAdvisor.privacyAgreement ? tolTemplate : null"
                            placement="bottom"
                        >
                            <img
                                [src]="serviceAdvisor.profilePic"
                                alt=""
                                class="service-advisor__tab-image--border service-advisor__tab-image--full"
                            />
                        </div>
                    </ng-template>
                    <div *ngIf="this.lastAppointment && serviceAdvisor.id == this.lastAppointment.serviceAdvisor.id">
                        <span class="glyphicon glyphicon-star favorite"></span>
                    </div>
                </div>
            </ng-template>
        </tab>
    </tabset>
</div>
<div class="loader-container online-agenda assistance-tabs__tab-content">
    <button type="button" class="calendar-prev-button" id="btnPrevOutletAgenda" [disabled]="prevButtonDisabled" (click)="previousView()">
        <span class="text-primary date-picker fa fa-chevron-left fa-lg"></span>
    </button>
    <input
        id="datepicker"
        type="text"
        bsDatepicker
        [bsConfig]="bsConfig"
        (bsValueChange)="changeCalendarDate($event)"
    />
    <button type="button" class="calendar-next-button" id="btnNextOutletAgenda" (click)="nextView()">
        <span class="text-primary date-picker fa fa-chevron-right fa-lg"></span>
    </button>
    <oas-timeslot-loader [show]="loading" [showTimeslotNotFoundBanner]="showBannerEmptySlot"></oas-timeslot-loader>
    <full-calendar
        #calendar
        [options]="calendarOptions"
    ></full-calendar>
</div>

<div #serviceAdvisorTooltip class="multioutlet__tooltip__sadv-list">
    <div
        *ngFor="let sadv of selectedEventSadvList; let i = index"
        class="multioutlet__tooltip__sadv-list-item"
        (click)="emitEvent(selectedEvent, sadv)"
    >
        <ng-container *ngIf="sadv.profilePic && sadv.privacyAgreement; else noPicture">
            <img [src]="sadv.profilePic" class="service-advisor__tab-image service-advisor__tab-image--empty" />
        </ng-container>
        <ng-template #noPicture>
            <div class="service-advisor__tab-image service-advisor__tab-image--empty service-advisor__color-{{ i }}">
                {{ sadv.name[0] + sadv.surname[0] | uppercase }}
            </div>
        </ng-template>
        <div class="sadv-list-item__name">
            <span>{{ sadv.privacyAgreement ? sadv.name : (sadv.name[0] | uppercase) }}</span>
            <span>{{ sadv.privacyAgreement ? sadv.surname : (sadv.surname[0] | uppercase) }}</span>
        </div>
    </div>
</div>

<ng-template #tolTemplate>
    <ng-container *ngIf="hoverServiceAdvisor">
        <div style="text-align: left !important;">
            <span i18n="@@name">Name</span>: {{ hoverServiceAdvisor.name }}
            <br />
            <span i18n="@@surname">Surname</span>: {{ hoverServiceAdvisor.surname }}
            <br />
            <span i18n="@@phone">Phone</span>: {{ hoverServiceAdvisor.mobilePhone }}
            <br />
            <span i18n="@@email">Email</span>: {{ hoverServiceAdvisor.email }}
            <br />
        </div>
    </ng-container>
</ng-template>

<oas-alert-error [errorResponse]="errorResponse"></oas-alert-error>
