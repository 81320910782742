import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { countryConfiguration } from '../../configuration/country.configuration';
import { AbstractRestService } from '../core/api/generic.service';
import { Utils } from '../core/utils';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { environment } from './../../environments/environment';
import { Logger } from './../core/logger.service';
import { BaseResponse, Customer, JwtAuthenticationResponse, RoleType, User } from './../shared/entities';

/**
 * AuthService uses JSON-Web-Token authorization strategy.
 * Fetched token and user details are stored in sessionStorage.
 */
@Injectable()
export class AuthService extends AbstractRestService<User, number> {
    public static readonly SIGNUP_URL = `${environment.apiUrl}/auth/signup`;
    public static readonly SIGNIN_URL = `${environment.apiUrl}/auth/signin`;
    public static readonly GCDM_LOGIN_URL = `${environment.apiUrl}/auth/gcdm-login`;
    public static readonly GCDM_LOGIN_CODE_URL = `${environment.apiUrl}/auth/gcdm-login/code`;
    public static readonly REFRESH_TOKEN_URL = `${environment.apiUrl}/auth/token/refresh`;
    public static readonly ACTIVATE_USER_URL = `${environment.apiUrl}/auth/user/activate`;
    public static readonly CHANGE_ACTIVATION_URL = `${environment.apiUrl}/auth/user/change-activation`;
    public static readonly CUSTOMER_ROOT_PATH = '/online';
    public static readonly ASM_ROOT_PATH = '/backoffice/asm';
    public static readonly S_ADV_ROOT_PATH = '/backoffice/service-advisor';
    public static readonly CIC_P_ROOT_PATH = '/backoffice/cic';
    public static readonly CIC_NSC_ROOT_PATH = '/backoffice/cic-nsc';
    public static readonly CIC_EP_ROOT_PATH = '/backoffice/cic-ep';
    public static readonly S_ASS_ROOT_PATH = '/backoffice/service-assistant';
    public static readonly OUTLET_ADMIN_ROOT_PATH = '/backoffice/outlet-admin';
    public static readonly WORKSHOP_MANAGER_ROOT_PATH = '/backoffice/workshop-manager';
    public static readonly NSC_ROOT_PATH = '/backoffice/nsc';
    public static readonly WELCOME_TERMINAL_PROFILE_ROOT_PATH = '/backoffice/welcome-terminal-profile';
    public static readonly RENTING_ROOT_PATH = '/backoffice/renting-profile';
    public static readonly GROUP_MANAGER_ROOT_PATH = '/backoffice/group-manager';
    public static readonly IS_PASSWORD_CORRECT = `${environment.apiUrl}/auth/check-password`;
    public static readonly CHECK_UNIQUE_USERNAME = `${environment.apiUrl}/auth/user/exist`;

    private token: string;
    private tokenOauth: string;
    private username: string;
    private userId: string;
    private role: any;
    private fixedOutlet: string;
    private ticketId: string;

    constructor(protected http: HttpClient, protected logger: Logger, protected router: Router) {
        super('user/', http, logger);
        this.refreshUserData();
    }

    /**
     * Refreshes userId, username and token from sessionStorage
     */
    public refreshUserData(): void {
        const user = sessionStorage.getItem('user');
        const tokenOauth = sessionStorage.getItem('tokenOauth');
        if (user) {
            this.saveUserDetails(JSON.parse(user), tokenOauth);
        }
    }

    /**
     * Registers new user and saves following token
     * @param customer
     */
    public signUp(customer: Customer, uuid: string): Promise<BaseResponse<{}>> {
        return this.http
            .post(AuthService.SIGNUP_URL, customer, {
                headers: this.generateHeaders(),
                params: !Utils.isNullOrUndefined(uuid) ? new HttpParams().set('preRegistrationUuid', uuid) : null,
            })
            .toPromise()
            .then(response => response as BaseResponse<{}>)
            .catch(error => this.handleError(error));
    }

    /**
     * Fetches and saves token for given user
     * @param username
     * @param password
     */
    public signIn(username: string, password: string): Promise<any> {
        const requestParam = {
            username: username,
            password: password,
        };

        return this.http
            .post<JwtAuthenticationResponse>(AuthService.SIGNIN_URL, requestParam, { headers: this.generateHeaders() })
            .toPromise()
            .then(res => {
                this.saveToken(res);
                const user = JSON.parse(sessionStorage.getItem('user'));
                const tokenOauth = sessionStorage.getItem('tokenOauth');
                this.saveUserDetails(user, tokenOauth);
                return user;
            })
            .catch(error => this.handleError(error));
    }
    /**
     * generate token for user
     * @param accessToken
     */
    public gcdmLogin(accessToken: string, expiration: number): Promise<any> {
        const requestBody = {
            accessToken,
            expiration,
        };

        return this.http
            .post<JwtAuthenticationResponse>(AuthService.GCDM_LOGIN_URL, requestBody, {
                headers: this.generateHeaders(),
            })
            .toPromise()
            .then(res => {
                this.saveToken(res);
                const user = JSON.parse(sessionStorage.getItem('user'));
                const tokenOauth = sessionStorage.getItem('tokenOauth');
                this.saveUserDetails(user, tokenOauth);
                return user;
            })
            .catch(error => this.handleError(error));
    }

   /**
    * generate token for user with code
    * @param accessToken
    */
   public gcdmLoginWithCode(code: string, uri: string, expiration: number): Promise<any> {
      const requestBody = {
         code,
         uri,
         expiration
      };

      return this.http
         .post<JwtAuthenticationResponse>(AuthService.GCDM_LOGIN_CODE_URL, requestBody, {
            headers: this.generateHeaders(),
         })
         .toPromise()
         .then(res => {
            this.saveToken(res);
            const user = JSON.parse(sessionStorage.getItem('user'));
            const tokenOauth = sessionStorage.getItem('tokenOauth');
            this.saveUserDetails(user, tokenOauth);
            return user;
         })
         .catch(error => this.handleError(error));
   }

    /**
     * Removes token and user details from sessionStorage and service's variables
     */
    public logout(): void {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('tokenOauth');
        this.token = null;
        this.username = null;
        this.userId = null;
        this.role = undefined;
    }

    public redirectToLogin(err): void {
        this.logout();
        let redirectTo = window.location.href.includes('backoffice') ? '/backoffice/login' : '/welcome';
        this.router.navigate([redirectTo]).then(() => {
            const modal: SweetAlertOptions = {
                title: err.error.errors[0].title,
                text: err.error.errors[0].message,
                icon: 'warning'
            };
            swal.fire(modal);
        });
    }

    /**
     * Refreshes token for the user with given token
     * @param token - which should be refreshed
     */
    public refreshToken(token: string): Promise<{}> {
        const requestParam = { token: this.token };

        return this.http
            .post<JwtAuthenticationResponse>(AuthService.REFRESH_TOKEN_URL, requestParam, {
                headers: this.generateHeaders(),
            })
            .toPromise()
            .then(res => {
                this.saveToken(res);
            })
            .catch(error => this.handleError(error));
    }

    public activateUser(token: string): Promise<void> {
        const url = `${AuthService.ACTIVATE_USER_URL}/${token}`;

        return this.http
            .put(url, null)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public changeActivation(token: string): Promise<void> {
        const url = `${AuthService.CHANGE_ACTIVATION_URL}`;
        const params = new HttpParams().set('token', token);

        return this.http
            .put(url, null, { params: params })
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public checkUniqueUsername(username: string, userType: string): Observable<any> {
        const requestBody = {
            username,
            userType
         };
        return this.http.post(AuthService.CHECK_UNIQUE_USERNAME, requestBody);
    }

    public checkUniqueUsernameOnlineBackoffice(username: string): Observable<any> {
        return forkJoin(
            this.checkUniqueUsername(username, 'online'),
            this.checkUniqueUsername(username, 'backoffice')
        );
    }

    public resetPassword(loginId: string): Promise<void> {
        const url = `${environment.apiUrl}/auth/user/${loginId}/reset-pwd`;

        return this.http
            .put(url, null)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public setPassword(token: string, password: string): Promise<void> {
        const url = `${environment.apiUrl}/auth/user/set-secret/${token}`;

        return this.http
            .put(url, password)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    /**
     * Checks if user is authorized
     * @return true is user authorized (there is token in sessionStorage) else false
     */
    public isAuthorized(): boolean {
        return Boolean(this.token);
    }

    /**
     * @return username if exists
     */
    public getUsername(): string {
        return this.username;
    }

    /**
     * @return userId if exists
     */
    public getUserId(): string {
        return this.userId;
    }

    /**
     * @return token if exists
     */
    public getToken(): string {
        return this.token;
    }

    /**
     * @return tokenOauth if exists
     */
    public getTokenOauth(): string {
        return this.tokenOauth;
    }

    /**
     * @return true if the logged-in user is an administrator
     */
    public isAdmin(): boolean {
        return this.role === <RoleType>'ROLE_DB_ADM';
    }

    /**
     * @return true if the logged-in user is a customer
     */
    public isCustomer(): boolean {
        return this.role === <RoleType>'ROLE_CST';
    }

    /**
     * @return true if the logged-in user is a service advisor
     */
    public isServiceAdvisor(): boolean {
        return this.role === <RoleType>'ROLE_S_ADV';
    }

    /**
     * @return true if the logged-in user is a cic
     */
    public isCic(): boolean {
        return this.role === <RoleType>'ROLE_CIC_P';
    }

    /**
     * @return true if the logged-in user is a national cic admin
     */
    public isCicEpManager(): boolean {
        return this.role === <RoleType>'ROLE_CIC_EP_MANAGER';
    }

    /**
     * @return true if the logged-in user is a national cic user
     */
    public isCicEpUser(): boolean {
        return this.role === <RoleType>'ROLE_CIC_EP_USER';
    }

    /**
     * @return true if the logged-in user is a dealer cic admin
     */
    public isCicNscAdmin(): boolean {
        return this.role === <RoleType>'ROLE_CIC_NSC_ADMIN';
    }

    /**
     * @return true if the logged-in user is a dealer cic user
     */
    public isCicNscUser(): boolean {
        return this.role === <RoleType>'ROLE_CIC_NSC_USER';
    }

    /**
     * @return true if the logged-in user is a service assistant
     */
    public isServiceAssistant(): boolean {
        return this.role === <RoleType>'ROLE_S_ASSISTANT';
    }

    /**
     * @return true if the logged-in user is an ASM
     */
    public isAsm(): boolean {
        return this.role === <RoleType>'ROLE_ASM';
    }

    /**
     * @return true if the logged-in user is an Outlet Admin
     */
    public isOutletAdmin(): boolean {
        return this.role === <RoleType>'ROLE_OUTLET_ADM';
    }

    /**
     * @return true if the logged-in user is an Workshop Manager
     */
    public isWorkshopManager(): boolean {
        return this.role === <RoleType>'ROLE_WORKSHOP_MANAGER';
    }

    /**
     * @return true if the logged-in user is an NSC
     */
    public isNsc(): boolean {
        return this.role === <RoleType>'ROLE_NSC';
    }

    /**
     * @return true if the logged-in user is an Welcome Terminal
     */
    public isWelcomeTerminalProfile(): boolean {
        return this.role === <RoleType>'ROLE_WELCOME_TERMINAL';
    }

    /**
     * @return true if the logged-in user is an Role Renting User
     */
    public isRentingUserProfile(): boolean {
        return this.role === <RoleType>'ROLE_RENTING_USER';
    }

    /**
     * @return true if the logged-in user is an Role Renting Admin
     */
    public isRentingAdmProfile(): boolean {
        return this.role === <RoleType>'ROLE_RENTING_ADM';
    }

    /**
     * @return true if the logged-in user is a Group Manager
     */
    public isGroupManager(): boolean {
       return this.role === <RoleType>'ROLE_GROUP_MANAGER';
    }

    /**
     * @return dashboard path if user is logged-in
     */
    public getDashboardPath(): string {
        let dashboard = '';

        if (this.isAdmin()) {
            // dashboard = '/backoffice/dealer/dashboard';
            dashboard = `${AuthService.CUSTOMER_ROOT_PATH}/dashboard`;
        } else if (this.isCustomer()) {
            dashboard = `${AuthService.CUSTOMER_ROOT_PATH}/dashboard`;
        } else if (this.isAsm()) {
            dashboard = `${AuthService.ASM_ROOT_PATH}/dashboard`;
        } else if (this.isServiceAdvisor()) {
            dashboard = `${AuthService.S_ADV_ROOT_PATH}/dashboard`;
        } else if (this.isCic()) {
            dashboard = `${AuthService.CIC_P_ROOT_PATH}/dashboard`;
        } else if (this.isCicNscAdmin() || this.isCicNscUser()) {
            dashboard = `${AuthService.CIC_NSC_ROOT_PATH}/dashboard`;
        } else if (this.isCicEpManager() || this.isCicEpUser()) {
            dashboard = `${AuthService.CIC_EP_ROOT_PATH}/dashboard`;
        } else if (this.isServiceAssistant()) {
            dashboard = `${AuthService.S_ASS_ROOT_PATH}/dashboard`;
        } else if (this.isOutletAdmin()) {
            dashboard = `${AuthService.OUTLET_ADMIN_ROOT_PATH}/dashboard`;
        } else if (this.isNsc()) {
            dashboard = `${AuthService.NSC_ROOT_PATH}/dashboard`;
        } else if (this.isWorkshopManager()) {
            dashboard = `${AuthService.WORKSHOP_MANAGER_ROOT_PATH}/outlet`;
        } else if (this.isWelcomeTerminalProfile()) {
            dashboard = `${AuthService.WELCOME_TERMINAL_PROFILE_ROOT_PATH}`;
        } else if (this.isRentingAdmProfile() || this.isRentingUserProfile()) {
            dashboard = `${AuthService.RENTING_ROOT_PATH}`;
        } else if(this.isGroupManager()){
            dashboard = `${AuthService.GROUP_MANAGER_ROOT_PATH}`;
        }

        return dashboard;
    }

    /**
     * @return root path if user is logged-in
     */
    public getRootPath(): string {
        let rootPath = '';

        if (this.isAdmin()) {
            // dashboard = '/backoffice/dealer/dashboard';
            rootPath = AuthService.CUSTOMER_ROOT_PATH;
        } else if (this.isCustomer()) {
            rootPath = AuthService.CUSTOMER_ROOT_PATH;
        } else if (this.isAsm()) {
            rootPath = AuthService.ASM_ROOT_PATH;
        } else if (this.isServiceAdvisor()) {
            rootPath = AuthService.S_ADV_ROOT_PATH;
        } else if (this.isCic()) {
            rootPath = AuthService.CIC_P_ROOT_PATH;
        } else if (this.isCicNscAdmin() || this.isCicNscUser()) {
            rootPath = AuthService.CIC_NSC_ROOT_PATH;
        } else if (this.isCicEpManager() || this.isCicEpUser()) {
            rootPath = AuthService.CIC_EP_ROOT_PATH;
        } else if (this.isServiceAssistant()) {
            rootPath = AuthService.S_ASS_ROOT_PATH;
        } else if (this.isOutletAdmin()) {
            rootPath = AuthService.OUTLET_ADMIN_ROOT_PATH;
        } else if (this.isNsc()) {
            rootPath = AuthService.NSC_ROOT_PATH;
        } else if (this.isWorkshopManager()) {
            rootPath = AuthService.WORKSHOP_MANAGER_ROOT_PATH;
        } else if (this.isWelcomeTerminalProfile()) {
            rootPath = AuthService.WELCOME_TERMINAL_PROFILE_ROOT_PATH;
        } else if (this.isRentingAdmProfile() || this.isRentingUserProfile()) {
            rootPath = AuthService.RENTING_ROOT_PATH;
        } else if (this.isGroupManager()) {
            rootPath = AuthService.GROUP_MANAGER_ROOT_PATH;
        }

        return rootPath;
    }

    getLogged(): any {
        return JSON.parse(sessionStorage.getItem('user'));
    }

    // Saves user details with token into sessionStorage as user item
    private saveToken(res: JwtAuthenticationResponse): void {
        const response = res && res.token;
        if (response) {
            const token = response;
            const claims = this.getTokenClaims(token);
            claims.token = token;
            if (claims.role.length === 1) {
                claims.selectedRole = claims.role[0].authority;
            }
            sessionStorage.setItem('user', JSON.stringify(claims));
            sessionStorage.setItem('tokenOauth', res.tokenOauth);
        } else {
            this.handleError('Token is null');
        }
    }

    // Saves user details into service properties
    private saveUserDetails(user, tokenOauth): void {
        this.token = user.token || '';
        this.username = user.sub || '';
        this.userId = user.id || '';
        this.role = user.selectedRole;
        this.tokenOauth = tokenOauth;
    }

    // Retrieves user details from token
    private getTokenClaims(token: string): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    // Generates Headers
    private generateHeaders(): HttpHeaders {
                let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Access-Control-Allow-Origin', '*');
        headers = headers.set('Access-Control-Allow-Headers', 'Origin, Authorization, Content-Type');
        headers = headers.set('Locale', countryConfiguration.locale);
        return headers;
    }

    public isRoleSelected(): boolean {
        return !!this.role;
    }

    public selectRole(role: RoleType): void {
        this.role = role;
        const user = JSON.parse(sessionStorage.getItem('user'));
        user.selectedRole = role;
        sessionStorage.setItem('user', JSON.stringify(user));
    }

    public getSelectedRole(): RoleType {
        return this.role;
    }

    public setFixedOutlet(outletId: string) {
        this.fixedOutlet = outletId;
    }

    public getFixedOutlet(): string {
        return this.fixedOutlet;
    }

    public setTicketId(ticketId: string) {
        this.ticketId = ticketId;
    }

    public getTicketId(): string {
        return this.ticketId;
    }

    public checkIsPasswordCorrect(password: string): Observable<boolean> {
        const requestBody = {          
            password
        };
        return this.http.post<boolean>(AuthService.IS_PASSWORD_CORRECT, requestBody);
    }
}
