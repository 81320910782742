import { Injectable } from '@angular/core';

import { environment } from './../../environments/environment';

@Injectable()
export class Logger {
    constructor() {}

    log(msg: any) {
        console.log(msg);
    }

    error(msg: any) {
        console.error(msg);
    }

    debug(msg: any) {
        if (environment.debug) {
            console.log(msg);
        }
    }
}
