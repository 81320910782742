import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService } from '../generic.service';
import {
    GetCourtesyCarAvailableBean,
    Vehicle,
    TechnicalCampaign,
    CustomerSearchBean,
    VehicleSearch,
    BsiContract,
    CCQuotaCalendarDayItem,
    MoveCourtesyVehicle,
    BaseResponse,
    CourtesyVehicleLock,  
} from './../../../shared/entities';
import { Logger } from './../../logger.service';

@Injectable()
export class VehicleService extends AbstractRestService<Vehicle, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/vehicle', http, logger);
    }

    public updateCourtesyCar(vehicleId: string, vehicle: Vehicle): Promise<void> {
        const url = `${this.actionUrl}/courtesy-car/${vehicleId}`;
        return this.http
            .put(url, vehicle)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public deleteCourtesyVehicle(vehicleId: string): Promise<void> {
        const url = `${this.actionUrl}/courtesy-vehicle/${vehicleId}`;
        return this.http
            .delete(url)
            .toPromise()
            .then(() => null)
            .catch(error => this.handleError(error));
    }

    public createCourtesyCar(vehicle: Vehicle): Promise<Vehicle> {
        const url = `${this.actionUrl}/courtesy-car`;
        return this.http
            .post(url, vehicle)
            .toPromise()
            .then(response => response as Vehicle[])
            .catch(error => this.handleError(error));
    }

    public getCourtesyCarAvailableUntil(activityId: Number): Promise<CCQuotaCalendarDayItem> {
        let params = new HttpParams();
        params = params.set('activityId', `${activityId}`);

        const url = `${this.actionUrl}/courtesy-car/availability`;
        return this.http
            .get(url, { params })
            .toPromise()
            .then(response => {
                return response as CCQuotaCalendarDayItem;
            })
            .catch(error => this.handleError(error));
    }

    public getCourtesyCarAvailability(bean: GetCourtesyCarAvailableBean): Promise<Vehicle[]> {
        const url = `${this.actionUrl}/courtesy-car/availability`;
        return this.http
            .post(url, bean)
            .toPromise()
            .then(response => response as Vehicle[])
            .catch(error => this.handleError(error));
    }

    public moveCourtesyVehicle(moveCourtesyVehicle: MoveCourtesyVehicle): Promise<void> {
        const url = `${this.actionUrl}/courtesy-car/move`;
        return this.http
            .post(url, moveCourtesyVehicle)
            .toPromise()
            .catch(error => this.handleError(error));
    }

    public checkUniqueVin(vin: string, outletId: string): Observable<any> {
        const url = `${this.actionUrl}/exist/outlet/${outletId}`;
        const requestBody = {
            vin
        };
        return this.http.post(url, requestBody);
    }

    /**
     * UCP Integration - Vechicle Picture related to VIN
     * @param vin
     */
    public getVehiclePictureUrlFromUCP(vin: string, series: string, role: string, isMoto?: boolean): Promise<string> {
        const url = `${this.actionUrl}/vehicle-picture`;
        const requestBody = {
            vin,
            series,
            role,
            isMoto
        };

        return this.http
            .post(url, requestBody, { responseType: 'text' })
            .toPromise()
            .then(response => response as string);
    }

    public getVehicleRemoteKeyRead(vin: string, role: string): Promise<boolean> {
        const url = `${this.actionUrl}/remote-key-read`;
        const requestBody = {
            vin,
            role
        };

        return this.http
        .post(url, requestBody)
        .toPromise()
        .then(response => response as boolean);
    }

    public getVehicleInformationFromVin(vin: string): Promise<BaseResponse<Vehicle>> {
        const url = `${this.actionUrl}/vehicle-information-from-vin`;
        const requestBody = {
            vin
        };

        return this.http
            .post(url, requestBody)
            .toPromise()
            .then(response => response as BaseResponse<Vehicle>)
            .catch(error => this.handleError(error));
    }

    public getVehicleInformationDComFromVin(outletId: string, vin: string): Promise<Vehicle> {
        const url = `${this.actionUrl}/vehicle-information-dcom/${outletId}/from-vin`;
        const requestBody = {
            vin
        };

        return this.http
            .post(url, requestBody)
            .toPromise()
            .then(response => response as Vehicle[])
            .catch(error => this.handleError(error));
    }

    public getVehicleBsiInformation(vin: string): Promise<BaseResponse<BsiContract[]>> {
        const url = `${this.actionUrl}/vehicle-bsi-information`;
        const requestBody = {
            vin
        };

        return this.http
            .post(url, requestBody)
            .toPromise()
            .then(response => response as BaseResponse<BsiContract[]>)
            .catch(error => this.handleError(error));
    }

    public getVehicleInformationFromPlate(plate: string): Promise<BaseResponse<Vehicle>> {
        const url = `${this.actionUrl}/vehicle-information-from-plate`;
        const requestBody = {
            plate
        };

        return this.http
            .post(url, requestBody)
            .toPromise()
            .then(response => response as Promise<BaseResponse<Vehicle>>)
            .catch(error => this.handleError(error));
    }

    public getVehicleInformationFromPlateWithoutRegister(plate: string): Promise<BaseResponse<string>> {
        const url = `${this.actionUrl}/vehicle-information-from-plate-without-register`;
        const requestBody = {
            plate
        };

        return this.http
            .post(url, requestBody, { responseType: 'text' })
            .toPromise()
            .then(response => response as string)
            .catch(error => this.handleError(error));
    }

    public getVehicleInformationFromPlateAndFiscalCode(
        plate: string,
        fiscalCode: string
    ): Promise<BaseResponse<Vehicle>> {
        const url = `${this.actionUrl}/vehicle-information-from-plate-fiscal-code`;
        const requestBody = {
            plate,
            fiscalCode
        };

        return this.http
        .post(url, requestBody)
        .toPromise()
        .then(response => response as Vehicle)
        .catch(error => this.handleError(error));
    }

    public hasTechnicalCampaigns(vin: string): Promise<BaseResponse<boolean>> {
        const url = `${this.actionUrl}/has-technical-campaigns`;
        const requestBody = {
            vin
        }

        return this.http
            .post(url, requestBody)
            .toPromise()
            .then(response => response as BaseResponse<boolean>)
            .catch(error => this.handleError(error));
    }

    public getTechnicalCampaigns(vin: string, fetchDetails = true): Promise<BaseResponse<TechnicalCampaign[]>> {
        const url = `${this.actionUrl}/technical-campaigns`;
        const requestBody = {
            vin,
            fetchDetails
        }

        return this.http
            .post(url, requestBody)
            .toPromise()
            .then(response => response as BaseResponse<TechnicalCampaign[]>)
            .catch(error => this.handleError(error));
    }

    public searchVehicle(customerSearchBean: CustomerSearchBean): Promise<VehicleSearch> {
        return (
            this.http
                .post(`${this.actionUrl}/search`, customerSearchBean)
                .toPromise()
                .then(response => response as VehicleSearch)
                // .then(response => response as CustomerSearch)
                .catch(error => this.handleError(error))
        );
    }

    public lockCourtesyCar(ccLock: CourtesyVehicleLock): Observable<any> {
        return (
            this.http
                .post(`${this.actionUrl}/courtesy-car/lock`, ccLock)
        );
    }
}
