import { Component, OnInit, Input } from '@angular/core';
import { StyleSwitcherService } from './../../core/style-switcher.service';

@Component({
    selector: 'oas-logo',
    templateUrl: './logo.component.html',
})
export class LogoComponent implements OnInit {
    @Input() public loginLogo: boolean;
    @Input() public backofficeLogo: boolean;

    public path: string;

    constructor(private styleSwitcherService: StyleSwitcherService) {}

    public ngOnInit() {
        this.path = this.getLogoPath();
        this.styleSwitcherService.brand.subscribe(() => (this.path = this.getLogoPath()));
    }

    private getLogoPath(): string {
        let logoPath;

        if (
            this.styleSwitcherService.isBrandSet() &&
            this.styleSwitcherService.brand.getValue().toLowerCase() === 'mini'
        ) {
            logoPath = 'assets/images/logos/MINI_Service_logo.png';
        } else if (
            this.styleSwitcherService.isBrandSet() &&
            this.styleSwitcherService.brand.getValue().toLowerCase() === 'bmw'
        ) {
            logoPath = 'assets/images/logos/BMW_Service_logo.png';
        } else if (
            this.styleSwitcherService.isBrandSet() &&
            this.styleSwitcherService.brand.getValue().toLowerCase() === 'bmw_motorrad'
        ) {
            if (this.loginLogo) {
                logoPath = 'assets/images/logos/Logo_BMW_Motorrad_Service_Login.png';
            } else {
                logoPath = 'assets/images/logos/Logo_BMW_Motorrad_Service.png';
            }
        }
        return logoPath;
    }
}
