<swal text="this won't be displayed" icon="success" [timer]="timer">
    <div *swalPortal="swalTargets.title">
        <ng-container *ngIf="titleTmpl; else simpleTitleTmpl">
            <ng-container *ngTemplateOutlet="titleTmpl"></ng-container>
        </ng-container>
    </div>
    <div *swalPortal>
        <ng-container *ngIf="contentTmpl; else simpleTextTmpl">
            <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
        </ng-container>
    </div>
</swal>

<ng-template #simpleTitleTmpl>
    <span *ngIf="title">{{ title }}</span>
    <span *ngIf="!title" i18n="@@operationCompletedSuccessfully">Operation completed successfully</span>
</ng-template>

<ng-template #simpleTextTmpl>
    <span>{{ text }}</span>
</ng-template>
