import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { User } from '../../../shared/entities';

@Component({
    template: ''
})
export abstract class UserFormComponent {
    @Input() public user: User;
    public userForm: UntypedFormGroup;
    public minLength = 8;
    public maxLength = 40;

    constructor(protected formBuilder: UntypedFormBuilder, protected authService: AuthService) {}

    protected matchPassword(control: AbstractControl): ValidationErrors | null {
        let result: ValidationErrors = null;

        if (control.get('password').value != control.get('confirmPassword').value) {
            control.get('confirmPassword').setErrors({ matchPassword: true });
            result = { matchPassword: true };
        }

        return result;
    }

    protected getUsernameFromFormGroup(userForm: UntypedFormGroup): string {
        if (userForm) {
            return userForm.get('username').value as string;
        }
        return undefined;
    }
}
