export enum ResourceType {
    COUNTRY = <any>'COUNTRY',
    DEALER = <any>'DEALER',
    OUTLET = <any>'OUTLET',
    OUTLET_GROUP = <any>'OUTLET_GROUP',
    SERVICE_ADVISOR = <any>'SERVICE_ADVISOR',
    WORKER = <any>'WORKER',
    COURTESY_VEHICLE = <any>'COURTESY_VEHICLE',
    FAST_LANE = <any>'FAST_LANE',
}

export enum ResourceGroupType {
    SERVICE_ADVISOR = <any>'SERVICE_ADVISOR',
    COURTESY_VEHICLE = <any>'COURTESY_VEHICLE',
    FAST_LANE = <any>'FAST_LANE',
    WORK_TEAM = <any>'WORK_TEAM',
}
