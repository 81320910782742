import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppointmentLink, AppointmentLinkType } from '../../../shared/entities';
import { Logger } from '../../logger.service';
import { AbstractRestService } from '../generic.service';

@Injectable()
export class AppointmentLinkService extends AbstractRestService<AppointmentLink, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/appointment-links', http, logger);
    }

    public async getOneByTypeAndUuidAndAppointmentId(
        appointmentLinkType: AppointmentLinkType,
        uuid: string,
        appointmentId: string
    ): Promise<AppointmentLink> {
        const params = new HttpParams()
            .set('appointmentLinkType', appointmentLinkType)
            .set('uuid', uuid)
            .set('appointmentId', appointmentId);

        try {
            const response = await this.http.get(`${this.actionUrl}`, { params: params }).toPromise();
            return response as AppointmentLink;
        } catch (error) {
            return await this.handleError(error);
        }
    }

    public async deleteAppointment(
        uuid: string,
        appointmentId: string,
        reason: string,
        appointmentLinkType: AppointmentLinkType = 'DELETE'
    ): Promise<AppointmentLink> {
        const params = new HttpParams().set('reason', reason).set('appointmentLinkType', appointmentLinkType);
        try {
            const response = await this.http
                .delete(`${this.actionUrl}/${uuid}/${appointmentId}`, { params: params })
                .toPromise();
            return response as AppointmentLink;
        } catch (error) {
            return await this.handleError(error);
        }
    }
}
