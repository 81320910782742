import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Logger } from './../../logger.service';
import { VehicleSeries } from './../../../shared/entities';
import { AbstractRestService } from '../generic.service';

@Injectable()
export class VehicleSeriesService extends AbstractRestService<VehicleSeries, string> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/vehicle-series', http, logger);
    }
}
