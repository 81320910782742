import { Injectable } from '@angular/core';

import { AuthGuard } from './auth-guard.service';

@Injectable()
export class AuthGuardCicEp extends AuthGuard {
    protected checkRole(): boolean {
        return this.authService.isCicEpManager() || this.authService.isCicEpUser();
    }
}
