import { Component, Input, TemplateRef } from '@angular/core';
import { Alert } from './alert';

@Component({
    selector: 'oas-alert-warning',
    templateUrl: './alert-warning.component.html',
})
export class AlertWarningComponent extends Alert {
    private static TITLE_DEF_VAL = 'Warning';
    /**
     * Alert title
     */
    @Input() public title = AlertWarningComponent.TITLE_DEF_VAL;

    /**
     * Alert content as plain text
     */
    @Input() public text: string;

    /**
     * Alert title template to use with SwalPartialDirective
     */
    @Input() public titleTmpl: TemplateRef<any>;

    /**
     * Alert content template to use with SwalPartialDirective
     */
    @Input() public contentTmpl: TemplateRef<any>;

    protected reset(): void {
        this.title = AlertWarningComponent.TITLE_DEF_VAL;
        this.text = null;
        this.titleTmpl = null;
        this.contentTmpl = null;
    }
}
