import { Component, Input, TemplateRef } from '@angular/core';
import { Alert } from './alert';

@Component({
    selector: 'oas-alert-success',
    templateUrl: './alert-success.component.html',
})
export class AlertSuccessComponent extends Alert {
    private static TIMER_DEF_VAL = 3000;
    /**
     * Alert title
     */
    @Input() public title: string;

    /**
     * Alert content as plain text
     */
    @Input() public text: string;

    /**
     * Alert timer to hide
     */
    @Input() public timer = AlertSuccessComponent.TIMER_DEF_VAL;

    /**
     * Alert title template to use with SwalPartialDirective
     */
    @Input() public titleTmpl: TemplateRef<any>;

    /**
     * Alert content template to use with SwalPartialDirective
     */
    @Input() public contentTmpl: TemplateRef<any>;

    protected reset(): void {
        this.title = null;
        this.text = null;
        this.timer = AlertSuccessComponent.TIMER_DEF_VAL;
        this.titleTmpl = null;
        this.contentTmpl = null;
    }
}
