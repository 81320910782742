import { HttpErrorResponse } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AppointmentService } from '../core/api/appointment/appointment.service';
import { OutletService } from '../core/api/outlet/outlet.service';
import { AlertErrorComponent } from '../shared/alert/alert-error.component';
import { AppComponent } from './../app.component';
import { CustomerService } from './../core/api/customer/customer.service';
import {
    ActivityPackage,
    Appointment,
    AppointmentStatus,
    Customer,
    Outlet,
    TssbTicket,
    Vehicle,
    VehicleBrand,
    BaseError,
    InfoBox,
    InfoBoxBsi,
    GeoMarker,
    TssbLeadTicket,
} from './../shared/entities';
import { AlertConfirmComponent } from '../shared/alert/alert-confirm.component';
import { Utils } from '../core/utils';
// import { MultimediaResourceModel } from 'multimedia/src/app/multimedia/models/multimedia-resource-model';
// import { OptionMultimedia } from 'multimedia/src/app/multimedia/models/option-multimedia';
import { VehicleService } from '../core/api/vehicle/vehicle.service';
import { CountryConfiguration } from './../country-configuration';
import { countryConfiguration } from './../../configuration/country.configuration';
import { AuthService } from '../auth/auth.service';
import { InfoBoxService } from '../core/api/info-box.service';
import { InfoBoxBsiService } from '../core/api/info-box-bsi.service';
import { TracyService } from '../shared/tracy.service';
import { dataAnalytics } from '../shared/data-analytics';
import { CountryConfigurationService } from '../../configuration/country-configuration.service';
import { TechnicalSettingConfiguration } from '../shared/technical-setting-configuration';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConsentService } from '../core/api/consent/consent.service';
import { LeadTssbService } from '../core/api/tssb/lead-tssb.service';
import swal, {SweetAlertOptions} from "sweetalert2";
import {TranslatePipe} from "../shared/pipe/translate.pipe";

@Component({
    selector: 'oas-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(
        public appComponent: AppComponent,
        private router: Router,
        private route: ActivatedRoute,
        private customerService: CustomerService,
        private appointmentService: AppointmentService,
        private outletService: OutletService,
        private authService: AuthService,
        private vehicleService: VehicleService,
        private infoBoxService: InfoBoxService,
        private infoBoxBsiService: InfoBoxBsiService,
        private tracyService: TracyService,
        private countryConfigurationService: CountryConfigurationService,
        private technicalSettingConfiguration: TechnicalSettingConfiguration,
        private modalService: BsModalService,
        private consentService: ConsentService,
        private leadTssbService: LeadTssbService,
        private translatePipe: TranslatePipe
    ) {
        this.countryConfiguration = this.countryConfigurationService.getCountryConfiguration();
        this.hostname = window.location.hostname;
        this.env = this.technicalSettingConfiguration.getTechnicalSetting('MULTIMEDIA_ENVIRONMENT');
        this.leadBatteryEnabled = this.countryConfiguration.enableLeadBattery;
    }

    @ViewChild('alertSuccess') private alertSuccess: AlertErrorComponent;
    @ViewChild('alertError') private alertError: AlertErrorComponent;
    @ViewChild('alertConfirm') private alertConfirm: AlertConfirmComponent;
    @ViewChild('warningUpdateProfile') private warningUpdateProfile: AlertErrorComponent;
    @ViewChild('cbsAppointmentTemplate') private cbsAppointmentTemplate: TemplateRef<any>;
    @ViewChild('missingVehicleInformationHeader') private missingVehicleInformationHeader: TemplateRef<any>;
    @ViewChild('missingVehicleInformationContent') private missingVehicleInformationContent: TemplateRef<any>;
    @ViewChild('termsAndConditionsTemplate') private termsAndConditionsTemplate: TemplateRef<any>;
    @ViewChild('yourVehicleTemplate') private yourVehicleTemplate: TemplateRef<any>;
    @ViewChild('dealerSelectionTemplate') private dealerSelectionTemplate: TemplateRef<any>;
    @ViewChild('assistanceHistory') private assistanceHistory: TemplateRef<any>;

    
    public requiredServicesLoading = false;
    public leadBatteryEnabled: boolean = false;
    private hostname: string;
    public countryConfiguration: CountryConfiguration;
    public errorResponse: HttpErrorResponse;
    public customer: Customer;
    public customerVehicleBrands: Set<VehicleBrand>;
    public selectedVehicle: Vehicle;
    public scheduledAppointment: Appointment;
    public selectedFavouriteOutlet: Outlet;
    public swiperIndex = 0;
    public errorsFromResponse: BaseError[];
    public currentSeasonName: string;
    public tssbTicket: TssbTicket;
    public leadTssbTickets: TssbTicket[] = [];
    public wrongCustomerInfo: Boolean = false;
    public preselectedCBSList: String[];
    public preselectedTireList: String[];
    public tssbInfoUrl: string;
    public infoBox: InfoBox = {};
    public infoBoxBsi: InfoBoxBsi = {};
    public vehicleBrand: VehicleBrand;

    public tcModalClosed = true;

    private profileBrand: string;

    public firstTime = true;
    public fromSPOA = false;
    public tssbInfoUrlEnable = true;

    public local: string;

    protected enableRemoteKeyRead: boolean;

    ssoUrl =
        window.location.hostname.includes('-int') || window.location.hostname.includes('-dev')
            ? 'https://customer-i.bmwgroup.com/oneid/#/'
            : 'https://customer.bmwgroup.com/oneid/#/';
    client = 'oas';
    currentBrand = 'BMW';

    private modal: SweetAlertOptions = {
        icon: 'warning',
        title: "",
    };

    protected termsAndConditionsModalRef: BsModalRef;
    protected modalConfig = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
        class: '',
    };

    private readonly today = new Date();
    private readonly season = {
        springStartDate: new Date(this.today.getFullYear(), 2, 21),
        summerStartDate: new Date(this.today.getFullYear(), 5, 21),
        autumnStartDate: new Date(this.today.getFullYear(), 8, 21),
        winterStartDate: new Date(this.today.getFullYear(), 11, 21),
        getSeasonName(date: Date) {
            // TODO: check the dates for the southern hemisphere and generally if they are the same for all countries
            if (this.springStartDate.getTime() <= date.getTime() && date.getTime() < this.summerStartDate.getTime()) {
                return 'spring';
            } else if (
                this.summerStartDate.getTime() <= date.getTime() &&
                date.getTime() < this.autumnStartDate.getTime()
            ) {
                return 'summer';
            } else if (
                this.autumnStartDate.getTime() <= date.getTime() &&
                date.getTime() < this.winterStartDate.getTime()
            ) {
                return 'autumn';
            } else {
                return 'winter';
            }
        },
    };

    // public multimediaResource: MultimediaResourceModel;
    public resourceToDelete: number;
    // public optionMultimedia = new OptionMultimedia(true, true, true, false, false, false, false, false, false);
    public multimediaAllowed: Map<string, boolean> = new Map();
    public env: string;
    public filterPredicate = act =>
        act.aprilActivityId != null ||
        (act.catalogType !== 'TECHNICAL_CAMPAIGNS' &&
        (act.activityType.showOnline ||
            act.activityType.id == '000-00-FLATRATE' ||
            act.activityType.id == '000-00-FIXEDPRICES' ||
            act.activityType.id == '000-00-PACKAGE'));


    public ngOnInit(): void {
        this.buildLeadAlertNotificationModal();
        this.checkConsent();
        this.getCurrentBrand();
        this.customer = this.route.snapshot.data.customer;
        this.customerVehicleBrands = new Set(this.customer.vehicleList.map(vehicle => vehicle.model.brand));
        const firstTime = this.route.snapshot.queryParamMap.get('firstTime');
        if (firstTime != null) {
            this.firstTime = firstTime == 'true';
        }

        this.currentSeasonName = this.season.getSeasonName(this.today);
        this.initLastMaintenance();

        const selectedVehicleIndex = sessionStorage.getItem('selectedVehicleIndex');
        // Capture selectedVehicleIndex if available
        if (selectedVehicleIndex) {
            this.swiperIndex = +selectedVehicleIndex;
        }

        const preselectedOutletInfo = sessionStorage.getItem('preselectedOutletInfo');
        // Capture preselectedOutletId if available
        if (preselectedOutletInfo) {
            this.outletService.getByOutletId("OUTL_" + preselectedOutletInfo).then(outlet => {
               if(outlet != null){
                  sessionStorage.setItem('preselectedOutlet', JSON.stringify(outlet));
               } else {
                  const modal: SweetAlertOptions = {
                     title: this.translatePipe.transform('OUTLET_NOT_FOUND_TITLE'),
                     text:  this.translatePipe.transform('OUTLET_NOT_FOUND_TEXT'),
                     icon: 'error',
                  };
                  swal.fire(modal);
               }
            });
        }
        this.checkCustomerInfo();
        this.local = this.countryConfiguration.locale;
    }

    private buildLeadAlertNotificationModal(): void {
        let span = document.createElement('span');
        let anchor = document.createElement('a');
        span.textContent = this.translatePipe.transform('availableNotificationOC');
        anchor.textContent = this.translatePipe.transform('availableNotificationOCMoreInfo');
        anchor.href = 'javascript:void(0)';
        anchor.onclick = () => this.openNotificationsPopup();
        span.appendChild(anchor);
        this.modal.html = span;
    }

    private checkConsent(): void {
        this.consentService.isLastTermsAndConditionsAccepted().then(isLastTermsAndConditionsAccepted => {
            if (!isLastTermsAndConditionsAccepted) {
                this.showTermsAndConditions();
            }
        });
    }

    private showTermsAndConditions(): void {
        this.tcModalClosed = false;
        this.termsAndConditionsModalRef = this.modalService.show(this.termsAndConditionsTemplate, {
            ...this.modalConfig,
            class: 'terms-and-conditions-modal modal-lg',
        });
    }

    private hideTermsAndConditions(): void {
        if (this.termsAndConditionsModalRef) {
            this.termsAndConditionsModalRef.hide();
        }
        this.tcModalClosed = true;
    }

    public onAgree(): void {
        this.consentService.createConsent().then(() => {
            this.hideTermsAndConditions();
        });
    }

    public closeCbsAppointment(): void {
        this.preselectedCBSList = undefined;
        this.preselectedTireList = undefined;
        this.appComponent.closeModal('cbs-appointment');
    }

    public updateAppointmentsVehicle(): void {
        if (this.selectedVehicle) {
            this.scheduledAppointment = this.firstOrNull(this.getVehicleAppointments('IN_VALIDATION'));
            if (this.scheduledAppointment == null) {
                this.scheduledAppointment = this.firstOrNull(this.getVehicleAppointments('PENDING'));
                if (this.scheduledAppointment == null) {
                    this.scheduledAppointment = this.firstOrNull(this.getVehicleAppointments('IN_PROCESS'));
                    if (this.scheduledAppointment == null) {
                        this.scheduledAppointment = this.firstOrNull(this.getVehicleAppointments('COMPLETED'));
                    }
                }
            }
        }
        if (this.scheduledAppointment != null) {
            this.setDataAnalyticsForServicesInDashboard();
            this.outletService.isOutletMMAllowed(this.scheduledAppointment.outlet.id).then(outletMMAllowed => {
                this.multimediaAllowed.set(this.scheduledAppointment.outlet.id, outletMMAllowed);
                if (outletMMAllowed) {
                    this.appointmentService
                        .getResourceFromAppointment(this.scheduledAppointment.id)
                        .then(multimediaResourceList => {
                            if (multimediaResourceList != null) {
                                this.scheduledAppointment.multimediaResourceList = multimediaResourceList;
                            }
                        });
                }
            });
        }
    }

    public ngAfterViewInit(): void {
        this.setDataAnalyticsForDashboard();
        if (this.wrongCustomerInfo && this.firstTime) {
            this.warningUpdateProfile.show().then(ok => {
                this.router.navigate(['/account-edit']);
            });
        }
    }

    public ngOnDestroy(): void {

        sessionStorage.setItem('selectedVehicleIndex', this.swiperIndex.toString());
        if (this.tcModalClosed) {
            this.hideTermsAndConditions();
        }
    }

    public async onClickAssistance() {
        if(this.tcModalClosed) {
            if (this.scheduledAppointment || this.wrongCustomerInfo) {
                return;
            }
            if (
                this.leadTssbTickets && this.leadTssbTickets.length > 0 &&
                this.leadTssbTickets[0].vin === this.selectedVehicle.vin
            ) {
                this.onClickLeadTssbTicket(this.leadTssbTickets);
            } else {
                const navigationExtras: NavigationExtras = { relativeTo: this.route };
                let assistanceNavigation;
    
                navigationExtras.queryParams = { RKR: false };
    
                if (this.selectedVehicle.vin) {
                    const navigationPath: any[] = [];
                    navigationPath.push('../appointment/vehicle');
                    navigationPath.push(this.selectedVehicle.id);
                    if (this.enableRemoteKeyRead) {
                        const remoteKeyRead = await this.retrieveVehicleRemoteKeyRead(this.selectedVehicle.vin);
                        if (remoteKeyRead != null) {
                            navigationExtras.queryParams.RKR = remoteKeyRead;
                        } else {
                            navigationExtras.queryParams.RKR = false;
                        }
                    }
    
                    if (this.fromSPOA) {
                        navigationExtras.queryParams = { spoa: true };
                    }
                    this.setDataAnalyticsForRequestedAppointment();
                    assistanceNavigation = this.router.navigate(navigationPath, navigationExtras);
                } else {
                    this.alertConfirm.titleTmpl = this.missingVehicleInformationHeader;
                    this.alertConfirm.contentTmpl = this.missingVehicleInformationContent;
                    this.alertConfirm.show().then(params => {
                        if (params.value) {
                            assistanceNavigation = this.router.navigate(
                                [this.customer.id + '/vehicle', { vehicleId: this.selectedVehicle.id }],
                                navigationExtras
                            );
                        }
                    });
                }
                assistanceNavigation.catch((error: HttpErrorResponse) => this.alertError.showHttpError(error));
            }
            return null;
        }
    }

    private retrieveVehicleRemoteKeyRead(vin: string) {
        return this.vehicleService.getVehicleRemoteKeyRead(vin, this.authService.getSelectedRole()).then(response => {
            return response;
        });
    }

    public thereAreRequiredServices(): boolean {
        return Utils.isNotNullOrUndefined(this.leadTssbTickets) && this.leadTssbTickets.length > 0;
    }

    public onClickTssbTicket(tssbTicket: TssbTicket, preselectedCBSList: String[] = []): void {
        if (this.scheduledAppointment || this.wrongCustomerInfo) {
            return;
        }

        this.appComponent.loading = true;
        const navigationExtras: NavigationExtras = {
            relativeTo: this.route,
            queryParams: {
                outletId: tssbTicket.outlet.id,
                ticketTssbId: tssbTicket.id,
            },
        };

        if (!Utils.isNullOrUndefined(preselectedCBSList)) {
            navigationExtras.queryParams.preselectedCBS = preselectedCBSList;
        }

        if (this.fromSPOA) {
            navigationExtras.queryParams.spoa = true;
        }
        const appointmentPath: any[] = [];
        appointmentPath.push('../appointment/vehicle');
        appointmentPath.push(this.selectedVehicle.id);

        this.router.navigate(appointmentPath, navigationExtras).then(result => {
            if (!result) {
                this.errorResponse = null;
                this.alertError.show();
            }
        });
    }

    public onClickLeadTssbTicket(tssbTickets: TssbLeadTicket[], preselectedCBSList: String[] = []): void {
        if (this.scheduledAppointment || this.wrongCustomerInfo) {
            return;
        }

        this.appComponent.loading = true;
        const navigationExtras: NavigationExtras = {
            relativeTo: this.route,
            queryParams: {
                outletId: tssbTickets[0].outlet.id,
                ticketLeadTssbId: tssbTickets.map(ticket => ticket.id).join(","),
            },
        };

        if (!Utils.isNullOrUndefined(preselectedCBSList)) {
            navigationExtras.queryParams.preselectedCBS = preselectedCBSList;
        }

        if (this.fromSPOA) {
            navigationExtras.queryParams.spoa = true;
        }
        const appointmentPath: any[] = [];
        appointmentPath.push('../appointment/vehicle');
        appointmentPath.push(this.selectedVehicle.id);

        this.router.navigate(appointmentPath, navigationExtras).then(result => {
            if (!result) {
                this.errorResponse = null;
                this.alertError.show();
            }
        });
    }

    public openNotificationsPopup(): void {
        if(this.tcModalClosed)  {
            swal.close();
            this.appComponent.openModal(
                'cbs-appointment',
                this.cbsAppointmentTemplate,
                'generic__modal'
            );
        }
    }

    public openVehiculePopup () {
        if (this.tcModalClosed) {
            this.appComponent.openModal(
                'yourVehicle',
                this.yourVehicleTemplate,
                'your-vehicles-modal modal-lg'
            )
        }
    }

    public openDealerSelectionPopup () {
        if (this.tcModalClosed) {
            this.appComponent.openModal(
                'dealerSelection',
                this.dealerSelectionTemplate,
                'your-vehicles-modal modal-xlg'
            )
        }
    }

    public openAssistanceHistoryPopup () {
        if(this.tcModalClosed) {
            this.setDataAnalyticsForHistoricalAppointments();
            this.appComponent.openModal(
                'assistance',
                this.assistanceHistory,
                'your-vehicles-modal modal-lg'
            )
        }
    }

    public navigateToVehiclePage() {
        if(this.tcModalClosed) {
            this.router.navigate([`/online/dashboard/${this.customer.id}/vehicle`]);
        }
    }

    public navigateToEditAccountPage() {
        if(this.tcModalClosed) {
            this.router.navigate(["/online/account-edit"]);
        }
    }

    public async onClickEditAssistance(appointment: Appointment) {
        if(this.tcModalClosed) {
            let remoteKeyRead: boolean;
            const navigationExtras: NavigationExtras = { relativeTo: this.route };
            if (this.enableRemoteKeyRead) {
                navigationExtras.queryParams = { RKR: false };
                remoteKeyRead = await this.retrieveVehicleRemoteKeyRead(appointment.vehicleVin);
                if (remoteKeyRead != null) {
                    navigationExtras.queryParams.RKR = remoteKeyRead;
                } else {
                    navigationExtras.queryParams.RKR = false;
                }
            }
            this.router
                .navigate(['../appointment', appointment.id], navigationExtras)
                .catch((error: HttpErrorResponse) => this.alertError.showHttpError(error));
        }
    }

    public changeSwiperIndex(selectedVehicleIndex: number): void {
        this.swiperIndex = selectedVehicleIndex;
        this.appComponent.closeModal('yourVehicle');
    }

    public onSelectVehicle(vehicle: Vehicle): void {
        if (vehicle && this.tcModalClosed ) {
            if (!this.selectedVehicle || this.selectedVehicle.id !== vehicle.id) {
                this.setDataAnalyticsForProductInDashboard(vehicle);
                this.selectedVehicle = vehicle;
                this.updateAppointmentsVehicle();
                this.changeStyle(vehicle.model.brand);

                this.requiredServicesLoading = false;
                this.tssbTicket = undefined;
                this.leadTssbTickets = undefined;
                if (Utils.isNullOrUndefined(this.scheduledAppointment) && countryConfiguration.enableLeadApi) {
                    if(Utils.isNotNullOrUndefined(vehicle) && Utils.isNotNullOrUndefined(vehicle.vin)){
                        this.requiredServicesLoading = true;
                        this.leadTssbService
                            .getLeadEventsByVin(vehicle.vin)
                            .then(tssbTicket => {
                                if (!Utils.isNullOrUndefined(tssbTicket) && tssbTicket.length > 0) {
                                    this.leadTssbTickets = tssbTicket.map(ticket => this.leadTssbService.mapperLeadToTssb(ticket));
                                    this.setDataAnalyticsTeleservicePopUp();
                                    swal.fire(this.modal);
                                }
                                this.requiredServicesLoading = false;
                            })
                            .catch(() => {
                                this.requiredServicesLoading = false;
                            });
                    }
                }

                this.vehicleService.getVehicleBsiInformation(vehicle.vin).then(response => {
                    const errors = response.errors;
                    if (errors.length !== 0) {
                        this.errorsFromResponse = errors;
                    } else {
                        vehicle.bsiContracts = response.payload;
                    }
                });
            }
        }
    }

    private changeStyle(brand: VehicleBrand): void {
        this.vehicleBrand = brand;
        this.setTssbInfoUrl(brand);
        this.getInfoBoxValue(brand);
        this.getInfoBoxBsiValue(brand);
        this.appComponent.switchBrand(brand);
    }

    private getInfoBoxValue(vehicleBrand: VehicleBrand) {
        this.infoBoxService.getInfoBoxValue(vehicleBrand).then(response => {
            this.infoBox = response;
        });
    }

    private getInfoBoxBsiValue(vehicleBrand: VehicleBrand) {
        this.infoBoxBsiService.getInfoBoxBsiValue(vehicleBrand).then(response => {
            this.infoBoxBsi = response;
        });
    }

    public setTssbInfoUrl(brand: VehicleBrand) {
        if (brand === 'BMW_I') {
            brand = 'BMW';
        }
        this.tssbInfoUrl = this.countryConfiguration[brand].tssbInfoUrl;
        if (Utils.isNullOrUndefined(this.tssbInfoUrl)) {
            this.tssbInfoUrlEnable = false;
        }
        this.enableRemoteKeyRead = this.countryConfiguration[brand].remoteKeyRead;
    }

    public saveFavouriteOutlet(): void {
        this.appComponent.loading = true;
        this.customerService
            .updateFavouriteOutlet(this.selectedFavouriteOutlet.id)
            .then(() => {
                this.customer.favouriteOutletId = this.selectedFavouriteOutlet.id;
                this.customer.favouriteOutlet = this.selectedFavouriteOutlet;
                this.appComponent.loading = false;
                this.alertSuccess.show().then(() => {
                    this.appComponent.closeModal('dealerSelection');
                });
            })
            .catch((errorResponse: HttpErrorResponse) => {
                this.errorResponse = errorResponse;
                this.appComponent.loading = false;
                this.alertError.show();
            });
    }

    public getVehicleAppointments(status?: string, vehicleId?: string): Appointment[] {
        const mVehicleId = vehicleId ? vehicleId : this.selectedVehicle.id;

        return this.customer.appointmentList.filter(appointment =>
            status
                ? appointment.vehicleId === mVehicleId && appointment.status === <AppointmentStatus>status
                : appointment.vehicleId === mVehicleId
        );
    }

    public firstOrNull(array) {
        return array.length >= 0 ? array[0] : null;
    }

    private initLastMaintenance() {
        for (const vehicle of this.customer.vehicleList) {
            const lastAppointment = <Appointment>this.firstOrNull(this.getVehicleAppointments('DELIVERED', vehicle.id));
            if (lastAppointment) {
                vehicle.lastMaintenance = lastAppointment.endTime;
            }
        }
    }

    /**
     * ngFor traking by id
     * @param activityPackage
     */
    public trackByActivityPackage(activityPackage: ActivityPackage) {
        return activityPackage.id;
    }

    public refreshCustomer() {
        this.customerService.getLogged(['appointmentList', 'favouriteOutlet']).then(customer => {
            if (customer.vehicleList.length > 0) {
                customer.vehicleList = customer.vehicleList.filter(vehicle => vehicle.status === 'active');
                this.customer = customer;
            } else {
                // no vehicles
                this.customer = customer;
                this.router.navigate([`/online/dashboard/${customer.id}/vehicle`, { novehicles: true }]);
            }
        });
    }

    public checkCustomerInfo(): void {
        if (this.customer.preferences.contactByMail || this.customer.preferences.contactBySms) {
            if (
                this.customer.address &&
                (Utils.isNullOrUndefined(this.customer.address.address) ||
                    Utils.isNullOrUndefined(this.customer.address.country) ||
                    Utils.isNullOrUndefined(this.customer.address.latitude) ||
                    Utils.isNullOrUndefined(this.customer.address.longitude) ||
                    Utils.isNullOrUndefined(this.customer.address.zipCode) ||
                    (!Utils.isNullOrUndefined(this.customer.address.locationComponentList) &&
                        this.customer.address.locationComponentList.filter(location => location.shortName != null)
                            .length === 0))
            ) {
                this.customer.address.longitude = this.countryConfiguration.longitude;
                this.customer.address.latitude = this.countryConfiguration.latitude;
                this.wrongCustomerInfo = false;
            } else {
                let address: GeoMarker = {
                    longitude: this.countryConfiguration.longitude,
                    latitude: this.countryConfiguration.latitude,
                };
                this.customer.address = address;
                this.wrongCustomerInfo = false;
            }
        } else {
            this.wrongCustomerInfo = true;
        }
    }

    public iconByType(resourceTypeId: number): string {
        switch (resourceTypeId) {
            case 1:
                return 'icon glyphicon glyphicon-camera';
            case 2:
                return 'icon glyphicon glyphicon-facetime-video';
            case 3:
                return 'icon glyphicon glyphicon-headphones';
            case 4:
                return 'icon glyphicon glyphicon-text-background';
        }
    }

    // public directPlay(multimediaResource: MultimediaResource) {
    //     const userId = multimediaResource.user != null ? multimediaResource.user.id : this.customer.id;
    //     const resource: MultimediaResourceModel = new MultimediaResourceModel(
    //         multimediaResource.id,
    //         new Date(multimediaResource.creationDate),
    //         multimediaResource.dealer.id,
    //         userId,
    //         '',
    //         multimediaResource.resourceTypeId,
    //         '',
    //         multimediaResource.resourceId,
    //         '',
    //         null,
    //         multimediaResource.customerId,
    //         multimediaResource.vehiclePlate,
    //         multimediaResource.resourceObservations,
    //         multimediaResource.size,
    //         multimediaResource.documentName,
    //         true,
    //         null
    //     );
    //     this.multimediaResource = resource;
    // }

    // public associateResource(resources: MultimediaResourceModel[]) {
    //     const resourcesToAssociate: MultimediaResource[] = [];
    //     resources.forEach(mmResource => {
    //         const multimedia: MultimediaResource = {
    //             id: mmResource.id,
    //             creationDate: new Date(mmResource.creationDate),
    //             customerId: mmResource.customerId,
    //             dealer: { id: mmResource.dealerId },
    //             documentName: mmResource.documentName,
    //             resourceId: mmResource.resourceId,
    //             resourceObservations: mmResource.observations,
    //             resourceTypeId: mmResource.typeId,
    //             sent: true,
    //             size: mmResource.size,
    //             user: { id: mmResource.creationUserId },
    //             vehiclePlate: mmResource.vehiclePlate,
    //         };
    //         resourcesToAssociate.push(multimedia);
    //     });

    //     this.appointmentService
    //         .associateResource(resourcesToAssociate, this.scheduledAppointment.id)
    //         .then(response => {
    //             resourcesToAssociate.forEach(resource => {
    //                 this.scheduledAppointment.multimediaResourceList.push(resource);
    //             });
    //         })
    //         .catch(error => {});
    // }

    // public disassociateResource(resources: MultimediaResourceModel[]) {
    //     this.appointmentService
    //         .disassociateResource([resources[0].id])
    //         .then(response => {
    //             this.scheduledAppointment.multimediaResourceList = this.scheduledAppointment.multimediaResourceList.filter(
    //                 resource => resource.id != resources[0].id
    //             );
    //         })
    //         .catch(error => {});
    // }

    // Analytics for the dashboard page
    public setDataAnalyticsForDashboard() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasDashboard,
            dataAnalytics.page.variant.real
        );

        if (!Utils.isNullOrUndefined(this.customer.favouriteOutlet)) {
            this.tracyService.setDealerInformationIntoDataAnalytics(
                this.customer.favouriteOutlet.dealerId,
                this.customer.favouriteOutlet.businessName,
                dataAnalytics.dealer.selectionState.selected,
                this.customer.favouriteOutletId
            );
        }

        this.tracyService.setUserInformationIntoDataAnalytics(dataAnalytics.profile.returningStatus.returning);
        this.tracyService.sendPageView(dataAnalytics.referenceName.dashboard);
    }

    // Analytics when we click on book an appointment
    private setDataAnalyticsForRequestedAppointment() {
        this.tracyService.setEventInformationIntoDataAnalytics(
            dataAnalytics.event.cause.default,
            dataAnalytics.event.effect.formStartIntention,
            dataAnalytics.event.eventAction.internalClick,
            'na',
            dataAnalytics.event.subCategory1.rfsOfOas,
            dataAnalytics.event.type.localForm
        );
        this.tracyService.sendEvent(dataAnalytics.event.referenceName.dashboardFormStartIntention);
    }

    // Analytics for the Historical Appointments
    public setDataAnalyticsForHistoricalAppointments() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasHistoricalAppointment,
            dataAnalytics.page.variant.virtual
        );

        this.tracyService.setUserInformationIntoDataAnalytics(dataAnalytics.profile.returningStatus.returning);
        this.tracyService.sendPageView(dataAnalytics.referenceName.historicalAppointments);
    }

    // Analytics implementation start teleservice pop up
    private setDataAnalyticsTeleservicePopUp() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasTeleservicePopUp,
            dataAnalytics.page.variant.virtual
        );
        this.tracyService.setUserInformationIntoDataAnalytics(dataAnalytics.profile.returningStatus.returning);
        this.tracyService.sendPageView(dataAnalytics.referenceName.teleservicePopUp);
    }

    private setDataAnalyticsForProductInDashboard(vehicle: Vehicle) {
        this.tracyService.resetProduct();
        this.tracyService.setProductInformationIntoDataAnalytics(
            Utils.hashSha1(vehicle.vin),
            dataAnalytics.product.productType.vehicle,
            vehicle.model.modelLabel,
            vehicle.model.brand,
            vehicle.model.id,
            dataAnalytics.product.vehicleSelection.selected
        );
    }

    private setDataAnalyticsForServicesInDashboard() {
        // activity services
        this.scheduledAppointment.activityList.forEach(activity => {
            this.tracyService.setProductInformationIntoDataAnalytics(
                activity.activityType.id,
                dataAnalytics.product.productType.service,
                activity.name,
                activity.workTeam.brand,
                'na',
                'na',
                'na',
                'na'
            );
        });

        // mobility services
        this.scheduledAppointment.mobilityList.forEach(mobility => {
            this.tracyService.setProductInformationIntoDataAnalytics(
                String(mobility.id),
                dataAnalytics.product.productType.service,
                mobility.mobilityType.descriptionLocalized,
                'na',
                'na',
                'na',
                'na',
                'na'
            );
        });
    }

    // Sent Analytics Event when Service Center chaned successfully
    public setDataAnalyticsEventForSavingFavouriteDealer() {
        this.tracyService.setEventInformationIntoDataAnalytics(
            dataAnalytics.event.cause.default,
            dataAnalytics.event.effect.serviceCenterChanged,
            dataAnalytics.event.eventAction.internalClick,
            'na',
            'na',
            dataAnalytics.event.type.sucess
        );
        this.tracyService.sendEvent(dataAnalytics.event.referenceName.changeServiceCenterSuccess);
    }

    public onClickSPOA() {
        if (this.tcModalClosed) {
            this.fromSPOA = true;
            this.onClickAssistance();
        }
    }

    public editProfile() {
        if(this.tcModalClosed) {
            return (
                this.ssoUrl +
                'profile' +
                '?client=' +
                this.client +
                '&country=' +
                this.countryConfiguration.countryCode +
                '&language=' +
                this.countryConfiguration.language +
                '&brand=' +
                this.getCurrentBrandToLowercase(this.profileBrand)
            );
        }
    }

   getCurrentBrandToLowercase(brand: string){
      if(brand == "BMW_MOTORRAD"){
         return "bmwmotorrad";
      }
      return brand.toLowerCase();
   }

   getCurrentBrand() {
      if (this.hostname.includes('.mini.')) {
         this.profileBrand = 'MINI';
      } else if (this.hostname.includes('.bmw-motorrad.')) {
         this.profileBrand = 'BMW_MOTORRAD';
      } else {
         this.profileBrand = 'BMW';
      }
   }

   filterTicketsByType(type: string){
      return this.leadTssbTickets.filter(ticket => ticket.ticketType == type).pop();
   }
}
