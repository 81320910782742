import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Logger } from '../../logger.service';
import { BlobFile, SearchSettings } from '../../../shared/entities';
import { ApiUtils } from '../utils/utils';

@Injectable()
export class FirstAvailableTimeslotService {
    private actionUrl: string;

    constructor(protected http: HttpClient, protected logger: Logger) {
        this.actionUrl = `${environment.apiUrl}/first-available-time-slot`;
    }

    public async exportCsv(searchSettings: SearchSettings): Promise<BlobFile> {
        const url = `${this.actionUrl}/export-csv`;
        try {
            const response = await this.http
                .post(url, searchSettings, { observe: 'response', responseType: 'blob' })
                .toPromise();
            const BOM = '\uFEFF';
            return {
                blob: new Blob([BOM, response.body], { type: 'text/csv;charset=utf-8' }),
                filename: ApiUtils.retrieveFileName(response),
            };
        } catch (error) {
            return await ApiUtils.handleError(error, this.logger);
        }
    }

    /* public getFruPerService(searchSettings?: SearchSettings): Promise<AverageFruPerService[]> {
        return this.http
            .post(`${this.actionUrl}/fru/perService`, searchSettings)
            .toPromise()
            .then(response => response as AverageFruPerService[])
            .catch(error => this.handleError(error));
    } */
}
