<div
    *ngIf="(loaded$ | async) && !(emailAlreadyAssociatedToGcdmAccount$ | async)"
    class="ui-checkbox registration-container"
>
    <input type="checkbox" id="register_account" [formControl]="sendPreRegistrationEmail" (change)="emitValue()" />
    <label for="register_account">
        <ng-container *ngIf="titleTmpl; else simpleTitleTmpl">
            <ng-container *ngTemplateOutlet="titleTmpl"></ng-container>
        </ng-container>
    </label>
</div>

<ng-template #simpleTitleTmpl>
    <span class="create-account" i18n="@@createAccountWithPreRegistration"
        >I want to create a {{ getCurrentBrandFromStyle() | translate | uppercase }} account</span
    >
</ng-template>
