<swal text="this won't be displayed" icon="warning">
    <div *swalPortal="swalTargets.title">
        <ng-container *ngIf="titleTmpl; else simpleTitleTmpl">
            <ng-container *ngTemplateOutlet="titleTmpl"></ng-container>
        </ng-container>
    </div>
    <div *swalPortal>
        <ng-container *ngIf="contentTmpl; else simpleTextTmpl">
            <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
        </ng-container>
    </div>
</swal>

<ng-template #simpleTitleTmpl>
    <span>{{ title }}</span>
</ng-template>

<ng-template #simpleTextTmpl>
    <span *ngIf="text">{{ text }}</span>
    <span *ngIf="!text" i18n="@@somethingWentWrong">Something went wrong</span>
</ng-template>
