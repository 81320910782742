import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {TssbLeadTireEvent, TssbTicket} from "../../entities";
import {AlertSuccessComponent} from "../../alert/alert-success.component";
import {BroadcastService} from "../../broadcast.service";
import {Utils} from "../../../core/utils";

@Component({
   selector: 'oas-tires-lead-api',
   templateUrl: './tires-lead-api.component.html',
   styleUrls: ['./tires-lead-api.component.scss']
})
export class TiresLeadApiComponent implements OnInit{
   @Input() tssbTicket: TssbTicket;
   @Input() online: boolean = false;
   @Input() showAdd: boolean = false;
   public listTires: TssbLeadTireEvent[] = [];
   private selectedTires: String[] = [];
   @Output() public selectedTiresList = new EventEmitter<String[]>();
   @ViewChild('alertSuccess') public alertSuccess: AlertSuccessComponent;
   constructor(private broadcastService: BroadcastService){

   }

   private leadAutoSelectTires(): void {
      if (
         !Utils.isNullOrUndefined(this.tssbTicket) &&
         !Utils.isNullOrUndefined(this.tssbTicket.id) &&
         !Utils.isNullOrUndefined(this.tssbTicket.tssbStaticTicketData.keyData.tireEvents) &&
         !Utils.isNullOrUndefined(this.tssbTicket.tssbStaticTicketData.keyData.tireEvents.length > 0)
      ) {
         this.listTires = this.tssbTicket.tssbStaticTicketData.keyData.tireEvents;
         if(this.online){
            this.listTires
               .forEach(tireEvent => {
                  if(tireEvent.urgency != null){
                     this.onSelect(tireEvent, true);
                  }
               });
         }
      }
   }

   public onSelect(tireEvent: TssbLeadTireEvent, checked: boolean): void {
      if (checked) {
         this.selectedTires.push(tireEvent.identifier);
      } else {
         this.selectedTires.splice(this.selectedTires.indexOf(tireEvent.identifier), 1);
      }
      this.selectedTiresList.emit(this.selectedTires);
   }

   ngOnInit(): void {
      this.leadAutoSelectTires();
   }

   public isAlreadySelected(tire: TssbLeadTireEvent): boolean {
      return this.selectedTires.includes(tire.identifier);
   }

   broadcastTireServices(){
      this.broadcastService.broadcast("tires-event", this.tssbTicket);
   }
}
