<!-- Customer fields -->
<form [formGroup]="customerForm">
    <!-- Name & Surname -->
    <div class="row">
        <div class="col-md-6">
            <div class="ui-input-wrapper">
                <label i18n="@@name">Name</label>
                <input
                    type="text"
                    class="ui-input login-form__input"
                    i18n-placeholder="@@nameRequired"
                    placeholder="Name"
                    formControlName="name"
                    [class.submitted]="submitted"
                    (keyup)="dataAnalyticsVisitorStartFillingForm()"
                />
            </div>
        </div>
        <div class="col-md-6">
            <div class="ui-input-wrapper">
                <label i18n="@@surname">Surname</label>
                <input
                    type="text"
                    class="ui-input login-form__input"
                    i18n-placeholder="@@surnameRequired"
                    placeholder="Surname"
                    formControlName="surname"
                    [class.submitted]="submitted"
                />
            </div>
        </div>
    </div>
    <!-- Name & Surname Validation Errors -->
    <div class="row">
        <div class="col-md-6">
            <ng-container *ngTemplateOutlet="requiredValidationError; context: { control: customerForm.get('name') }">
            </ng-container>
        </div>
        <div class="col-md-6">
            <ng-container
                *ngTemplateOutlet="requiredValidationError; context: { control: customerForm.get('surname') }"
            >
            </ng-container>
        </div>
    </div>
    <!-- Fiscal Code Validation Errors
   <div class="row" *ngIf="outlet">
      <div class="col-md-12">
            <ng-container
            *ngTemplateOutlet="requiredValidationError;context:{control:customerForm.get('socialId')}"></ng-container>
      </div>
   </div>-->
    <!-- Gender -->
    <div class="row" [ngClass]="{' d-flex':!countryConfiguration.appointmentFastCustomerCreationGenderEnabled}"> 
        <div class="col-md-6" >
            <div class="ui-input-wrapper">
                <label i18n="@@secondSurname">Second surname</label>
                <input
                    type="text"
                    class="ui-input login-form__input"
                    i18n-placeholder="@@secondSurname"
                    placeholder="Second surname"
                    formControlName="secondSurname"
                />
            </div>
        </div>
        
        <div class="col-md-6" *ngIf="countryConfiguration.appointmentFastCustomerCreationGenderEnabled">
            <div class="ui-select-wrapper">
                <select
                    class="ui-select login-form__input"
                    formControlName="gender"
                    placeholder="Gender"
                    i18n-placeholder="@@genderRequired"
                    [class.submitted]="submitted"
                >
                    <option value="" disabled selected hidden i18n="@@gender">Gender</option>
                    <option value="MALE"  i18n="@@male">Male</option>
                    <option value="FEMALE" i18n="@@female">Female</option>
                </select>
                <!-- Gender Validation Errors -->

                <div class="col-md-12">
                    <div
                        *ngIf="
                            customerForm.get('gender').invalid &&
                            customerForm.get('gender').touched &&
                            customerForm.get('gender').errors.required
                        "
                        class="errors"
                    >
                        <p i18n="@@required">Required!</p>
                    </div>
                </div>
                <div class="col-md-12"><div><p>&nbsp;</p></div></div>
            </div>
        </div>           
    </div>
   
    <!-- Email & Phone Number -->
    <div class="row"  [ngClass]="{ 'grey-background': countryConfiguration.eitherPhoneOrEmailEnabled }">
        <div class="container-div">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            <p  i18n="@@phoneOrEmailIsRequired" class="noteBoxes type2" *ngIf="countryConfiguration.eitherPhoneOrEmailEnabled">
               One of Phone Or Email is Required!</p>
        </div>
        <div class="col-md-6">
            <div class="ui-input-wrapper">
                <label i18n="@@email">Email</label>
                <input
                    type="text"
                    class="ui-input login-form__input"
                    i18n-placeholder="@@emailRequired"
                    placeholder="Email"
                    formControlName="email"
                    (blur)="onBlurEmail($event.target.value)"
                        [class.submitted]="submitted"
                    [required]="isEmailRequired()"
                />
            </div>
        </div>
        <div class="col-md-6">
            <div class="ui-input-wrapper">
                <label i18n="@@phone">Phone</label>
                <input
                    type="text"
                    class="ui-input login-form__input"
                    i18n-placeholder="@@phoneRequired"
                    placeholder="Phone"
                    formControlName="phone"
                    [class.submitted]="submitted"
                    [required]="isPhoneRequired()"
                    
                />
            </div>
        </div>
    </div>

    <!-- Email & Phone Number Validation Errors -->
    <div class="row">
        <div class="col-md-6">
            <ng-container *ngTemplateOutlet="emailValidationError; context: { control: customerForm.get('email') }">
            </ng-container>
        </div>
        <div class="col-md-6">
            <ng-container *ngTemplateOutlet="phoneValidationError; context: { control: customerForm.get('phone') }">
            </ng-container>
        </div>
    </div>

    <!-- Address -->
    <div *ngIf="!hideAddress" (click)="openEditAddressModal()" class="address-pick">
        <div class="ui-input-wrapper address">
            <label i18n="@@address">Address</label>
            <input
                type="text"
                class="ui-input login-form__input"
                i18n-placeholder="@@address"
                placeholder="Address"
                formControlName="address"
                (keypress)="onAddressKeyPress()"
                [class.submitted]="submitted"
                autocomplete="nope"
            />
        </div>
    </div>

    <!-- Address Validation Errors -->
    <div *ngIf="!hideAddress" class="row">
        <div class="col-md-6">
            <ng-container
                *ngTemplateOutlet="requiredValidationError; context: { control: customerForm.get('address') }"
            >
            </ng-container>
        </div>
    </div>
</form>

<ng-template #requiredValidationError let-control="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched || submitted)" class="ui-input-wrapper">
        <div class="errors">
            <p *ngIf="control.errors.required" i18n="@@required">
                Required!
            </p>
            <p *ngIf="control.errors.pattern" i18n="@@invalidValue">
                Invalid value!
            </p>
        </div>
    </div>
</ng-template>

<ng-template #emailValidationError let-control="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched || submitted)" class="ui-input-wrapper">
        <div class="errors">
            <p *ngIf="control.errors.required" i18n="@@required">
                Required!
            </p>
            <p *ngIf="control.errors.email || control.errors.pattern" i18n="@@invalidEmail">
                Invalid email!
            </p>
            <p *ngIf="control.errors.oasUsernameValidator" i18n="@@existEmail">
                The email provided already exist!
            </p>
        </div>
    </div>
</ng-template>

<ng-template #phoneValidationError let-control="control">
    <div *ngIf="control.invalid && (control.dirty || control.touched || submitted)" class="ui-input-wrapper">
        <div class="errors">
            <p *ngIf="control.errors.required" i18n="@@required">
                Required!
            </p>
            <p *ngIf="control.errors.mobilePhone" i18n="@@invalidPhone">
                Phone number not valid!
            </p>
        </div>
    </div>
</ng-template>

<ng-template #editAddressModal>
    <div class="modal-header services__modal-header">
        <button type="button" class="close" aria-label="Close" (click)="appComponent.closeModal('oas-maps-edit')">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" i18n="@@editCustomerAddress">Edit customer address</h4>
    </div>
    <div class="modal-body">
        <oas-address-pick [showMap]="true" [address]="customerOri.address" (newAddress)="manageNewAddress($event)">
        </oas-address-pick>
    </div>
</ng-template>
