import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertConfirmComponent } from '../../shared/alert/alert-confirm.component';
import { AppComponent } from './../../app.component';
import { VehicleService } from './../../core/api/vehicle/vehicle.service';
import { AlertErrorComponent } from './../../shared/alert/alert-error.component';
import { AlertSuccessComponent } from './../../shared/alert/alert-success.component';
import { Customer, Vehicle } from './../../shared/entities';
import { dataAnalytics } from '../../shared/data-analytics';
import { TracyService } from '../../shared/tracy.service';
import { Utils } from '../../core/utils';

@Component({
    selector: 'oas-vehicle-list',
    templateUrl: './vehicle-list.component.html',
})
export class VehicleListComponent implements OnInit {
    @Input() public customer: Customer;
    // tslint:disable-next-line:no-output-rename
    @Output('select') private event = new EventEmitter<number>();
    @Output('refresh') private refresh = new EventEmitter<void>();
    @ViewChild('alertConfirm') private alertConfirm: AlertConfirmComponent;
    @ViewChild('alertSuccess') private alertSuccess: AlertSuccessComponent;
    @ViewChild('alertError') private alertError: AlertErrorComponent;

    public errorResponse: HttpErrorResponse;
    public vehicles: Vehicle[];

    constructor(
        private appComponent: AppComponent,
        private router: Router,
        private route: ActivatedRoute,
        private vehicleService: VehicleService,
        private tracyService: TracyService
    ) {
        this.setDataAnalytics();
    }

    public ngOnInit() {
        this.vehicles = Object.assign([], this.customer.vehicleList);
    }

    public onSelect(vehicle: Vehicle) {
        this.event.emit(this.customer.vehicleList.findIndex(v => v.vin && v.vin.includes(vehicle.vin)));
        this.setDataAnalyticsSendEventvehiculeSelection(vehicle);
    }

    public deleteVehicle(event: Event, vehicle: Vehicle): void {
        event.stopPropagation();
        this.alertConfirm.show().then(result => {
            if (result.value) {
                this.appComponent.loading = true;

                this.vehicleService
                    .delete(vehicle.id)
                    .then(() => {
                        this.appComponent.loading = false;
                        this.appComponent.closeModal('yourVehicle');
                        this.refresh.emit();
                        this.alertSuccess.show();
                    })
                    .catch((errorResponse: HttpErrorResponse) => {
                        this.errorResponse = errorResponse;
                        this.appComponent.loading = false;
                        this.alertError.show();
                    });
            }
        });
    }

    public editVehicle(event: Event, vehicle: Vehicle): void {
        event.stopPropagation();
        this.router.navigate([`${this.customer.id}/vehicle`, { vehicleId: vehicle.id }], { relativeTo: this.route });
        this.appComponent.closeModal('yourVehicle');
    }

    public updateFilter(plate: string): void {
        this.vehicles = [];
        if (plate) {
            this.vehicles = Object.assign(
                [],
                this.customer.vehicleList.filter(vehicle => vehicle.plate && vehicle.plate.includes(plate))
            );
        } else {
            this.vehicles = this.customer.vehicleList;
        }
    }

    private setDataAnalytics() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );
        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasYourVehicles,
            dataAnalytics.page.variant.virtual
        );
        this.tracyService.setUserInformationIntoDataAnalytics('na');
        this.tracyService.sendPageView(dataAnalytics.referenceName.vehicleSelection);
    }
    private setDataAnalyticsSendEventvehiculeSelection(vehicle: Vehicle) {
        this.tracyService.resetProduct();
        this.tracyService.setProductInformationIntoDataAnalytics(
            Utils.hashSha1(vehicle.vin),
            dataAnalytics.product.productType.vehicle,
            vehicle.model.modelLabel,
            vehicle.model.brand,
            vehicle.model.id
        );
        this.tracyService.sendEvent(dataAnalytics.event.referenceName.vehicleSelection);
    }
}
