<div class="registration-page" [ngClass]="enableVehiclePictureUcp ? '':'registration-page__wizard'" [ngStyle]="showNewBackgroundImage() && { 'background-image': 'url(' + 'assets/images/covers/bmw_motorrad/cover-motorrad-welcome.jpg' + ')' }">
    <div class="container-logo-absolute">
        <oas-logo></oas-logo>
    </div>
    <div class="registration-page__mobile"></div>
    <div class="registration-page__row-wrapper">
        <div class="registration-page__form-wrapper">
            <ngx-loading [show]="loading"></ngx-loading>
            <div class="registration-page__form">
                <ng-content></ng-content>
            </div>
        </div>


        <div *ngIf="enableVehiclePictureUcp" class="registration-page__vehicle-wrapper">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <oas-vehicle-picture-ucp
                            [vin]="vin"
                            [vehicleSeriesId]="vehicleSeriesId"
                        ></oas-vehicle-picture-ucp>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
