import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Activity } from '../entities';

@Component({
    selector: 'oas-activity-basket-modal',
    templateUrl: './activity-basket-modal.component.html',
    styleUrls: ['./activity-basket-modal.component.scss'],
})
export class ActivityBasketModalComponent implements OnInit {
    @Input() activities: Activity[];
    @Input() readOnly: boolean;
    @Input() readOnlyActivityIds: string[];
    @Output() public deleteActivity = new EventEmitter<Activity>();

    constructor() {}

    ngOnInit() {}

    public filterPredicate(activity: Activity): boolean {
        return (
            activity.catalogType !== 'TECHNICAL_CAMPAIGNS' &&
            (activity.activityType.showOnline ||
                activity.activityType.id === '000-00-FLATRATE' ||
                activity.activityType.id === '000-00-FIXEDPRICES' ||
                activity.activityType.id === '000-00-PACKAGE')
        );
    }

    public trackByActivity(activity: Activity): string {
        return activity && activity.activityType ? activity.activityType.id : null;
    }

    public removeActivity(activity: Activity) {
        if (!this.readOnly) {
            const index = this.activities.indexOf(activity);

            if (index >= 0) {
                this.deleteActivity.emit(this.activities[index]);
            }
        }
    }

    public isActivityReadOnly(activity: Activity): boolean {
        return (
            this.readOnlyActivityIds.includes(activity.activityType.id) ||
            this.readOnlyActivityIds.includes(activity.aprilActivityId)
        );
    }
}
