import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from './../../logger.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AttachmentService {
    private actionUrl: string;

    constructor(protected http: HttpClient, protected logger: Logger) {
        this.actionUrl = `${environment.apiUrl}/attachment`;
    }

    public uploadAttachmentTemp(attachment: File, fileName: string): Promise<string> {
        fileName = fileName.replace(/\s/g, '_');

        const url = `${this.actionUrl}/temp/${fileName}`;

        return this.http
            .post(url, attachment, { responseType: 'text' })
            .toPromise()
            .then(response => response as string)
            .catch(error => this.handleError(error));
    }

    public deleteAttachmentTemp(attachment: string): Promise<void> {
        const url = `${this.actionUrl}/temp/${attachment}`;

        return this.http
            .delete(url, { responseType: 'text' })
            .toPromise()
            .catch(error => this.handleError(error));
    }

    public moveAttachments(appointmentId: string, attachments: string[]): Promise<string[]> {
        const url = `${this.actionUrl}/temp/appointment/${appointmentId}`;

        return this.http
            .post(url, attachments)
            .toPromise()
            .then(response => response as string[])
            .catch(error => this.handleError(error));
    }

    public getAppointmentAttachments(appointmentId: string): Promise<string[]> {
        const url = `${this.actionUrl}/appointment/${appointmentId}`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as string[])
            .catch(error => this.handleError(error));
    }

    public deleteAttachment(appointmentId: string, attachment: string): Promise<void> {
        const url = `${this.actionUrl}/${attachment}/appointment/${appointmentId}`;

        return this.http
            .delete(url)
            .toPromise()
            .catch(error => this.handleError(error));
    }

    public uploadAttachment(appointmentId: string, attachment: File, fileName: string): Promise<string> {
        fileName = fileName.replace(/\s/g, '_');
        const url = `${this.actionUrl}/appointment/${appointmentId}/${fileName}`;

        return this.http
            .post(url, attachment, { responseType: 'text' })
            .toPromise()
            .then(response => response as string)
            .catch(error => this.handleError(error));
    }

    protected handleError(error: any): Promise<any> {
        this.logger.debug('error');
        this.logger.debug(error);

        return Promise.reject(error);
    }
}
