<oas-customer-wizard>
  <a [routerLink]="['/backoffice/login']" class="dismiss-request text-primary">
      <i class="fa fa-chevron-left"> </i>
      <span i18n="@@backToLogin">Back to login</span>
  </a>
  <div class="login-form__section">
      <img src="assets/images/ic_account-register.svg" alt="" />
      <h2 class="login-form__section-title" i18n="@@resetPassword">
          Reset password
      </h2>
  </div>
  <h1 class="login-form__title" i18n="@@newPassword">New password</h1>
  <form [formGroup]="userForm">
      <!-- Password -->
      <div [tooltip]="pswTooltip" class="ui-input-wrapper">
          <input
              type="password"
              class="ui-input login-form__input registration__icon-password"
              i18n-placeholder="@@password"
              placeholder="Password"
              formControlName="password"
              (change)="matchPassword()"
          />
      </div>
      <!-- Password Validation Errors -->
      <ng-container
          *ngTemplateOutlet="passwordValidationError; context: { control: userForm.get('password') }"
      ></ng-container>
      <!-- Confirm Password -->
      <div class="ui-input-wrapper">
          <input
              type="password"
              class="ui-input login-form__input registration__icon--empty"
              i18n="@@confirmPassword"
              placeholder="Confirm Password"
              formControlName="confirmPassword"
              (change)="matchPassword()"
          />
      </div>
      <!-- Confirm Password Validation Errors -->
      <ng-container
          *ngTemplateOutlet="matchPasswordValidationError; context: { control: userForm.get('confirmPassword') }"
      ></ng-container>
      <div class="navigation__buttons">
          <div class="next-step__button-wrapper" data-tracking-regionid="online service appointment">
              <div *ngIf="passwordUsed" class="alert alert-danger" role="alert" i18n="@@passwordUsed">
                  Password already used. Please fill another one!
              </div>
              <button
                  class="ui-button btn btn-primary"
                  [disabled]="userForm.invalid"
                  (click)="setPassword()"
                  i18n="@@sendRequest"
                  data-tracking-linkid="local > password activation"
              >
                  Send request
              </button>
          </div>
      </div>
  </form>
</oas-customer-wizard>

<ng-template #passwordValidationError let-control="control">
  <div *ngIf="control.invalid && (control.dirty || control.touched)" class="ui-input-wrapper">
      <div class="errors">
          <p *ngIf="control.errors.required" i18n="@@required">
              Required!
          </p>
          <p *ngIf="control.errors.minlength" i18n="@@minLength">Minimum length is {{ minLength }}!</p>
          <p *ngIf="control.errors.maxlength" i18n="@@maxLength">Maximum length is {{ maxLength }}!</p>
          <p *ngIf="control.errors.compliantPassword" i18n="@@notCompliantPassword">
              Password not compliant!
          </p>
      </div>
  </div>
</ng-template>

<ng-template #matchPasswordValidationError let-control="control">
  <div
      *ngIf="control.invalid && (control.dirty || control.touched) && control.errors.matchPassword"
      class="ui-input-wrapper"
  >
      <div class="errors">
          <p i18n="@@invalidPassword">Password not match!</p>
      </div>
  </div>
</ng-template>

<ng-template #pswTooltip>
  <div style="text-align:left;" i18n="@@backofficePasswordRules">
    Requirements:
    <ul>
        <li>Password needs to be between 15 and 30 characters</li>
        <li>Must have at least 1 number, 1 Uppercase and 1 lowercase letter</li>
        <li>No special character allowed except for:</li>
        <li style="list-style-type: none;">&amp; * : # _ - </li>
    </ul>
  </div>
</ng-template>

<oas-alert-error #alertError [errorResponse]="errorResponse"></oas-alert-error>
<oas-alert-success
  #alertSuccess
  i18n-text="@@confirmSetPassword"
  i18n-title="@@success"
  title="Success"
  text="Reset Password Completed"
></oas-alert-success>

