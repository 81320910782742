<div class="container">
    <div class="title-container container-separator">
        <div class="go-back-link d-flex align-items-center" (click)="goToWelcome()">
            <span class="go-back-link-image"></span>
            <span i18n="@@homepage">Homepage</span> 
        </div>
    </div>
    <ng-container [ngSwitch]="countryCode">
        <oas-dsa-it *ngSwitchCase="'IT'"></oas-dsa-it>
        <oas-dsa-pt *ngSwitchCase="'PT'"></oas-dsa-pt>
        <oas-dsa-it *ngSwitchDefault></oas-dsa-it>
    </ng-container>
</div>
