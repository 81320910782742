<div class="input-group vehicle-search">
    <input
        type="text"
        class="ui-input"
        (change)="updateFilter($event.target.value)"
        i18n-placeholder="@@plate"
        placeholder="plate"
    />
</div>
<ng-container *ngFor="let vehicle of vehicles; let i = index">
    <div *ngIf="i % 3 === 0" class="row vehicle-row row-md-eq-height">
        <div
            class="col-md-4 vehicle-cell"
            (click)="onSelect(vehicles[i])"
            data-tracking-regionid="online service appointment"
        >
            <oas-vehicle-picture-ucp
                [vin]="vehicles[i].vin"
                [vehicleSeriesId]="vehicles[i]?.model?.series?.id"
            ></oas-vehicle-picture-ucp>
            <p>
                <strong>
                    {{ vehicles[i].model?.modelLabel }}
                </strong>
                <br />
                ({{ vehicles[i].plate }})
            </p>
            <button
                class="btn button--clear vehicle-cell__edit-vehicle"
                (click)="editVehicle($event, vehicles[i])"
                data-tracking-linkid="local > vehicle edit clicked"
            >
                <i class="fa fa-pencil fa-lg"></i>
            </button>

            <button
                class="btn button--clear vehicle-cell__remove-vehicle"
                (click)="deleteVehicle($event, vehicles[i])"
                data-tracking-linkid="local > vehicle remove clicked"
            >
                <i class="fa fa-times-circle-o fa-lg"></i>
            </button>
        </div>
        <div *ngIf="i + 1 < vehicles.length" class="col-md-4 vehicle-cell" (click)="onSelect(vehicles[i + 1])">
            <oas-vehicle-picture-ucp
                [vin]="vehicles[i + 1].vin"
                [vehicleSeriesId]="vehicles[i + 1]?.model?.series?.id"
            ></oas-vehicle-picture-ucp>
            <p>
                <strong>
                    {{ vehicles[i + 1].model?.modelLabel }}
                </strong>
                <br />
                ({{ vehicles[i + 1].plate }})
            </p>
            <button class="btn button--clear vehicle-cell__edit-vehicle" (click)="editVehicle($event, vehicles[i + 1])">
                <i class="fa fa-pencil fa-lg"></i>
            </button>

            <button
                class="btn button--clear vehicle-cell__remove-vehicle"
                (click)="deleteVehicle($event, vehicles[i + 1])"
            >
                <i class="fa fa-times-circle-o fa-lg"></i>
            </button>
        </div>
        <div *ngIf="i + 2 < vehicles.length" class="col-md-4 vehicle-cell" (click)="onSelect(vehicles[i + 2])">
            <oas-vehicle-picture-ucp
                [vin]="vehicles[i + 2].vin"
                [vehicleSeriesId]="vehicles[i + 2]?.model?.series?.id"
            ></oas-vehicle-picture-ucp>
            <p>
                <strong>
                    {{ vehicles.model?.modelLabel }}
                </strong>
                <br />
                ({{ vehicles[i + 2].plate }})
            </p>
            <button class="btn button--clear vehicle-cell__edit-vehicle" (click)="editVehicle($event, vehicles[i + 2])">
                <i class="fa fa-pencil fa-lg"></i>
            </button>

            <button
                class="btn button--clear vehicle-cell__remove-vehicle"
                (click)="deleteVehicle($event, vehicles[i + 2])"
            >
                <i class="fa fa-times-circle-o fa-lg"></i>
            </button>
        </div>
    </div>
</ng-container>

<oas-alert-confirm #alertConfirm></oas-alert-confirm>
<oas-alert-success #alertSuccess></oas-alert-success>
<oas-alert-error #alertError [errorResponse]="errorResponse"></oas-alert-error>
