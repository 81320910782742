import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { AppComponent } from '../../app.component';
import { Utils } from '../../core/utils';
import { TracyService } from '../tracy.service';
import { dataAnalytics } from '../data-analytics';

@Component({
    selector: 'oas-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements OnInit {
    @ViewChild('epaasContainer') epaasContainer;

    constructor(public appComponent: AppComponent, private location: Location, private tracyService: TracyService) {
        // prevent scrolling to bottom fix for Chrome
        window.scrollTo(0, 1);
    }

    ngOnInit() {
        if (!Utils.isNullOrUndefined(window['epaas'])) {
            window['epaas'].api.policypage.then(policypageBundle =>
                policypageBundle.showPolicyPage(this.epaasContainer.nativeElement)
            );
        }

        this.setDataAnalytics();
    }

    public previousLocation(): void {
        this.location.back();
    }

    //Analytics implementation start
    private setDataAnalytics() {
        this.tracyService.setComponentInformationIntoDataAnalytics(
            dataAnalytics.component.componentId.localStandaloneOas,
            '1'
        );

        this.tracyService.setPageInformationIntoDataAnalytics(
            dataAnalytics.page.pageID.lOasCookies,
            dataAnalytics.page.variant.real
        );

        this.tracyService.setUserInformationIntoDataAnalytics(dataAnalytics.profile.returningStatus.returning);

        this.tracyService.sendPageView(dataAnalytics.referenceName.cookies);
    }
}
