import { Injectable, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class ValidateOnlineUsernameOnBlurDirective implements AsyncValidator {
    @Input() public validateFormControl: UntypedFormControl;

    constructor(protected authService: AuthService) {}

    validate(c: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> {
        return this.authService
            .checkUniqueUsername(c.value, 'online')
            .pipe(
                map((data: Observable<any>) => {
                    if (data) {
                        return { oasUsernameValidator: true };
                    }
                }),
                catchError(err => of(null))
            )
            .toPromise();
    }
    registerOnValidatorChange?(fn: () => void): void {
        throw new Error('Method not implemented.');
    }
}
