import { Injectable } from '@angular/core';
// import { tracy } from '../../assets/tracking/tracyLib-OnlineAppointmentScheduler-v4.4';
import { CountryConfigurationService } from '../../configuration/country-configuration.service';
import { StyleSwitcherService } from '../core/style-switcher.service';
import { Utils } from '../core/utils';
import { CountryConfiguration } from '../country-configuration';
import { SciDataAnalytics } from '../sci-data-analytics';
import { dataAnalytics } from './data-analytics';
import { VehicleBrand } from './entities';
import { TechnicalSettingConfiguration } from './technical-setting-configuration';

@Injectable()
export class TracyService {
    public sciDataAnalytics: SciDataAnalytics;
    public countryConfiguration: CountryConfiguration;
    public list: string[] = [];
    public NUMMAXTIMEOUT:number=10;
    public numMaxTimeOut: number=0;
public tracy:any;
    constructor(
        private styleSwitcherService: StyleSwitcherService,
        countryConfigurationService: CountryConfigurationService,
        private technicalSettingConfiguration: TechnicalSettingConfiguration,
    ) {
        this.sciDataAnalytics = new SciDataAnalytics(
            this.technicalSettingConfiguration.getTechnicalSetting('TRACY_ENVIRONMENT')
        );
        this.tracy = window['tracy'];
        this.callSetTimeout();
        this.countryConfiguration = countryConfigurationService.getCountryConfiguration();
        // window['tracyLib'] = tracy;
        window['tracyData'] = this.sciDataAnalytics.tracyData;
        /*if (this.tracy) {
            this.tracy.epaasFree();
            this.tracy.setDebug(true);
        }*/
       
    }

    public setComponentInformationIntoDataAnalytics(componentId: string, version: string) {
        this.sciDataAnalytics.tracyData.component.componentId = componentId;
        this.sciDataAnalytics.tracyData.component.componentVersion = version;
    }

    public setPageInformationIntoDataAnalytics(pageId: string, variant: string) {
        this.sciDataAnalytics.tracyData.page.pageId = pageId;
        this.sciDataAnalytics.tracyData.page.destinationUrl = document.location.href;
        this.sciDataAnalytics.tracyData.page.referringUrl = document.referrer;
        const brand = this.styleSwitcherService.brand.getValue().toUpperCase() as VehicleBrand;
        this.sciDataAnalytics.tracyData.page.brand = brand === 'BMW_MOTORRAD' ? 'motorrad' : brand.toLocaleLowerCase();
        this.sciDataAnalytics.tracyData.page.language = this.countryConfiguration.language;
        this.sciDataAnalytics.tracyData.page.geoRegion = this.countryConfiguration.countryCode;
        this.sciDataAnalytics.tracyData.page.variant = variant;
        this.sciDataAnalytics.tracyData.page.currencyCode = this.countryConfiguration.currencyCode;
    }

    public setUserInformationIntoDataAnalytics(returningStatus: string) {
        this.sciDataAnalytics.tracyData.user.profileId = JSON.parse(sessionStorage.getItem('user'))
            ? JSON.parse(sessionStorage.getItem('user')).id
            : 'na';
        this.sciDataAnalytics.tracyData.user.loggedInStatus = JSON.parse(sessionStorage.getItem('user'))
            ? 'logged-in'
            : 'not logged-in';
        this.sciDataAnalytics.tracyData.user.returningStatus = returningStatus;
        this.sciDataAnalytics.tracyData.user.language = this.countryConfiguration.language;
        this.sciDataAnalytics.tracyData.user.country = this.countryConfiguration.countryCode;
    }

    public setEventInformationIntoDataAnalytics(
        cause: string,
        effect: string,
        eventAction: string,
        requestedURL: string,
        subCategory1: string,
        type: string
    ) {
        this.sciDataAnalytics.tracyData.event.cause = cause;
        this.sciDataAnalytics.tracyData.event.effect = effect;
        this.sciDataAnalytics.tracyData.event.eventAction = eventAction;
        this.sciDataAnalytics.tracyData.event.requestedURL = requestedURL;
        this.sciDataAnalytics.tracyData.event.subCategory1 = subCategory1;
        this.sciDataAnalytics.tracyData.event.type = type;
    }

    public setProductInformationIntoDataAnalytics(
        productID?: string,
        productType?: string,
        productName?: string,
        manufacturer?: string,
        modelCode?: string,
        vehicleSelection?: string,
        yearOfRegistration?: string,
        subCategory1?: string
    ) {
        this.sciDataAnalytics.tracyData.product.productID = productID;
        this.sciDataAnalytics.tracyData.product.productType = productType;
        this.sciDataAnalytics.tracyData.product.productName = productName;
        this.sciDataAnalytics.tracyData.product.manufacturer = manufacturer;
        this.sciDataAnalytics.tracyData.product.modelCode = modelCode;
        this.sciDataAnalytics.tracyData.product.vehicleSelection = vehicleSelection ? vehicleSelection : 'na';
        this.sciDataAnalytics.tracyData.product.yearOfRegistration = yearOfRegistration ? yearOfRegistration : 'na';
        this.sciDataAnalytics.tracyData.product.subCategory1 = subCategory1 ? subCategory1 : 'owned';

        if(Utils.isNotNullOrUndefined(this.tracy) && Utils.isNotNullOrUndefined(this.tracy.product)) {
            this.tracy.product.add(dataAnalytics.product.referenceName.localProduct);
        }
    }

    public setDealerInformationIntoDataAnalytics(
        dealerId: string,
        dealerName: string,
        outletId: string,
        selectionState: string
    ) {
        this.sciDataAnalytics.tracyData.dealer.dealerId = dealerId ? dealerId : 'na';
        this.sciDataAnalytics.tracyData.dealer.dealerName = dealerName;
        this.sciDataAnalytics.tracyData.dealer.outletID = outletId;
        this.sciDataAnalytics.tracyData.dealer.selectionState = selectionState;
    }

    public setAlertDataAnalytics(regionId: string, linkId: string, linkIdForCancel?: string) {
        /**
         * since its impossible to modify the alert for activity map
         * its preferable to inject the element right from js
         */
        setTimeout(() => {
            const activityMapRegion: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(
                'swal2-popup swal2-modal swal2-icon-warning swal2-show'
                ) as HTMLCollectionOf<HTMLElement>;
            activityMapRegion[0].setAttribute('data-tracking-regionid', regionId);
            const linkIdRegion: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(
                'swal2-confirm swal2-styled'
            ) as HTMLCollectionOf<HTMLElement>;
            linkIdRegion[0].setAttribute('data-tracking-linkid', linkId);
            if (linkIdForCancel) {
                const linkIdRegion2: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(
                    'swal2-cancel swal2-styled'
                ) as HTMLCollectionOf<HTMLElement>;
                linkIdRegion2[0].setAttribute('data-tracking-linkid', linkIdForCancel);
            }
        }, 100)
    }

    public callSetTimeout() {
        setTimeout(() => {
            this.callMethodStack();

        }, 10);
    }

    public callMethodStack() {
        if (window['tracy']) {
            this.tracy =window['tracy']
            this.numMaxTimeOut = 0;
            for (let index = 0; index < this.list.length; index++) {
                this.tracy.page(this.list[index]); //sending Page View
            }
            this.list = [];
        } else {

            if (this.numMaxTimeOut < this.NUMMAXTIMEOUT) {
                this.callSetTimeout();
                this.numMaxTimeOut++;
            } else {
                console.error("Reached Max Time Out, Max is 10")
            }
        }
    }

    public sendPageView(name: string) {
        if (Utils.isAnalyticsCookiesEnabled()) {
            if (this.tracy) {
                this.tracy.page(name);
            } else {
                this.list.push(name) //store page name untill tracy has value
            }
        }
    }

    public sendEvent(name: string, subCategory?: string, effect?: string) {
        this.sciDataAnalytics.tracyData.event.timeStamp = Date.now().toString();
        this.sciDataAnalytics.tracyData.event.subCategory1 = subCategory ? subCategory : 'na';
        this.sciDataAnalytics.tracyData.event.effect = effect ? effect : 'na';
        if (Utils.isAnalyticsCookiesEnabled() && this.tracy !== undefined && this.tracy !== null) {
            this.tracy.event(name);
        }
    }

    public resetProduct() {
        if(this.tracy)
        this.tracy.product.reset();
    }
}
