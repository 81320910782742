import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    BaseResponse,
   TssbCheckboxFilter, TssbLeadBatteryEvent, TssbLeadCcmEvent,
   TssbLeadDMSCheckResponse,
   TssbLeadFeedbackDictionary,
   TssbLeadTicket, TssbLeadTicketFeedbackResponse, TssbLeadTicketFilters, TssbLeadTireEvent, TssbServiceState,
   TssbTicket, TssbTicketCBS,
   TssbTicketLogsSummary, LeadTicketResponse
} from '../../../shared/entities';
import { Logger } from '../../logger.service';
import { AbstractRestService } from '../generic.service';
import { Utils } from '../../utils';

@Injectable({
    providedIn: 'root'
})
export class LeadTssbService extends AbstractRestService<TssbTicket, number> {
    constructor(protected http: HttpClient, protected logger: Logger) {
        super('/lead-tssb-tickets', http, logger);
    }

    getLeadTickets(
        tssbCheckboxFilter?: TssbCheckboxFilter,
        tssbCountFilter?: TssbCheckboxFilter
    ): Promise<LeadTicketResponse> {
        const url = `${this.actionUrl}`;

        // const filters = [tssbCheckboxFilter, tssbCountFilter];

        return this.http
            .post<LeadTicketResponse>(url, tssbCheckboxFilter)
            .toPromise()
            .then(response => {
                response?.leadList.map(item => {
                    item.createTimeStamp = item.sendTimestamp;
                    return item;
                })
                return response;
            }
            )
            .catch(error => this.handleError(error));
    }

    getLeadTicketDetails(ticketId: number): Promise<TssbLeadTicket> {
        const url = `${this.actionUrl}/${ticketId}`;
        return this.http
            .get<TssbLeadTicket>(url)
            .toPromise()
            .then(response => {
                if (response != null) {
                    response.createTimeStamp = response.sendTimestamp;
                }
                return response;
            })
            .catch(error => this.handleError(error));
    }

   getPackagesByTicketId(body: TssbCheckboxFilter, searchText: string = null): Promise<TssbLeadTicketFilters> {
      let url = `${this.actionUrl}/packages`;

      if(searchText != null){
        url += `?searchText=${searchText}`; 
      }
      return this.http
         .post<TssbLeadTicketFilters>(url, body)
         .toPromise()
         .then(response =>  response)
         .catch(error => this.handleError(error));
   }

   setDefaultCustomer(ticketId: number, customerId){
      const url = `${this.actionUrl}/${ticketId}/customers/${customerId}`;
      return this.http
         .put(url, null)
         .toPromise()
         .then(() => null)
         .catch(error => this.handleError(error));
   }

   checkDMS(ticketId): Promise<BaseResponse<TssbLeadDMSCheckResponse>>{
    const url = `${this.actionUrl}/${ticketId}/check-dms`;
        return this.http
            .get<BaseResponse<TssbLeadDMSCheckResponse>>(url)
            .toPromise()
            .then(response => response)
            .catch(error => this.handleError(error));
   }

    getLeadTicketDetailsByVin(vin: string): Promise<TssbLeadTicket> {
        const url = `${this.actionUrl}/vin`;
        const requestBody = {
            vin
        };
        return this.http
            .post<TssbLeadTicket>(url,requestBody)
            .toPromise()
            .then(response => {
                if (response != null) {
                    response.createTimeStamp = response.sendTimestamp;
                }
                return response;
            })
            .catch(error => this.handleError(error));
    }

    getLeadTicketFeedbackSummary(ticketId: number): Promise<TssbTicketLogsSummary> {
        const url = `${this.actionUrl}/${ticketId}/feedback/summary`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as TssbTicketLogsSummary)
            .catch(error => this.handleError(error));
    }

    getFeedbackPostponeOptions(): Promise<string[]> {
        const url = `${this.actionUrl}/feedback-postpone-options`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response)
            .catch(error => this.handleError(error));
    }

    cancelTicket(ticketId: number, reasonId: number, customReason: string): Promise<TssbLeadTicket> {
        const url = `${this.actionUrl}/${ticketId}/cancel`;
        const params = new HttpParams().append('reasonId', reasonId + '').append('customReason', customReason);

        return this.http
            .get<TssbLeadTicket>(url, { params })
            .toPromise()
            .then(response => {
                response.createTimeStamp = response.sendTimestamp;
                return response;
            })
            .catch(error => this.handleError(error));
    }

   cancelTickets(ticketIds: number[]): Promise<boolean> {
      const url = `${this.actionUrl}/cancel-tickets`;

      return this.http
         .post<TssbLeadTicket>(url, ticketIds)
         .toPromise()
         .then(response => response)
         .catch(error => this.handleError(error));
   }

    postponeTicket(ticketId: number, reasonId: number, customReason: string, postponeTime: string) {
        const url = `${this.actionUrl}/${ticketId}/postpone`;
        const params = new HttpParams()
            .append('reasonId', reasonId + '')
            .append('customReason', customReason)
            .append('postponeTime', postponeTime);

        return this.http
            .get<TssbLeadTicket>(url, { params })
            .toPromise()
            .then(response => response)
            .catch(error => this.handleError(error));
    }
    public sendEmail(ticketId: number, email: string): Promise<TssbTicket> {
        const url = `${this.actionUrl}/${ticketId}/contact/email`;
        const requestBody = {          
            email
        };
        return this.http
            .post(url, requestBody)
            .toPromise()
            .then(response => response as TssbTicket)
            .catch(error => this.handleError(error));
    }

    public openTicket(ticketId: number): Promise<TssbTicket> {
        const url = `${this.actionUrl}/${ticketId}/contacting`;

        return this.http
            .get(url)
            .toPromise()
            .then(response => response as TssbTicket)
            .catch(error => this.handleError(error));
    }
    getCount(tssbCheckboxFilter?: TssbCheckboxFilter): Promise<number> {
        const url = `${this.actionUrl}/count`;

        return this.http
            .post<[TssbLeadTicket]>(url, tssbCheckboxFilter)
            .toPromise()
            .then(response => response)
            .catch(error => this.handleError(error));
    }

    public getTicketLogsSummary(ticketId: number): Promise<TssbTicketLogsSummary> {
        const url = `${this.actionUrl}/${ticketId}/logs/summary`;

        return this.http
            .post(url, null)
            .toPromise()
            .then(response => response as TssbTicketLogsSummary)
            .catch(error => this.handleError(error));
    }

    getCancellationFeedbackDictionary(): Promise<TssbLeadFeedbackDictionary[]> {
        const url = `${this.actionUrl}/feedback-dictionary/cancellation`;

        return this.http
            .get<[TssbLeadFeedbackDictionary]>(url)
            .toPromise()
            .then(response => response)
            .catch(error => this.handleError(error));
    }
    getPostponeFeedbackDictionary(): Promise<TssbLeadFeedbackDictionary[]> {
        const url = `${this.actionUrl}/feedback-dictionary/postpone`;

        return this.http
            .get<[TssbLeadFeedbackDictionary]>(url)
            .toPromise()
            .then(response => response)
            .catch(error => this.handleError(error));
    }

   getLeadEventsByVin(vin: string): Promise<TssbLeadTicket[]> {
      const url = `${this.actionUrl}/events-by-vin`;
      const requestBody = {
        vin
      };
      return this.http
         .post<TssbLeadTicket>(url, requestBody)
         .toPromise()
         .then(response => {
            if (response != null) {
               response.createTimeStamp = response.sendTimestamp;
            }
            return response;
         })
         .catch(error => this.handleError(error));
   }

   mapperLeadToTssb(ticket: TssbLeadTicket) {
      const tssTicket: TssbTicket = {
         id: ticket.id,
         notificationId: ticket.notificationId,
         vinShort: ticket.vin,
         vinLongPrefix: ticket.vin,
         vehicleVin: ticket.vin,
         vehicleId: ticket.vin,
         vin: ticket.vin,
         brandShort: ticket.brand,
         brand: ticket.brand,
         countryCode: ticket.countryCode,
         ticketType: ticket.ticketType,
         ticketState: ticket.status,
         ticketStateTranslation: ticket.leadTicketStatusTranslation,
         createTimestamp: ticket.createTimeStamp,
         partnerNumber: ticket.partnerNumber,
         outletNumber: ticket.outletNumber,
         buNo: ticket.buNo,
         remark:ticket.remark,
         vehicleModelId: ticket.model,
         customerDataElement: Utils.isNotEmptyArray(ticket.customer) && ticket.customer.length > 0 ? ticket.customer.find(c => c.defaultCustomer === true) : null,
         assignedTo: ticket.assignedTo,
         outlet: ticket.outlet,
         reasonCustom: ticket.feedbacks != null && ticket.feedbacks.length ? ticket.feedbacks[0].feedbackRemark : '',
         vehiclePlate: ticket.vehiclePlate,
         dueDate: ticket.dueDate,
         productionDate: ticket.productionDate,
         tssbStaticTicketData: {
            keyData: {
               mileage: ticket.milage,
               model: ticket.model,
               averageDistance: ticket.averageDistance,
               firstRegistration: ticket.vehicleMatriculationDate,
               conditionBasedServices:
                  ticket.cbsEvents != null
                     ? ticket.cbsEvents.map(item => {
                        const event: TssbTicketCBS = {
                           id: item.id,
                           identifier: item.cbsEventIdentifier,
                           state: item.cbsState as TssbServiceState,
                           description: item.description,
                           remainingDistance: item.remainingDistance,
                           remainingTime: item.remainingTime,
                           price: item.price,
                           siCheck: item.siCheck
                        };
                        return event;
                     })
                     : null,
               tireEvents:
                  ticket.tireEvents != null
                     ? ticket.tireEvents.map(item => {
                        const event: TssbLeadTireEvent = {
                           id: item.id,
                           identifier: item.identifier,
                           counter: item.counter,
                           urgency: item.urgency,
                           remainingDistance: item.remainingDistance,
                           text: item.text,
                        };
                        return event;
                     })
                     : null,
                batteryEvents:
                    ticket.batteryEvents != null
                    ? ticket.batteryEvents.map(item => {
                        const event: TssbLeadBatteryEvent = {
                            id: item.id,
                            identifier: item.identifier,
                            text: item.text,
                            batteryType: item.batteryType,
                            capacity: item.capacity,
                            wearAgeComponent: item.wearAgeComponent,
                            wearLifeTimePercentage: item.wearLifeTimePercentage,
                            healthClass: item.healthClass,
                            stateOfChargeHealthClass: item.stateOfChargeHealthClass
                        };
                        return event;
                    })
                    : null,
               ccmEvents:
                  ticket.ccmEvents != null
                     ? ticket.ccmEvents.map(item => {
                        const event: TssbLeadCcmEvent = {
                           id: item.id,
                           identifier: item.identifier,
                           counter: item.counter,
                           ccms: item.ccms,
                           urgency: item.urgency,
                           text: item.text
                        };
                        return event;
                     })
                     : null,
            },
         },
      };
      return tssTicket;
   }

   getLeadEventsByVinAndType(vin: string, type: string): Promise<TssbLeadTicket>{
      const url = `${this.actionUrl}/vin-and-type`;
      const requestBody = {
        vin,
        type
      };
      return this.http
         .post<TssbLeadTicket>(url, requestBody)
         .toPromise()
         .then(response => {
            return response;
         })
         .catch(error => this.handleError(error));
   }

   public updateRemark(ticketId: number, remark?: string): Promise<boolean> {
    const url = `${this.actionUrl}/${ticketId}/update-remark`;

    return this.http
        .post(url, remark.length > 0 ? remark : ' ')
        .toPromise()
        .then(response => response as boolean)
        .catch(error => this.handleError(error));
    }

   public getTicketIdFromVinAppointment(vin: string): Promise<string[]> {
      const url = `${this.actionUrl}/id-from-vin-appointment`;
      return this.http
         .post(url, vin)
         .toPromise()
         .then(response => response as string[])
         .catch(error => this.handleError(error));
   }

   public getFeedbacksByVinAndTicketType(vin: string, ticketType: string): Promise<TssbLeadTicketFeedbackResponse[]> {
    const url = `${this.actionUrl}/previous-feedbacks/${vin}/${ticketType}`;
    return this.http
       .get(url)
       .toPromise()
       .then(response => response as TssbLeadTicketFeedbackResponse[])
       .catch(error => this.handleError(error));
    }

    public retrieveCBSServicesWithPrices(ticketId: number): Promise<TssbLeadTicket> {
        const url = `${this.actionUrl}/cbs-prices/${ticketId}`;
        return this.http
           .get(url)
           .toPromise()
           .then(response => response as TssbLeadTicket)
           .catch(error => this.handleError(error));
    }
}
