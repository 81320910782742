export let dataAnalytics = {
    referenceName: {
        login: 'oas > local > login',
        logout: 'oas > local > logout',
        registration: 'oas > local > registration',
        registrationComplete: 'oas > local > Registration complete',
        recoverPwd: 'oas > local > recover password',
        recoverPwdConfirm: 'oas > local > recover pwd confirm',
        pwdActivation: 'oas > local > password activation',
        pwdActivationSuccess: 'oas > local > password activation success',
        historicalAppointments: 'oas > local > historical appointment',
        advantagesPopup: 'oas > local > adv complete registration',
        accountEdit: 'oas > local > account edit',
        addVehicle: 'oas > local > add vehicle',
        teleservicePopUp: 'oas > local > teleservice popup',
        serviceSelection: 'oas > local > service selection',
        serviceCenterSelection: 'oas > local > service center selection',
        additionalService: 'oas > local > additional service',
        appointmentSummary: 'oas > local > appointment summary',
        appointmentSuccess: 'oas > local > appointment success',
        appointmentSchedule: 'oas > local > schedule appointment',
        dashboard: 'oas > local > dashboard',
        serviceCenterChange: 'oas > local > service center change',
        footer: 'oas > local > footer',
        vehicleSelection: 'oas > local > vehicle selection',
        cookies: 'oas > local > cookies',
        guestVehicle: 'oas > local > guest vehicle license plate',
        serviceSelectionModified: 'oas > local > service selection modified',
        guestVehicleLicensePlate: 'sales > guest vehicle license  plate',
        guestVehicleModel: 'sales > guest vehicle model',
        pickUpDateAndDelivery: 'sales > pick-up date and  delivery',
    },
    event: {
        referenceName: {
            loginFormStartIntetion: 'oas > login > local > form start intention',
            registrationFormStart: 'oas > registration > local > form started',
            registrationFormFinishIntention: 'oas > registration > local > form finish intention',
            registrationComeBackClicked: 'oas > registration > local > come back clicked',
            registrationFormFill: 'oas > registration > local > form fill',
            registrationCompleteFormFinished: 'oas > registration complete > local > form finished',
            changeServiceCenterSuccess: 'oas > change service center > local > service center successfully changed',
            dashboardFormStartIntention: 'oas > dashboard > local > form start intention',
            accountEditProfileDeleted: 'oas > account edit > local > profile deleted',
            vehicleSelection: 'oas > vehicle selection > local > vehicle selected',
            serviceSelecltionFormStart: 'oas > service selection > local > form started',
            summaryFormFinishedIntention: 'oas > appointment summary > local > form finished intention',
            successFormFinishedIntention: 'oas > appointment success > local > form finished',
        },
        type: {
            localForm: 'local form',
            sucess: 'success',
            ux: 'ux',
            dealer: 'dealer',
        },
        eventAction: {
            internalClick: 'intenal click',
            dropDownMenu: 'drop-down menu',
            eventActionType: ['call', 'close content'],
        },
        eventPoints: {
            na: 'na',
        },
        eventName: {
            form: 'form',
            error: 'error',
            interaction: 'interaction',
            search: 'search',
        },
        effect: {
            formStartIntention: 'local > form start intention',
            loginDenied: 'local > login denied',
            passwordEdited: 'local > password edited',
            profileDetailEdited: 'local > profile detail edited',
            mailingEdited: 'local > mailing edited',
            profileDeleted: 'local > profile deleted',
            vehicleAdded: 'local > vehicle added',
            addressSelected: 'local > search by address',
            outletSelected: 'local > search by service center',
            serviceCenterChanged: 'local > service center successfully changed',
            guestVehicle: 'local > vehicle plate',
        },
        cause: {
            default: 'default',
            invalidCredentials: 'user typed invalid Credentials',
        },

        subCategory1: {
            code503: '503',
            registrationOas: 'registration of online service appointment',
            edit_account: {
                1: 'edit account 1/3',
                2: 'edit account 2/3',
                3: 'edit account 3/3',
            },
            rfsOfOas: 'RFS of online service appointment',
            confirmationRegistration: 'online service appointment > confirmation registration',
            passwordConfirmation: 'online service appointment > password confirmation',
        },
    },
    timeStamp: Date.now().toString(),
    component: {
        componentId: {
            localStandaloneOas: 'local > standalone > online service appointment',
        },
    },
    page: {
        pageID: {
            na: 'na',
            lOasLogin: 'local > online service appointment > login',
            lOasRegistration: 'local > online service appointment > registration',
            lOasRecoverPwd: 'local > online service appointment > recover password',
            lOasRecoverPwdSent: 'local > online service appointment > recover password sent',
            lOasPwdActivation: 'local > online service appointment > password activation',
            lOasHistoricalAppointment: 'local > online service appointment > historical appointment',
            lOasRegistrationComplete: 'local > online service appointment > registration complete',
            lOasLogout: 'local > online service appointment > logout',
            lOasCookies: 'local > online service appointment > cookies',
            lOasFooter: 'local > online service appointment > footer',
            lOasAdvantagesPopup: 'local > online service appointment > advantages of complete registration',
            lOasEditAccount: 'local > online service appointment > account edit',
            lOasAddVehicle: 'local > online service appointment > add vehicle',
            lOasTeleservicePopUp: 'local > online service appointment > teleservice pop up',
            lOasYourVehicles: 'local > online service appointment > your vehicles',
            lOasServicesSelection: 'local > online service appointment > service selection',
            lOasServiceCenterSelection: 'local > online service appointment > service center selection',
            lOasMobilityServiceSelection: 'local > online service appointment > mobility service selection',
            lOasAppointmentSummary: 'local > standalone > online service appointment',
            lOasAppointmentSuccess: 'local > online service appointment > appointment success',
            lOasAppointmentSchedule: 'local > online service appointment > schedule appointment',
            lOasDashboard: 'local > online service appointment > dashboard',
            lOasGuestVehicle:'local > online service appointment > guest vehicle license plate',
            lOasGuestVehicleLicensePlate: 'local > online service appointment > guest vehicle license plate',
            lOasGuestVehicleLicenseeModel: 'local > online service appointment > guest vehicle model',
            LoasPickUpDateAndDelivery:'local > online service appointment > pick-up date and delivery',
            lOasServiceSelectionModified:'local > online service appointment > service selection modified'

        },
        variant: {
            real: 'real page',
            virtual: 'virtual page',
        },
    },
    profile: {
        returningStatus: {
            new: 'new',
            returning: 'returning',
        },
        loggedInStatus: {
            loggedIn: 'logged-in',
            notLoggedIn: 'not logged-in',
        },
    },
    dealer: {
        selectionState: {
            selected: 'selected',
            unselected: 'unselected',
        },
    },
    product: {
        referenceName: {
            localProduct: 'oas > local > product',
        },
        productType: {
            vehicle: 'vehicle',
            service: 'service',
        },
        subCategory: {
            vehicleInspection: 'vehicle inspection',
            vehicleDiagnostic: 'vehicle diagnostic',
            tires: 'tires',
            vehicleBody: 'vehicle body',
            otherServices: 'other services',
        },
        vehicleSelection: {
            carSaved: 'car saved',
            selected: 'selected',
            notSelected: 'not selected',
        },
    },
    activityMap: {
        region: {
            oas: 'online service appointment',
        },
        link: {
            resetLinkSentSuccess: 'local > password reset link sent successfully',
            pwdActivated: 'local > password activated',
            registrationComplete: 'local > registration completed',
            confirmLogout: 'local > logout confirm',
            cancelLogout: 'local > logout cancel',
        },
    },
};
