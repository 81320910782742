import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false,
})
export class FilterPipe<T> implements PipeTransform {
    transform(items: T[], predicateFn: (value: T) => boolean): T[] {
        if (!items) {
            return [];
        }
        if (!predicateFn) {
            return items;
        }

        return items.filter(predicateFn);
    }
}
