<swal text="this won't be displayed" icon="error">
    <div *swalPortal="swalTargets.title">
        <ng-container *ngIf="isDisplayableError(); else noDisplayableErrorTitleTmpl">
            <span *ngIf="!isDCOMInternalError() && title">{{ title }}</span>
            <span *ngIf="!isDCOMInternalError() && !title" i18n="@@defaultErrorTitle">An error has occurred</span>
            <span *ngIf="isDCOMInternalError()" i18n="@@errorSearchInOutletTitle">Error in the dcom system</span>
        </ng-container>
    </div>
    <div *swalPortal>
        <ng-container *ngIf="isDisplayableError(); else noDisplayableErrorContentTmpl">
            <span *ngIf="errorResponse.error.message; else multipleError">{{ errorResponse.error?.message }}</span>
            <ng-template #multipleError>
                <span *ngFor="let error of errorResponse.error.errors">{{ error?.message }}</span>
            </ng-template>
        </ng-container>
    </div>
</swal>

<ng-template #noDisplayableErrorTitleTmpl>
    <ng-container *ngTemplateOutlet="noErrorResponseTitleTmpl"></ng-container>
</ng-template>

<ng-template #noErrorResponseTitleTmpl>
    <span *ngIf="title">{{ title }}</span>
    <span *ngIf="!title" i18n="@@defaultErrorTitle">An error has occurred</span>
</ng-template>


<ng-template #noDisplayableErrorContentTmpl>
    <ng-container *ngTemplateOutlet="noErrorResponseContentTmpl"></ng-container>
</ng-template>


<ng-template #noErrorResponseContentTmpl>
    <span *ngIf="text">{{ text }}</span>
    <ng-container *ngIf="html">{{ html }}</ng-container>
    <ng-container *ngIf="contentTmpl">
        <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
    </ng-container>
    <span *ngIf="!text && !html && !contentTmpl" i18n="@@defaultAlertErrorText">Something went wrong</span>
</ng-template>